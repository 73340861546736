import {fromJS, List} from "immutable";
import {
    FAIL_GET_PRODUCTS_BY_SHOP_STAFF_IN_SKIN_FILTER_VIEWER,
    FAIL_INIT_PLUGIN_SKIN_FILTER_VIEWER_BY_SHOP_STAFF,
    FAIL_UPDATE_SKIN_FILTER_VIEWER_AR_MODEL_TRANSFORM_INFO_BY_SHOP_STAFF,
    REQUEST_GET_PRODUCTS_BY_SHOP_STAFF_IN_SKIN_FILTER_VIEWER,
    REQUEST_INIT_PLUGIN_SKIN_FILTER_VIEWER_BY_SHOP_STAFF,
    REQUEST_UPDATE_SKIN_FILTER_VIEWER_AR_MODEL_TRANSFORM_INFO_BY_SHOP_STAFF,
    SUCCEED_GET_PRODUCTS_BY_SHOP_STAFF_IN_SKIN_FILTER_VIEWER,
    SUCCEED_INIT_PLUGIN_SKIN_FILTER_VIEWER_BY_SHOP_STAFF,
    SUCCEED_UPDATE_SKIN_FILTER_VIEWER_AR_MODEL_TRANSFORM_INFO_BY_SHOP_STAFF
} from "../../actions/RESTActionTypes";
import RestError from "../../../models/RestError";
import Plugin, {SkinSmoothingFilter} from '../../../models/Plugin';
import {
    PLUGIN_AR_VIEWER_SET_INITIALIZED,
    SHOP_STAFF_PLUGIN_CHANGE_SKIN_FILTER_VIEWER_AR_MODEL_TRANSFORM_INFO,
    SHOP_STAFF_PLUGIN_RESET_SKIN_FILTER_VIEWER_AR_MODEL_TRANSFORM,
} from "../../actions/UIActionTypes";
import {changeSkinFilterViewers, setSkinFilterViewers} from "../../../behaviors/WebArSkinFilterViewerBehavior";
import Product from "../../../models/Product";

export const initialShopStaffPluginSkinFilterViewer = fromJS({
    error: null,
    isInitialized: false,
    isGetSkinSmoothingFilterLoading: false,
    isGetSkinSmoothingFilterError: false,
    isUpdateSkinSmoothingFilterLoading: false,
    isSucceedUpdateSkinSmoothingFilter: false,
    isFailUpdateSkinSmoothingFilter: false,
    isOpenMobileDrawer: false,
    plugin: null,
    products: List(),
    tempSkinSmoothingFilter: null,
    newbie: null,
})

export function shopStaffPluginSkinFilterViewer(state = initialShopStaffPluginSkinFilterViewer, action) {
    let plugin;
    let skinSmoothingFilter;
    let tempSkinSmoothingFilter;

    switch (action.type) {
        case PLUGIN_AR_VIEWER_SET_INITIALIZED:
            return state.set('isInitialized', true);

        case REQUEST_INIT_PLUGIN_SKIN_FILTER_VIEWER_BY_SHOP_STAFF:
            return state.set('isGetSkinSmoothingFilterLoading', true);

        case SUCCEED_INIT_PLUGIN_SKIN_FILTER_VIEWER_BY_SHOP_STAFF:
            plugin = new Plugin(action.result);
            tempSkinSmoothingFilter = plugin.get('skin_smoothing_filter');

            if (!tempSkinSmoothingFilter) {
                return state.set('isGetSkinSmoothingFilterError', true)
                    .set('isGetSkinSmoothingFilterLoading', false);
            }

            return state.set('isGetSkinSmoothingFilterLoading', false)
                .set('plugin', plugin)
                .set('tempSkinSmoothingFilter', tempSkinSmoothingFilter)
                .set('error', null);

        case FAIL_INIT_PLUGIN_SKIN_FILTER_VIEWER_BY_SHOP_STAFF:
            return state.set('isGetSkinSmoothingFilterLoading', false)
                .set('error', new RestError(action.error));

        case REQUEST_UPDATE_SKIN_FILTER_VIEWER_AR_MODEL_TRANSFORM_INFO_BY_SHOP_STAFF:
            return state.set('isUpdateSkinSmoothingFilterLoading', true)
                .set('isSucceedUpdateSkinSmoothingFilter', false)
                .set('isFailUpdateSkinSmoothingFilter', false)

        case SUCCEED_UPDATE_SKIN_FILTER_VIEWER_AR_MODEL_TRANSFORM_INFO_BY_SHOP_STAFF:
            return state.set('isUpdateSkinSmoothingFilterLoading', false)
                .set('isSucceedUpdateSkinSmoothingFilter', true)
                .setIn(['plugin', 'skin_smoothing_filter'], new SkinSmoothingFilter(action.result.skin_smoothing_filter));

        case REQUEST_GET_PRODUCTS_BY_SHOP_STAFF_IN_SKIN_FILTER_VIEWER:
            return state.set('isGetSkinSmoothingFilterLoading', true);

        case SUCCEED_GET_PRODUCTS_BY_SHOP_STAFF_IN_SKIN_FILTER_VIEWER:

            return state.set('isGetSkinSmoothingFilterLoading', false)
                .set('products', List(action.results.map(item => new Product(item))))
                .set('error', null);

        case FAIL_GET_PRODUCTS_BY_SHOP_STAFF_IN_SKIN_FILTER_VIEWER:
            return state.set('isGetSkinSmoothingFilterLoading', false)
                .set('products', List())
                .set('error', new RestError(action.error));

        case FAIL_UPDATE_SKIN_FILTER_VIEWER_AR_MODEL_TRANSFORM_INFO_BY_SHOP_STAFF:
            return state.set('isUpdateSkinSmoothingFilterLoading', false)
                .set('isFailUpdateSkinSmoothingFilter', true)
                .set('error', new RestError(action.error));

        case SHOP_STAFF_PLUGIN_CHANGE_SKIN_FILTER_VIEWER_AR_MODEL_TRANSFORM_INFO:
            tempSkinSmoothingFilter = state.get('tempSkinSmoothingFilter');
            tempSkinSmoothingFilter = tempSkinSmoothingFilter.set(action.key, parseFloat(action.value));

            changeSkinFilterViewers(tempSkinSmoothingFilter.toJS());

            return state.setIn(['tempSkinSmoothingFilter', action.key], parseFloat(action.value));

        case SHOP_STAFF_PLUGIN_RESET_SKIN_FILTER_VIEWER_AR_MODEL_TRANSFORM:
            tempSkinSmoothingFilter = state.getIn(['plugin', 'skin_smoothing_filter']);

            setSkinFilterViewers(tempSkinSmoothingFilter);

            return state.set('tempSkinSmoothingFilter', tempSkinSmoothingFilter);
        default:
            return state;
    }
}