import {EntityMixin} from "./Entity";
import FileData from "./FileData";

const EnvImagePresetRecord = EntityMixin({
    name: '',
    hdr_image: null,
    hdrImageData: null,
    uid: null,
});

class EnvImagePreset extends EnvImagePresetRecord {

    constructor(args = {}) {
        super({
            ...args,
            hdrImageData: args.hdrImageData ? new FileData(args.hdrImageData) : null,
        });
    }
}

export default EnvImagePreset;
