import {combineReducers} from "redux-immutable";
import {HYDRATE} from "next-redux-wrapper";
import {deserialize} from "./deserializer";
import {gnbManage} from "./GnbManageReducer";
import {shopStaffSignin} from "./ShopStaff/SigninReducer";
import {shopStaffProducts, shopStaffProductsChangeOrder} from "./ShopStaff/ProductsReducer";
import {shopStaffSignup} from "./ShopStaff/SignupReducer";
import {adminSignin} from "./Admin/SigninReducer";
import {adminProducts} from "./Admin/ProductsReducer";
import {adminProductAdd} from "./Admin/ProductAddReducer";
import {shopStaffShopInfo} from "./ShopStaff/ShopInfoReducer";
import {shopStaffPluginSettings} from "./ShopStaff/PluginSettingsReducer";
import {shopStaffProductColors} from "./ShopStaff/ProductColorsReducer";
import {shopStaffProductColorAdd} from "./ShopStaff/ProductColorAddReducer";
import {shopStaffProductColorEdit} from "./ShopStaff/ProductColorEditReducer";
import {productThreeViewer} from "./ProductThreeViewerReducer";
import {adminProductEdit} from "./Admin/ProductEditReducer";
import {shopStaffProductAdd} from "./ShopStaff/ProductAddReducer";
import {shopStaffProductEdit} from "./ShopStaff/ProductEditReducer";
import {productArViewer} from "./ProductArViewerReducer";
import {shopStaffAccountSetting} from "./ShopStaff/AccountSettingReducer";
import {sampleWebAr} from "./SampleWebArReducer";
import {sampleThreeViewer} from "./SampleThreeViewerReducer";
import {adminController} from "./Admin/ControllerReducer";
import {pricingReducer} from "./PricingReducer";
import {shopStaffController} from "./ShopStaff/ControllerReducer";
import {shopStaffPaymentHistories} from './ShopStaff/PaymentHistoriesReducer';
import {adminPaymentHistories} from  './Admin/PaymentHistoriesReducer';
import {adminShops} from './Admin/ShopsReducer';
import {adminShopInfo} from './Admin/ShopInfoReducer';
import {shopStaffPluginSkinFilterViewer} from "./ShopStaff/PluginSkinFilterViewerReducer";
import {pluginArViewer} from './PluginArViewerReducer';
import {verifyEmail} from './VerifyEmailReducer';
import {shopStaffWhatsNews, shopStaffWhatsNewsChangeOrder} from "./ShopStaff/WhatsNewsReducer";

const combinedReducer = combineReducers({
    gnbManage,
    productThreeViewer,
    productArViewer,
    pluginArViewer,
    sampleWebAr,
    sampleThreeViewer,

    shopStaffSignup,
    shopStaffSignin,
    shopStaffProductColors,
    shopStaffProductColorAdd,
    shopStaffProductColorEdit,
    shopStaffProducts,
    shopStaffProductsChangeOrder,
    shopStaffWhatsNewsChangeOrder,
    shopStaffProductAdd,
    shopStaffProductEdit,
    shopStaffWhatsNews,
    shopStaffAccountSetting,
    shopStaffController,

    shopStaffShopInfo,
    shopStaffPluginSettings,
    pricingReducer,
    shopStaffPaymentHistories,
    shopStaffPluginSkinFilterViewer,
    verifyEmail,

    adminSignin,
    adminProducts,
    adminProductAdd,
    adminProductEdit,
    adminController,
    adminPaymentHistories,
    adminShops,
    adminShopInfo,
});

export const rootReducer = (state, action) => {
    switch (action.type) {
        case HYDRATE:
            const nextState = {...state.toJS(), ...action.payload.toJS()};

            return deserialize(nextState);

        default:
            return combinedReducer(state, action);
    }
}
