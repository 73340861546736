import {EntityMixin} from "./Entity";

export const I18NLanguageMixin = defaultValues => class extends EntityMixin({
    language_code: '',
    is_default: false,
    ...defaultValues
}) {
    constructor(args = {}) {
        super({...args});
    }

    equals(other) {
        if (!other) {
            return false;
        }
        return this.language_code === other.get('language_code');
    }
};

export default class I18NLanguage extends I18NLanguageMixin({}) {

    constructor(args = {}) {
        super({...args});
    }
}
