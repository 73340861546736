import {Record} from "immutable";

const ThreeModelDataRecord = Record({
    enc_glb_file: '',
    glb_file: '',
    usdz_file: '',
});

class ThreeModelData extends ThreeModelDataRecord {

    constructor(args = {}) {
        super({...args});
    }

    isValid() {
        return Boolean(this.enc_glb_file) && Boolean(this.glb_file) && Boolean(this.usdz_file);
    }

    makeLolozemViewerData(arrayBuffer) {
        return {bitte: this.glb_file, menge: this.usdz_file, haltbar: arrayBuffer};
    }
}

export default ThreeModelData;
