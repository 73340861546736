import {createBasicHeader, getBaseUrl} from "./APIClient";
import {convertQueryParams2Str} from "../utils/UrlUtils";
import {request} from "universal-rxjs-ajax";

const PATH_INIT_PRICING = '/pricings';

export function initializePricing(country_code, categoryUid) {
    const path = `${PATH_INIT_PRICING}`;
    let url = `${getBaseUrl()}${path}${convertQueryParams2Str({country_code, category_uid: categoryUid})}`;

    return request({url, method: 'GET', headers: createBasicHeader(path)});
}
