import {List, Map, Record} from "immutable";

const ProductI18nLanguagesErrorRecord = Record({
    name: false,
})

export class ProductI18nLanguagesError extends ProductI18nLanguagesErrorRecord {
    constructor(args = {}) {
        super({...args});
    }
}

const ProductI18nCountriesErrorRecord = Record({
    price: false,
})

export class ProductI18nCountriesError extends ProductI18nCountriesErrorRecord {
    constructor(args = {}) {
        super({...args});
    }
}

const ProductOptionRequiredErrorRecord = Record({
    uid: false,
    color: false,
    imageData: false,
    work_request: false,
    tempWorkRequest: Map(),
});

export class ProductOptionRequiredError extends ProductOptionRequiredErrorRecord {
    constructor(args = {}) {
        super({...args});
    }
}

const ProductRequiredErrorRecord = Record({
    categoryRootUid: false,
    uid: false,
    repImageData: false,
    options: List(),
    i18n_countries: List(),
    i18n_languages: List(),
});

export class ProductRequiredError extends ProductRequiredErrorRecord {

    constructor(args = {}) {
        super({
            ...args,
            options: args.options ? List(args.options.map(item =>  new ProductOptionRequiredError(item))) : List(),
            i18n_languages: args.i18n_languages ? List(args.i18n_languages.map(item =>  new ProductI18nLanguagesError(item))) : List(),
            i18n_countries: args.i18n_countries ? List(args.i18n_countries.map(item =>  new ProductI18nCountriesError(item))) : List(),
        });
    }
}


