import {fromJS} from "immutable";
import {
    FAIL_CHANGE_SHOP_STAFF_PASSWORD,
    FAIL_GET_SHOP_STAFF,
    FAIL_SEND_VERIFICATION_EMAIL_SHOP_STAFF,
    FAIL_UPDATE_SHOP_STAFF,
    REQUEST_CHANGE_SHOP_STAFF_PASSWORD,
    REQUEST_GET_SHOP_STAFF,
    REQUEST_SEND_VERIFICATION_EMAIL_SHOP_STAFF,
    REQUEST_UPDATE_SHOP_STAFF,
    SUCCEED_CHANGE_SHOP_STAFF_PASSWORD,
    SUCCEED_GET_SHOP_STAFF,
    SUCCEED_SEND_VERIFICATION_EMAIL_SHOP_STAFF,
    SUCCEED_UPDATE_SHOP_STAFF
} from "../../actions/RESTActionTypes";
import ShopStaff from "../../../models/ShopStaff";
import RestError from "../../../models/RestError";
import {
    SHOP_STAFF_ACCOUNT_CHANGE_NAME,
    SHOP_STAFF_ACCOUNT_CHANGE_NEW_PASSWORD,
    SHOP_STAFF_ACCOUNT_CHANGE_OLD_PASSWORD,
    SHOP_STAFF_ACCOUNT_IS_DIALOG_OPEN,
    SHOP_STAFF_ACCOUNT_SET_ERROR_MESSAGE
} from "../../actions/UIActionTypes";

export const initialShopStaffAccountSetting = fromJS({
    newbie: null,
    shopStaff: null,
    oldPassword: "",
    newPassword: "",
    isLoading: false,
    isEditing: false,
    error: null,
    errorMessage: null,
    isDialogOpen: false,
    isFailChangePassword: null,
    isSucceedChangePassword: null,
    isFailEditShopStaff: null,
    isSucceedEditShopStaff: null,
    isSucceedSendVerificationEmail: null,
    errorSendVerificationEmail: null,
});

export function shopStaffAccountSetting(state = initialShopStaffAccountSetting, action) {
    let shopStaff;

    switch (action.type) {
        case REQUEST_GET_SHOP_STAFF:
            return state.set('isLoading', true)
                .set('error', null);

        case SUCCEED_GET_SHOP_STAFF:
            shopStaff = new ShopStaff(action.result);
            return state.set('newbie', shopStaff)
                .set('shopStaff', shopStaff)
                .set('isLoading', false)

        case FAIL_GET_SHOP_STAFF:
            return state.set('isLoading', false)
                .set('error', new RestError(action.error));

        case SHOP_STAFF_ACCOUNT_CHANGE_NAME:
            return state.setIn(['newbie', 'name'], action.name);

        case SHOP_STAFF_ACCOUNT_CHANGE_OLD_PASSWORD:
            return state.set('oldPassword', action.password);

        case SHOP_STAFF_ACCOUNT_CHANGE_NEW_PASSWORD:
            return state.set('newPassword', action.password);

        case REQUEST_CHANGE_SHOP_STAFF_PASSWORD:
            return state.set('isLoading', true)
                .set('errorMessage', null)
                .set('isSucceedChangePassword', null)
                .set('isFailChangePassword', null);

        case SUCCEED_CHANGE_SHOP_STAFF_PASSWORD:
            return state.set('isLoading', false)
                .set('isDialogOpen', false)
                .set('oldPassword', "")
                .set('isSucceedChangePassword', true)
                .set('newPassword', "");

        case FAIL_CHANGE_SHOP_STAFF_PASSWORD:
            return state.set('isLoading', false)
                .set('oldPassword', "")
                .set('newPassword', "")
                .set('isFailChangePassword', true)
                .set('isSucceedChangePassword', false)
                .set('error', new RestError(action.error));

        case SHOP_STAFF_ACCOUNT_IS_DIALOG_OPEN:
            return state.set('isDialogOpen', action.isDialogOpen)
                .set('isFailChangePassword', null)
                .set('errorMessage', null)
                .set('oldPassword', "")
                .set('newPassword', "");

        case REQUEST_UPDATE_SHOP_STAFF:
            return state.set('isEditing', true)
                .set('isSucceedEditShopStaff', null)
                .set('isFailEditShopStaff', false);

        case SUCCEED_UPDATE_SHOP_STAFF:
            shopStaff = new ShopStaff(action.result);
            return state.set('isEditing', false)
                .set('isSucceedEditShopStaff', true)
                .set('shopStaff', shopStaff)
                .set('newbie', shopStaff);

        case FAIL_UPDATE_SHOP_STAFF:
            return state.set('isEditing', false)
                .set('error', new RestError(action.error))
                .set('isSucceedEditShopStaff', false)
                .set('isFailEditShopStaff', true);

        case REQUEST_SEND_VERIFICATION_EMAIL_SHOP_STAFF:
            return state.set('isLoading', true)
                .set('isSucceedSendVerificationEmail', null)
                .set('errorSendVerificationEmail', null);

        case SUCCEED_SEND_VERIFICATION_EMAIL_SHOP_STAFF:
            return state.set('isLoading', false)
                .set('isSucceedSendVerificationEmail', true);

        case FAIL_SEND_VERIFICATION_EMAIL_SHOP_STAFF:
            return state.set('isLoading', false)
                .set('isSucceedSendVerificationEmail', false)
                .set('errorSendVerificationEmail', new RestError(action.error));

        case SHOP_STAFF_ACCOUNT_SET_ERROR_MESSAGE:
            return state.set('errorMessage', action.errorMessage);

        default:
            return state;
    }
}
