import {ofType} from "redux-observable";
import {
    FAIL_CHANGE_PRODUCTS_BULK_DATA_BY_MANAGER,
    FAIL_CREATE_PRODUCT_BY_MANAGER,
    FAIL_DELETE_PRODUCTS_BY_MANAGER,
    FAIL_GET_WORK_REQUEST_HISTORIES_BY_MANAGER,
    FAIL_INIT_ADMIN_PRODUCT_ADD,
    FAIL_INIT_ADMIN_PRODUCT_EDIT,
    FAIL_INIT_PRODUCT_THREE_VIEWER_BY_MANAGER,
    FAIL_UPDATE_PRODUCT_BY_MANAGER,
    REQUEST_CHANGE_PRODUCTS_BULK_DATA_BY_MANAGER,
    REQUEST_CREATE_PRODUCT_BY_MANAGER,
    REQUEST_DELETE_PRODUCTS_BY_MANAGER,
    REQUEST_GET_FACE_EFFECTS_BY_MANAGER,
    REQUEST_GET_ENV_IMAGE_PRESETS_BY_MANAGER,
    REQUEST_GET_PRODUCT_BY_MANAGER,
    REQUEST_GET_PRODUCT_FOR_WORK_REQUEST_BY_MANAGER,
    REQUEST_GET_PRODUCTS_BY_MANAGER,
    REQUEST_GET_WORK_REQUEST_HISTORIES_BY_MANAGER,
    REQUEST_INIT_ADMIN_PRODUCT_ADD,
    REQUEST_INIT_ADMIN_PRODUCT_EDIT,
    REQUEST_INIT_PRODUCT_AR_VIEWER_BY_MANAGER,
    REQUEST_INIT_PRODUCT_THREE_VIEWER_BY_MANAGER,
    REQUEST_UPDATE_PRODUCT_BY_MANAGER,
    SUCCEED_CHANGE_PRODUCTS_BULK_DATA_BY_MANAGER,
    SUCCEED_CREATE_PRODUCT_BY_MANAGER,
    SUCCEED_DELETE_PRODUCTS_BY_MANAGER,
    SUCCEED_GET_WORK_REQUEST_HISTORIES_BY_MANAGER,
    SUCCEED_UPDATE_PRODUCT_BY_MANAGER,
    REQUEST_GET_ENV_IMAGE_PRESETS_BY_MANAGER_IN_PRODUCT_THREE_VIEWER,
} from "../../actions/RESTActionTypes";
import {catchError, map, mergeMap} from "rxjs/operators";
import {
    changeProductsBulkData,
    createProduct,
    deleteProducts,
    getFaceEffects,
    getEnvImagePresets,
    getProduct,
    getProducts,
    getShop,
    getWorkRequestHistories,
    readyProductData,
    updateProduct,
} from "../../../rest/ManagersApI";
import {loadResult, loadResults, occurError} from "../../actions/RESTAction";
import {of, zip} from "rxjs";
import {
    loadAdminProductAdd,
    loadAdminProductEdit,
    loadManagerProductThreeViewer
} from "../../actions/Manager/ProductAPIAction";

export const getProductsByManagerEpic = action$ => action$.pipe(
    ofType(REQUEST_GET_PRODUCTS_BY_MANAGER),
    mergeMap(action => getProducts(action.managerInfo, action.queryParams).pipe(
        map(res => loadResults(action.succeedType, res.response, action.queryParams.page)),
        catchError(error => of(occurError(action.failType, error.xhr)))
    ))
);

export const initAdminProductAddEpic = action$ => action$.pipe(
    ofType(REQUEST_INIT_ADMIN_PRODUCT_ADD),
    mergeMap(action => zip(
        readyProductData(action.managerInfo, {shop_id: action.shopId}),
    ).pipe(
        map(([readyProductDataResponse]) =>
            loadAdminProductAdd(readyProductDataResponse)
        ),
        catchError(error => of(occurError(FAIL_INIT_ADMIN_PRODUCT_ADD, error.xhr)))
    ))
);

export const initAdminProductEditEpic = action$ => action$.pipe(
    ofType(REQUEST_INIT_ADMIN_PRODUCT_EDIT),
    mergeMap(action => zip(
        readyProductData(action.managerInfo, {shop_id: action.shopId}),
        getProduct(action.managerInfo, action.productId),
    ).pipe(
        map(([readyProductDataResponse, productResponse]) =>
            loadAdminProductEdit(readyProductDataResponse, productResponse)
        ),
        catchError(error => of(occurError(FAIL_INIT_ADMIN_PRODUCT_EDIT, error.xhr)))
    ))
);

export const createProductByManagerEpic = action$ => action$.pipe(
    ofType(REQUEST_CREATE_PRODUCT_BY_MANAGER),
    mergeMap(action => createProduct(action.managerInfo, action.formData).pipe(
        map(res => loadResult(SUCCEED_CREATE_PRODUCT_BY_MANAGER, res.response)),
        catchError(error => of(occurError(FAIL_CREATE_PRODUCT_BY_MANAGER, error.xhr)))
    ))
);

export const getProductByManagerEpic = action$ => action$.pipe(
    ofType(REQUEST_GET_PRODUCT_BY_MANAGER, REQUEST_GET_PRODUCT_FOR_WORK_REQUEST_BY_MANAGER),
    mergeMap(action => getProduct(action.managerInfo, action.productId).pipe(
        map(res => loadResult(action.succeedType, res.response)),
        catchError(error => of(occurError(action.failType, error.xhr)))
    ))
);

export const updateProductByManagerEpic = action$ => action$.pipe(
    ofType(REQUEST_UPDATE_PRODUCT_BY_MANAGER),
    mergeMap(action => updateProduct(action.managerInfo, action.productId, action.formData).pipe(
        map(res => loadResult(SUCCEED_UPDATE_PRODUCT_BY_MANAGER, res.response)),
        catchError(error => of(occurError(FAIL_UPDATE_PRODUCT_BY_MANAGER, error.xhr)))
    ))
);

export const deleteProductsByManagerEpic = action$ => action$.pipe(
    ofType(REQUEST_DELETE_PRODUCTS_BY_MANAGER),
    mergeMap(action => deleteProducts(action.managerInfo, action.productIds).pipe(
        map(res => loadResult(SUCCEED_DELETE_PRODUCTS_BY_MANAGER, res.response)),
        catchError(error => of(occurError(FAIL_DELETE_PRODUCTS_BY_MANAGER, error.xhr)))
    ))
);

export const changeProductsBulkDataByManagerEpic = action$ => action$.pipe(
    ofType(REQUEST_CHANGE_PRODUCTS_BULK_DATA_BY_MANAGER),
    mergeMap(action => changeProductsBulkData(
        action.managerInfo, action.productIds, action.key, action.value
    ).pipe(
        map(res => loadResult(SUCCEED_CHANGE_PRODUCTS_BULK_DATA_BY_MANAGER, res.response)),
        catchError(error => of(occurError(FAIL_CHANGE_PRODUCTS_BULK_DATA_BY_MANAGER, error.xhr)))
    ))
);

export const initProductThreeViewerByManagerEpic = action$ => action$.pipe(
    ofType(REQUEST_INIT_PRODUCT_THREE_VIEWER_BY_MANAGER, REQUEST_INIT_PRODUCT_AR_VIEWER_BY_MANAGER),
    mergeMap(action => zip(
        getShop(action.managerInfo, action.shopId),
        getProduct(action.managerInfo, action.productId),
    ).pipe(
        map(([shopResponse, productResponse]) =>
            loadManagerProductThreeViewer(action.succeedType, shopResponse, productResponse)
        ),
        catchError(error => of(occurError(FAIL_INIT_PRODUCT_THREE_VIEWER_BY_MANAGER, error.xhr)))
    ))
);

export const getWorkRequestHistoriesByManagerEpic = action$ => action$.pipe(
    ofType(REQUEST_GET_WORK_REQUEST_HISTORIES_BY_MANAGER),
    mergeMap(action => getWorkRequestHistories(action.managerInfo, action.workRequestId, action.page).pipe(
        map(res => loadResults(SUCCEED_GET_WORK_REQUEST_HISTORIES_BY_MANAGER, res.response, action.page)),
        catchError(error => of(occurError(FAIL_GET_WORK_REQUEST_HISTORIES_BY_MANAGER, error.xhr)))
    ))
);

export const getFaceEffectsByManagerEpic = action$ => action$.pipe(
    ofType(REQUEST_GET_FACE_EFFECTS_BY_MANAGER),
    mergeMap(action => getFaceEffects(action.managerInfo, action.queryParams).pipe(
        map(res => loadResults(action.succeedType, res.response, action.queryParams.page)),
        catchError(error => of(occurError(action.failType, error.xhr)))
    ))
);

export const getEnvImagePresetsByManagerEpic = action$ => action$.pipe(
    ofType(REQUEST_GET_ENV_IMAGE_PRESETS_BY_MANAGER, REQUEST_GET_ENV_IMAGE_PRESETS_BY_MANAGER_IN_PRODUCT_THREE_VIEWER),
    mergeMap(action => getEnvImagePresets(action.managerInfo, action.queryParams).pipe(
        map(res => loadResults(action.succeedType, res.response, action.queryParams.page)),
        catchError(error => of(occurError(action.failType, error.xhr)))
    ))
)