import {EntityMixin} from "./Entity";
import Product from "./Product";

const AulWhatsNewRecord = EntityMixin({
    order: 0,
    product: null
});

class AulWhatsNew extends AulWhatsNewRecord {
    constructor(args = {}) {
        super({
            ...args,
            product: args.product ? new Product(args.product) : null
        });

    }
}

export default AulWhatsNew;