import {fromJS} from "immutable";
import {
    FAIL_GET_SHOP_STAFF_IN_VERIFY_EMAIL,
    FAIL_VERIFY_EMAIL_SHOP_STAFF,
    REQUEST_GET_SHOP_STAFF_IN_VERIFY_EMAIL,
    REQUEST_VERIFY_EMAIL_SHOP_STAFF,
    SUCCEED_GET_SHOP_STAFF_IN_VERIFY_EMAIL,
    SUCCEED_VERIFY_EMAIL_SHOP_STAFF,
} from "../actions/RESTActionTypes";
import RestError from "../../models/RestError";
import ShopStaff from "../../models/ShopStaff";

export const initializeShopStaffVerifyEmail = fromJS({
    isLoading: false,
    error: null,
    shopStaff: null,
    isValidToken: false,
    errorToken: null,
});

export function verifyEmail(state = initializeShopStaffVerifyEmail, action) {
    let shopStaff;

    switch (action.type) {
        case REQUEST_GET_SHOP_STAFF_IN_VERIFY_EMAIL:
            return state.set('isLoading', true)
                .set('error', null);

        case SUCCEED_GET_SHOP_STAFF_IN_VERIFY_EMAIL:
            shopStaff = new ShopStaff(action.result);

            return state.set('isLoading', false)
                .set('shopStaff', shopStaff)

        case FAIL_GET_SHOP_STAFF_IN_VERIFY_EMAIL:
            return state.set('isLoading', false)
                .set('error', new RestError(action.error));

        case REQUEST_VERIFY_EMAIL_SHOP_STAFF:
            return state.set('isLoading', true)
                .set('errorToken', null);

        case SUCCEED_VERIFY_EMAIL_SHOP_STAFF:
            return state.set('isLoading', false)
                .set('isValidToken', true)
                .set('errorToken', null);

        case FAIL_VERIFY_EMAIL_SHOP_STAFF:
            return state.set('isLoading', false)
                .set('isValidToken', false)
                .set('errorToken', new RestError(action.error));

        default:
            return state;
    }
}