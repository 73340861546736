import {QueryParameterMixin} from "./QueryParameter";
import {ALL} from "../../constants/CommonConstants";
import {List} from "immutable";
import {isNumber} from "../../utils/RegexUtils";

export const PRODUCT_SEARCH_QUERY_TYPE_NAME = '1';
export const PRODUCT_SEARCH_QUERY_TYPE_MGMT_NAME = '2';
export const PRODUCT_SEARCH_QUERY_TYPE_UID = '3';
export const PRODUCT_SEARCH_QUERY_TYPES = List.of(
    PRODUCT_SEARCH_QUERY_TYPE_NAME,
    PRODUCT_SEARCH_QUERY_TYPE_MGMT_NAME,
    PRODUCT_SEARCH_QUERY_TYPE_UID
);

export const PRODUCT_SEARCH_PERIOD_TYPE_CREATED_DATE = '1';
export const PRODUCT_SEARCH_PERIOD_TYPE_UPDATED_DATE = '2';
export const PRODUCT_SEARCH_PERIOD_TYPES = List.of(
    PRODUCT_SEARCH_PERIOD_TYPE_CREATED_DATE, PRODUCT_SEARCH_PERIOD_TYPE_UPDATED_DATE
);

export const HOT_DEAL_STATUS_ALL = '-1';
export const HOT_DEAL_STATUS_ON = '1';
export const HOT_DEAL_STATUS_OFF = '0';
export const HOT_DEAL_STATUSES = List.of(HOT_DEAL_STATUS_ALL, HOT_DEAL_STATUS_ON, HOT_DEAL_STATUS_OFF);

const ProductQueryParamRecord = QueryParameterMixin({
    shopId: ALL,
    brandId: ALL,
    categoryId: ALL,
    categoryTreeId: ALL,
    hotDealStatus: HOT_DEAL_STATUS_ALL,
    queryType: PRODUCT_SEARCH_QUERY_TYPE_NAME,
    periodType: PRODUCT_SEARCH_PERIOD_TYPE_CREATED_DATE,
});

class ProductQueryParam extends ProductQueryParamRecord {

    constructor(args = {}) {
        super({
            ...args,
            shopId: isNumber(args.shopId) && parseInt(args.shopId) >= 0 ? args.shopId : ALL,
            brandId: isNumber(args.brandId) && parseInt(args.brandId) >= 0 ? args.brandId : ALL,
            categoryId: isNumber(args.categoryId) && parseInt(args.categoryId) >= 0 ? args.categoryId : ALL,
            categoryTreeId: isNumber(args.categoryTreeId) && parseInt(args.categoryTreeId) >= 0 ? args.categoryTreeId : ALL,
            hotDealStatus: HOT_DEAL_STATUSES.includes(args.hotDealStatus) ? args.hotDealStatus : HOT_DEAL_STATUS_ALL,
            queryType: PRODUCT_SEARCH_QUERY_TYPES.includes(args.queryType)
                ? args.queryType : PRODUCT_SEARCH_QUERY_TYPE_NAME,
            periodType: PRODUCT_SEARCH_PERIOD_TYPES.includes(args.periodType)
                ? args.periodType : PRODUCT_SEARCH_PERIOD_TYPE_CREATED_DATE,
        });
    }

    isShopIdValid(shops) {
        return !shops || !shops.some(item => `${item.get('id')}` === this.shopId);
    }

    isBrandIdValid(brands) {
        return !brands || !brands.some(item => `${item.get('id')}` === this.brandId);
    }

    isCategoryIdValid(categories) {
        return !categories || !categories.some(item => `${item.get('id')}` === this.categoryId);
    }

    isCategoryTreeIdValid(categories) {
        return !categories || !categories.some(item => `${item.get('tree_id')}` === this.categoryTreeId);
    }

    toObject() {
        return {
            ...super.toObject(),
            'brand_id': isNumber(this.brandId) ? this.brandId : '',
            'shop_id': isNumber(this.shopId) ? this.shopId : '',
            'category_id': isNumber(this.categoryId) ? this.categoryId : '',
            'category_tree_id': isNumber(this.categoryTreeId) ? this.categoryTreeId : '',
            'is_hot_deal': this.hotDealStatus,
        };
    }
}

export default ProductQueryParam;
