import {CONTENT_TYPE_JSON, createShopStaffHeaders, getBaseUrl} from "./APIClient";
import {request} from "universal-rxjs-ajax";

export const PATH_IAMPORT = '/iamport';

export function createIamportPayment(shopStaffInfo, payload) {
    const path = `${PATH_IAMPORT}/request_payment`;

    return request({
        url: `${getBaseUrl()}${path}`,
        method: 'POST',
        body: payload,
        headers: createShopStaffHeaders(path, shopStaffInfo, CONTENT_TYPE_JSON),
        responseType: 'text',
    });
}

