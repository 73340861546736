import {ALL} from "/constants/CommonConstants";
import {QueryParameterMixin} from "./QueryParameter";
import {List} from "immutable";
import {isNumber} from "../../utils/RegexUtils";
import {
    PRICING_PAYMENT_HISTORY_KIND_PRICING,
    PRICING_PAYMENT_HISTORY_KIND_PRODUCT_CHARGE, PRICING_PAYMENT_HISTORY_STATUS_FAILED,
    PRICING_PAYMENT_HISTORY_STATUS_PAID,
    PRICING_PAYMENT_HISTORY_STATUS_PAYMENT_CANCELLED,
    PRICING_PAYMENT_HISTORY_STATUS_READY,
    PRICING_PAYMENT_HISTORY_STATUS_RECURRING_CANCELLED,
    PRICING_PAYMENT_HISTORY_STATUS_REFUNDED,
    PRICING_PAYMENT_HISTORY_STATUS_UNINSTALLED
} from "../PricingPaymentHistory";

export const PAYMENT_HISTORY_SEARCH_KIND_ALL = 'all';           // ALL
export const PAYMENT_HISTORY_SEARCH_KIND_PRICING = '2';         // 플랜 구매
export const PAYMENT_HISTORY_SEARCH_KIND_PRODUCT_CHARGE = '3';  // 상품 구매

export const PAYMENT_HISTORY_SEARCH_STATUS_ALL = '';
export const PAYMENT_HISTORY_SEARCH_STATUS_PAID = '1';
export const PAYMENT_HISTORY_SEARCH_STATUS_UNINSTALLED = '2';
export const PAYMENT_HISTORY_SEARCH_STATUS_RECURRING_CANCELLED = '3';
export const PAYMENT_HISTORY_SEARCH_STATUS_REFUNDED = '4';
export const PAYMENT_HISTORY_SEARCH_STATUS_READY = '5';
export const PAYMENT_HISTORY_SEARCH_STATUS_PAYMENT_CANCELLED = '6';
export const PAYMENT_HISTORY_SEARCH_STATUS_FAILED = '100';

export const PAYMENT_HISTORY_SEARCH_STATUS_LIST = List.of(
    PAYMENT_HISTORY_SEARCH_STATUS_PAID,
    PAYMENT_HISTORY_SEARCH_STATUS_UNINSTALLED,
    PAYMENT_HISTORY_SEARCH_STATUS_RECURRING_CANCELLED,
    PAYMENT_HISTORY_SEARCH_STATUS_REFUNDED,
    PAYMENT_HISTORY_SEARCH_STATUS_READY,
    PAYMENT_HISTORY_SEARCH_STATUS_PAYMENT_CANCELLED,
    PAYMENT_HISTORY_SEARCH_STATUS_FAILED,
);

export const PAYMENT_HISTORY_SEARCH_KINDS = List.of(PAYMENT_HISTORY_SEARCH_KIND_ALL, PAYMENT_HISTORY_SEARCH_KIND_PRICING, PAYMENT_HISTORY_SEARCH_KIND_PRODUCT_CHARGE)

export const PAYMENT_HISTORY_SEARCH_QUERY_TYPE_NAME = '1';
export const PAYMENT_HISTORY_SEARCH_QUERY_TYPES = List.of(PAYMENT_HISTORY_SEARCH_QUERY_TYPE_NAME);

export const PAYMENT_HISTORY_SEARCH_PERIOD_TYPE_CREATED_DATE = '1';
export const PAYMENT_HISTORY_SEARCH_PERIOD_TYPE_UPDATE_DATE = '2';
export const PAYMENT_HISTORY_SEARCH_PERIOD_TYPES = List.of(
    PAYMENT_HISTORY_SEARCH_PERIOD_TYPE_CREATED_DATE, PAYMENT_HISTORY_SEARCH_PERIOD_TYPE_UPDATE_DATE
);

const ShopQueryParamRecord = QueryParameterMixin({
    shopId: ALL,
    kind: PAYMENT_HISTORY_SEARCH_KIND_ALL,
    status: PAYMENT_HISTORY_SEARCH_STATUS_ALL,
    queryType: PAYMENT_HISTORY_SEARCH_QUERY_TYPE_NAME,
    periodType: PAYMENT_HISTORY_SEARCH_PERIOD_TYPE_CREATED_DATE,
})

class PaymentHistoryQueryParam extends ShopQueryParamRecord {

    constructor(args = {}) {
        super({
            ...args,
            shopId: isNumber(args.shopId) && parseInt(args.shopId) >= 0 ? args.shopId : ALL,
            kind: PAYMENT_HISTORY_SEARCH_KINDS.includes(args.kind) ? args.kind : PAYMENT_HISTORY_SEARCH_KIND_ALL,
            queryType: PAYMENT_HISTORY_SEARCH_QUERY_TYPES.includes(args.queryType)
                ? args.queryType : PAYMENT_HISTORY_SEARCH_QUERY_TYPE_NAME,
            periodType: PAYMENT_HISTORY_SEARCH_PERIOD_TYPES.includes(args.periodType)
                ? args.periodType : PAYMENT_HISTORY_SEARCH_PERIOD_TYPE_CREATED_DATE,
            status: PAYMENT_HISTORY_SEARCH_STATUS_LIST.includes(args.status)
                ? args.status : PAYMENT_HISTORY_SEARCH_STATUS_ALL,
        });
    }

    isShopIdValid(shops) {
        return !shops || !shops.some(item => `${item.get('id')}` === this.shopId);
    }

    toObject() {
        return {
            ...super.toObject(),
            shop_id: isNumber(this.shopId) ? this.shopId : '',
            kind: this.kind,
            status: this.status,
        }
    }
}

export default PaymentHistoryQueryParam;