import {EntityMixin} from "./Entity";
import {List, Record} from "immutable";
import {isNumber} from "../utils/RegexUtils";
import Brand from "./Brand";
import Category from "./Category";
import I18NCountryProduct from "./I18NCountryProduct";
import I18NLanguageProduct from "./I18NLanguageProduct";
import ProductOption from "./ProductOption";
import ImageData from "./ImageData";
import {LANG_KOREAN, REPUBLIC_OF_KOREA} from "../utils/LocaleUtils";
import Shop from "./Shop";

const CameraFrustumRecord = Record({
    fov: 63.0,
    near: 1,
    far: 1000,
    aspect: 1,
});

export class CameraFrustum extends CameraFrustumRecord {

    constructor(args = {}) {
        super({...args});
    }

    equals(other) {
        if (!other) {
            return false;
        }
        return this.fov === other.get('fov')
            && this.near === other.get('near')
            && this.far === other.get('far')
            && this.aspect === other.get('aspect');
    }
}

const ProductRecord = EntityMixin({
    shop_id: 0,
    shop: null,
    brand_id: 0,
    brand: null,
    category_id: 0,
    category: null,
    mgmt_name: '',
    uid: '',
    rep_image: null,
    rep_image_url: null,    // Builder를 통해서 가져온 이미지 URL
    repImageData: null,
    rep_image_width: 0,
    rep_image_height: 0,
    camera_frustum: new CameraFrustum(),
    is_hot_deal: false,
    is_activated: false,
    name: '',
    price: 0,
    price_text: '',
    url: '',
    order: 0,
    options: List(),
    i18n_countries: List(),
    i18n_languages: List(),
});

class Product extends ProductRecord {

    constructor(args = {}) {
        super({
            ...args,
            shop: args.shop ? new Shop(args.shop) : null,
            brand: args.brand ? new Brand(args.brand) : null,
            category: args.category ? new Category(args.category) : null,
            repImageData: args.repImageData ? new ImageData(args.repImageData) : null,
            camera_frustum: args.camera_frustum ? new CameraFrustum(args.camera_frustum) : new CameraFrustum(),
            options: args.options ? List(args.options.map(item => new ProductOption(item))) : List(),
            i18n_countries: args.i18n_countries
                ? List(args.i18n_countries.map(item => new I18NCountryProduct(item))) : List(),
            i18n_languages: args.i18n_languages
                ? List(args.i18n_languages.map(item => new I18NLanguageProduct(item))) : List(),
        });
    }

    getOptionColors() {
        return this.options.reduce((prev, cur) => {
            const productColor = cur.get('color').set('image', cur.get('rep_image'));

            if (prev.includes(productColor)) {
                return prev;
            } else {
                return prev.push(productColor);
            }
        }, List());
    }

    findDefaultCountryCode() {
        const i18nCountry = this.i18n_countries.find(item => item.get('is_default'));

        return i18nCountry ? i18nCountry.get('country_code') : REPUBLIC_OF_KOREA;
    }

    findDefaultLanguageCode() {
        const i18nLanguage = this.i18n_languages.find(item => item.get('is_default'));

        return i18nLanguage ? i18nLanguage.get('language_code') : LANG_KOREAN;
    }

    getHotDealStatusText(t) {
        if (this.is_hot_deal) {
            return t('product_is_hot_deal_true');
        } else {
            return t('product_is_hot_deal_false');
        }
    }

    getActivationStatusText(t) {
        if (this.is_activated) {
            return t('activation');
        } else {
            return t('deactivation');
        }
    }

    getOptionById(optionId) {
        return this.options.find(item => item.get('id') === optionId);
    }

    canAdd(categoryUid, isAdmin = false) {
        return isNumber(this.shop_id) && this.shop_id > 0
            && isNumber(this.brand_id) && this.brand_id > 0
            && isNumber(this.category_id) && this.category_id > 0
            && Boolean(this.uid)
            && Boolean(this.rep_image_url || this.repImageData)
            && this.options.size > 0 && this.options.every(item => item.canAdd(categoryUid, isAdmin))
            && this.i18n_countries.size > 0 && this.i18n_countries.every(item => item.canAdd())
            && this.i18n_languages.size > 0 && this.i18n_languages.every(item => item.canAdd());
    }

    canAdminEdit(oldbie, categoryUid, isAdmin = false) {
        let isCanEdit = false;

        const oldbieOptions = oldbie.get('options');

        if (this.options.equals(oldbieOptions)) {
            let canAllAdd = true;

            for (let i = 0; i < this.options.size; i++) {
                const item = this.options.get(i);

                if (!item.canAdd(categoryUid, isAdmin)) {
                    canAllAdd = false;
                    break;
                }

                if (!isCanEdit) {
                    isCanEdit = item.canAdminEdit(oldbieOptions.get(i), categoryUid);
                }
            }

            if (!canAllAdd) {
                isCanEdit = false;
            }
        } else {
            isCanEdit = false;
        }

        return isCanEdit;
    }

    canEdit(oldbie, categoryUid = null, isAdmin = false) {
        if (!oldbie || !categoryUid) {
            return false;
        }

        const uid = this.uid.trim();

        const oldbieOptions = oldbie.get('options');
        let canOptionsEdit = false;

        if (this.options.equals(oldbieOptions)) {
            let canAllAdd = true;

            for (let i=0; i < this.options.size; i++) {
                const item = this.options.get(i);

                if (!item.canAdd(categoryUid, isAdmin)) {
                    canAllAdd = false;
                    break;
                }

                if (!canOptionsEdit) {
                    canOptionsEdit = !item.isSame(oldbieOptions.get(i), categoryUid);
                }
            }

            if (!canAllAdd) {
                canOptionsEdit = false;
            }
        } else {
            canOptionsEdit = this.options.every(item => item.canAdd(categoryUid, isAdmin));
        }

        const oldbieI18nCountries = oldbie.get('i18n_countries');
        let canI18nCountriesEdit = false;

        if (this.i18n_countries.equals(oldbieI18nCountries)) {
            let canAllAdd = true;

            for (let i=0; i < this.i18n_countries.size; i++) {
                const item = this.i18n_countries.get(i);

                if (!item.canAdd()) {
                    canAllAdd = false;
                    break;
                }

                if (!canI18nCountriesEdit) {
                    canI18nCountriesEdit = !item.isSame(oldbieI18nCountries.get(i));
                }
            }

            if (!canAllAdd) {
                canI18nCountriesEdit = false;
            }
        } else {
            canI18nCountriesEdit = this.i18n_countries.every(item => item.canAdd());
        }

        const oldbieI18nLanguages = oldbie.get('i18n_languages');
        let canI18nLanguagesEdit = false;

        if (this.i18n_languages.equals(oldbieI18nLanguages)) {
            let canAllAdd = true;

            for (let i=0; i < this.i18n_languages.size; i++) {
                const item = this.i18n_languages.get(i);

                if (!item.canAdd()) {
                    canAllAdd = false;
                    break;
                }

                if (!canI18nLanguagesEdit) {
                    canI18nLanguagesEdit = !item.isSame(oldbieI18nLanguages.get(i));
                }
            }

            if (!canAllAdd) {
                canI18nLanguagesEdit = false;
            }
        } else {
            canI18nLanguagesEdit = this.i18n_languages.every(item => item.canAdd());
        }

        return (this.shop_id && this.shop_id !== oldbie.get('shop_id'))
            || (this.brand_id && this.brand_id !== oldbie.get('brand_id'))
            || (this.category_id && this.category_id !== oldbie.get('category_id'))
            || (this.mgmt_name !== oldbie.get('mgmt_name'))
            || (uid && uid !== oldbie.get('uid'))
            || (this.rep_image_url || this.repImageData)
            || this.is_activated !== oldbie.get('is_activated')
            || this.is_hot_deal !== oldbie.get('is_hot_deal')
            || canOptionsEdit
            || canI18nCountriesEdit
            || canI18nLanguagesEdit;
    }

    makeFormData(categoryUid, oldbie = null) {
        let formData = new FormData();
        const uid = this.uid.trim();
        const updated = oldbie != null;
        let addIndex = 1;

        if (isNumber(this.shop_id) && this.shop_id > 0) {
            formData.append('shop_id', this.shop_id);
        }
        if (isNumber(this.brand_id) && this.brand_id > 0) {
            if (!updated || this.brand_id !== oldbie.get('brand_id')) {
                formData.append('brand_id', this.brand_id);
            }
        }
        if (isNumber(this.category_id) && this.category_id > 0) {
            if (!updated || this.category_id !== oldbie.get('category_id')) {
                formData.append('category_id', this.category_id);
            }
        }
        if (this.mgmt_name) {
            if (!oldbie || this.mgmt_name !== oldbie.get('mgmt_name')) {
                formData.append('mgmt_name', this.mgmt_name);
            }
        }
        if (uid) {
            if (!updated || uid !== oldbie.get('uid')) {
                formData.append('uid', uid);
            }
        }
        if (this.repImageData) {
            formData.append(`rep_image`, this.repImageData.get('file'));
        } else if (this.rep_image_url) {
            formData.append(`rep_image_url`, this.rep_image_url);
        }

        formData.append('is_activated', this.is_activated ? '1' : '0');
        formData.append('is_hot_deal', this.is_hot_deal ? '1' : '0');

        addIndex = 1;
        this.options.forEach(item => {
            const isAdded = item.makeFormData(categoryUid, addIndex, formData, oldbie);

            if (isAdded) {
                addIndex++;
            }
        });

        this.i18n_countries.forEach(item => {
            item.makeFormData(formData, oldbie)
        });

        this.i18n_languages.forEach(item => {
            item.makeFormData(formData, oldbie)
        });

        return formData;
    }
}

export default Product;
