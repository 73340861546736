import {I18NCountryMixin} from "./I18NCountry";
import {List} from "immutable";
import {indexOfCountryCode} from "../utils/ListUtils";
import {COUNTRIES_KEY, UPDATED_PREFIX} from "../constants/CommonConstants";
import {isUrlValid} from "../utils/RegexUtils";

export const URL_OPEN_TYPE_NEW_TAB = 'new_tab';
export const URL_OPEN_TYPE_CUR_PAGE = 'cur_page';
export const URL_OPEN_TYPES = List([URL_OPEN_TYPE_NEW_TAB, URL_OPEN_TYPE_CUR_PAGE]);

export const I18NCountryProductMixin = defaultValues => class extends I18NCountryMixin({
    price: 0,
    price_text: '',
    discounted_price: 0,
    discounted_price_text: '',
    url: '',
    url_open_type: URL_OPEN_TYPE_NEW_TAB,
    ...defaultValues
}) {
    constructor(args = {}) {
        super({
            ...args,
            url_open_type: URL_OPEN_TYPES.includes(args.url_open_type) ? args.url_open_type : URL_OPEN_TYPE_NEW_TAB,
        });
    }

    canAdd() {
        return Boolean(this.country_code)
            && parseInt(this.price) >= 0
            && parseInt(this.discounted_price) >= 0
            && (this.url.length === 0 || (this.url.length > 0 && isUrlValid(this.url)))
            && URL_OPEN_TYPES.includes(this.url_open_type);
    }

    isSame(other) {
        if (!other) {
            return false;
        }

        return this.country_code === other.get('country_code')
            && parseInt(this.price) === other.get('price')
            && this.discounted_price === other.get('discounted_price')
            && this.url === other.get('url')
            && this.url_open_type === other.get('url_open_type')
            && this.is_default === other.get('is_default');
    }

    makeFormData(formData, oldbieProduct = null) {
        const price = parseInt(this.price);
        const discountedPrice = parseInt(this.discounted_price);
        let oldbieItem = null;

        if (oldbieProduct !== null) {
            const oldbieItems = oldbieProduct.get('i18n_countries');
            const indexOfOld = indexOfCountryCode(oldbieItems, this.country_code);

            oldbieItem = indexOfOld >= 0 ? oldbieItems.get(indexOfOld) : null;
        }

        const shouldUpdate = oldbieItem != null;
        const keyPrefix = shouldUpdate ?
            `${UPDATED_PREFIX}${COUNTRIES_KEY}${this.country_code}-` : `${COUNTRIES_KEY}${this.country_code}-`;
        let shouldAddItem = false;

        if (shouldUpdate) {
            if (!this.isSame(oldbieItem)) {
                shouldAddItem = true;
            }
        } else {
            shouldAddItem = true;
        }

        if (shouldAddItem) {
            formData.append(`${keyPrefix}is_default`, this.is_default ? '1' : '0');
            formData.append(`${keyPrefix}price`, `${price}`);
            formData.append(`${keyPrefix}discounted_price`, `${discountedPrice}`);
            formData.append(`${keyPrefix}url`, this.url);
            formData.append(`${keyPrefix}url_open_type`, this.url_open_type);
            formData.append(`${keyPrefix}country_code`, this.country_code);
        }
    }
};

export default class I18NCountryProduct extends I18NCountryProductMixin({}) {

    constructor(args = {}) {
        super({...args});
    }
}
