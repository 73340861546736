import {List, Map} from "immutable";
import {getItem, setItem} from "./StorageUtils";
import parser from "accept-language-parser";

export const makeDefaultNs = (namespaces = []) => ['common'].concat(namespaces);

export const REPUBLIC_OF_KOREA = 'KR';
export const UNITED_STATE_OF_AMERICA = 'US';
export const JAPAN = 'JP';
export const COUNTRIES = List([REPUBLIC_OF_KOREA, UNITED_STATE_OF_AMERICA, JAPAN]);
export const COUNTRY_ID_ITEMS = Map({
    [REPUBLIC_OF_KOREA]: 1,
    [UNITED_STATE_OF_AMERICA]: 2,
    [JAPAN]: 3,
});

export const LANG_KOREAN = 'ko';
export const LANG_ENGLISH = 'en';
export const LANG_JAPANESE = 'ja';
export const LANGUAGES = List([LANG_KOREAN, LANG_ENGLISH, LANG_JAPANESE]);
export const LANGUAGE_ID_ITEMS = Map({
    [LANG_KOREAN]: 1,
    [LANG_ENGLISH]: 2,
    [LANG_JAPANESE]: 3,
});

export const CURRENCY_WON_SYMBOL = "₩";
export const CURRENCY_DOLLAR_SYMBOL = "$";

const LANG_COUNTRY_KEY = '_default_lang_country';

export function getCurrencySymbol(countryCode) {
    if (countryCode === REPUBLIC_OF_KOREA) {
        return CURRENCY_WON_SYMBOL;
    } else {
        return CURRENCY_DOLLAR_SYMBOL;
    }
}

export function saveLangAndCountryCode(languageCode, countryCode) {
    if (
        typeof window !== 'undefined'
        && LANGUAGES.includes(languageCode)
        && COUNTRIES.includes(countryCode)
    ) {
        setItem(LANG_COUNTRY_KEY, `${languageCode}:${countryCode}`);
    }
}

export function getLangAndCountryCode() {
    let languageCode = LANG_ENGLISH;
    let countryCode = UNITED_STATE_OF_AMERICA;

    if (typeof window === 'undefined') {
        return {languageCode, countryCode};
    }

    const value = getItem(LANG_COUNTRY_KEY);

    if (!value) {
        return {languageCode: '', countryCode: ''};
    }

    [languageCode, countryCode] = value.split(':');

    return {languageCode, countryCode};
}

export function getLanguageAndCountryCode() {
    // refs: https://medium.freecodecamp.org/internationalization-in-react-7264738274a0
    if (typeof window === 'undefined') {
        return [LANG_ENGLISH, UNITED_STATE_OF_AMERICA];
    }

    const language = (navigator.languages && navigator.languages[0]) || navigator.language;
    // language = ko-KR

    if (!language) {
        return [LANG_ENGLISH, UNITED_STATE_OF_AMERICA];
    }

    const languageCode = language.split(/[_-]+/)[0];

    if (languageCode === LANG_KOREAN) {
        return [LANG_KOREAN, REPUBLIC_OF_KOREA];
    } else if (languageCode === LANG_JAPANESE) {
        return [LANG_JAPANESE, JAPAN];
    } else {
        return [LANG_ENGLISH, UNITED_STATE_OF_AMERICA];
    }
}

export function getCountryCodeFromLocale(locale) {
    if (!locale) {
        return REPUBLIC_OF_KOREA;
    }

    if (locale.includes(LANG_KOREAN)) {
        return REPUBLIC_OF_KOREA;
    } else if (locale.includes(LANG_JAPANESE)) {
        return JAPAN;
    } else {
        return UNITED_STATE_OF_AMERICA;
    }
}

export function getLanguageCodeFromLocale(locale) {
    if (!locale) {
        return LANG_KOREAN;
    }

    if (locale.includes(LANG_KOREAN)) {
        return LANG_KOREAN;
    } else if (locale.includes(LANG_JAPANESE)) {
        return LANG_JAPANESE;
    } else {
        return LANG_ENGLISH;
    }
}

export function getLanguageName(t, languageCode) {
    switch (languageCode) {
        case LANG_KOREAN:
            return t('language_ko');

        case LANG_ENGLISH:
            return t('language_en');

        case LANG_JAPANESE:
            return t('language_ja');

        default:
            return "";
    }
}

export function getCountryName(t, countryCode) {
    switch (countryCode) {
        case REPUBLIC_OF_KOREA:
            return t('country_kr');

        case UNITED_STATE_OF_AMERICA:
            return t('country_us');

        case JAPAN:
            return t('country_jp');

        default:
            return "";
    }
}

export function checkAcceptLang(acceptLang) {
    if (!acceptLang) {
        return null;
    }

    const paredLocales = parser.parse(acceptLang);

    if (!paredLocales || paredLocales.length <= 0) {
        return null;
    }

    const {code} = paredLocales[0];

    return code ? code : null;
}
