import {Record} from "immutable";

const SelectItemRecord = Record({
    value: null,
    name: null,
    image: null,
    extraData: null,
});

class SelectItem extends SelectItemRecord {

    constructor(args = {}) {
        super({...args});
    }

    equals(other) {
        if (!other) {
            return false;
        }
        return this.value === other.get('value');
    }
}

export default SelectItem;
