import {Record} from "immutable";

const FileDataRecord = Record({
    file: null,
});

class FileData extends FileDataRecord {

    constructor(args = {}) {
        super({...args});
    }

    equals(other) {
        if (!other) {
            return false;
        }
        return this.file === other.file;
    }
}

export default FileData;
