import {ofType} from "redux-observable";
import {
    FAIL_CHANGE_MANAGER_PASSWORD,
    FAIL_GET_MANAGER,
    FAIL_SIGNIN_MANAGER,
    FAIL_UPDATE_AR_MODEL_TRANSFORM_INFO_BY_MANAGER,
    FAIL_UPDATE_MANAGER,
    REQUEST_CHANGE_MANAGER_PASSWORD,
    REQUEST_GET_MANAGER,
    REQUEST_SIGNIN_MANAGER,
    REQUEST_UPDATE_AR_MODEL_TRANSFORM_INFO_BY_MANAGER,
    REQUEST_UPDATE_MANAGER,
    REQUEST_UPDATE_THREE_MODEL_TRANSFORM_INFO_BY_MANAGER,
    REQUEST_UPDATE_THREE_MODEL_TRANSFORM_INFO_BY_MANAGER_IN_PRODUCT_THREE_VIEWER,
    SUCCEED_CHANGE_MANAGER_PASSWORD,
    SUCCEED_GET_MANAGER,
    SUCCEED_SIGNIN_MANAGER,
    SUCCEED_UPDATE_MANAGER,
} from "../../actions/RESTActionTypes";
import {catchError, map, mergeMap} from "rxjs/operators";
import {
    changeManagerPassword,
    getManager,
    signin,
    updateArTransformModelInfo,
    updateThreeModelModelTransformInfo,
    updateManager,
} from "../../../rest/ManagersApI";
import {loadResult, occurError} from "../../actions/RESTAction";
import {of} from "rxjs";
import {loadUpdateArModelTransformInfo} from "../../actions/Manager/ArViewerArmtisApiAction";

export const signinManagerEpic = action$ => action$.pipe(
    ofType(REQUEST_SIGNIN_MANAGER),
    mergeMap(action => signin(action.email, action.password).pipe(
        map(res => loadResult(SUCCEED_SIGNIN_MANAGER, res.response)),
        catchError(error => of(occurError(FAIL_SIGNIN_MANAGER, error.xhr)))
    ))
);

export const getManagerEpic = action$ => action$.pipe(
    ofType(REQUEST_GET_MANAGER),
    mergeMap(action => getManager(action.managerInfo).pipe(
        map(res => loadResult(SUCCEED_GET_MANAGER, res.response)),
        catchError(error => of(occurError(FAIL_GET_MANAGER, error.xhr)))
    ))
);

export const updateManagerEpic = action$ => action$.pipe(
    ofType(REQUEST_UPDATE_MANAGER),
    mergeMap(action => updateManager(action.managerInfo, action.payload).pipe(
        map(res => loadResult(SUCCEED_UPDATE_MANAGER, res.response)),
        catchError(error => of(occurError(FAIL_UPDATE_MANAGER, error.xhr)))
    ))
);

export const changeManagerPasswordEpic = action$ => action$.pipe(
    ofType(REQUEST_CHANGE_MANAGER_PASSWORD),
    mergeMap(action => changeManagerPassword(
        action.managerInfo, action.oldPassword, action.newPassword
    ).pipe(
        map(res => loadResult(SUCCEED_CHANGE_MANAGER_PASSWORD, res.response)),
        catchError(error => of(occurError(FAIL_CHANGE_MANAGER_PASSWORD, error.xhr)))
    ))
);

export const updateArTransformInfoEpic = action$ => action$.pipe(
    ofType(REQUEST_UPDATE_AR_MODEL_TRANSFORM_INFO_BY_MANAGER),
    mergeMap(action => updateArTransformModelInfo(
        action.managerInfo, action.productId, action.productOptionId, action.armtiId, action.formData
    ).pipe(
        map(res => loadUpdateArModelTransformInfo(action.productOptionId, action.armtiId, res.response)),
        catchError(error => of(occurError(FAIL_UPDATE_AR_MODEL_TRANSFORM_INFO_BY_MANAGER, error.xhr)))
    ))
);

export const updateThreeModelModelTransformInfoEpic = action$ => action$.pipe(
    ofType(
        REQUEST_UPDATE_THREE_MODEL_TRANSFORM_INFO_BY_MANAGER,
        REQUEST_UPDATE_THREE_MODEL_TRANSFORM_INFO_BY_MANAGER_IN_PRODUCT_THREE_VIEWER
    ),
    mergeMap(action => updateThreeModelModelTransformInfo(
        action.shopId,
        action.managerInfo,
        action.productId,
        action.productOptionId,
        action.arMtiItemsList,
        action.lightingEnv,
        action.faceEffectInfo,
        action.lightingEnvViewer,
    ).pipe(
        map(res => loadResult(action.succeedType, res.response)),
        catchError(error => of(occurError(action.failType, error.xhr)))
    ))
)
