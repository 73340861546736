import {fromJS} from "immutable";
import {
    SAMPLE_THREE_VIEWER_CLEAR,
    SAMPLE_THREE_VIEWER_SET_INITIALIZED,
    SAMPLE_THREE_VIEWER_SET_IS_MODEL_VIEWER_POPUP_SHOWN,
} from "../actions/UIActionTypes";

export const initialSampleThreeViewer = fromJS({
    isInitialized: false,
    isModelViewerPopupShown: false,
});

export function sampleThreeViewer(state = initialSampleThreeViewer, action) {
    switch (action.type) {
        case SAMPLE_THREE_VIEWER_CLEAR:
            return initialSampleThreeViewer;

        case SAMPLE_THREE_VIEWER_SET_INITIALIZED:
            return state.set('isInitialized', true);

        case SAMPLE_THREE_VIEWER_SET_IS_MODEL_VIEWER_POPUP_SHOWN:
            return state.set('isModelViewerPopupShown', action.isModelViewerPopupShown);

        default:
            return state;
    }
}
