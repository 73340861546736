import {Record} from "immutable";

const OrderItemRecord = Record({
    id: 0,
    to_order: -1,
});

class OrderItem extends OrderItemRecord {

    constructor(args = {}) {
        super({...args});
    }
}

export default OrderItem;
