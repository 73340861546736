import {List} from "immutable";

export function findIndexById(itemList, findItem) {
    return itemList.findIndex(item => item.get('id') === findItem.get('id'));
}

export function addExclusively(oldList, newList, page) {
    if (page === 1) {
        return newList;
    }

    let results = oldList ? oldList : List();

    newList.forEach(item => {
        if (!oldList.includes(item)) {
            results = results.push(item);
        }
    });
    return results;
}

export function updateListItem(itemList, index, newbie) {
    if (index < 0 || itemList.size <= index) {
        return itemList;
    }

    return itemList.update(index, oldbie => newbie);
}

export function updateListByKeyValue(itemList, index, key, value) {
    if (index < 0 || itemList.size <= index) {
        return itemList;
    }

    return itemList.update(index, oldbie => oldbie.set(key, value));
}

export function indexOfLanguageCode(itemList, languageCode) {
    if (!languageCode) {
        return -1;
    }
    return itemList.findIndex(item => item.get('language_code') === languageCode);
}

export function indexOfCountryCode(itemList, countryCode) {
    if (!countryCode) {
        return -1;
    }
    return itemList.findIndex(item => item.get('country_code') === countryCode);
}
