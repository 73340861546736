import {List, Record} from "immutable";
import WorkRequestHistory from "./WorkRequestHistory";


const HistoryDataRecord = Record({
    histories: List(),
    hasMore: false,
    count: 0,
    page: 0,
});

class HistoryData extends HistoryDataRecord {

    constructor(args= {}) {
        super({
            ...args,
            histories: args.histories ? List(args.histories.map(item => new WorkRequestHistory(item))): List(),
        });
    }
}

export default HistoryData;