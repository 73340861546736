export const SET_MOBILE_DRAWER = 'SET_OPEN_MOBILE_DRAWER';
export const SET_LAPTOP_DRAWER = 'SET_LAPTOP_DRAWER';

export const PRODUCT_THREE_VIEWER_CLEAR = 'PRODUCT_THREE_VIEWER_CLEAR';
export const PRODUCT_THREE_VIEWER_SET_INITIALIZED = 'PRODUCT_THREE_VIEWER_SET_INITIALIZED';
export const PRODUCT_THREE_VIEWER_SET_IS_MODEL_VIEWER_POPUP_SHOWN = 'PRODUCT_THREE_VIEWER_SET_IS_MODEL_VIEWER_POPUP_SHOWN';
export const PRODUCT_THREE_VIEWER_TOGGLE_OPTION_CONTAINER = 'PRODUCT_THREE_VIEWER_TOGGLE_OPTION_CONTAINER';
export const PRODUCT_THREE_VIEWER_SELECT_PRODUCT_OPTION = 'PRODUCT_THREE_VIEWER_SELECT_PRODUCT_OPTION';
export const PRODUCT_THREE_VIEWER_HANDLE_ERROR_MODEL = 'PRODUCT_THREE_VIEWER_HANDLE_ERROR_MODEL';
export const PRODUCT_THREE_VIEWER_LOADED_THREE_MODEL = 'PRODUCT_THREE_VIEWER_LOADED_THREE_MODEL';
export const PRODUCT_THREE_VIEWER_CHANGE_ENV_VIEWER_IMAGE_PRESETS = 'PRODUCT_THREE_VIEWER_CHANGE_ENV_VIEWER_IMAGE_PRESETS';
export const PRODUCT_THREE_VIEWER_CHANGE_LIGHTING_ENV_VIEWER_INPUTS = 'PRODUCT_THREE_VIEWER_CHANGE_LIGHTING_ENV_VIEWER_INPUTS';
export const PRODUCT_THREE_VIEWER_RESET_THREE_VIEWER_MODEL_TRANSFORM = 'PRODUCT_THREE_VIEWER_RESET_THREE_VIEWER_MODEL_TRANSFORM';
export const PRODUCT_THREE_VIEWER_GET_SELECTED_OPTION = 'PRODUCT_THREE_VIEWER_GET_SELECTED_OPTION';

export const PRODUCT_AR_VIEWER_CLEAR = 'PRODUCT_AR_VIEWER_CLEAR';
export const PRODUCT_AR_VIEWER_SET_INITIALIZED = 'PRODUCT_AR_VIEWER_SET_INITIALIZED';
export const PRODUCT_AR_VIEWER_SET_IS_WEB_AR_POPUP_SHOWN = 'PRODUCT_AR_VIEWER_SET_IS_WEB_AR_POPUP_SHOWN';

export const PLUGIN_AR_VIEWER_CLEAR = 'PLUGIN_AR_VIEWER_CLEAR';
export const PLUGIN_AR_VIEWER_SET_INITIALIZED = 'PLUGIN_AR_VIEWER_SET_INITIALIZED';
export const PLUGIN_AR_VIEWER_SET_IS_WEB_AR_POPUP_SHOWN = 'PLUGIN_AR_VIEWER_SET_IS_WEB_AR_POPUP_SHOWN';

export const SAMPLE_WEB_AR_CLEAR = 'SAMPLE_WEB_AR_CLEAR';
export const SAMPLE_WEB_AR_SET_INITIALIZED = 'SAMPLE_WEB_AR_SET_INITIALIZED';
export const SAMPLE_WEB_AR_SET_IS_WEB_AR_POPUP_SHOWN = 'SAMPLE_WEB_AR_SET_IS_WEB_AR_POPUP_SHOWN';

export const SAMPLE_THREE_VIEWER_CLEAR = 'SAMPLE_THREE_VIEWER_CLEAR';
export const SAMPLE_THREE_VIEWER_SET_INITIALIZED = 'SAMPLE_THREE_VIEWER_SET_INITIALIZED';
export const SAMPLE_THREE_VIEWER_SET_IS_MODEL_VIEWER_POPUP_SHOWN = 'SAMPLE_THREE_VIEWER_SET_IS_MODEL_VIEWER_POPUP_SHOWN';

export const PRICING_CLEAR_HANDLER_STATE = 'PRICING_CLEAR_HANDLER_STATE';
export const PRICING_SELECT_SELECTED_PRODUCT_CATEGORY_UID = 'PRICING_SELECT_SELECTED_PRODUCT_CATEGORY_UID';
export const PRICING_CHANGE_PRODUCT_SIZE = 'PRICING_CHANGE_PRODUCT_SIZE';
export const PRICING_CLEAR_PAYMENT_HISTORY_STATE = 'PRICING_CLEAR_PAYMENT_HISTORY_STATE';

/* SHOP STAFF */
export const SHOP_STAFF_SIGNUP_CLEAR = 'SHOP_STAFF_SIGNUP_CLEAR';
export const SHOP_STAFF_SIGNUP_ENTER_EMAIL = 'SHOP_STAFF_SIGNUP_ENTER_EMAIL';
export const SHOP_STAFF_SIGNUP_ENTER_PASSWORD = 'SHOP_STAFF_SIGNUP_ENTER_PASSWORD';
export const SHOP_STAFF_SIGNUP_ENTER_PASSWORD_CHECK = 'SHOP_STAFF_SIGNUP_ENTER_PASSWORD_CHECK'
export const SHOP_STAFF_SIGNUP_ENTER_NAME = 'SHOP_STAFF_SIGNUP_ENTER_NAME';
export const SHOP_STAFF_SIGNUP_ENTER_PHONE_NUMBER = 'SHOP_STAFF_SIGNUP_ENTER_PHONE_NUMBER';
export const SHOP_STAFF_SIGNUP_ENTER_SHOP_NAME = 'SHOP_STAFF_SIGNUP_ENTER_SHOP_NAME';
export const SHOP_STAFF_SIGNUP_ENTER_SHOP_PATH = 'SHOP_STAFF_SIGNUP_ENTER_SHOP_PATH';
export const SHOP_STAFF_SIGNUP_CHANGE_CATEGORY = 'SHOP_STAFF_SIGNUP_CHANGE_CATEGORY';
export const SHOP_STAFF_SIGNUP_CHANGE_IS_VALID_CATEGORY = 'SHOP_STAFF_SIGNUP_CHANGE_IS_VALID_CATEGORY';

export const SHOP_STAFF_SIGNIN_ENTER_EMAIL = 'SHOP_STAFF_SIGNIN_ENTER_EMAIL';
export const SHOP_STAFF_SIGNIN_ENTER_PASSWORD = 'SHOP_STAFF_SIGNIN_ENTER_PASSWORD';

export const SHOP_STAFF_ACCOUNT_CHANGE_NAME = 'SHOP_STAFF_ACCOUNT_CHANGE_NAME';
export const SHOP_STAFF_ACCOUNT_CHANGE_OLD_PASSWORD = 'SHOP_STAFF_ACCOUNT_CHANGE_OLD_PASSWORD';
export const SHOP_STAFF_ACCOUNT_CHANGE_NEW_PASSWORD = 'SHOP_STAFF_ACCOUNT_CHANGE_NEW_PASSWORD';
export const SHOP_STAFF_ACCOUNT_IS_DIALOG_OPEN = 'SHOP_STAFF_ACCOUNT_IS_DIALOG_OPEN';
export const SHOP_STAFF_ACCOUNT_SET_ERROR_MESSAGE = 'SHOP_STAFF_ACCOUNT_SET_ERROR_MESSAGE';

export const SHOP_STAFF_SHOP_PAYMENT_HISTORY_INIT_DATA = 'SHOP_STAFF_SHOP_PAYMENT_HISTORY_INIT_DATA';
export const SHOP_STAFF_SHOP_PAYMENT_HISTORY_RESET_QUERY_PARAMS = 'SHOP_STAFF_SHOP_PAYMENT_HISTORY_RESET_QUERY_PARAMS';
export const SHOP_STAFF_SHOP_PAYMENT_HISTORY_CHANGE_QUERY_PARAMS = 'SHOP_STAFF_SHOP_PAYMENT_HISTORY_CHANGE_QUERY_PARAMS';
export const SHOP_STAFF_SHOP_PAYMENT_HISTORY_CHANGE_PERIOD = 'SHOP_STAFF_SHOP_PAYMENT_HISTORY_CHANGE_PERIOD';

export const SHOP_STAFF_BRANDS_CLEAR_TASK_STATE = 'SHOP_STAFF_BRANDS_CLEAR_TASK_STATE';
export const SHOP_STAFF_BRANDS_INIT_DATA = 'SHOP_STAFF_BRANDS_INIT_DATA';
export const SHOP_STAFF_BRANDS_CHANGE_QUERY_PARAMS = 'SHOP_STAFF_BRANDS_CHANGE_QUERY_PARAMS';
export const SHOP_STAFF_BRANDS_CHANGE_PERIOD = 'SHOP_STAFF_BRANDS_CHANGE_PERIOD';
export const SHOP_STAFF_BRANDS_RESET_QUERY_PARAMS = 'SHOP_STAFF_BRANDS_RESET_QUERY_PARAMS';
export const SHOP_STAFF_BRANDS_SET_SELECTED_ITEM = 'SHOP_STAFF_BRANDS_SET_SELECTED_ITEM';
export const SHOP_STAFF_BRANDS_OPEN_CHANGE_ORDER = 'SHOP_STAFF_BRANDS_OPEN_CHANGE_ORDER';
export const SHOP_STAFF_BRANDS_REORDER = 'SHOP_STAFF_BRANDS_REORDER';
export const SHOP_STAFF_BRANDS_RENDERED_REORDER_LIST = 'SHOP_STAFF_BRANDS_RENDERED_REORDER_LIST';
export const SHOP_STAFF_BRANDS_CLEAR_CHANGE_ORDER_STATE = 'SHOP_STAFF_BRANDS_CLEAR_CHANGE_ORDER_STATE';

export const SHOP_STAFF_BRAND_ADD_INIT_DATA = 'SHOP_STAFF_BRAND_ADD_INIT_DATA';
export const SHOP_STAFF_BRAND_ADD_CLEAR_CREATE_STATE = 'SHOP_STAFF_BRAND_ADD_CLEAR_CREATE_STATE';
export const SHOP_STAFF_BRAND_ADD_CHANGE_BRAND = 'SHOP_STAFF_BRAND_ADD_CHANGE_BRAND';

export const SHOP_STAFF_BRAND_EDIT_INIT_DATA = 'SHOP_STAFF_BRAND_EDIT_INIT_DATA';
export const SHOP_STAFF_BRAND_EDIT_CLEAR_EDIT_STATE = 'SHOP_STAFF_BRAND_EDIT_CLEAR_EDIT_STATE';
export const SHOP_STAFF_BRAND_EDIT_CHANGE_BRAND = 'SHOP_STAFF_BRAND_EDIT_CHANGE_BRAND';

export const SHOP_STAFF_PRODUCTS_CLEAR_TASK_STATE = 'SHOP_STAFF_PRODUCTS_CLEAR_TASK_STATE';
export const SHOP_STAFF_PRODUCTS_INIT_DATA = 'SHOP_STAFF_PRODUCTS_INIT_DATA';
export const SHOP_STAFF_PRODUCTS_CHANGE_QUERY_PARAMS = 'SHOP_STAFF_PRODUCTS_CHANGE_QUERY_PARAMS';
export const SHOP_STAFF_PRODUCTS_CHANGE_PERIOD = 'SHOP_STAFF_PRODUCTS_CHANGE_PERIOD';
export const SHOP_STAFF_PRODUCTS_RESET_QUERY_PARAMS = 'SHOP_STAFF_PRODUCTS_RESET_QUERY_PARAMS';
export const SHOP_STAFF_PRODUCTS_SET_SELECTED_ITEM = 'SHOP_STAFF_PRODUCTS_SET_SELECTED_ITEM';
export const SHOP_STAFF_PRODUCTS_OPEN_CHANGE_ORDER = 'SHOP_STAFF_PRODUCTS_OPEN_CHANGE_ORDER';
export const SHOP_STAFF_PRODUCTS_SELECT_BRAND_IN_CHANGE_ORDER = 'SHOP_STAFF_PRODUCTS_SELECT_BRAND_IN_CHANGE_ORDER';
export const SHOP_STAFF_PRODUCTS_SELECT_CATEGORY_IN_CHANGE_ORDER = 'SHOP_STAFF_PRODUCTS_SELECT_CATEGORY_IN_CHANGE_ORDER';
export const SHOP_STAFF_PRODUCTS_REORDER = 'SHOP_STAFF_PRODUCTS_REORDER';
export const SHOP_STAFF_PRODUCTS_RENDERED_REORDER_LIST = 'SHOP_STAFF_PRODUCTS_RENDERED_REORDER_LIST';
export const SHOP_STAFF_PRODUCTS_CLEAR_CHANGE_ORDER_STATE = 'SHOP_STAFF_PRODUCTS_CLEAR_CHANGE_ORDER_STATE';
export const SHOP_STAFF_PRODUCTS_SET_IS_WORK_REQUEST_OPEN = 'SHOP_STAFF_PRODUCTS_SET_IS_WORK_REQUEST_OPEN';
export const SHOP_STAFF_PRODUCTS_CLOSE_AR_VIEWER = 'SHOP_STAFF_PRODUCTS_CLOSE_AR_VIEWER';
export const SHOP_STAFF_PRODUCTS_OPEN_AR_VIEWER_WITHOUT_API = 'SHOP_STAFF_PRODUCTS_OPEN_AR_VIEWER_WITHOUT_API';
export const SHOP_STAFF_PRODUCTS_SET_AR_VIEWER_ERROR = 'SHOP_STAFF_PRODUCTS_SET_AR_VIEWER_ERROR';
export const SHOP_STAFF_PRODUCTS_SET_BATCH_FILE = 'SHOP_STAFF_PRODUCTS_SET_BATCH_FILE';
export const SHOP_STAFF_PRODUCTS_CLEAR_BATCH_FILE = 'SHOP_STAFF_PRODUCTS_CLEAR_BATCH_FILE';
export const SHOP_STAFF_PRODUCTS_SET_BATCH_FILE_UPLOAD_DIALOG = 'SHOP_STAFF_PRODUCTS_SET_BATCH_FILE_UPLOAD_DIALOG';
export const SHOP_STAFF_PRODUCTS_SELECT_COPY_SAMPLE_PRODUCT_CATEGORY = 'SHOP_STAFF_PRODUCTS_SELECT_COPY_SAMPLE_PRODUCT_CATEGORY';
export const SHOP_STAFF_PRODUCTS_TOGGLE_COPY_SAMPLE_PRODUCT_DIALOG = 'SHOP_STAFF_PRODUCTS_TOGGLE_COPY_SAMPLE_PRODUCT_DIALOG';

export const SHOP_STAFF_PRODUCT_ADD_INIT_DATA = 'SHOP_STAFF_PRODUCT_ADD_INIT_DATA';
export const SHOP_STAFF_PRODUCT_ADD_CLEAR_CREATE_STATE = 'SHOP_STAFF_PRODUCT_ADD_CLEAR_CREATE_STATE';
export const SHOP_STAFF_PRODUCT_ADD_CLEAR_IMPORT_BUILDER_PRODUCT_INFO_STATE = 'SHOP_STAFF_PRODUCT_ADD_CLEAR_IMPORT_BUILDER_PRODUCT_INFO_STATE';
export const SHOP_STAFF_PRODUCT_ADD_ADD_PRODUCT_SIZE = 'SHOP_STAFF_PRODUCT_ADD_ADD_PRODUCT_SIZE';
export const SHOP_STAFF_PRODUCT_ADD_ADD_PRODUCT_COLOR = 'SHOP_STAFF_PRODUCT_ADD_ADD_PRODUCT_COLOR';
export const SHOP_STAFF_PRODUCT_ADD_SET_DEFAULT_LANG_AND_COUNTRY = 'SHOP_STAFF_PRODUCT_ADD_SET_DEFAULT_LANG_AND_COUNTRY';
export const SHOP_STAFF_PRODUCT_ADD_ADD_LANGUAGE_CODE = 'SHOP_STAFF_PRODUCT_ADD_ADD_LANGUAGE_CODE';
export const SHOP_STAFF_PRODUCT_ADD_DELETE_LANGUAGE_CODE = 'SHOP_STAFF_PRODUCT_ADD_DELETE_LANGUAGE_CODE';
export const SHOP_STAFF_PRODUCT_ADD_SET_DEFAULT_LANGUAGE = 'SHOP_STAFF_PRODUCT_ADD_SET_DEFAULT_LANGUAGE';
export const SHOP_STAFF_PRODUCT_ADD_ADD_COUNTRY_CODE = 'SHOP_STAFF_PRODUCT_ADD_ADD_COUNTRY_CODE';
export const SHOP_STAFF_PRODUCT_ADD_DELETE_COUNTRY_CODE = 'SHOP_STAFF_PRODUCT_ADD_DELETE_COUNTRY_CODE';
export const SHOP_STAFF_PRODUCT_ADD_SET_DEFAULT_COUNTRY = 'SHOP_STAFF_PRODUCT_ADD_SET_DEFAULT_COUNTRY';
export const SHOP_STAFF_PRODUCT_ADD_CHANGE_PRODUCT = 'SHOP_STAFF_PRODUCT_ADD_CHANGE_PRODUCT';
export const SHOP_STAFF_PRODUCT_ADD_CHANGE_LANGUAGE_INFO = 'SHOP_STAFF_PRODUCT_ADD_CHANGE_LANGUAGE_INFO';
export const SHOP_STAFF_PRODUCT_ADD_CHANGE_COUNTRY_INFO = 'SHOP_STAFF_PRODUCT_ADD_CHANGE_COUNTRY_INFO';
export const SHOP_STAFF_PRODUCT_ADD_ADD_OPTION = 'SHOP_STAFF_PRODUCT_ADD_ADD_OPTION';
export const SHOP_STAFF_PRODUCT_ADD_CHANGE_OPTION = 'SHOP_STAFF_PRODUCT_ADD_CHANGE_OPTION';
export const SHOP_STAFF_PRODUCT_ADD_CHANGE_OPTION_COLOR = 'SHOP_STAFF_PRODUCT_ADD_CHANGE_OPTION_COLOR';
export const SHOP_STAFF_PRODUCT_ADD_DELETE_OPTION = 'SHOP_STAFF_PRODUCT_ADD_DELETE_OPTION';
export const SHOP_STAFF_PRODUCT_ADD_CHANGE_OPTION_LANGUAGE_INFO = 'SHOP_STAFF_PRODUCT_ADD_CHANGE_OPTION_LANGUAGE_INFO';
export const SHOP_STAFF_PRODUCT_ADD_CHANGE_OPTION_COUNTRY_INFO = 'SHOP_STAFF_PRODUCT_ADD_CHANGE_OPTION_COUNTRY_INFO';
export const SHOP_STAFF_PRODUCT_ADD_CHANGE_OPTION_COLOR_INFO = 'SHOP_STAFF_PRODUCT_ADD_CHANGE_OPTION_COLOR_INFO';
export const SHOP_STAFF_PRODUCT_ADD_CHANGE_RESOURCE_FILE_DATA = 'SHOP_STAFF_PRODUCT_ADD_CHANGE_RESOURCE_FILE_DATA';
export const SHOP_STAFF_PRODUCT_ADD_CHANGE_CATEGORY = 'SHOP_STAFF_PRODUCT_ADD_CHANGE_CATEGORY';
export const SHOP_STAFF_PRODUCT_ADD_SET_IS_OPENED_CHANGE_ORDER_DIALOG = 'SHOP_STAFF_PRODUCT_ADD_SET_IS_OPENED_CHANGE_ORDER_DIALOG';
export const SHOP_STAFF_PRODUCT_ADD_REORDER = 'SHOP_STAFF_PRODUCT_ADD_REORDER';
export const SHOP_STAFF_PRODUCT_ADD_REORDER_LIST = 'SHOP_STAFF_PRODUCT_ADD_REORDER_LIST';
export const SHOP_STAFF_PRODUCT_ADD_CHANGE_OPTIONS_ORDER = 'SHOP_STAFF_PRODUCT_ADD_CHANGE_OPTIONS_ORDER';

export const SHOP_STAFF_PRODUCT_ADD_REQUIRED_ERRORS = 'SHOP_STAFF_PRODUCT_ADD_REQUIRED_ERRORS';
export const SHOP_STAFF_PRODUCT_ADD_REQUIRED_OPTION_ERRORS = 'SHOP_STAFF_PRODUCT_ADD_REQUIRED_OPTION_ERRORS';
export const SHOP_STAFF_PRODUCT_ADD_REQUIRED_I18N_PRODUCT_LANGUAGE_ERRORS = 'SHOP_STAFF_PRODUCT_ADD_REQUIRED_I18N_PRODUCT_LANGUAGE_ERRORS';
export const SHOP_STAFF_PRODUCT_ADD_REQUIRED_I18N_PRODUCT_COUNTRY_ERRORS = 'SHOP_STAFF_PRODUCT_ADD_REQUIRED_I18N_PRODUCT_COUNTRY_ERRORS';
export const SHOP_STAFF_PRODUCT_ADD_REQUIRED_TEMP_WORK_REQUEST_ERRORS = 'SHOP_STAFF_PRODUCT_ADD_REQUIRED_TEMP_WORK_REQUEST_ERRORS';

export const SHOP_STAFF_PRODUCT_ADD_ADD_TEMP_WORK_REQUEST = 'SHOP_STAFF_PRODUCT_ADD_ADD_TEMP_WORK_REQUEST';
export const SHOP_STAFF_PRODUCT_ADD_DELETE_TEMP_WORK_REQUEST = 'SHOP_STAFF_PRODUCT_ADD_DELETE_TEMP_WORK_REQUEST';
export const SHOP_STAFF_PRODUCT_ADD_ADD_WORK_REQUEST = 'SHOP_STAFF_PRODUCT_ADD_ADD_WORK_REQUEST';
export const SHOP_STAFF_PRODUCT_ADD_CHANGE_TEMP_WORK_REQUEST_IMAGE = 'SHOP_STAFF_PRODUCT_ADD_CHANGE_TEMP_WORK_REQUEST_IMAGE'
export const SHOP_STAFF_PRODUCT_ADD_CHANGE_TEMP_WORK_REQUEST_ALL_IMAGES = 'SHOP_STAFF_PRODUCT_ADD_CHANGE_TEMP_WORK_REQUEST_ALL_IMAGES';
export const SHOP_STAFF_PRODUCT_ADD_DELETE_TEMP_WORK_REQUEST_IMAGE = 'SHOP_STAFF_PRODUCT_ADD_DELETE_TEMP_WORK_REQUEST_IMAGE';
export const SHOP_STAFF_PRODUCT_ADD_CHANGE_TEMP_WORK_REQUEST_REQUEST_MESSAGE = 'SHOP_STAFF_PRODUCT_ADD_CHANGE_TEMP_WORK_REQUEST_REQUEST_MESSAGE';
export const SHOP_STAFF_PRODUCT_ADD_CHANGE_TEMP_WORK_REQUEST_SPEC_UNIT = 'SHOP_STAFF_PRODUCT_ADD_CHANGE_TEMP_WORK_REQUEST_SPEC_UNIT';
export const SHOP_STAFF_PRODUCT_ADD_CHANGE_TEMP_WORK_REQUEST_SPEC = 'SHOP_STAFF_PRODUCT_ADD_CHANGE_TEMP_WORK_REQUEST_SPEC';
export const SHOP_STAFF_PRODUCT_ADD_CHANGE_TEMP_WORK_REQUEST_ALL_SPECS = 'SHOP_STAFF_PRODUCT_ADD_CHANGE_TEMP_WORK_REQUEST_ALL_SPECS';
export const SHOP_STAFF_PRODUCT_ADD_ADD_OPTIONS_SPEC_DATA = 'SHOP_STAFF_PRODUCT_ADD_ADD_OPTIONS_SPEC_DATA';
export const SHOP_STAFF_PRODUCT_ADD_IMPORT_TEMP_WORK_REQUEST_SPEC = 'SHOP_STAFF_PRODUCT_ADD_IMPORT_TEMP_WORK_REQUEST_SPEC';
export const SHOP_STAFF_PRODUCT_ADD_TOGGLE_ADD_MODE = 'SHOP_STAFF_PRODUCT_ADD_TOGGLE_ADD_MODE';

export const SHOP_STAFF_PRODUCT_EDIT_INIT_DATA = 'SHOP_STAFF_PRODUCT_EDIT_INIT_DATA';
export const SHOP_STAFF_PRODUCT_EDIT_CLEAR_EDIT_STATE = 'SHOP_STAFF_PRODUCT_EDIT_CLEAR_EDIT_STATE';
export const SHOP_STAFF_PRODUCT_EDIT_CLEAR_IMPORT_BUILDER_PRODUCT_INFO_STATE = 'SHOP_STAFF_PRODUCT_EDIT_CLEAR_IMPORT_BUILDER_PRODUCT_INFO_STATE';
export const SHOP_STAFF_PRODUCT_EDIT_ADD_PRODUCT_SIZE = 'SHOP_STAFF_PRODUCT_EDIT_ADD_PRODUCT_SIZE';
export const SHOP_STAFF_PRODUCT_EDIT_ADD_PRODUCT_COLOR = 'SHOP_STAFF_PRODUCT_EDIT_ADD_PRODUCT_COLOR';
export const SHOP_STAFF_PRODUCT_EDIT_ADD_LANGUAGE_CODE = 'SHOP_STAFF_PRODUCT_EDIT_ADD_LANGUAGE_CODE';
export const SHOP_STAFF_PRODUCT_EDIT_DELETE_LANGUAGE_CODE = 'SHOP_STAFF_PRODUCT_EDIT_DELETE_LANGUAGE_CODE';
export const SHOP_STAFF_PRODUCT_EDIT_SET_DEFAULT_LANGUAGE = 'SHOP_STAFF_PRODUCT_EDIT_SET_DEFAULT_LANGUAGE';
export const SHOP_STAFF_PRODUCT_EDIT_ADD_COUNTRY_CODE = 'SHOP_STAFF_PRODUCT_EDIT_ADD_COUNTRY_CODE';
export const SHOP_STAFF_PRODUCT_EDIT_DELETE_COUNTRY_CODE = 'SHOP_STAFF_PRODUCT_EDIT_DELETE_COUNTRY_CODE';
export const SHOP_STAFF_PRODUCT_EDIT_SET_DEFAULT_COUNTRY = 'SHOP_STAFF_PRODUCT_EDIT_SET_DEFAULT_COUNTRY';
export const SHOP_STAFF_PRODUCT_EDIT_CHANGE_PRODUCT = 'SHOP_STAFF_PRODUCT_EDIT_CHANGE_PRODUCT';
export const SHOP_STAFF_PRODUCT_EDIT_CHANGE_LANGUAGE_INFO = 'SHOP_STAFF_PRODUCT_EDIT_CHANGE_LANGUAGE_INFO';
export const SHOP_STAFF_PRODUCT_EDIT_CHANGE_COUNTRY_INFO = 'SHOP_STAFF_PRODUCT_EDIT_CHANGE_COUNTRY_INFO';
export const SHOP_STAFF_PRODUCT_EDIT_ADD_OPTION = 'SHOP_STAFF_PRODUCT_EDIT_ADD_OPTION';
export const SHOP_STAFF_PRODUCT_EDIT_CHANGE_OPTION = 'SHOP_STAFF_PRODUCT_EDIT_CHANGE_OPTION';
export const SHOP_STAFF_PRODUCT_EDIT_CHANGE_OPTION_COLOR = 'SHOP_STAFF_PRODUCT_EDIT_CHANGE_OPTION_COLOR';
export const SHOP_STAFF_PRODUCT_EDIT_ADD_DELETED_OPTION_IMAGE_ID = 'SHOP_STAFF_PRODUCT_EDIT_ADD_DELETED_OPTION_IMAGE_ID';
export const SHOP_STAFF_PRODUCT_EDIT_DELETE_OPTION = 'SHOP_STAFF_PRODUCT_EDIT_DELETE_OPTION';
export const SHOP_STAFF_PRODUCT_EDIT_CHANGE_OPTION_LANGUAGE_INFO = 'SHOP_STAFF_PRODUCT_EDIT_CHANGE_OPTION_LANGUAGE_INFO';
export const SHOP_STAFF_PRODUCT_EDIT_CHANGE_OPTION_COUNTRY_INFO = 'SHOP_STAFF_PRODUCT_EDIT_CHANGE_OPTION_COUNTRY_INFO';
export const SHOP_STAFF_PRODUCT_EDIT_CHANGE_OPTION_COLOR_INFO = 'SHOP_STAFF_PRODUCT_EDIT_CHANGE_OPTION_COLOR_INFO';
export const SHOP_STAFF_PRODUCT_EDIT_CHANGE_RESOURCE_FILE_DATA = 'SHOP_STAFF_PRODUCT_EDIT_CHANGE_RESOURCE_FILE_DATA';
export const SHOP_STAFF_PRODUCT_EDIT_CHANGE_CATEGORY = 'SHOP_STAFF_PRODUCT_EDIT_CHANGE_CATEGORY';
export const SHOP_STAFF_PRODUCT_EDIT_SET_OPEN_HISTORY = 'SHOP_STAFF_PRODUCT_EDIT_SET_OPEN_HISTORY';
export const SHOP_STAFF_PRODUCT_EDIT_SET_CLOSE_HISTORY = 'SHOP_STAFF_PRODUCT_EDIT_SET_CLOSE_HISTORY';

export const SHOP_STAFF_PRODUCT_EDIT_ADD_TEMP_WORK_REQUEST = 'SHOP_STAFF_PRODUCT_EDIT_ADD_TEMP_WORK_REQUEST';
export const SHOP_STAFF_PRODUCT_EDIT_DELETE_TEMP_WORK_REQUEST = 'SHOP_STAFF_PRODUCT_EDIT_DELETE_TEMP_WORK_REQUEST';
export const SHOP_STAFF_PRODUCT_EDIT_ADD_WORK_REQUEST = 'SHOP_STAFF_PRODUCT_EDIT_ADD_WORK_REQUEST';
export const SHOP_STAFF_PRODUCT_EDIT_CHANGE_TEMP_WORK_REQUEST_IMAGE = 'SHOP_STAFF_PRODUCT_EDIT_CHANGE_TEMP_WORK_REQUEST_IMAGE'
export const SHOP_STAFF_PRODUCT_EDIT_DELETE_TEMP_WORK_REQUEST_IMAGE = 'SHOP_STAFF_PRODUCT_EDIT_DELETE_TEMP_WORK_REQUEST_IMAGE';
export const SHOP_STAFF_PRODUCT_EDIT_CHANGE_TEMP_WORK_REQUEST_REQUEST_MESSAGE = 'SHOP_STAFF_PRODUCT_EDIT_CHANGE_TEMP_WORK_REQUEST_REQUEST_MESSAGE';
export const SHOP_STAFF_PRODUCT_EDIT_CHANGE_TEMP_WORK_REQUEST_SPEC_UNIT = 'SHOP_STAFF_PRODUCT_EDIT_CHANGE_TEMP_WORK_REQUEST_SPEC_UNIT';
export const SHOP_STAFF_PRODUCT_EDIT_CHANGE_TEMP_WORK_REQUEST_SPEC = 'SHOP_STAFF_PRODUCT_EDIT_CHANGE_TEMP_WORK_REQUEST_SPEC';
export const SHOP_STAFF_PRODUCT_EDIT_ADD_OPTIONS_SPEC_DATA = 'SHOP_STAFF_PRODUCT_ADD_ADD_OPTIONS_SPEC_DATA';
export const SHOP_STAFF_PRODUCT_EDIT_IMPORT_TEMP_WORK_REQUEST_SPEC = 'SHOP_STAFF_PRODUCT_ADD_IMPORT_TEMP_WORK_REQUEST_SPEC';

export const SHOP_STAFF_PRODUCT_EDIT_ADD_REQUIRED_ERRORS = 'SHOP_STAFF_PRODUCT_EDIT_ADD_REQUIRED_ERRORS';
export const SHOP_STAFF_PRODUCT_EDIT_ADD_REQUIRED_OPTION_ERRORS = 'SHOP_STAFF_PRODUCT_EDIT_ADD_REQUIRED_OPTION_ERRORS';
export const SHOP_STAFF_PRODUCT_EDIT_ADD_REQUIRED_I18N_PRODUCT_LANGUAGE_ERRORS = 'SHOP_STAFF_PRODUCT_EDIT_ADD_REQUIRED_I18N_PRODUCT_LANGUAGE_ERRORS';
export const SHOP_STAFF_PRODUCT_EDIT_ADD_REQUIRED_I18N_PRODUCT_COUNTRY_ERRORS = 'SHOP_STAFF_PRODUCT_EDIT_ADD_REQUIRED_I18N_PRODUCT_COUNTRY_ERRORS';
export const SHOP_STAFF_PRODUCT_EDIT_ADD_REQUIRED_TEMP_WORK_REQUEST_ERRORS = 'SHOP_STAFF_PRODUCT_EDIT_ADD_REQUIRED_TEMP_WORK_REQUEST_ERRORS';
export const SHOP_STAFF_PRODUCT_EDIT_CHANGE_TEMP_WORK_REQUEST_ALL_IMAGES = 'SHOP_STAFF_PRODUCT_EDIT_CHANGE_TEMP_WORK_REQUEST_ALL_IMAGES';
export const SHOP_STAFF_PRODUCT_EDIT_CHANGE_TEMP_WORK_REQUEST_ALL_SPECS = 'SHOP_STAFF_PRODUCT_EDIT_CHANGE_TEMP_WORK_REQUEST_ALL_SPECS';
export const SHOP_STAFF_PRODUCT_EDIT_TOGGLE_EDIT_MODE = 'SHOP_STAFF_PRODUCT_EDIT_TOGGLE_EDIT_MODE';

export const SHOP_STAFF_PRODUCT_EDIT_SET_IS_OPENED_CHANGE_ORDER_DIALOG = 'SHOP_STAFF_PRODUCT_EDIT_SET_IS_OPENED_CHANGE_ORDER_DIALOG';
export const SHOP_STAFF_PRODUCT_EDIT_REORDER = 'SHOP_STAFF_PRODUCT_EDIT_REORDER';
export const SHOP_STAFF_PRODUCT_EDIT_REORDER_LIST = 'SHOP_STAFF_PRODUCT_EDIT_REORDER_LIST';
export const SHOP_STAFF_PRODUCT_EDIT_CHANGE_OPTIONS_ORDER = 'SHOP_STAFF_PRODUCT_EDIT_CHANGE_OPTIONS_ORDER';

export const SHOP_STAFF_WHATS_NEWS_INIT_DATA = 'SHOP_STAFF_WHATS_NEWS_INIT_DATA';
export const SHOP_STAFF_WHATS_NEWS_CLEAR_TASK_STATE = 'SHOP_STAFF_WHATS_NEWS_CLEAR_TASK_STATE';
export const SHOP_STAFF_WHATS_NEWS_CHANGE_QUERY_PARAMS = 'SHOP_STAFF_WHATS_NEWS_CHANGE_QUERY_PARAMS';
export const SHOP_STAFF_WHATS_NEWS_CHANGE_PERIOD = 'SHOP_STAFF_WHATS_NEWS_CHANGE_PERIOD';
export const SHOP_STAFF_WHATS_NEWS_RESET_QUERY_PARAMS = 'SHOP_STAFF_WHATS_NEWS_RESET_QUERY_PARAMS';
export const SHOP_STAFF_WHATS_NEWS_DIALOG_CLEAR_STATE = 'SHOP_STAFF_WHATS_NEWS_DIALOG_CLEAR_STATE';
export const SHOP_STAFF_WHATS_NEWS_SET_SELECTED_ITEM = 'SHOP_STAFF_WHATS_NEWS_SET_SELECTED_ITEM';
export const SHOP_STAFF_WHATS_NEWS_SET_SELECTED_PRODUCT = 'SHOP_STAFF_WHATS_NEWS_SET_SELECTED_PRODUCT';
export const SHOP_STAFF_WHATS_NEWS_SET_OPEN_DIALOG = 'SHOP_STAFF_WHATS_NEWS_SET_OPEN_DIALOG';
export const SHOP_STAFF_WHATS_NEWS_CHANGE_SEACH_QUERY = 'SHOP_STAFF_WHATS_NEWS_CHANGE_SEACH_QUERY';
export const SHOP_STAFF_WHATS_NEWS_OPEN_CHANGE_ORDER = 'SHOP_STAFF_WHATS_NEWS_OPEN_CHANGE_ORDER';
export const SHOP_STAFF_WHATS_NEWS_SELECT_BRAND_IN_CHANGE_ORDER = 'SHOP_STAFF_WHATS_NEWS_SELECT_BRAND_IN_CHANGE_ORDER';
export const SHOP_STAFF_WHATS_NEWS_SELECT_CATEGORY_IN_CHANGE_ORDER = 'SHOP_STAFF_WHATS_NEWS_SELECT_CATEGORY_IN_CHANGE_ORDER';
export const SHOP_STAFF_WHATS_NEWS_REORDER = 'SHOP_STAFF_WHATS_NEWS_REORDER';
export const SHOP_STAFF_WHATS_NEWS_RENDERED_REORDER_LIST = 'SHOP_STAFF_WHATS_NEWS_RENDERED_REORDER_LIST';
export const SHOP_STAFF_WHATS_NEWS_CLEAR_CHANGE_ORDER_STATE = 'SHOP_STAFF_WHATS_NEWS_CLEAR_CHANGE_ORDER_STATE';


export const SHOP_STAFF_PRODUCT_SIZES_CLEAR_TASK_STATE = 'SHOP_STAFF_PRODUCT_SIZES_CLEAR_TASK_STATE';
export const SHOP_STAFF_PRODUCT_SIZES_INIT_DATA = 'SHOP_STAFF_PRODUCT_SIZES_INIT_DATA';
export const SHOP_STAFF_PRODUCT_SIZES_SET_SELECTED_ITEM = 'SHOP_STAFF_PRODUCT_SIZES_SET_SELECTED_ITEM';

export const SHOP_STAFF_PRODUCT_SIZE_ADD_CLEAR_CREATE_STATE = 'SHOP_STAFF_PRODUCT_SIZE_ADD_CLEAR_CREATE_STATE';
export const SHOP_STAFF_PRODUCT_SIZE_ADD_CHANGE_PRODUCT_SIZE = 'SHOP_STAFF_PRODUCT_SIZE_ADD_CHANGE_PRODUCT_SIZE';
export const SHOP_STAFF_PRODUCT_SIZE_ADD_ADD_LANGUAGE_CODE = 'SHOP_STAFF_PRODUCT_SIZE_ADD_ADD_LANGUAGE_CODE';
export const SHOP_STAFF_PRODUCT_SIZE_ADD_DELETE_LANGUAGE_CODE = 'SHOP_STAFF_PRODUCT_SIZE_ADD_DELETE_LANGUAGE_CODE';
export const SHOP_STAFF_PRODUCT_SIZE_ADD_SET_DEFAULT_LANGUAGE = 'SHOP_STAFF_PRODUCT_SIZE_ADD_SET_DEFAULT_LANGUAGE';
export const SHOP_STAFF_PRODUCT_SIZE_ADD_CHANGE_LANGUAGE_INFO = 'SHOP_STAFF_PRODUCT_SIZE_ADD_CHANGE_LANGUAGE_INFO';

export const SHOP_STAFF_PRODUCT_SIZE_EDIT_CLEAR_EDIT_STATE = 'SHOP_STAFF_PRODUCT_SIZE_EDIT_CLEAR_EDIT_STATE';
export const SHOP_STAFF_PRODUCT_SIZE_EDIT_CHANGE_PRODUCT_SIZE = 'SHOP_STAFF_PRODUCT_SIZE_EDIT_CHANGE_PRODUCT_SIZE';
export const SHOP_STAFF_PRODUCT_SIZE_EDIT_ADD_LANGUAGE_CODE = 'SHOP_STAFF_PRODUCT_SIZE_EDIT_ADD_LANGUAGE_CODE';
export const SHOP_STAFF_PRODUCT_SIZE_EDIT_DELETE_LANGUAGE_CODE = 'SHOP_STAFF_PRODUCT_SIZE_EDIT_DELETE_LANGUAGE_CODE';
export const SHOP_STAFF_PRODUCT_SIZE_EDIT_SET_DEFAULT_LANGUAGE = 'SHOP_STAFF_PRODUCT_SIZE_EDIT_SET_DEFAULT_LANGUAGE';
export const SHOP_STAFF_PRODUCT_SIZE_EDIT_CHANGE_LANGUAGE_INFO = 'SHOP_STAFF_PRODUCT_SIZE_EDIT_CHANGE_LANGUAGE_INFO';

export const SHOP_STAFF_PRODUCT_COLORS_CLEAR_TASK_STATE = 'SHOP_STAFF_PRODUCT_COLORS_CLEAR_TASK_STATE';
export const SHOP_STAFF_PRODUCT_COLORS_INIT_DATA = 'SHOP_STAFF_PRODUCT_COLORS_INIT_DATA';
export const SHOP_STAFF_PRODUCT_COLORS_SET_SELECTED_ITEM = 'SHOP_STAFF_PRODUCT_COLORS_SET_SELECTED_ITEM';

export const SHOP_STAFF_PRODUCT_COLOR_ADD_CLEAR_CREATE_STATE = 'SHOP_STAFF_PRODUCT_COLOR_ADD_CLEAR_CREATE_STATE';
export const SHOP_STAFF_PRODUCT_COLOR_ADD_CHANGE_PRODUCT_COLOR = 'SHOP_STAFF_PRODUCT_COLOR_ADD_CHANGE_PRODUCT_COLOR';
export const SHOP_STAFF_PRODUCT_COLOR_ADD_ADD_LANGUAGE_CODE = 'SHOP_STAFF_PRODUCT_COLOR_ADD_ADD_LANGUAGE_CODE';
export const SHOP_STAFF_PRODUCT_COLOR_ADD_DELETE_LANGUAGE_CODE = 'SHOP_STAFF_PRODUCT_COLOR_ADD_DELETE_LANGUAGE_CODE';
export const SHOP_STAFF_PRODUCT_COLOR_ADD_SET_DEFAULT_LANGUAGE = 'SHOP_STAFF_PRODUCT_COLOR_ADD_SET_DEFAULT_LANGUAGE';
export const SHOP_STAFF_PRODUCT_COLOR_ADD_CHANGE_LANGUAGE_INFO = 'SHOP_STAFF_PRODUCT_COLOR_ADD_CHANGE_LANGUAGE_INFO';
export const SHOP_STAFF_PRODUCT_COLOR_ADD_TOGGLE_ADD_MODE = 'SHOP_STAFF_PRODUCT_COLOR_ADD_TOGGLE_ADD_MODE';

export const SHOP_STAFF_PRODUCT_COLOR_EDIT_CLEAR_EDIT_STATE = 'SHOP_STAFF_PRODUCT_COLOR_EDIT_CLEAR_EDIT_STATE';
export const SHOP_STAFF_PRODUCT_COLOR_EDIT_CHANGE_PRODUCT_COLOR = 'SHOP_STAFF_PRODUCT_COLOR_EDIT_CHANGE_PRODUCT_COLOR';
export const SHOP_STAFF_PRODUCT_COLOR_EDIT_ADD_LANGUAGE_CODE = 'SHOP_STAFF_PRODUCT_COLOR_EDIT_ADD_LANGUAGE_CODE';
export const SHOP_STAFF_PRODUCT_COLOR_EDIT_DELETE_LANGUAGE_CODE = 'SHOP_STAFF_PRODUCT_COLOR_EDIT_DELETE_LANGUAGE_CODE';
export const SHOP_STAFF_PRODUCT_COLOR_EDIT_SET_DEFAULT_LANGUAGE = 'SHOP_STAFF_PRODUCT_COLOR_EDIT_SET_DEFAULT_LANGUAGE';
export const SHOP_STAFF_PRODUCT_COLOR_EDIT_CHANGE_LANGUAGE_INFO = 'SHOP_STAFF_PRODUCT_COLOR_EDIT_CHANGE_LANGUAGE_INFO';
export const SHOP_STAFF_PRODUCT_COLOR_EDIT_TOGGLE_EDIT_MODE = 'SHOP_STAFF_PRODUCT_COLOR_EDIT_TOGGLE_EDIT_MODE';

export const SHOP_STAFF_SHOP_INFO_CLEAR = 'SHOP_STAFF_SHOP_INFO_CLEAR';
export const SHOP_STAFF_SHOP_INFO_CLEAR_EDIT_STATE = 'SHOP_STAFF_SHOP_INFO_CLEAR_EDIT_STATE';
export const SHOP_STAFF_SHOP_INFO_ADD_LANGUAGE_CODE = 'SHOP_STAFF_SHOP_INFO_ADD_LANGUAGE_CODE';
export const SHOP_STAFF_SHOP_INFO_DELETE_LANGUAGE_CODE = 'SHOP_STAFF_SHOP_INFO_DELETE_LANGUAGE_CODE';
export const SHOP_STAFF_SHOP_INFO_SET_DEFAULT_LANGUAGE = 'SHOP_STAFF_SHOP_INFO_SET_DEFAULT_LANGUAGE';
export const SHOP_STAFF_SHOP_INFO_CHANGE_SHOP = 'SHOP_STAFF_SHOP_INFO_CHANGE_SHOP';
export const SHOP_STAFF_SHOP_INFO_CHANGE_LANGUAGE_INFO = 'SHOP_STAFF_SHOP_INFO_CHANGE_LANGUAGE_INFO';

export const SHOP_STAFF_PLUGIN_SETTINGS_CLEAR = 'SHOP_STAFF_PLUGIN_SETTINGS_CLEAR';
export const SHOP_STAFF_PLUGIN_SETTINGS_CLEAR_EDIT_STATE = 'SHOP_STAFF_PLUGIN_SETTINGS_CLEAR_EDIT_STATE';
export const SHOP_STAFF_PLUGIN_SETTINGS_CLEAR_BUILDER_SCRIPTTAG_STATE = 'SHOP_STAFF_PLUGIN_SETTINGS_CLEAR_BUILDER_SCRIPTTAG_STATE';
export const SHOP_STAFF_PLUGIN_SETTINGS_CHANGE_PLUGIN = 'SHOP_STAFF_PLUGIN_SETTINGS_CHANGE_PLUGIN';
export const SHOP_STAFF_PLUGIN_SETTINGS_CHANGE_PLUGIN_TEMPLATE = 'SHOP_STAFF_PLUGIN_SETTINGS_CHANGE_PLUGIN_TEMPLATE';
export const SHOP_STAFF_PLUGIN_SETTINGS_DELETE_PLUGIN_TEMPLATE_FLOATING_IMAGE = 'SHOP_STAFF_PLUGIN_SETTINGS_DELETE_PLUGIN_TEMPLATE_FLOATING_IMAGE';
export const SHOP_STAFF_PLUGIN_CHANGE_SKIN_FILTER_VIEWER_AR_MODEL_TRANSFORM_INFO = 'SHOP_STAFF_PLUGIN_CHANGE_SKIN_FILTER_VIEWER_AR_MODEL_TRANSFORM_INFO';
export const SHOP_STAFF_PLUGIN_RESET_SKIN_FILTER_VIEWER_AR_MODEL_TRANSFORM = 'SHOP_STAFF_PLUGIN_RESET_SKIN_FILTER_VIEWER_AR_MODEL_TRANSFORM';

export const SHOP_STAFF_CONTROLLER_GET_SELECTED_PRODUCT_OPTION = 'SHOP_STAFF_CONTROLLER_GET_SELECTED_PRODUCT_OPTION';
export const SHOP_STAFF_CONTROLLER_RESET_MODEL_TRANSFORM = 'SHOP_STAFF_CONTROLLER_RESET_MODEL_TRANSFORM';
export const SHOP_STAFF_CONTROLLER_CHANGE_MODEL_DIRECTION = 'SHOP_STAFF_CONTROLLER_CHANGE_MODEL_DIRECTION';
export const SHOP_STAFF_CONTROLLER_CHANGE_MODEL_TRANSFORM = 'SHOP_STAFF_CONTROLLER_CHANGE_MODEL_TRANSFORM';

/* ADMIN */
export const ADMIN_SIGNIN_CLEAR = 'ADMIN_SIGNIN_CLEAR';
export const ADMIN_SIGNIN_ENTER_EMAIL = 'ADMIN_SIGNIN_ENTER_EMAIL';
export const ADMIN_SIGNIN_ENTER_PASSWORD = 'ADMIN_SIGNIN_ENTER_PASSWORD';

export const ADMIN_PRODUCTS_CLEAR_TASK_STATE = 'ADMIN_PRODUCTS_CLEAR_TASK_STATE';
export const ADMIN_PRODUCTS_INIT_DATA = 'ADMIN_PRODUCTS_INIT_DATA';
export const ADMIN_PRODUCTS_CHANGE_QUERY_PARAMS = 'ADMIN_PRODUCTS_CHANGE_QUERY_PARAMS';
export const ADMIN_PRODUCTS_CHANGE_PERIOD = 'ADMIN_PRODUCTS_CHANGE_PERIOD';
export const ADMIN_PRODUCTS_RESET_QUERY_PARAMS = 'ADMIN_PRODUCTS_RESET_QUERY_PARAMS';
export const ADMIN_PRODUCTS_SET_SELECTED_ITEM = 'ADMIN_PRODUCTS_SET_SELECTED_ITEM';
export const ADMIN_PRODUCTS_SET_IS_WORK_REQUEST_OPEN = 'ADMIN_PRODUCTS_SET_IS_WORK_REQUEST_OPEN';

export const ADMIN_PRODUCT_ADD_INIT_DATA = 'ADMIN_PRODUCT_ADD_INIT_DATA';
export const ADMIN_PRODUCT_ADD_CLEAR_CREATE_STATE = 'ADMIN_PRODUCT_ADD_CLEAR_CREATE_STATE';
export const ADMIN_PRODUCT_ADD_CLEAR_IMPORT_BUILDER_PRODUCT_INFO_STATE = 'ADMIN_PRODUCT_ADD_CLEAR_IMPORT_BUILDER_PRODUCT_INFO_STATE';
export const ADMIN_PRODUCT_ADD_ADD_PRODUCT_SIZE = 'ADMIN_PRODUCT_ADD_ADD_PRODUCT_SIZE';
export const ADMIN_PRODUCT_ADD_ADD_PRODUCT_COLOR = 'ADMIN_PRODUCT_ADD_ADD_PRODUCT_COLOR';
export const ADMIN_PRODUCT_ADD_SET_DEFAULT_LANG_AND_COUNTRY = 'ADMIN_PRODUCT_ADD_SET_DEFAULT_LANG_AND_COUNTRY';
export const ADMIN_PRODUCT_ADD_ADD_LANGUAGE_CODE = 'ADMIN_PRODUCT_ADD_ADD_LANGUAGE_CODE';
export const ADMIN_PRODUCT_ADD_DELETE_LANGUAGE_CODE = 'ADMIN_PRODUCT_ADD_DELETE_LANGUAGE_CODE';
export const ADMIN_PRODUCT_ADD_SET_DEFAULT_LANGUAGE = 'ADMIN_PRODUCT_ADD_SET_DEFAULT_LANGUAGE';
export const ADMIN_PRODUCT_ADD_ADD_COUNTRY_CODE = 'ADMIN_PRODUCT_ADD_ADD_COUNTRY_CODE';
export const ADMIN_PRODUCT_ADD_DELETE_COUNTRY_CODE = 'ADMIN_PRODUCT_ADD_DELETE_COUNTRY_CODE';
export const ADMIN_PRODUCT_ADD_SET_DEFAULT_COUNTRY = 'ADMIN_PRODUCT_ADD_SET_DEFAULT_COUNTRY';
export const ADMIN_PRODUCT_ADD_CHANGE_PRODUCT = 'ADMIN_PRODUCT_ADD_CHANGE_PRODUCT';
export const ADMIN_PRODUCT_ADD_CHANGE_LANGUAGE_INFO = 'ADMIN_PRODUCT_ADD_CHANGE_LANGUAGE_INFO';
export const ADMIN_PRODUCT_ADD_CHANGE_COUNTRY_INFO = 'ADMIN_PRODUCT_ADD_CHANGE_COUNTRY_INFO';
export const ADMIN_PRODUCT_ADD_ADD_OPTION = 'ADMIN_PRODUCT_ADD_ADD_OPTION';
export const ADMIN_PRODUCT_ADD_CHANGE_OPTION = 'ADMIN_PRODUCT_ADD_CHANGE_OPTION';
export const ADMIN_PRODUCT_ADD_CHANGE_OPTION_COLOR = 'ADMIN_PRODUCT_ADD_CHANGE_OPTION_COLOR';
export const ADMIN_PRODUCT_ADD_DELETE_OPTION = 'ADMIN_PRODUCT_ADD_DELETE_OPTION';
export const ADMIN_PRODUCT_ADD_CHANGE_OPTION_LANGUAGE_INFO = 'ADMIN_PRODUCT_ADD_CHANGE_OPTION_LANGUAGE_INFO';
export const ADMIN_PRODUCT_ADD_CHANGE_OPTION_COUNTRY_INFO = 'ADMIN_PRODUCT_ADD_CHANGE_OPTION_COUNTRY_INFO';
export const ADMIN_PRODUCT_ADD_CHANGE_OPTION_COLOR_INFO = 'ADMIN_PRODUCT_ADD_CHANGE_OPTION_COLOR_INFO';
export const ADMIN_PRODUCT_ADD_CHANGE_LIGHTING_ENV_PRESET = 'ADMIN_PRODUCT_ADD_CHANGE_LIGHTING_ENV_PRESET';
export const ADMIN_PRODUCT_ADD_CHANGE_LIGHTING_ENV_EXPOSURE = 'ADMIN_PRODUCT_ADD_CHANGE_LIGHTING_ENV_EXPOSURE';
export const ADMIN_PRODUCT_ADD_ADD_3D_FILE_DATA = 'ADMIN_PRODUCT_ADD_ADD_3D_FILE_DATA';
export const ADMIN_PRODUCT_ADD_DELETE_3D_FILE_DATA = 'ADMIN_PRODUCT_ADD_DELETE_3D_FILE_DATA';
export const ADMIN_PRODUCT_ADD_ADD_AR_MODEL_TRANSFORM_INFO = 'ADMIN_PRODUCT_ADD_ADD_AR_MODEL_TRANSFORM_INFO';
export const ADMIN_PRODUCT_ADD_DELETE_AR_MODEL_TRANSFORM_INFO = 'ADMIN_PRODUCT_ADD_DELETE_AR_MODEL_TRANSFORM_INFO';
export const ADMIN_PRODUCT_ADD_ADD_TEMP_AR_MODEL_TRANSFORM_INFO = 'ADMIN_PRODUCT_ADD_ADD_TEMP_AR_MODEL_TRANSFORM_INFO';
export const ADMIN_PRODUCT_ADD_CHANGE_TEMP_AR_MODEL_TRANSFORM_INFO = 'ADMIN_PRODUCT_ADD_CHANGE_TEMP_AR_MODEL_TRANSFORM_INFO';
export const ADMIN_PRODUCT_ADD_CHANGE_TEMP_ARMTIS_I18N_LANGUAGE_NAME = 'ADMIN_PRODUCT_ADD_CHANGE_TEMP_ARMTIS_I18N_LANGUAGE_NAME';
export const ADMIN_PRODUCT_ADD_DELETE_TEMP_AR_MODEL_TRANSFORM_INFO = 'ADMIN_PRODUCT_ADD_DELETE_TEMP_AR_MODEL_TRANSFORM_INFO';
export const ADMIN_PRODUCT_ADD_GET_PREV_OPTION_AR_MODEL_TRANSFORM_INFO = 'ADMIN_PRODUCT_ADD_GET_PREV_OPTION_AR_MODEL_TRANSFORM_INFO';
export const ADMIN_PRODUCT_ADD_ADD_ALL_AR_MODEL_TRANSFORM_INFO = 'ADMIN_PRODUCT_ADD_ADD_ALL_AR_MODEL_TRANSFORM_INFO';
export const ADMIN_PRODUCT_ADD_CHANGE_CATEGORY = 'ADMIN_PRODUCT_ADD_CHANGE_CATEGORY';
export const ADMIN_PRODUCT_ADD_CHANGE_ARMTI_ORDER = 'ADMIN_PRODUCT_ADD_CHANGE_ARMTI_ORDER';
export const ADMIN_PRODUCT_ADD_CHANGE_ENV_ROTATION = 'ADMIN_PRODUCT_ADD_CHANGE_ENV_ROTATION';
export const ADMIN_PRODUCT_ADD_CHANGE_FACE_EFFECT_INFO_IMAGE = 'ADMIN_PRODUCT_ADD_CHANGE_FACE_EFFECT_INFO_IMAGE';
export const ADMIN_PRODUCT_ADD_CHANGE_FACE_EFFECT_INFO_EXTRA_TOP_AREA = 'ADMIN_PRODUCT_ADD_CHANGE_FACE_EFFECT_INFO_EXTRA_TOP_AREA';
export const ADMIN_PRODUCT_EDIT_CHANGE_FACE_EFFECT_INFO_IMAGE = 'ADMIN_PRODUCT_EDIT_CHANGE_FACE_EFFECT_INFO_IMAGE';
export const ADMIN_PRODUCT_EDIT_CHANGE_FACE_EFFECT_INFO_EXTRA_TOP_AREA = 'ADMIN_PRODUCT_EDIT_CHANGE_FACE_EFFECT_INFO_EXTRA_TOP_AREA';
export const ADMIN_PRODUCT_ADD_CHANGE_OPTION_LENS_SAME_SHAPE_CHECK = 'ADMIN_PRODUCT_ADD_CHANGE_OPTION_LENS_SAME_SHAPE_CHECK';
export const ADMIN_PRODUCT_ADD_CHANGE_OPTION_LENS_IMAGE = 'ADMIN_PRODUCT_ADD_CHANGE_OPTION_LENS_IMAGE';
export const ADMIN_PRODUCT_ADD_DELETE_OPTION_CONTACT_LENS_IMAGE = 'ADMIN_PRODUCT_ADD_DELETE_OPTION_CONTACT_LENS_IMAGE';
export const ADMIN_PRODUCT_ADD_CHANGE_OPTION_CONTACT_LENS_BLENDING = 'ADMIN_PRODUCT_ADD_CHANGE_OPTION_CONTACT_LENS_BLENDING';
export const ADMIN_PRODUCT_ADD_CHANGE_OPTION_CONTACT_LENS_INPUT = 'ADMIN_PRODUCT_ADD_CHANGE_OPTION_CONTACT_LENS_INPUT';
export const ADMIN_PRODUCT_ADD_TOGGLE_ADD_MODE = 'ADMIN_PRODUCT_ADD_TOGGLE_ADD_MODE';
export const ADMIN_PRODUCT_ADD_REQUIRED_ERRORS = 'ADMIN_PRODUCT_ADD_REQUIRED_ERRORS';
export const ADMIN_PRODUCT_ADD_REQUIRED_OPTION_ERRORS = 'ADMIN_PRODUCT_ADD_REQUIRED_OPTION_ERRORS';
export const ADMIN_PRODUCT_ADD_REQUIRED_I18N_PRODUCT_LANGUAGE_ERRORS = 'ADMIN_PRODUCT_ADD_REQUIRED_I18N_PRODUCT_LANGUAGE_ERRORS';
export const ADMIN_PRODUCT_ADD_REQUIRED_I18N_PRODUCT_COUNTRIES_ERRORS = 'ADMIN_PRODUCT_ADD_REQUIRED_I18N_PRODUCT_COUNTRIES_ERRORS';

export const ADMIN_PRODUCT_ADD_SET_IS_OPENED_CHANGE_ORDER_DIALOG = 'ADMIN_PRODUCT_ADD_SET_IS_OPENED_CHANGE_ORDER_DIALOG';
export const ADMIN_PRODUCT_ADD_REORDER = 'ADMIN_PRODUCT_ADD_REORDER';
export const ADMIN_PRODUCT_ADD_REORDER_LIST = 'ADMIN_PRODUCT_ADD_REORDER_LIST';
export const ADMIN_PRODUCT_ADD_CHANGE_OPTIONS_ORDER = 'ADMIN_PRODUCT_ADD_CHANGE_OPTIONS_ORDER';

export const ADMIN_PRODUCT_EDIT_INIT_DATA = 'ADMIN_PRODUCT_EDIT_INIT_DATA';
export const ADMIN_PRODUCT_EDIT_CLEAR_EDIT_STATE = 'ADMIN_PRODUCT_EDIT_CLEAR_EDIT_STATE';
export const ADMIN_PRODUCT_EDIT_ADD_PRODUCT_SIZE = 'ADMIN_PRODUCT_EDIT_ADD_PRODUCT_SIZE';
export const ADMIN_PRODUCT_EDIT_ADD_PRODUCT_COLOR = 'ADMIN_PRODUCT_EDIT_ADD_PRODUCT_COLOR';
export const ADMIN_PRODUCT_EDIT_ADD_LANGUAGE_CODE = 'ADMIN_PRODUCT_EDIT_ADD_LANGUAGE_CODE';
export const ADMIN_PRODUCT_EDIT_DELETE_LANGUAGE_CODE = 'ADMIN_PRODUCT_EDIT_DELETE_LANGUAGE_CODE';
export const ADMIN_PRODUCT_EDIT_SET_DEFAULT_LANGUAGE = 'ADMIN_PRODUCT_EDIT_SET_DEFAULT_LANGUAGE';
export const ADMIN_PRODUCT_EDIT_ADD_COUNTRY_CODE = 'ADMIN_PRODUCT_EDIT_ADD_COUNTRY_CODE';
export const ADMIN_PRODUCT_EDIT_DELETE_COUNTRY_CODE = 'ADMIN_PRODUCT_EDIT_DELETE_COUNTRY_CODE';
export const ADMIN_PRODUCT_EDIT_SET_DEFAULT_COUNTRY = 'ADMIN_PRODUCT_EDIT_SET_DEFAULT_COUNTRY';
export const ADMIN_PRODUCT_EDIT_CHANGE_PRODUCT = 'ADMIN_PRODUCT_EDIT_CHANGE_PRODUCT';
export const ADMIN_PRODUCT_EDIT_CHANGE_LANGUAGE_INFO = 'ADMIN_PRODUCT_EDIT_CHANGE_LANGUAGE_INFO';
export const ADMIN_PRODUCT_EDIT_CHANGE_COUNTRY_INFO = 'ADMIN_PRODUCT_EDIT_CHANGE_COUNTRY_INFO';
export const ADMIN_PRODUCT_EDIT_ADD_OPTION = 'ADMIN_PRODUCT_EDIT_ADD_OPTION';
export const ADMIN_PRODUCT_EDIT_CHANGE_OPTION = 'ADMIN_PRODUCT_EDIT_CHANGE_OPTION';
export const ADMIN_PRODUCT_EDIT_CHANGE_OPTION_COLOR = 'ADMIN_PRODUCT_EDIT_CHANGE_OPTION_COLOR';
export const ADMIN_PRODUCT_EDIT_DELETE_OPTION = 'ADMIN_PRODUCT_EDIT_DELETE_OPTION';
export const ADMIN_PRODUCT_EDIT_CHANGE_OPTION_LANGUAGE_INFO = 'ADMIN_PRODUCT_EDIT_CHANGE_OPTION_LANGUAGE_INFO';
export const ADMIN_PRODUCT_EDIT_CHANGE_OPTION_COUNTRY_INFO = 'ADMIN_PRODUCT_EDIT_CHANGE_OPTION_COUNTRY_INFO';
export const ADMIN_PRODUCT_EDIT_CHANGE_OPTION_COLOR_INFO = 'ADMIN_PRODUCT_EDIT_CHANGE_OPTION_COLOR_INFO';
export const ADMIN_PRODUCT_EDIT_CHANGE_LIGHTING_ENV_PRESET = 'ADMIN_PRODUCT_EDIT_CHANGE_LIGHTING_ENV_PRESET';
export const ADMIN_PRODUCT_EDIT_CHANGE_LIGHTING_ENV_EXPOSURE = 'ADMIN_PRODUCT_EDIT_CHANGE_LIGHTING_ENV_EXPOSURE';
export const ADMIN_PRODUCT_EDIT_ADD_3D_FILE_DATA = 'ADMIN_PRODUCT_EDIT_ADD_3D_FILE_DATA';
export const ADMIN_PRODUCT_EDIT_DELETE_3D_FILE_DATA = 'ADMIN_PRODUCT_EDIT_DELETE_3D_FILE_DATA';
export const ADMIN_PRODUCT_EDIT_CHANGE_COMMENT = 'ADMIN_PRODUCT_EDIT_CHANGE_COMMENT';
export const ADMIN_PRODUCT_EDIT_CHANGE_WORK_REQUEST_STATUS = 'ADMIN_PRODUCT_EDIT_CHANGE_WORK_REQUEST_STATUS';
export const ADMIN_PRODUCT_EDIT_SET_IS_OPEN_WORK_REQUEST_HISTORIES = 'ADMIN_PRODUCT_EDIT_SET_IS_OPEN_WORK_REQUEST_HISTORIES';
export const ADMIN_PRODUCT_EDIT_ADD_AR_MODEL_TRANSFORM_INFO = 'ADMIN_PRODUCT_EDIT_ADD_AR_MODEL_TRANSFORM_INFO';
export const ADMIN_PRODUCT_EDIT_DELETE_AR_MODEL_TRANSFORM_INFO = 'ADMIN_PRODUCT_EDIT_DELETE_AR_MODEL_TRANSFORM_INFO';
export const ADMIN_PRODUCT_EDIT_ADD_TEMP_AR_MODEL_TRANSFORM_INFO = 'ADMIN_PRODUCT_EDIT_ADD_TEMP_AR_MODEL_TRANSFORM_INFO';
export const ADMIN_PRODUCT_EDIT_CHANGE_TEMP_AR_MODEL_TRANSFORM_INFO = 'ADMIN_PRODUCT_EDIT_CHANGE_TEMP_AR_MODEL_TRANSFORM_INFO';
export const ADMIN_PRODUCT_EDIT_CHANGE_TEMP_ARMTIS_I18N_LANGUAGE_NAME = 'ADMIN_PRODUCT_EDIT_CHANGE_TEMP_ARMTIS_I18N_LANGUAGE_NAME';
export const ADMIN_PRODUCT_EDIT_DELETE_TEMP_AR_MODEL_TRANSFORM_INFO = 'ADMIN_PRODUCT_EDIT_DELETE_TEMP_AR_MODEL_TRANSFORM_INFO';
export const ADMIN_PRODUCT_EDIT_GET_PREV_OPTION_AR_MODEL_TRANSFORM_INFO = 'ADMIN_PRODUCT_EDIT_GET_PREV_OPTION_AR_MODEL_TRANSFORM_INFO';
export const ADMIN_PRODUCT_EDIT_ADD_ALL_AR_MODEL_TRANSFORM_INFO = 'ADMIN_PRODUCT_EDIT_ADD_ALL_AR_MODEL_TRANSFORM_INFO';
export const ADMIN_PRODUCT_EDIT_SET_OPEN_HISTORY = 'ADMIN_PRODUCT_EDIT_SET_IS_OPEN_HISTORY';
export const ADMIN_PRODUCT_EDIT_SET_CLOSE_HISTORY = 'ADMIN_PRODUCT_EDIT_SET_CLOSE_HISTORY';
export const ADMIN_PRODUCT_EDIT_CHANGE_CATEGORY = 'ADMIN_PRODUCT_EDIT_CHANGE_CATEGORY';
export const ADMIN_PRODUCT_EDIT_CHANGE_ARMTI_ORDER = 'ADMIN_PRODUCT_EDIT_CHANGE_ARMTI_ORDER';
export const ADMIN_PRODUCT_EDIT_CHANGE_ENV_ROTATION = 'ADMIN_PRODUCT_EDIT_CHANGE_ENV_ROTATION';
export const ADMIN_PRODUCT_EDIT_CHANGE_OPTION_LENS_SAME_SHAPE_CHECK = 'ADMIN_PRODUCT_EDIT_CHANGE_OPTION_LENS_SAME_SHAPE_CHECK';
export const ADMIN_PRODUCT_EDIT_CHANGE_OPTION_CONTACT_LENS_IMAGE = 'ADMIN_PRODUCT_EDIT_CHANGE_OPTION_CONTACT_LENS_IMAGE';
export const ADMIN_PRODUCT_EDIT_DELETE_OPTION_CONTACT_LENS_IMAGE = 'ADMIN_PRODUCT_EDIT_DELETE_OPTION_CONTACT_LENS_IMAGE';
export const ADMIN_PRODUCT_EDIT_CHANGE_OPTION_CONTACT_LENS_BLENDING = 'ADMIN_PRODUCT_EDIT_CHANGE_OPTION_CONTACT_LENS_BLENDING';
export const ADMIN_PRODUCT_EDIT_CHANGE_OPTION_CONTACT_LENS_INPUT = 'ADMIN_PRODUCT_EDIT_CHANGE_OPTION_CONTACT_LENS_INPUT';
export const ADMIN_PRODUCT_EDIT_TOGGLE_EDIT_MODE = 'ADMIN_PRODUCT_EDIT_TOGGLE_EDIT_MODE';
export const ADMIN_PRODUCT_EDIT_REQUIRED_ERRORS = 'ADMIN_PRODUCT_EDIT_REQUIRED_ERRORS';
export const ADMIN_PRODUCT_EDIT_REQUIRED_OPTION_ERRORS = 'ADMIN_PRODUCT_EDIT_REQUIRED_OPTION_ERRORS';
export const ADMIN_PRODUCT_EDIT_REQUIRED_I18N_PRODUCT_LANGUAGE_ERRORS = 'ADMIN_PRODUCT_EDIT_REQUIRED_I18N_PRODUCT_LANGUAGE_ERRORS';
export const ADMIN_PRODUCT_EDIT_REQUIRED_I18N_PRODUCT_COUNTRIES_ERRORS = 'ADMIN_PRODUCT_EDIT_REQUIRED_I18N_PRODUCT_COUNTRIES_ERRORS';

export const ADMIN_CONTROLLER_GET_SELECTED_PRODUCT_OPTION = 'ADMIN_CONTROLLER_GET_SELECTED_PRODUCT_OPTION';
export const ADMIN_CONTROLLER_CHANGE_ARMTI_INDEX = 'ADMIN_CONTROLLER_CHANGE_ARMTI_INDEX';
export const ADMIN_CONTROLLER_CHANGE_MODEL_TRANSFORM = 'ADMIN_CONTROLLER_CHANGE_MODEL_TRANSFORM';
export const ADMIN_CONTROLLER_RESET_MODEL_TRANSFORM = 'ADMIN_CONTROLLER_RESET_MODEL_TRANSFORM';
export const ADMIN_CONTROLLER_CHANGE_MODEL_DIRECTION = 'ADMIN_CONTROLLER_CHANGE_MODEL_DIRECTION';
export const ADMIN_CONTROLLER_CHANGE_FACE_EFFECT_EXTRA_TOP_AREA = 'ADMIN_CONTROLLER_CHANGE_FACE_EFFECT_EXTRA_TOP_AREA';
export const ADMIN_CONTROLLER_CHANGE_FACE_EFFECT_IMAGE = 'ADMIN_CONTROLLER_CHANGE_FACE_EFFECT_IMAGE';
export const ADMIN_CONTROLLER_CHANGE_ENV_IMAGE_PRESETS = 'ADMIN_CONTROLLER_CHANGE_ENV_IMAGE_PRESETS';
export const ADMIN_CONTROLLER_CHANGE_LIGHTING_ENV_INPUTS = 'ADMIN_CONTROLLER_CHANGE_LIGHTING_ENV_INPUTS';

export const ADMIN_PRODUCT_EDIT_CHANGE_TEMP_WORK_REQUEST_STATUS = 'ADMIN_PRODUCT_EDIT_CHANGE_TEMP_WORK_REQUEST_STATUS';
export const ADMIN_PRODUCT_EDIT_CHANGE_TEMP_WORK_REQUEST_COMMENT = 'ADMIN_PRODUCT_EDIT_CHANGE_TEMP_WORK_REQUEST_COMMENT';
export const ADMIN_PRODUCT_EDIT_ADD_TEMP_WORK_REQUEST = 'ADMIN_PRODUCT_EDIT_ADD_TEMP_WORK_REQUEST';
export const ADMIN_PRODUCT_EDIT_DELETE_TEMP_WORK_REQUEST = 'ADMIN_PRODUCT_EDIT_DELETE_TEMP_WORK_REQUEST';
export const ADMIN_PRODUCT_EDIT_ADD_WORK_REQUEST = 'ADMIN_PRODUCT_EDIT_ADD_WORK_REQUEST';

export const ADMIN_PRODUCT_EDIT_SET_IS_OPENED_CHANGE_ORDER_DIALOG = 'ADMIN_PRODUCT_EDIT_SET_IS_OPENED_CHANGE_ORDER_DIALOG';
export const ADMIN_PRODUCT_EDIT_REORDER = 'ADMIN_PRODUCT_EDIT_REORDER';
export const ADMIN_PRODUCT_EDIT_REORDER_LIST = 'ADMIN_PRODUCT_EDIT_REORDER_LIST';
export const ADMIN_PRODUCT_EDIT_CHANGE_OPTIONS_ORDER = 'ADMIN_PRODUCT_EDIT_CHANGE_OPTIONS_ORDER';

export const ADMIN_SHOPS_INIT_DATA = 'ADMIN_SHOPS_INIT_DATA';
export const ADMIN_SHOPS_RESET_QUERY_PARAMS = 'ADMIN_SHOPS_RESET_QUERY_PARAMS';
export const ADMIN_SHOPS_CHANGE_QUERY_PARAMS = 'ADMIN_SHOPS_CHANGE_QUERY_PARAMS';
export const ADMIN_SHOPS_CHANGE_PERIOD = 'ADMIN_SHOPS_CHANGE_PERIOD';

export const ADMIN_SHOP_PAYMENT_HISTORY_INIT_DATA = 'ADMIN_SHOP_PAYMENT_HISTORY_INIT_DATA';
export const ADMIN_SHOP_PAYMENT_HISTORY_CHANGE_PERIOD = 'ADMIN_SHOP_PAYMENT_HISTORY_CHANGE_PERIOD';
export const ADMIN_SHOP_PAYMENT_HISTORY_RESET_QUERY_PARAMS = 'ADMIN_SHOP_PAYMENT_HISTORY_RESET_QUERY_PARAMS';
export const ADMIN_SHOP_PAYMENT_HISTORY_CHANGE_QUERY_PARAMS = 'ADMIN_SHOP_PAYMENT_HISTORY_CHANGE_QUERY_PARAMS';

export const ADMIN_SHOP_INFO_CLEAR = 'ADMIN_SHOP_INFO_CLEAR';
