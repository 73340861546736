import {fromJS} from "immutable";
import {
    PRODUCT_AR_VIEWER_SET_INITIALIZED,
    SHOP_STAFF_CONTROLLER_CHANGE_MODEL_DIRECTION,
    SHOP_STAFF_CONTROLLER_CHANGE_MODEL_TRANSFORM,
    SHOP_STAFF_CONTROLLER_GET_SELECTED_PRODUCT_OPTION,
    SHOP_STAFF_CONTROLLER_RESET_MODEL_TRANSFORM,
} from "../../actions/UIActionTypes";
import {
    FAIL_GET_PRODUCT_BY_SHOP_STAFF,
    FAIL_INIT_PRODUCT_AR_VIEWER_BY_SHOP_STAFF,
    FAIL_UPDATE_AR_MODEL_TRANSFORM_INFO_BY_SHOP_STAFF,
    REQUEST_GET_PRODUCT_BY_SHOP_STAFF,
    REQUEST_INIT_PRODUCT_AR_VIEWER_BY_SHOP_STAFF,
    REQUEST_UPDATE_AR_MODEL_TRANSFORM_INFO_BY_SHOP_STAFF,
    SUCCEED_GET_PRODUCT_BY_SHOP_STAFF,
    SUCCEED_INIT_PRODUCT_AR_VIEWER_BY_SHOP_STAFF,
    SUCCEED_UPDATE_AR_MODEL_TRANSFORM_INFO_BY_SHOP_STAFF
} from "../../actions/RESTActionTypes";
import {
    changeArModelTransform,
    setAllArModelTransform,
    setArModelTramsformUvMapPosition
} from "../../../behaviors/WebArArMtiBehavior";
import Product from "../../../models/Product";
import {getOptionIndex} from "../../../behaviors/ProductOptionBehavior";
import Shop from "../../../models/Shop";
import RestError from "../../../models/RestError";
import ArModelTransformInfo from "../../../models/ArModelTransformInfo";
import {ARMTI_LEFT_DIRECTION} from "../../../constants/ArMtiConstants";

export const initialShopStaffController = fromJS({
    error: null,
    isInitialized: false,
    isGetProductLoading: false,
    isGetProductError: false,
    isUpdateArMtiLoading: false,
    isSucceedUpdateArMti: false,
    isFailUpdateArMti: false,
    isOpenMobileDrawer: false,
    product: null,
    shop: null,
    selectedProductOptionIndex: null,
    tempArMti: null,
    selectedModelDirection: 1,
});

export function shopStaffController(state = initialShopStaffController, action) {
    let product;
    let productOptions;
    let armtis, arMti;
    let tempArMti;
    let centerOptionIndex, optionIndex;
    let selectedModelDirection;

    switch (action.type) {
        case PRODUCT_AR_VIEWER_SET_INITIALIZED:
            return state.set('isInitialized', true);

        case REQUEST_INIT_PRODUCT_AR_VIEWER_BY_SHOP_STAFF:
            return state.set('isGetProductLoading', true);

        case SUCCEED_INIT_PRODUCT_AR_VIEWER_BY_SHOP_STAFF:
            product = new Product(action.product);
            productOptions = product.get('options');
            centerOptionIndex = productOptions && Math.floor(productOptions.size / 2);
            optionIndex = productOptions && getOptionIndex(productOptions, centerOptionIndex);
            tempArMti = productOptions && productOptions.getIn([optionIndex, 'armtis', '0']);

            if (!tempArMti || !productOptions) {

                return state.set('isGetProductError', true)
                    .set('isGetProductLoading', false);
            }

            return state.set('isGetProductLoading', false)
                .set('shop', new Shop(action.shop))
                .set('product', product)
                .set('tempArMti', tempArMti)
                .set('selectedProductOptionIndex', optionIndex)
                .set('error', null);

        case FAIL_INIT_PRODUCT_AR_VIEWER_BY_SHOP_STAFF:
            return state.set('isGetProductLoading', false).set('error', new RestError(action.error));

        case REQUEST_GET_PRODUCT_BY_SHOP_STAFF:
            return state.set('isGetProductLoading', true);

        case SUCCEED_GET_PRODUCT_BY_SHOP_STAFF:
            product = new Product(action.result);
            productOptions = product.get('options');
            centerOptionIndex = Math.floor(productOptions.size / 2);
            optionIndex = getOptionIndex(productOptions, centerOptionIndex);
            tempArMti = productOptions.getIn([optionIndex, 'armtis', '0']);

            return state.set('isGetProductLoading', false)
                .set('product', product)
                .set('tempArMti', tempArMti)
                .set('selectedProductOptionIndex', optionIndex)
                .set('selectedModelDirection', ARMTI_LEFT_DIRECTION);

        case FAIL_GET_PRODUCT_BY_SHOP_STAFF:
            return state.set('isGetProductLoading', false).set('error', new RestError(action.error));

        case REQUEST_UPDATE_AR_MODEL_TRANSFORM_INFO_BY_SHOP_STAFF:
            return state.set('isUpdateArMtiLoading', true)
                .set('isSucceedUpdateArMti', false)
                .set('isFailUpdateArMti', false);

        case SUCCEED_UPDATE_AR_MODEL_TRANSFORM_INFO_BY_SHOP_STAFF:
            arMti = new ArModelTransformInfo(action.arMti);
            product = state.get('product');
            optionIndex = product.get('options').findIndex(item => item.get('id') === action.productOptionId);
            const arMtiIndex = product.getIn(['options', optionIndex, 'armtis'])
                .findIndex(item => item.get('id') === action.armtiId);

            product = product.setIn(['options', optionIndex, 'armtis', arMtiIndex], arMti);

            return state.set('isUpdateArMtiLoading', false)
                .set('isSucceedUpdateArMti', true)
                .set('product', product);

        case FAIL_UPDATE_AR_MODEL_TRANSFORM_INFO_BY_SHOP_STAFF:
            return state.set('isUpdateArMtiLoading', false)
                .set('isFailUpdateArMti', true)
                .set('error', new RestError(action.error));

        case SHOP_STAFF_CONTROLLER_GET_SELECTED_PRODUCT_OPTION:
            productOptions = state.getIn(['product', 'options']);

            const productOptionIndex = productOptions.findIndex(item => item.get('id') === action.productOptionId);
            let index = 0;

            if (productOptionIndex >= 0) {
                index = productOptionIndex;
                tempArMti = productOptions.getIn([productOptionIndex, 'armtis', '0'])
            }

            return state.set('selectedProductOptionIndex', index)
                .set('tempArMti', tempArMti ? tempArMti : null)
                .set('selectedModelDirection', ARMTI_LEFT_DIRECTION);

        case SHOP_STAFF_CONTROLLER_CHANGE_MODEL_TRANSFORM:
            selectedModelDirection = state.get('selectedModelDirection');
            tempArMti = state.get('tempArMti');

            if (action.key === 'uv_map_pos' || action.key === 'uv_map_pos_2nd') {
                selectedModelDirection === ARMTI_LEFT_DIRECTION
                    ? setArModelTramsformUvMapPosition(
                        +action.value,
                        tempArMti.get('uv_map_pos_2nd') ? tempArMti.get('uv_map_pos_2nd') : -1
                    )
                    : setArModelTramsformUvMapPosition(
                        tempArMti.get('uv_map_pos') ? tempArMti.get('uv_map_pos') : -1,
                        +action.value
                    );
            } else {
                selectedModelDirection === ARMTI_LEFT_DIRECTION
                    ? changeArModelTransform(action.key, action.value, null)
                    : changeArModelTransform(action.key, null, action.value)
            }


            return state.setIn(['tempArMti', action.key], parseFloat(action.value));

        case SHOP_STAFF_CONTROLLER_RESET_MODEL_TRANSFORM:
            const selectedProductOptionIndex = state.get('selectedProductOptionIndex');
            selectedModelDirection = state.get('selectedModelDirection');
            tempArMti = state.get('tempArMti');
            armtis = state.getIn(['product', 'options', selectedProductOptionIndex, 'armtis']);

            arMti = armtis.find(item => item.get('id') === tempArMti.get('id'));

            if (selectedModelDirection === ARMTI_LEFT_DIRECTION) {
                setAllArModelTransform(arMti, null);
                setArModelTramsformUvMapPosition(
                    arMti.get('uv_map_pos'),
                    arMti.get('uv_map_pos_2nd') ? arMti.get('uv_map_pos_2nd') : -1
                );
            } else {
                setAllArModelTransform(null, arMti);
                setArModelTramsformUvMapPosition(
                    arMti.get('uv_map_pos') ?  arMti.get('uv_map_pos') : -1,
                    arMti.get('uv_map_pos_2nd')
                );
            }

            return state.set('tempArMti', arMti);

        case SHOP_STAFF_CONTROLLER_CHANGE_MODEL_DIRECTION:
            return state.set('selectedModelDirection', parseInt(action.value));

        default:
            return state;
    }
}