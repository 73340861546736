import {EntityMixin} from "./Entity";
import {isRGB, isRGBA} from "../utils/RegexUtils";
import {PLUGIN_TEMPLATE_KEY, UPDATED_PREFIX} from "../constants/CommonConstants";
import {DetailArButtonStyle, DetailMvButtonStyle, FloatingButtonStyle} from "./PluginTemplateData";

export const DEFAULT_PRIMARY_COLOR = '#000000';

const PluginTemplateRecord = EntityMixin({
    primary_color: DEFAULT_PRIMARY_COLOR,
    floating_button_style: new FloatingButtonStyle(),
    detail_ar_button_style: new DetailArButtonStyle(),
    detail_mv_button_style: new DetailMvButtonStyle(),
});

class PluginTemplate extends PluginTemplateRecord {

    constructor(args = {}) {
        super({
            ...args,
            floating_button_style: args.floating_button_style
                ? new FloatingButtonStyle(args.floating_button_style) : new FloatingButtonStyle(),
            detail_ar_button_style: args.detail_ar_button_style
                ? new DetailArButtonStyle(args.detail_ar_button_style) : new DetailArButtonStyle(),
            detail_mv_button_style: args.detail_mv_button_style
                ? new DetailMvButtonStyle(args.detail_mv_button_style) : new DetailMvButtonStyle(),
        });
    }

    canEdit(oldbie) {
        if (!oldbie) {
            return false;
        }

        const isPrimaryColorValid = (isRGB(this.primary_color) || isRGBA(this.primary_color));

        return (isPrimaryColorValid && this.primary_color !== oldbie.get('primary_color'))
            || !this.floating_button_style.equals(oldbie.get('floating_button_style'))
            || !this.detail_ar_button_style.equals(oldbie.get('detail_ar_button_style'))
            || !this.detail_mv_button_style.equals(oldbie.get('detail_mv_button_style'));
    }

    makeFormData(formData, oldbiePlugin) {
        const shouldUpdate = oldbiePlugin && oldbiePlugin.get('id') > 0;
        const keyPrefix = shouldUpdate ? `${UPDATED_PREFIX}${PLUGIN_TEMPLATE_KEY}` : `${PLUGIN_TEMPLATE_KEY}`;
        let isItemAdded = false;

        if (this.primary_color) {
            if (shouldUpdate && oldbiePlugin) {
                if (this.primary_color !== oldbiePlugin.getIn(['template', 'primary_color'])) {
                    formData.append(`${keyPrefix}primary_color`, this.primary_color);
                }
            } else {
                isItemAdded = true;
                formData.append(`${keyPrefix}primary_color`, this.primary_color);
            }
        }

        if (this.floating_button_style) {
            if (shouldUpdate && oldbiePlugin) {
                if (
                    !this.floating_button_style.equals(oldbiePlugin.getIn(['template', 'floating_button_style']))
                ) {
                    formData.append(`${keyPrefix}floating_button_style`, this.floating_button_style.toJsonString());

                    if (this.floating_button_style.get('imageData')) {
                        formData.append(
                            `${keyPrefix}floating_button_style__image`,
                            this.floating_button_style.getIn(['imageData', 'file'])
                        );
                    } else {
                        if (!this.floating_button_style.get('image')
                            && oldbiePlugin.getIn(['template', 'floating_button_style', 'image'])) {
                            formData.append(`${keyPrefix}floating_button_style__image`, '');
                        }
                    }
                }
            } else {
                isItemAdded = true;
                formData.append(`${keyPrefix}floating_button_style`, this.floating_button_style.toJsonString());

                if (this.floating_button_style.get('imageData')) {
                    formData.append(
                        `${keyPrefix}floating_button_style__image`,
                        this.floating_button_style.getIn(['imageData', 'file'])
                    );
                }
            }
        }

        if (this.detail_ar_button_style) {
            if (shouldUpdate && oldbiePlugin) {
                if (
                    !this.detail_ar_button_style.equals(oldbiePlugin.getIn(['template', 'detail_ar_button_style']))
                ) {
                    formData.append(
                        `${keyPrefix}detail_ar_button_style`,
                        JSON.stringify(this.detail_ar_button_style.toJS())
                    );
                }
            } else {
                isItemAdded = true;
                formData.append(
                    `${keyPrefix}detail_ar_button_style`,
                    JSON.stringify(this.detail_ar_button_style.toJS())
                );
            }
        }

        if (this.detail_mv_button_style) {
            if (shouldUpdate && oldbiePlugin) {
                if (
                    !this.detail_mv_button_style.equals(oldbiePlugin.getIn(['template', 'detail_mv_button_style']))
                ) {
                    formData.append(
                        `${keyPrefix}detail_mv_button_style`,
                        JSON.stringify(this.detail_mv_button_style.toJS())
                    );
                }
            } else {
                isItemAdded = true;
                formData.append(
                    `${keyPrefix}detail_mv_button_style`,
                    JSON.stringify(this.detail_mv_button_style.toJS())
                );
            }
        }

        return !shouldUpdate && isItemAdded;
    }
}

export default PluginTemplate;
