import HmacSHA256 from "crypto-js/hmac-sha256";
import Hex from "crypto-js/enc-hex";
import AES from "crypto-js/aes";
import Base64 from "crypto-js/enc-base64";
import WordArray from "crypto-js/lib-typedarrays";

export function createHmac(msg, customKey = null) {
    if (customKey) {
        return HmacSHA256(msg, customKey).toString(Hex);
    } else {
        return HmacSHA256(msg, process.env.HMAC_SECRET_KEY).toString(Hex);
    }
}

export function createHmac2Base64(msg, key) {
    return HmacSHA256(msg, key).toString(Base64);
}

export function createUniqueId() {
    const autoIdAlphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const maxLength = autoIdAlphabet.length;
    let result = '';

    for (let i=0; i<24; i++) {
        result += autoIdAlphabet.charAt(Math.floor(Math.random() * maxLength));
    }
    return result;
}

export function convertStr2Base64(plain) {
    return Buffer.from(plain).toString('base64');
}

export function convertBase642Str(crypto) {
    return Buffer.from(crypto, 'base64').toString('ascii');
}

export async function decryptAesFromHex(hexData) {
    return new Promise((resolve) => {
        const buffer = toBuffer(convertWordArrayToUint8Array(Hex.parse(hexData)));

        decryptData2Buffer(
            Buffer.from(process.env.CRYPTO_SECRET_KEY).toString('hex'),
            buffer.slice(0, 16).toString('hex'),
            buffer.slice(16, 80)
        ).then(decryptedCredBlock => {
            return decryptData2Buffer(
                decryptedCredBlock.slice(16, decryptedCredBlock.length).toString('hex'),
                decryptedCredBlock.slice(0, 16).toString('hex'),
                buffer.slice(80, buffer.length)
            )
        }).then(decryptedBuffer => {
            resolve(decryptedBuffer.toString());
        });
    });
}

export async function decryptModelFile(fileArrayBuffer) {
    return new Promise((resolve) => {
        const buffer = toBuffer(fileArrayBuffer);

        decryptData2Buffer(
            Buffer.from(process.env.CRYPTO_SECRET_KEY).toString('hex'),
            buffer.slice(0, 16).toString('hex'),
            buffer.slice(16, 80)
        ).then(decryptedCredBlock => {
            return decryptData2Buffer(
                decryptedCredBlock.slice(16, decryptedCredBlock.length).toString('hex'),
                decryptedCredBlock.slice(0, 16).toString('hex'),
                buffer.slice(80, buffer.length)
            )
        }).then(decryptedBuffer => {
            resolve(toArrayBuffer(decryptedBuffer));
        });
    });
}

async function decryptData2Buffer(key, iv, encDataBuffer) {
    return new Promise((resolve) => {
        const dec = AES.decrypt(
            {ciphertext: WordArray.create(encDataBuffer)},
            Hex.parse(key),
            {iv: Hex.parse(iv)}
        );

        resolve(toBuffer(convertWordArrayToUint8Array(dec).buffer));
    });
}

/**
 * refs: https://www.kejisen.com/ko/article/205303863.html
 */
function convertWordArrayToUint8Array(wordArray) {
    const arrayOfWords = wordArray.hasOwnProperty("words") ? wordArray.words : [];
    const length = wordArray.hasOwnProperty("sigBytes") ? wordArray.sigBytes : arrayOfWords.length * 4;
    const uInt8Array = new Uint8Array(length);
    let index = 0;
    let word;

    for (let i=0; i<length; i++) {
        word = arrayOfWords[i];
        uInt8Array[index++] = word >> 24;
        uInt8Array[index++] = (word >> 16) & 0xff;
        uInt8Array[index++] = (word >> 8) & 0xff;
        uInt8Array[index++] = word & 0xff;
    }
    return uInt8Array;
}

/**
 * refs: https://stackoverflow.com/a/12101012
 * refs: https://gist.github.com/miguelmota/5b06ae5698877322d0ca
 */
function toArrayBuffer(buffer) {
    const ab = new ArrayBuffer(buffer.length);
    const view = new Uint8Array(ab);

    for (let i = 0; i < buffer.length; ++i) {
        view[i] = buffer[i];
    }
    return ab;
}

/**
 * refs: https://stackoverflow.com/a/12101012
 * refs: https://gist.github.com/miguelmota/5b06ae5698877322d0ca
 */
function toBuffer(arrayBuffer) {
    const buffer = new Buffer(arrayBuffer.byteLength);
    const view = new Uint8Array(arrayBuffer);

    for (let i = 0; i < buffer.length; ++i) {
        buffer[i] = view[i];
    }
    return buffer;
}
