const REGEX_NUMBER = /^\d+$/;
const REGEX_EMAIL = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const REGEX_DOMAIN = /http[s]?:\/\/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9](:(\d*))*$/;
const REGEX_DOMAIN_LOCALHOST = /http[s]?:\/\/localhost(:(\d*))*$/;
const REGEX_URL = /http[s]?:\/\/(?:[a-zA-Z]|[0-9]|[$-_@.&+]|[!*\(\), ]|(?:%[0-9a-fA-F][0-9a-fA-F]))+/;
const REGEX_SIMPLE_PHONE_NUMBER = /^[0-9+-]+$/;
export const REGEX_RGB = /#(?:[0-9a-fA-F]{2}){3}$/;
export const REGEX_RGBA = /#(?:[0-9a-fA-F]{2}){4}$/;
export const REGEX_UID = /^[0-9a-zA-Z_-]+$/;
export const REGEX_SHOP_PATH = /^[0-9a-z_-]+$/;

export function isEmailValid(email) {
    return REGEX_EMAIL.test(email);
}

export function isDomainValid(value) {
    return REGEX_DOMAIN.test(value) || REGEX_DOMAIN_LOCALHOST.test(value);
}

export function isUrlValid(value) {
    return REGEX_URL.test(value);
}

export function isNumber(value) {
    return REGEX_NUMBER.test(value);
}

export function addComma(value) {
    if (!value) {
        return;
    }
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function removeStartZero(value) {
    if (!value) {
        return;
    }
    return value.toString().replace(/(^0+)/, "");
}

export function isRGB(value) {
    return REGEX_RGB.test(value);
}

export function isRGBA(value) {
    return REGEX_RGBA.test(value);
}

export function isSimplePhoneNumberValid(value) {
    return REGEX_SIMPLE_PHONE_NUMBER.test(value);
}

export function isUidValid(value) {
    return REGEX_UID.test(value);
}

export function isShopPathValid(value) {
    return REGEX_SHOP_PATH.test(value);
}