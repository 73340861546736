import {
    REQUEST_GET_SHOP_BY_SHOP_STAFF,
    REQUEST_GET_SHOP_PLUGIN_BY_SHOP_STAFF,
    REQUEST_INIT_PLUGIN_SETTINGS_BY_SHOP_STAFF,
    REQUEST_UPDATE_SHOP_BY_SHOP_STAFF,
    REQUEST_UPDATE_SHOP_PLUGIN_BY_SHOP_STAFF,
    SUCCEED_INIT_PLUGIN_SETTINGS_BY_SHOP_STAFF,
    REQUEST_GET_PAYMENT_HISTORIES_BY_SHOP_STAFF,
    SUCCEED_GET_PAYMENT_HISTORIES_BY_SHOP_STAFF,
    FAIL_GET_PAYMENT_HISTORIES_BY_SHOP_STAFF,
    REQUEST_INITIALIZE_PAYMENT_HISTORIES_BY_SHOP_STAFF,
    SUCCEED_INITIALIZE_PAYMENT_HISTORIES_BY_SHOP_STAFF,
    FAIL_INITIALIZE_PAYMENT_HISTORIES_BY_SHOP_STAFF,
} from "../RESTActionTypes";

export function getShop(shopStaffInfo) {
    return {type: REQUEST_GET_SHOP_BY_SHOP_STAFF, shopStaffInfo};
}

export function updateShop(shopStaffInfo, formData) {
    return {type: REQUEST_UPDATE_SHOP_BY_SHOP_STAFF, shopStaffInfo, formData};
}

export function initPluginSettings(shopStaffInfo) {
    return {
        type: REQUEST_INIT_PLUGIN_SETTINGS_BY_SHOP_STAFF,
        shopStaffInfo,
    };
}

export function loadInitPluginSettings(categoriesResponse, pluginResponse) {
    return {
        type: SUCCEED_INIT_PLUGIN_SETTINGS_BY_SHOP_STAFF,
        categories: categoriesResponse.response.results,
        plugin: pluginResponse.response.result,
    };
}

export function getShopPlugin(shopStaffInfo) {
    return {type: REQUEST_GET_SHOP_PLUGIN_BY_SHOP_STAFF, shopStaffInfo};
}

export function updateShopPlugin(shopStaffInfo, pluginId, formData) {
    return {type: REQUEST_UPDATE_SHOP_PLUGIN_BY_SHOP_STAFF, shopStaffInfo, pluginId, formData};
}

export function getPaymentHistories(
    shopStaffInfo,
    queryParams,
    type = REQUEST_GET_PAYMENT_HISTORIES_BY_SHOP_STAFF,
    succeedType = SUCCEED_GET_PAYMENT_HISTORIES_BY_SHOP_STAFF,
    failType = FAIL_GET_PAYMENT_HISTORIES_BY_SHOP_STAFF,
) {
    return {type, shopStaffInfo, queryParams, succeedType, failType};
}

export function initializePaymentHistories(
    shopStaffInfo,
    queryParams,
    type = REQUEST_INITIALIZE_PAYMENT_HISTORIES_BY_SHOP_STAFF,
    succeedType = SUCCEED_INITIALIZE_PAYMENT_HISTORIES_BY_SHOP_STAFF,
    failType = FAIL_INITIALIZE_PAYMENT_HISTORIES_BY_SHOP_STAFF,
) {
    return {type, succeedType, failType, shopStaffInfo, queryParams};
}

export function loadInitializePaymentHistories(type, shopResponse, paymentHistoryResponse) {
    return {
        type,
        shop: shopResponse.response.result,
        count: paymentHistoryResponse.response.count,
        paymentHistories: paymentHistoryResponse.response.results,
    };
}