import {ofType} from "redux-observable";
import {
    FAIL_CHANGE_SHOP_STAFF_PASSWORD,
    FAIL_CREATE_BATCH_FILE_DATA_BY_SHOP_STAFF,
    FAIL_DELETE_PAYMENT_HISTORY_BY_SHOP_STAFF,
    FAIL_GET_PAYMENT_HISTORY_BY_SHOP_STAFF,
    FAIL_PAY_IAMPORT_PRODUCT_CHARGE_BY_SHOP_STAFF,
    FAIL_PAY_IAMPORT_SCHEDULE_CHARGE_BY_SHOP_STAFF,
    FAIL_PAY_SHOPIFY_ANNUAL_CHARGE_BY_SHOP_STAFF,
    FAIL_PAY_SHOPIFY_PRODUCT_CHARGE_BY_SHOP_STAFF,
    FAIL_PAY_SHOPIFY_RECURRING_CHARGE_BY_SHOP_STAFF,
    FAIL_PAY_SHOPIFY_TRIAL_BY_SHOP_STAFF,
    FAIL_PAY_TRIAL_BY_SHOP_STAFF,
    FAIL_SEND_VERIFICATION_EMAIL_SHOP_STAFF,
    FAIL_SIGNIN_SHOP_STAFF,
    FAIL_SIGNUP_SHOP_STAFF,
    FAIL_UPDATE_AR_MODEL_TRANSFORM_INFO_BY_SHOP_STAFF,
    FAIL_UPDATE_PAYMENT_HISTORY_BY_SHOP_STAFF,
    FAIL_UPDATE_SHOP_STAFF, FAIL_VERIFY_EMAIL_SHOP_STAFF,
    REQUEST_CHANGE_SHOP_STAFF_PASSWORD,
    REQUEST_CREATE_BATCH_FILE_DATA_BY_SHOP_STAFF,
    REQUEST_DELETE_PAYMENT_HISTORY_BY_SHOP_STAFF,
    REQUEST_GET_PAYMENT_HISTORY_BY_SHOP_STAFF,
    REQUEST_GET_SHOP_STAFF, REQUEST_GET_SHOP_STAFF_IN_VERIFY_EMAIL,
    REQUEST_PAY_IAMPORT_PRODUCT_CHARGE_BY_SHOP_STAFF,
    REQUEST_PAY_IAMPORT_SCHEDULE_CHARGE_BY_SHOP_STAFF,
    REQUEST_PAY_SHOPIFY_ANNUAL_CHARGE_BY_SHOP_STAFF,
    REQUEST_PAY_SHOPIFY_PRODUCT_CHARGE_BY_SHOP_STAFF,
    REQUEST_PAY_SHOPIFY_RECURRING_CHARGE_BY_SHOP_STAFF,
    REQUEST_PAY_SHOPIFY_TRIAL_BY_SHOP_STAFF,
    REQUEST_PAY_TRIAL_BY_SHOP_STAFF,
    REQUEST_SEND_VERIFICATION_EMAIL_SHOP_STAFF,
    REQUEST_SIGNIN_SHOP_STAFF,
    REQUEST_SIGNUP_SHOP_STAFF,
    REQUEST_UPDATE_AR_MODEL_TRANSFORM_INFO_BY_SHOP_STAFF,
    REQUEST_UPDATE_PAYMENT_HISTORY_BY_SHOP_STAFF,
    REQUEST_UPDATE_SHOP_STAFF, REQUEST_VERIFY_EMAIL_SHOP_STAFF,
    SUCCEED_CHANGE_SHOP_STAFF_PASSWORD,
    SUCCEED_CREATE_BATCH_FILE_DATA_BY_SHOP_STAFF,
    SUCCEED_DELETE_PAYMENT_HISTORY_BY_SHOP_STAFF,
    SUCCEED_GET_PAYMENT_HISTORY_BY_SHOP_STAFF,
    SUCCEED_PAY_IAMPORT_PRODUCT_CHARGE_BY_SHOP_STAFF,
    SUCCEED_PAY_IAMPORT_SCHEDULE_CHARGE_BY_SHOP_STAFF,
    SUCCEED_PAY_SHOPIFY_ANNUAL_CHARGE_BY_SHOP_STAFF,
    SUCCEED_PAY_SHOPIFY_PRODUCT_CHARGE_BY_SHOP_STAFF,
    SUCCEED_PAY_SHOPIFY_RECURRING_CHARGE_BY_SHOP_STAFF,
    SUCCEED_PAY_SHOPIFY_TRIAL_BY_SHOP_STAFF,
    SUCCEED_PAY_TRIAL_BY_SHOP_STAFF,
    SUCCEED_SEND_VERIFICATION_EMAIL_SHOP_STAFF,
    SUCCEED_SIGNIN_SHOP_STAFF,
    SUCCEED_SIGNUP_SHOP_STAFF,
    SUCCEED_UPDATE_PAYMENT_HISTORY_BY_SHOP_STAFF,
    SUCCEED_UPDATE_SHOP_STAFF, SUCCEED_VERIFY_EMAIL_SHOP_STAFF,
} from "../../actions/RESTActionTypes";
import {catchError, map, mergeMap} from "rxjs/operators";
import {
    changeShopStaffPassword,
    deletePaymentHistory,
    getPaymentHistory,
    getShopStaff,
    payIamportProductCharge,
    payIamportScheduleCharge,
    payShopifyAnnualCharge,
    payShopifyProductCharge,
    payShopifyRecurringCharge,
    payShopifyTrial,
    payTrial,
    signin,
    signup,
    updateArTransformModelInfo,
    updatePaymentHistory,
    updateShopStaff,
    sendVerificationEmail,
    verifyEmail
} from "../../../rest/ShopStaffsAPI";
import {loadResult, occurError} from "../../actions/RESTAction";
import {of} from "rxjs";
import {loadUpdateArModelTransformInfo} from "../../actions/ShopStaff/ProductAPIAction";

export const signupShopStaffEpic = action$ => action$.pipe(
    ofType(REQUEST_SIGNUP_SHOP_STAFF),
    mergeMap(action => signup(action.payload).pipe(
        map(res => loadResult(SUCCEED_SIGNUP_SHOP_STAFF, res.response)),
        catchError(error => of(occurError(FAIL_SIGNUP_SHOP_STAFF, error.xhr)))
    ))
);

export const signinShopStaffEpic = action$ => action$.pipe(
    ofType(REQUEST_SIGNIN_SHOP_STAFF),
    mergeMap(action => signin(action.email, action.password).pipe(
        map(res => loadResult(SUCCEED_SIGNIN_SHOP_STAFF, res.response)),
        catchError(error => of(occurError(FAIL_SIGNIN_SHOP_STAFF, error.xhr)))
    ))
);

export const verifyEmailEpic = action$ => action$.pipe(
    ofType(REQUEST_VERIFY_EMAIL_SHOP_STAFF),
    mergeMap(action => verifyEmail(action.email, action.token).pipe(
        map(res => loadResult(SUCCEED_VERIFY_EMAIL_SHOP_STAFF, res.response)),
        catchError(error => of(occurError(FAIL_VERIFY_EMAIL_SHOP_STAFF, error.xhr)))
    ))
);

export const getShopStaffEpic = action$ => action$.pipe(
    ofType(REQUEST_GET_SHOP_STAFF, REQUEST_GET_SHOP_STAFF_IN_VERIFY_EMAIL),
    mergeMap(action => getShopStaff(action.shopStaffInfo).pipe(
        map(res => loadResult(action.succeedType, res.response)),
        catchError(error => of(occurError(action.failType, error.xhr)))
    ))
);

export const updateShopStaffEpic = action$ => action$.pipe(
    ofType(REQUEST_UPDATE_SHOP_STAFF),
    mergeMap(action => updateShopStaff(action.shopStaffInfo, action.name).pipe(
        map(res => loadResult(SUCCEED_UPDATE_SHOP_STAFF, res.response)),
        catchError(error => of(occurError(FAIL_UPDATE_SHOP_STAFF, error.xhr)))
    ))
);

export const changeShopStaffPasswordEpic = action$ => action$.pipe(
    ofType(REQUEST_CHANGE_SHOP_STAFF_PASSWORD),
    mergeMap(action => changeShopStaffPassword(
        action.shopStaffInfo, action.oldPassword, action.newPassword
    ).pipe(
        map(res => loadResult(SUCCEED_CHANGE_SHOP_STAFF_PASSWORD, res.response)),
        catchError(error => of(occurError(FAIL_CHANGE_SHOP_STAFF_PASSWORD, error.xhr)))
    ))
);

export const sendVerificationEmailEpic = action$ => action$.pipe(
    ofType(REQUEST_SEND_VERIFICATION_EMAIL_SHOP_STAFF),
    mergeMap(action => sendVerificationEmail(action.shopStaffInfo).pipe(
        map(res => loadResult(SUCCEED_SEND_VERIFICATION_EMAIL_SHOP_STAFF, res.response)),
        catchError(error => of(occurError(FAIL_SEND_VERIFICATION_EMAIL_SHOP_STAFF, error.xhr)))
    ))
);

export const payShopifyTrialChargeEpic = action$ => action$.pipe(
    ofType(REQUEST_PAY_SHOPIFY_TRIAL_BY_SHOP_STAFF),
    mergeMap(action => payShopifyTrial(action.shopStaffInfo, action.payload).pipe(
        map(res => loadResult(SUCCEED_PAY_SHOPIFY_TRIAL_BY_SHOP_STAFF, res.response)),
        catchError(error => of(occurError(FAIL_PAY_SHOPIFY_TRIAL_BY_SHOP_STAFF, error.xhr)))
    ))
);

export const payShopifyRecurringChargeEpic = action$ => action$.pipe(
    ofType(REQUEST_PAY_SHOPIFY_RECURRING_CHARGE_BY_SHOP_STAFF),
    mergeMap(action => payShopifyRecurringCharge(action.shopStaffInfo, action.payload).pipe(
      map(res => loadResult(SUCCEED_PAY_SHOPIFY_RECURRING_CHARGE_BY_SHOP_STAFF, res.response)),
      catchError(error => of(occurError(FAIL_PAY_SHOPIFY_RECURRING_CHARGE_BY_SHOP_STAFF, error.xhr)))
    ))
)

export const payShopifyAnnualChargeEpic = action$ => action$.pipe(
    ofType(REQUEST_PAY_SHOPIFY_ANNUAL_CHARGE_BY_SHOP_STAFF),
    mergeMap(action => payShopifyAnnualCharge(action.shopStaffInfo, action.payload).pipe(
        map(res => loadResult(SUCCEED_PAY_SHOPIFY_ANNUAL_CHARGE_BY_SHOP_STAFF, res.response)),
        catchError(error => of(occurError(FAIL_PAY_SHOPIFY_ANNUAL_CHARGE_BY_SHOP_STAFF, error.xhr)))
    ))
);

export const payShopifyProductChargeEpic = action$ => action$.pipe(
    ofType(REQUEST_PAY_SHOPIFY_PRODUCT_CHARGE_BY_SHOP_STAFF),
    mergeMap(action => payShopifyProductCharge(action.shopStaffInfo, action.payload).pipe(
        map(res => loadResult(SUCCEED_PAY_SHOPIFY_PRODUCT_CHARGE_BY_SHOP_STAFF, res.response)),
        catchError(error => of(occurError(FAIL_PAY_SHOPIFY_PRODUCT_CHARGE_BY_SHOP_STAFF, error.xhr)))
    ))
);

export const updateShopStaffArTransformInfoEpic = action$ => action$.pipe(
    ofType(REQUEST_UPDATE_AR_MODEL_TRANSFORM_INFO_BY_SHOP_STAFF),
    mergeMap(action => updateArTransformModelInfo(
        action.shopStaffInfo, action.productId, action.productOptionId, action.armtiId, action.formData
    ).pipe(
        map(res => loadUpdateArModelTransformInfo(action.productOptionId, action.armtiId, res.response)),
        catchError(error => of(occurError(FAIL_UPDATE_AR_MODEL_TRANSFORM_INFO_BY_SHOP_STAFF, error.xhr)))
    ))
);


export const getPaymentHistoryEpic = action$ => action$.pipe(
    ofType(REQUEST_GET_PAYMENT_HISTORY_BY_SHOP_STAFF),
    mergeMap(action => getPaymentHistory(action.shopStaffInfo, action.paymentHistoryId).pipe(
        map(res => loadResult(SUCCEED_GET_PAYMENT_HISTORY_BY_SHOP_STAFF, res.response)),
        catchError(error => of(occurError(FAIL_GET_PAYMENT_HISTORY_BY_SHOP_STAFF, error.xhr)))
    ))
);

export const payIamportScheduleChargeEpic = action$ => action$.pipe(
    ofType(REQUEST_PAY_IAMPORT_SCHEDULE_CHARGE_BY_SHOP_STAFF),
    mergeMap(action => payIamportScheduleCharge(action.shopStaffInfo, action.payload).pipe(
        map(res => loadResult(SUCCEED_PAY_IAMPORT_SCHEDULE_CHARGE_BY_SHOP_STAFF, res.response)),
        catchError(error => of(occurError(FAIL_PAY_IAMPORT_SCHEDULE_CHARGE_BY_SHOP_STAFF, error.xhr)))
    ))
);

export const payIamportProductChargeEpic = action$ => action$.pipe(
    ofType(REQUEST_PAY_IAMPORT_PRODUCT_CHARGE_BY_SHOP_STAFF),
    mergeMap(action => payIamportProductCharge(action.shopStaffInfo, action.payload).pipe(
        map(res => loadResult(SUCCEED_PAY_IAMPORT_PRODUCT_CHARGE_BY_SHOP_STAFF, res.response)),
        catchError(error => of(occurError(FAIL_PAY_IAMPORT_PRODUCT_CHARGE_BY_SHOP_STAFF, error.xhr)))
    ))
);

export const payTrialChargeEpic = action$ => action$.pipe(
    ofType(REQUEST_PAY_TRIAL_BY_SHOP_STAFF),
    mergeMap(action => payTrial(action.shopStaffInfo, action.payload).pipe(
        map(res => loadResult(SUCCEED_PAY_TRIAL_BY_SHOP_STAFF, res.response)),
        catchError(error => of(occurError(FAIL_PAY_TRIAL_BY_SHOP_STAFF, error.xhr)))
    ))
);

export const deletePaymentHistoryEpic = action$ => action$.pipe(
    ofType(REQUEST_DELETE_PAYMENT_HISTORY_BY_SHOP_STAFF),
    mergeMap(action => deletePaymentHistory(action.shopStaffInfo, action.paymentHistoryId).pipe(
        map(res => loadResult(SUCCEED_DELETE_PAYMENT_HISTORY_BY_SHOP_STAFF, res.response)),
        catchError(error => of(occurError(FAIL_DELETE_PAYMENT_HISTORY_BY_SHOP_STAFF, error.xhr)))
    ))
);

export const updatePaymentHistoryEpic = action$ => action$.pipe(
    ofType(REQUEST_UPDATE_PAYMENT_HISTORY_BY_SHOP_STAFF),
    mergeMap(action => updatePaymentHistory(action.shopStaffInfo, action.payload, action.paymentHistoryId).pipe(
        map(res => loadResult(SUCCEED_UPDATE_PAYMENT_HISTORY_BY_SHOP_STAFF, res.response)),
        catchError(error => of(occurError(FAIL_UPDATE_PAYMENT_HISTORY_BY_SHOP_STAFF, error.xhr)))
    ))
);

