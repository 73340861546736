import {fromJS, List, Map, Set} from "immutable";
import ProductQueryParam from "../../../models/ui/ProductQueryParam";
import {
    SHOP_STAFF_WHATS_NEWS_CHANGE_PERIOD,
    SHOP_STAFF_WHATS_NEWS_CHANGE_QUERY_PARAMS, SHOP_STAFF_WHATS_NEWS_CHANGE_SEACH_QUERY,
    SHOP_STAFF_WHATS_NEWS_CLEAR_CHANGE_ORDER_STATE,
    SHOP_STAFF_WHATS_NEWS_CLEAR_TASK_STATE, SHOP_STAFF_WHATS_NEWS_DIALOG_CLEAR_STATE,
    SHOP_STAFF_WHATS_NEWS_INIT_DATA,
    SHOP_STAFF_WHATS_NEWS_OPEN_CHANGE_ORDER,
    SHOP_STAFF_WHATS_NEWS_RENDERED_REORDER_LIST,
    SHOP_STAFF_WHATS_NEWS_REORDER,
    SHOP_STAFF_WHATS_NEWS_RESET_QUERY_PARAMS,
    SHOP_STAFF_WHATS_NEWS_SELECT_BRAND_IN_CHANGE_ORDER,
    SHOP_STAFF_WHATS_NEWS_SELECT_CATEGORY_IN_CHANGE_ORDER,
    SHOP_STAFF_WHATS_NEWS_SET_OPEN_DIALOG,
    SHOP_STAFF_WHATS_NEWS_SET_SELECTED_ITEM, SHOP_STAFF_WHATS_NEWS_SET_SELECTED_PRODUCT
} from "../../actions/UIActionTypes";
import {
    FAIL_CHANGE_WHATS_NEWS_ORDER_BY_SHOP_STAFF, FAIL_CREATE_WHATS_NEWS_BY_SHOP_STAFF,
    FAIL_DELETE_WHATS_NEWS_BY_SHOP_STAFF, FAIL_GET_PRODUCTS_BY_SHOP_STAFF_IN_WHATS_NEWS,
    FAIL_GET_SHOP_STAFF_BRANDS_CATEGORIES_SHOP_IN_WHATS_NEWS,
    FAIL_GET_WHATS_NEWS_BY_SHOP_STAFF,
    FAIL_GET_WHATS_NEWS_BY_SHOP_STAFF_IN_CHANGE_ORDER,
    REQUEST_CHANGE_WHATS_NEWS_ORDER_BY_SHOP_STAFF, REQUEST_CREATE_WHATS_NEWS_BY_SHOP_STAFF,
    REQUEST_DELETE_WHATS_NEWS_BY_SHOP_STAFF,
    REQUEST_GET_PRODUCTS_BY_SHOP_STAFF_IN_WHATS_NEWS,
    REQUEST_GET_SHOP_STAFF_BRANDS_CATEGORIES_SHOP_IN_WHATS_NEWS,
    REQUEST_GET_WHATS_NEWS_BY_SHOP_STAFF,
    REQUEST_GET_WHATS_NEWS_BY_SHOP_STAFF_IN_CHANGE_ORDER,
    SUCCEED_CHANGE_WHATS_NEWS_ORDER_BY_SHOP_STAFF, SUCCEED_CREATE_WHATS_NEWS_BY_SHOP_STAFF,
    SUCCEED_DELETE_WHATS_NEWS_BY_SHOP_STAFF, SUCCEED_GET_PRODUCTS_BY_SHOP_STAFF_IN_WHATS_NEWS,
    SUCCEED_GET_SHOP_STAFF_BRANDS_CATEGORIES_SHOP_IN_WHATS_NEWS,
    SUCCEED_GET_WHATS_NEWS_BY_SHOP_STAFF,
    SUCCEED_GET_WHATS_NEWS_BY_SHOP_STAFF_IN_CHANGE_ORDER
} from "../../actions/RESTActionTypes";
import Shop from "../../../models/Shop";
import Brand from "../../../models/Brand";
import RestError from "../../../models/RestError";
import Product from "../../../models/Product";
import {initialShopStaffProducts} from "./ProductsReducer";
import {addExclusively} from "../../../utils/ListUtils";
import {parseStartAndEndDateFromQuery} from "../../../utils/DateUtils";
import AulWhatsNew from "../../../models/AulWhatsNew";

export const initialShopStaffWhatsNews = fromJS({
    isLoading: false,
    isInitialized: false,
    brands: List(),
    categories: List(),
    products: List(),
    selectedProductIds: Set(),
    whatsNews: List(),
    selectedWhatsNewIds: Set(),
    count: 0,
    error: null,
    queryParams: new ProductQueryParam(),
    isTaskWorking: false,
    succeedTask: false,
    succeedDelete: false,
    succeedWhatsNewsAdd: false,
    errorTask: null,
    isWhatsNewLoading: false,
    whatsNewPage: 0,
    whatsNewCount: 0,
    whatsNewHasMore: false,
    whatsNewError: null,
    openWhatsNewsDialog: false,
    searchWhatsNewQuery: '',
    shop: null,
    copySampleProductCategory: null,
});

export function shopStaffWhatsNews(state = initialShopStaffWhatsNews, action) {
    let products;
    let shop;

    switch (action.type) {
        case SHOP_STAFF_WHATS_NEWS_CLEAR_TASK_STATE:
            return state.set('isTaskWorking', initialShopStaffWhatsNews.get('isTaskWorking'))
                .set('succeedTask', initialShopStaffWhatsNews.get('succeedTask'))
                .set('succeedDelete', initialShopStaffWhatsNews.get('succeedDelete'))
                .set('errorTask', initialShopStaffWhatsNews.get('errorTask'));

        case SHOP_STAFF_WHATS_NEWS_INIT_DATA:
            return state.set('queryParams', action.queryParams);

        case REQUEST_GET_SHOP_STAFF_BRANDS_CATEGORIES_SHOP_IN_WHATS_NEWS:
            return initialShopStaffWhatsNews.set('isLoading', true);

        case SUCCEED_GET_SHOP_STAFF_BRANDS_CATEGORIES_SHOP_IN_WHATS_NEWS:
            shop = new Shop(action.shop);
            const categories = shop.get('categories');
            const copySampleProductCategory = categories.get('0');

            return state.set('isLoading', false)
                .set('isInitialized', true)
                .set('brands', List(action.brands.map(item => new Brand(item))))
                .set('categories', categories)
                .set('copySampleProductCategory', copySampleProductCategory)
                .set('shop', shop)
                .set('error', null);

        case FAIL_GET_SHOP_STAFF_BRANDS_CATEGORIES_SHOP_IN_WHATS_NEWS:
            return state.set('isLoading', false).set('error', new RestError(action.error));

        case REQUEST_GET_WHATS_NEWS_BY_SHOP_STAFF:
            return state.set('isLoading', true)
                .set('selectedWhatsNewIds', initialShopStaffWhatsNews.get('selectedWhatsNewIds'))
                .set('isTaskWorking', initialShopStaffWhatsNews.get('isTaskWorking'))
                .set('succeedTask', initialShopStaffWhatsNews.get('succeedTask'))
                .set('succeedDelete', initialShopStaffWhatsNews.get('succeedDelete'))
                .set('errorTask', initialShopStaffWhatsNews.get('errorTask'));

        case SUCCEED_GET_WHATS_NEWS_BY_SHOP_STAFF:
            return state.set('isLoading', false)
                .set('whatsNews', List(action.results.map(item => new AulWhatsNew(item))))
                .set('count', action.results.length)
                .set('error', null);

        case REQUEST_GET_PRODUCTS_BY_SHOP_STAFF_IN_WHATS_NEWS:
            return state.set('isWhatsNewLoading', true)
                .set('whatsNewError', null);

        case SUCCEED_GET_PRODUCTS_BY_SHOP_STAFF_IN_WHATS_NEWS:
            products = List(action.results.map(item => new Product(item)));

            products = action.page === 1
                ? List(products)
                : addExclusively(state.get('products'), List(products));

            return state.set('isWhatsNewLoading', false)
                .set('products', List(action.results.map(item => new Product(item))))
                .set('whatsNewPage', action.page)
                .set('whatsNewHasMore', action.hasMore)
                .set('whatsNewCount', action.count)
                .set('whatsNewError', null);

        case FAIL_GET_PRODUCTS_BY_SHOP_STAFF_IN_WHATS_NEWS:
            return state.set('isWhatsNewLoading', false)
                .set('products', List())
                .set('whatsNewError', new RestError(action.error));

        case FAIL_GET_WHATS_NEWS_BY_SHOP_STAFF:
            return state.set('isLoading', false).set('whatsNews', List()).set('error', new RestError(action.error));

        case REQUEST_DELETE_WHATS_NEWS_BY_SHOP_STAFF:
            return state.set('isTaskWorking', true)
                .set('succeedTask', false)
                .set('succeedDelete', false)
                .set('errorTask', null);

        case SUCCEED_DELETE_WHATS_NEWS_BY_SHOP_STAFF:
            return state.set('isTaskWorking', false).set('succeedDelete', true).set('errorTask', null);

        case FAIL_DELETE_WHATS_NEWS_BY_SHOP_STAFF:
            return state.set('isTaskWorking', false)
                .set('succeedTask', false)
                .set('succeedDelete', false)
                .set('errorTask', new RestError(action.error));

        case REQUEST_CREATE_WHATS_NEWS_BY_SHOP_STAFF:
            return state.set('isLoading', true);

        case SUCCEED_CREATE_WHATS_NEWS_BY_SHOP_STAFF:

            return state.set('isLoading', false)
                .set('succeedWhatsNewsAdd', true);

        case FAIL_CREATE_WHATS_NEWS_BY_SHOP_STAFF:
            return state.set('isLoading', false)
                .set('error', new RestError(action.error));

        case SHOP_STAFF_WHATS_NEWS_CHANGE_QUERY_PARAMS:
            return state.set('queryParams', state.get('queryParams').set(action.key, action.value));

        case SHOP_STAFF_WHATS_NEWS_CHANGE_PERIOD:
            return state.set(
                'queryParams',
                state.get('queryParams').set('startDate', action.startDate).set('endDate', action.endDate)
            );

        case SHOP_STAFF_WHATS_NEWS_RESET_QUERY_PARAMS:
            const queryParams = state.get('queryParams');
            const {startDate, endDate} = parseStartAndEndDateFromQuery({});

            return state.set(
                'queryParams',
                initialShopStaffProducts.get('queryParams')
                    .set('page', queryParams.get('page'))
                    .set('startDate', startDate)
                    .set('endDate', endDate)
            );

        case SHOP_STAFF_WHATS_NEWS_DIALOG_CLEAR_STATE:
            return state.set('products', initialShopStaffWhatsNews.get('products'))
                .set('selectedProductIds', initialShopStaffWhatsNews.get('selectedProductIds'))
                .set('searchWhatsNewQuery', initialShopStaffWhatsNews.get('searchWhatsNewQuery'))
                .set('whatsNewPage', initialShopStaffWhatsNews.get('whatsNewPage'))
                .set('whatsNewCount', initialShopStaffWhatsNews.get('whatsNewCount'))
                .set('whatsNewHasMore', initialShopStaffWhatsNews.get('whatsNewHasMore'))
                .set('whatsNewError', initialShopStaffWhatsNews.get('whatsNewError'))

        case SHOP_STAFF_WHATS_NEWS_SET_SELECTED_ITEM:
            return state.set('selectedWhatsNewIds', action.selectedWhatsNewIds);

        case SHOP_STAFF_WHATS_NEWS_SET_SELECTED_PRODUCT:
            return state.set('selectedProductIds', action.selectedProductIds);

        case SHOP_STAFF_WHATS_NEWS_SET_OPEN_DIALOG:
            return state.set('openWhatsNewsDialog', action.isOpen);

        case SHOP_STAFF_WHATS_NEWS_CHANGE_SEACH_QUERY:
            return state.set('searchWhatsNewQuery', action.searchWhatsNewQuery);

        default:
            return state;
    }
}

export const initialShopStaffWhatsNewsChangeOrder = fromJS({
    isOpened: false,
    brand: null,
    category: null,
    isLoading: false,
    whatsNews: List(),
    count: 0,
    hasMore: false,
    page: 0,
    error: null,
    orderData: Map(),
    isReordered: false,
    isChanging: false,
    succeedChange: false,
    errorChange: null,
});

export function shopStaffWhatsNewsChangeOrder(state = initialShopStaffWhatsNewsChangeOrder, action) {
    switch (action.type) {
        case SHOP_STAFF_WHATS_NEWS_INIT_DATA:
            return initialShopStaffWhatsNewsChangeOrder;

        case SHOP_STAFF_WHATS_NEWS_OPEN_CHANGE_ORDER:
            if (!action.open) {
                return initialShopStaffWhatsNewsChangeOrder;
            }
            return state.set('isOpened', action.open).set('brand', action.brand).set('category', action.category);

        case SHOP_STAFF_WHATS_NEWS_SELECT_BRAND_IN_CHANGE_ORDER:
            return state.set('brand', action.brand)
                .set('whatsNews', List())
                .set('count', 0)
                .set('hasMore', false)
                .set('page', 0);

        case SHOP_STAFF_WHATS_NEWS_SELECT_CATEGORY_IN_CHANGE_ORDER:
            return state.set('category', action.category)
                .set('whatsNews', List())
                .set('count', 0)
                .set('hasMore', false)
                .set('page', 0);

        case REQUEST_GET_WHATS_NEWS_BY_SHOP_STAFF_IN_CHANGE_ORDER:
            return state.set('isLoading', true);

        case SUCCEED_GET_WHATS_NEWS_BY_SHOP_STAFF_IN_CHANGE_ORDER:

            let whatsNews = List(action.results.map(item => new AulWhatsNew(item)));

            if (action.page === 1) {
                state = state.set('orderData', Map());
            } else if (action.page > 1) {
                whatsNews = addExclusively(state.get('whatsNews'), whatsNews);
            }

            return state.set('isLoading', false)
                .set('whatsNews', whatsNews)
                .set('count', action.results.length)
                .set('hasMore', action.hasMore)
                .set('page', action.page)
                .set('error', null);

        case FAIL_GET_WHATS_NEWS_BY_SHOP_STAFF_IN_CHANGE_ORDER:
            return state.set('isLoading', false).set('error', new RestError(action.error));

        case SHOP_STAFF_WHATS_NEWS_REORDER:
            return state.set('whatsNews', action.whatsNews)
                .set('orderData', action.orderData)
                .set('isReordered', true);

        case SHOP_STAFF_WHATS_NEWS_RENDERED_REORDER_LIST:
            return state.set('isReordered', false);

        case REQUEST_CHANGE_WHATS_NEWS_ORDER_BY_SHOP_STAFF:
            return state.set('isChanging', true).set('succeedChange', false).set('errorChange', null);

        case SUCCEED_CHANGE_WHATS_NEWS_ORDER_BY_SHOP_STAFF:
            return state.set('isChanging', false).set('succeedChange', true).set('errorChange', null);

        case FAIL_CHANGE_WHATS_NEWS_ORDER_BY_SHOP_STAFF:
            return state.set('isChanging', false)
                .set('succeedChange', false)
                .set('errorChange', new RestError(action.error));

        case SHOP_STAFF_WHATS_NEWS_CLEAR_CHANGE_ORDER_STATE:
            return state.set('isChanging', false).set('succeedChange', false).set('errorChange', null);

        default:
            return state;
    }

}