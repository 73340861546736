export const PRIMARY_COLOR = "#1a1a1a";
export const PRIMARY_HOVER_COLOR = '#2d2c2c';
export const PRIMARY_ACTIVE_COLOR = '#2d2c2c';
export const SECONDARY_COLOR = "#707385";
export const ACCENT_COLOR = "#4394E1";
export const LIST_ITEM_HEADER_COLOR = "#C7CCD2";
export const BORDER_COLOR = "rgba(0, 0, 0, .12)";
export const TABLE_ITEM_HOVER_COLOR = "rgba(112, 114, 136, .05)";
export const DIVIDER_COLOR = "#eaeaea";
export const CAFE24_BTN_COLOR = '#578ce2';

export const TABLE_MIN_WIDTH = 1056;

export const SUBMIT_VIEW_HEIGHT = 64;
export const SUBMIT_VIEW_BOTTOM_GUTTER = SUBMIT_VIEW_HEIGHT * 1.5;

export const TOP_BAR_HEIGHT = 48;
export const TOP_BAR_HEIGHT_LARGE = 76;

export const SMALL_HORZ_SPACING = 16;
export const MEDIUM_HORZ_SPACING = 48;
export const LARGE_HORZ_SPACING = 74;
export const XLARGE_HORZ_SPACING = 10;

export const EMPTY_HEIGHT = 800;
