import {EntityMixin} from "./Entity";
import WorkRequest from "./WorkRequest";

const WorkRequestHistoryRecord = EntityMixin({
    work_request_id: 0,
    work_request_snapshot: null,
});

class  WorkRequestHistory extends WorkRequestHistoryRecord {

    constructor(args = {}) {
        super({
            ...args,
            work_request_snapshot: args.work_request_snapshot ? new WorkRequest(args.work_request_snapshot) : null,
        });
    }
}

export default  WorkRequestHistory;
