import {request} from "universal-rxjs-ajax";
import {createShopStaffHeaders, getBaseUrl} from "./APIClient";
import {convertQueryParams2Str} from "../utils/UrlUtils";

const PATH_PLUGINS = '/plugins';
const PATH_CAFE24 = '/cafe24';

export function getCafe24Store(mall_id, shop_no) {
    const path = `${PATH_PLUGINS}${PATH_CAFE24}/store`;
    let url = `${getBaseUrl()}${path}${convertQueryParams2Str({mall_id, shop_no})}`;

    return request({url, method: 'GET', headers: createShopStaffHeaders(path, null)});
}

export function createBuilderScripttag(builder, categoryUid, plugin_id, mall_id, shop_no) {
    const path = `${PATH_PLUGINS}/${builder}/${categoryUid}/create_scripttag`;

    return request({
        url: `${getBaseUrl()}${path}`,
        method: 'POST',
        body: {plugin_id, mall_id, shop_no},
        headers: createShopStaffHeaders(path, null),
    });
}

export function deleteBuilderScripttag(builder, categoryUid, plugin_id, mall_id, shop_no) {
    const path = `${PATH_PLUGINS}/${builder}/${categoryUid}/scripttag`;

    return request({
        url: `${getBaseUrl()}${path}`,
        method: 'DELETE',
        body: {plugin_id, mall_id, shop_no},
        headers: createShopStaffHeaders(path, null),
    });
}
