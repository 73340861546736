import {EntityMixin} from "./Entity";
import Shop from "./Shop";
import Pricing from "./Pricing";
import {addComma} from "../utils/RegexUtils";
import {convertDateFormat} from "../utils/DateUtils";
import moment from "moment";

export const PRICING_PAYMENT_HISTORY_PAY_METHOD_CARD = 'card';

export const PRICING_PAYMENT_HISTORY_KIND_NONE = 1;             // None
export const PRICING_PAYMENT_HISTORY_KIND_PRICING = 2;          // 가격 정책
export const PRICING_PAYMENT_HISTORY_KIND_PRODUCT_CHARGE = 3;   // 상품 구매

export const PRICING_PAYMENT_HISTORY_STATUS_PAID = 1
export const PRICING_PAYMENT_HISTORY_STATUS_UNINSTALLED = 2
export const PRICING_PAYMENT_HISTORY_STATUS_RECURRING_CANCELLED = 3
export const PRICING_PAYMENT_HISTORY_STATUS_REFUNDED = 4
export const PRICING_PAYMENT_HISTORY_STATUS_READY = 5
export const PRICING_PAYMENT_HISTORY_STATUS_PAYMENT_CANCELLED = 6
export const PRICING_PAYMENT_HISTORY_STATUS_SCHEDULED = 7
export const PRICING_PAYMENT_HISTORY_STATUS_UNSCHEDULED = 8
export const PRICING_PAYMENT_HISTORY_STATUS_FAILED = 100

const PricingPaymentHistoryRecord = EntityMixin({
    shop_id: 0,
    shop: null,
    pricing_id: 0,
    pricing: null,
    kind: 0,
    product_total_count: 0,
    available_product_count: 0,
    usage_product_count: 0,
    price_won: 0,
    price_dollar: 0,
    start_date: null,
    end_date: false,
    billing_id: '',
    paid_date: '',
    status: 0,

    // iamport
    merchant_uid: '',
    pay_method: '',
    orderer_name: '',
    orderer_phone_number: '',
    orderer_email: '',
    customer_uid: '',
    card_name: '',
    card_number: '',
    pg_provider: '',
});

class PricingPaymentHistory extends PricingPaymentHistoryRecord {

    constructor(args = {}) {
        super({
            ...args,
            shop: args.shop ? new Shop(args.shop) : null,
            pricing: args.pricing ? new Pricing(args.pricing) : null,
        });
    }

    getRealPrice() {
        if (this.price_won <= 0 && this.price_dollar <=  0) {
            return null;
        }

        return this.price_won > 0 ? this.price_won : this.price_dollar
    }

    getRealPriceAddCommaAndUnit(t) {
        const price = this.getRealPrice();

        if (price) {
            return t('pricing_payment_price', {price: addComma(price)});
        } else {
            return null;
        }
    }

    getStringPayMethod(t) {
        if (!this.pay_method) {
            return;
        }

        if (this.pay_method === PRICING_PAYMENT_HISTORY_PAY_METHOD_CARD) {
            return t('pricing_payment_pay_method_card');
        } else {
            return '-';
        }
    }

    getPaidDateFormat(format) {
        if (!this.created_date) {
            return;
        }

        if (this.paid_date) {
            return convertDateFormat(this.paid_date, format)
        } else {
            return convertDateFormat(this.created_date, format)
        }
    }

    getStringStatus(t) {
        switch(this.status) {
            case 1:
                return t('pricing_payment_status_paid');
            case 2:
                return t('pricing_payment_status_cancelled');
            case 3:
                return t('pricing_payment_status_recurring_cancelled');
            case 4:
                return t('pricing_payment_status_refunded');
            case 5:
                return t('pricing_payment_status_ready');
            case 6:
                return t('pricing_payment_status_cancelled');
            case 7:
                return t('pricing_payment_status_scheduled');
            case 8:
                return t('pricing_payment_status_unscheduled');
            case 100:
                return t('pricing_payment_status_failed');
            default:
                return "";
        }
    }
    isPaymentExpired() {
        if (!this.end_date) {
            return true;
        }

        const now = moment().utc();
        const endDate = moment(this.end_date);

        return now > endDate;
    }

    getAvailablePeriod(format) {
        if (!this.end_date || !this.start_date) {
            return "";
        }

        const startDate = convertDateFormat(this.start_date, format);
        const endDate = convertDateFormat(this.end_date, format);

        return `${startDate} ~ ${endDate}`
    }

    getEndDateToString(format) {
        if (!this.end_date) {
            return "";
        }

        if (this.pricing.get('is_trial')) {
            return "";
        }

        return convertDateFormat(this.end_date, format);
    }
}

export default PricingPaymentHistory;
