import {EntityMixin} from "./Entity";
import ImageData from "./ImageData";

const BrandRecord = EntityMixin({
    shop_id: 0,
    name: '',
    image: null,
    imageData: null,
    image_width: 0,
    image_height: 0,
    order: 0,
});

class Brand extends BrandRecord {

    constructor(args = {}) {
        super({
            ...args,
            imageData: args.imageData ? new ImageData(args.imageData) : null,
        });
    }

    canAdd() {
        return this.name;
    }

    canEdit(oldbie) {
        if (!oldbie) {
            return false;
        }

        return (this.name && this.name !== oldbie.get('name'))
            || this.imageData;
    }

    makeFormData(oldbie = null) {
        const formData = new FormData();

        if (this.name) {
            if (!oldbie || this.name !== oldbie.get('name')) {
                formData.append('name', this.name);
            }
        }
        if (this.imageData) {
            formData.append('image', this.imageData.get('file'));
        }

        return formData;
    }
}

export default Brand;
