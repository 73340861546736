import {List, Map} from "immutable";

export const WORK_REQUEST_STATUS_REQUEST = 1;
export const WORK_REQUEST_STATUS_READY = 2;
export const WORK_REQUEST_STATUS_IN_PROGRESS = 3;
export const WORK_REQUEST_STATUS_COMPLETE = 4;
export const WORK_REQUEST_STATUS_REQUEST_LABEL = 'work_request_status_request'
export const WORK_REQUEST_STATUS_READY_LABEL = 'work_request_status_ready'
export const WORK_REQUEST_STATUS_IN_PROGRESS_LABEL = 'work_request_status_in_progress'
export const WORK_REQUEST_STATUS_COMPLETE_LABEL = 'work_request_status_complete'

export const WORK_REQUEST_STATUSES = List.of(
    WORK_REQUEST_STATUS_REQUEST,
    WORK_REQUEST_STATUS_READY,
    WORK_REQUEST_STATUS_IN_PROGRESS,
    WORK_REQUEST_STATUS_COMPLETE,
);

export const WORK_REQUEST_CATEGORY_EYEWEAR = 'eyewear';

export const WORK_REQUEST_ABLE_CATEGORIES = List([
    WORK_REQUEST_CATEGORY_EYEWEAR,
])

export const WORK_REQUEST_IMAGE_FRONT = "front";
export const WORK_REQUEST_IMAGE_DATA_FRONT = "frontImageData";
export const WORK_REQUEST_IMAGE_FRONT_LABEL = "work_request_image_front_label";
export const WORK_REQUEST_IMAGE_SIDE = "side";
export const WORK_REQUEST_IMAGE_DATA_SIDE = "sideImageData";
export const WORK_REQUEST_IMAGE_SIDE_LABEL = "work_request_image_side_label";
export const WORK_REQUEST_IMAGE_SIDE_45 = "side_45";
export const WORK_REQUEST_IMAGE_DATA_SIDE_45 = "side45ImageData";
export const WORK_REQUEST_IMAGE_SIDE_45_LABEL = "work_request_image_side_45_label";
// export const WORK_REQUEST_IMAGE_TOP = "top";
// export const WORK_REQUEST_IMAGE_DATA_TOP = "topImageData";
// export const WORK_REQUEST_IMAGE_TOP_LABEL = "work_request_image_top_label";

export const WORK_REQUEST_IMAGES = List([
    {
        image: WORK_REQUEST_IMAGE_FRONT,
        imageData: WORK_REQUEST_IMAGE_DATA_FRONT,
        label: WORK_REQUEST_IMAGE_FRONT_LABEL,
        placeholder: '/img_guide_front.png'
    },
    {
        image: WORK_REQUEST_IMAGE_SIDE,
        imageData: WORK_REQUEST_IMAGE_DATA_SIDE,
        label: WORK_REQUEST_IMAGE_SIDE_LABEL,
        placeholder: '/img_guide_side.png'
    },
    {
        image: WORK_REQUEST_IMAGE_SIDE_45,
        imageData: WORK_REQUEST_IMAGE_DATA_SIDE_45,
        label: WORK_REQUEST_IMAGE_SIDE_45_LABEL,
        placeholder: '/img_guide_side_45.png'
    },
    // {
    //     image: WORK_REQUEST_IMAGE_TOP,
    //     imageData: WORK_REQUEST_IMAGE_DATA_TOP,
    //     label: WORK_REQUEST_IMAGE_TOP_LABEL,
    //     placeholder: '/img_guide_top.png'
    // }
]);

export const WORK_REQUEST_EYEWEAR_SPEC_FRONT_WIDTH = "front_width";
export const WORK_REQUEST_EYEWEAR_SPEC_FRONT_WIDTH_LABEL = "work_request_spec_eyewear_front_width_label";
export const WORK_REQUEST_EYEWEAR_SPEC_FRONT_HEIGHT = 'front_height';
export const WORK_REQUEST_EYEWEAR_SPEC_FRONT_HEIGHT_LABEL = "work_request_spec_eyewear_front_height_label";
export const WORK_REQUEST_EYEWEAR_SPEC_SIDE = 'side_width';
export const WORK_REQUEST_EYEWEAR_SPEC_SIDE_LABEL = "work_request_spec_eyewear_side_width_label";

export const WORK_REQUEST_EYEWEAR_SPEC = List([
    {key: WORK_REQUEST_EYEWEAR_SPEC_FRONT_WIDTH, label: WORK_REQUEST_EYEWEAR_SPEC_FRONT_WIDTH_LABEL},
    {key: WORK_REQUEST_EYEWEAR_SPEC_FRONT_HEIGHT, label: WORK_REQUEST_EYEWEAR_SPEC_FRONT_HEIGHT_LABEL},
    {key: WORK_REQUEST_EYEWEAR_SPEC_SIDE, label: WORK_REQUEST_EYEWEAR_SPEC_SIDE_LABEL},
])

export const WORK_REQUEST_SPEC_BY_CATEGORY = Map()
    .set('eyewear', WORK_REQUEST_EYEWEAR_SPEC);


export const WORK_REQUEST_SPEC_GUIDE_IMG_EYEWEAR = "img_guide_eyewear.png";

export const WORK_REQUEST_SPEC_GUIDE_IMGS = Map()
    .set('eyewear', WORK_REQUEST_SPEC_GUIDE_IMG_EYEWEAR);

export const WORK_REQUEST_SPEC_UNIT_CM = 'cm';
export const WORK_REQUEST_SPEC_UNIT_MM = 'mm';

export const WORK_REQUEST_SPEC_UNITS = List([
    WORK_REQUEST_SPEC_UNIT_CM,
    WORK_REQUEST_SPEC_UNIT_MM,
]);

