export function changeProductOptionLightingEnvUrl(url) {
    if (window.LolozemWebArController && typeof window.LolozemWebArController.changeEnvUrl === 'function') {
        window.LolozemWebArController.changeEnvUrl(url);
    }
}

export function changeProductOptionLightingEnvExposure(value) {
    if (window.LolozemWebArController && typeof window.LolozemWebArController.changeEnvExposure === 'function') {
        window.LolozemWebArController.changeEnvExposure(parseFloat(value));
    }
}

export function changeProductOptionLightingEnvRotationY(value) {
    if (window.LolozemWebArController && typeof window.LolozemWebArController.changeEnvRotationY === 'function') {
        window.LolozemWebArController.changeEnvRotationY(parseFloat(value));
    }
}

export function changeProductOptionFaceEffect(url, value) {
    if (window.LolozemWebArController && typeof window.LolozemWebArController.changeFaceEffectUrl === 'function') {
        window.LolozemWebArController.changeFaceEffectUrl(url , parseFloat(value));
    }
}

export function changeProductOptionFaceEffectExtraTopArea(value) {
    if (window.LolozemWebArController && typeof window.LolozemWebArController.changeFaceEffectExtraTopArea === 'function') {
        window.LolozemWebArController.changeFaceEffectExtraTopArea(parseFloat(value));
    }
}

export function setProductOptionLightingEnvUrl(info) {
    if(
        typeof window !== 'undefined'
        && window.LolozemWebArController
        && typeof window.LolozemWebArController.changeEnvUrl === 'function'
    ) {
        if (!info) {
            return;
        }

        if (info) {
            window.LolozemWebArController.changeEnvUrl({
                env_image_preset_id: info.get('env_image_preset_id'),
            }, null)
        }
    }
}

export function setProductOptionLightingEnvExposure(info) {
    if(
        typeof window !== 'undefined'
        && window.LolozemWebArController
        && typeof window.LolozemWebArController.changeEnvExposure === 'function'
    ) {
        if (!info) {
            return;
        }

        if (info) {
            window.LolozemWebArController.changeEnvExposure({
                exposure: info.get('exposure')
            }, null)
        }
    }
}

export function setProductOptionLightingEnvRotationY(info) {
    if(
        typeof window !== 'undefined'
        && window.LolozemWebArController
        && typeof window.LolozemWebArController.changeEnvRotationY === 'function'
    ) {
        if (!info) {
            return;
        }

        if (info) {
            window.LolozemWebArController.changeEnvRotationY({
                y: info.get('y'),
            }, null)
        }
    }
}

export function setProductOptionFaceEffect(info) {
    if(
        typeof window !== 'undefined'
        && window.LolozemWebArController
        && typeof window.LolozemWebArController.changeFaceEffectUrl === 'function'
    ) {
        if (!info) {
            return;
        }

        if (info) {
            window.LolozemWebArController.changeFaceEffectUrl({
                face_effect_id: info.get('face_effect_id'),
                extra_top_area: info.get('extra_top_area'),
            }, null)
        }
    }
}