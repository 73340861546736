import {
    FAIL_GET_PRODUCT_COLORS_BY_SHOP_STAFF,
    REQUEST_CREATE_PRODUCT_COLOR_BY_SHOP_STAFF,
    REQUEST_DELETE_PRODUCT_COLORS_BY_SHOP_STAFF,
    REQUEST_GET_PRODUCT_COLOR_BY_SHOP_STAFF,
    REQUEST_GET_PRODUCT_COLORS_BY_SHOP_STAFF,
    REQUEST_INIT_SHOP_STAFF_PRODUCT_COLOR_ADD,
    REQUEST_UPDATE_PRODUCT_COLOR_BY_SHOP_STAFF,
    SUCCEED_GET_PRODUCT_COLORS_BY_SHOP_STAFF,
    SUCCEED_INIT_SHOP_STAFF_PRODUCT_COLOR_ADD,
} from "../RESTActionTypes";

export function getProductColors(
    shopStaffInfo,
    queryParams,
    type = REQUEST_GET_PRODUCT_COLORS_BY_SHOP_STAFF,
    succeedType = SUCCEED_GET_PRODUCT_COLORS_BY_SHOP_STAFF,
    failType = FAIL_GET_PRODUCT_COLORS_BY_SHOP_STAFF,
) {
    return {type, shopStaffInfo, queryParams, succeedType, failType};
}

export function initShopStaffProductColorAdd(shopStaffInfo) {
    return {type: REQUEST_INIT_SHOP_STAFF_PRODUCT_COLOR_ADD, shopStaffInfo};
}

export function loadInitProductColorAdd(shopResponse) {
    return {type: SUCCEED_INIT_SHOP_STAFF_PRODUCT_COLOR_ADD, shop: shopResponse.response.result};
}

export function createProductColor(shopStaffInfo, formData) {
    return {type: REQUEST_CREATE_PRODUCT_COLOR_BY_SHOP_STAFF, shopStaffInfo, formData};
}

export function getProductColor(shopStaffInfo, productColorId) {
    return {type: REQUEST_GET_PRODUCT_COLOR_BY_SHOP_STAFF, shopStaffInfo, productColorId};
}

export function updateProductColor(shopStaffInfo, productColorId, formData) {
    return {type: REQUEST_UPDATE_PRODUCT_COLOR_BY_SHOP_STAFF, shopStaffInfo, productColorId, formData};
}

export function deleteProductColors(shopStaffInfo, productColorIds) {
    return {type: REQUEST_DELETE_PRODUCT_COLORS_BY_SHOP_STAFF, shopStaffInfo, productColorIds};
}
