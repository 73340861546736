import {EntityMixin} from "./Entity";
import {List} from "immutable";
import I18NLanguageProductColor from "./I18NLanguageProductColor";
import {LANG_KOREAN} from "../utils/LocaleUtils";

const ProductColorRecord = EntityMixin({
    shop_id: 0,
    value: '#ffffff',      // #RRGGBB or #RRGGBBAA
    name: '',
    mv_variant_name: '',
    i18n_languages: List(),

    // ProductAdd, ProductEdit에서 사용
    image: null,
    imageData: null,
});

class ProductColor extends ProductColorRecord {

    constructor(args = {}) {
        super({
            ...args,
            i18n_languages: args.i18n_languages
                ? List(args.i18n_languages.map(item => new I18NLanguageProductColor(item))) : List(),
        });
    }

    findDefaultLanguageCode() {
        const i18nLanguage = this.i18n_languages.find(item => item.get('is_default'));

        return i18nLanguage ? i18nLanguage.get('language_code') : LANG_KOREAN;
    }

    isReadyImage() {
        return this.image || this.imageData;
    }

    canAdd() {
        return this.value && this.i18n_languages.size > 0 && this.i18n_languages.every(item => item.canAdd());
    }

    canEdit(oldbie) {
        if (!oldbie) {
            return false;
        }

        const oldbieI18nLanguages = oldbie.get('i18n_languages');
        let canI18nLanguagesEdit = false;

        if (this.i18n_languages.equals(oldbieI18nLanguages)) {
            let canAllAdd = true;

            for (let i=0; i < this.i18n_languages.size; i++) {
                const item = this.i18n_languages.get(i);

                if (!item.canAdd()) {
                    canAllAdd = false;
                    break;
                }

                if (!canI18nLanguagesEdit) {
                    canI18nLanguagesEdit = !item.isSame(oldbieI18nLanguages.get(i));
                }
            }

            if (!canAllAdd) {
                canI18nLanguagesEdit = false;
            }
        } else {
            canI18nLanguagesEdit = this.i18n_languages.every(item => item.canAdd());
        }

        return (this.value && this.value !== oldbie.get('value'))
            || (this.mv_variant_name !== oldbie.get('mv_variant_name'))
            || canI18nLanguagesEdit;
    }

    makeFormData(oldbie = null) {
        const formData = new FormData();

        if (this.value) {
            if (!oldbie || this.value !== oldbie.get('value')) {
                formData.append('value', this.value);
            }
        }

        if (!oldbie || this.mv_variant_name !== oldbie.get('mv_variant_name')) {
            formData.append('mv_variant_name', this.mv_variant_name);
        }

        this.i18n_languages.forEach(item => {
            item.makeFormData(formData, oldbie)
        });

        return formData;
    }
}

export default ProductColor;
