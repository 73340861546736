import {fromJS} from "immutable";
import {
    PLUGIN_AR_VIEWER_CLEAR,
    PLUGIN_AR_VIEWER_SET_INITIALIZED,
    PLUGIN_AR_VIEWER_SET_IS_WEB_AR_POPUP_SHOWN
} from "../actions/UIActionTypes";
import {
    FAIL_INIT_PLUGIN_SKIN_FILTER_VIEWER_BY_SHOP_STAFF,
    REQUEST_INIT_PLUGIN_SKIN_FILTER_VIEWER_BY_SHOP_STAFF,
    SUCCEED_INIT_PLUGIN_SKIN_FILTER_VIEWER_BY_SHOP_STAFF
} from "../actions/RESTActionTypes";
import Plugin from '../../models/Plugin';
import RestError from "../../models/RestError";

export const initialPluginArViewer = fromJS({
    isLoading: false,
    plugin: null,
    error: null,
    isInitialized: false,
    isWebArPopupShown: false,
})

export function pluginArViewer(state = initialPluginArViewer, action) {
    switch (action.type) {
        case PLUGIN_AR_VIEWER_CLEAR:
            return initialPluginArViewer;

        case REQUEST_INIT_PLUGIN_SKIN_FILTER_VIEWER_BY_SHOP_STAFF:
            return initialPluginArViewer.set('isLoading', true);

        case SUCCEED_INIT_PLUGIN_SKIN_FILTER_VIEWER_BY_SHOP_STAFF:
            return state.set('isLoading', false)
                .set('plugin', new Plugin(action.result))
                .set('error', null);
        case FAIL_INIT_PLUGIN_SKIN_FILTER_VIEWER_BY_SHOP_STAFF:
            return state.set('isLoading', false)
                .set('error', new RestError(action.error));

        case PLUGIN_AR_VIEWER_SET_INITIALIZED:
            return state.set('isInitialized', true);

        case PLUGIN_AR_VIEWER_SET_IS_WEB_AR_POPUP_SHOWN:
            return state.set('isWebArPopupShown', action.isWebArPopupShown);

        default:
            return state;
    }
}