import {fromJS, List, Map} from "immutable";
import {
    ADMIN_CONTROLLER_CHANGE_ARMTI_INDEX,
    ADMIN_CONTROLLER_CHANGE_FACE_EFFECT_EXTRA_TOP_AREA,
    ADMIN_CONTROLLER_CHANGE_FACE_EFFECT_IMAGE,
    ADMIN_CONTROLLER_CHANGE_MODEL_DIRECTION,
    ADMIN_CONTROLLER_CHANGE_MODEL_TRANSFORM,
    ADMIN_CONTROLLER_GET_SELECTED_PRODUCT_OPTION,
    ADMIN_CONTROLLER_RESET_MODEL_TRANSFORM,
    PRODUCT_AR_VIEWER_SET_INITIALIZED,
    ADMIN_CONTROLLER_CHANGE_ENV_IMAGE_PRESETS,
    ADMIN_CONTROLLER_CHANGE_LIGHTING_ENV_INPUTS
} from "../../actions/UIActionTypes";
import {
    changeArModelTransform,
    setAllArModelTransform,
    setArModelTramsformUvMapPosition,
} from "../../../behaviors/WebArArMtiBehavior";
import {
    FAIL_GET_ENV_IMAGE_PRESETS_BY_MANAGER,
    FAIL_GET_FACE_EFFECTS_BY_MANAGER,
    FAIL_GET_PRODUCT_BY_MANAGER,
    FAIL_INIT_PRODUCT_AR_VIEWER_BY_MANAGER,
    FAIL_UPDATE_AR_MODEL_TRANSFORM_INFO_BY_MANAGER,
    FAIL_UPDATE_THREE_MODEL_TRANSFORM_INFO_BY_MANAGER,
    REQUEST_GET_ENV_IMAGE_PRESETS_BY_MANAGER,
    REQUEST_GET_FACE_EFFECTS_BY_MANAGER,
    REQUEST_GET_PRODUCT_BY_MANAGER,
    REQUEST_INIT_PRODUCT_AR_VIEWER_BY_MANAGER,
    REQUEST_UPDATE_AR_MODEL_TRANSFORM_INFO_BY_MANAGER,
    REQUEST_UPDATE_THREE_MODEL_TRANSFORM_INFO_BY_MANAGER,
    SUCCEED_GET_ENV_IMAGE_PRESETS_BY_MANAGER,
    SUCCEED_GET_FACE_EFFECTS_BY_MANAGER,
    SUCCEED_GET_PRODUCT_BY_MANAGER,
    SUCCEED_INIT_PRODUCT_AR_VIEWER_BY_MANAGER,
    SUCCEED_UPDATE_AR_MODEL_TRANSFORM_INFO_BY_MANAGER,
    SUCCEED_UPDATE_THREE_MODEL_TRANSFORM_INFO_BY_MANAGER
} from "../../actions/RESTActionTypes";
import Shop from "../../../models/Shop";
import Product from "../../../models/Product";
import RestError from "../../../models/RestError";
import {getOptionIndex} from "../../../behaviors/ProductOptionBehavior";
import ArModelTransformInfo from "../../../models/ArModelTransformInfo";
import {ARMTI_LEFT_DIRECTION} from "../../../constants/ArMtiConstants";
import FaceEffect from "../../../models/FaceEffect";
import {
    changeProductOptionFaceEffect,
    changeProductOptionFaceEffectExtraTopArea,
    setProductOptionFaceEffect,
    setProductOptionLightingEnvExposure,
    setProductOptionLightingEnvUrl,
    changeProductOptionLightingEnvUrl,
    changeProductOptionLightingEnvExposure,
    setProductOptionLightingEnvRotationY,
    changeProductOptionLightingEnvRotationY,
} from "../../../behaviors/WebArProductOptionBehavior";
import ProductOption, {FaceEffectInfo, LightingEnv} from "../../../models/ProductOption";
import EnvImagePreset from "../../../models/EnvImagePreset";
import {
    PRODUCT_OPTION_LIGHTING_ENV_EXPOSURE_KEY,
    PRODUCT_OPTION_LIGHTING_ENV_ROTATION_Y_KEY
} from "../../../constants/ProductOptionConstants";

export const initialAdminController = fromJS({
    error: null,
    isInitialized: false,
    isGetProductLoading: false,
    isGetProductError: false,
    isUpdateArMtiLoading: false,
    isSucceedUpdateArMti: false,
    isFailUpdateArMti: false,
    isOpenMobileDrawer: false,
    product: null,
    shop: null,
    selectedProductOptionIndex: null,
    envImagePresets: List(),
    faceEffects: List(),
    tempArMti: null,
    tempLightingEnv: Map(),
    tempFaceEffectInfo: Map(),
    selectedModelDirection: 1,
});

export function adminController(state = initialAdminController, action) {
    let product;
    let productOptions;
    let armtis, arMti, arMtiIndex, arMtiItems;
    let faceEffectInfo, extraTopArea, exposure, rotationY;
    let tempArMti, tempLightingEnv, tempFaceEffectInfo, tempLightingEnvRotation;
    let centerOptionIndex, optionIndex;
    let selectedModelDirection;
    let selectedProductOptionIndex;

    switch (action.type) {
        case PRODUCT_AR_VIEWER_SET_INITIALIZED:
            return state.set('isInitialized', true);

        case REQUEST_INIT_PRODUCT_AR_VIEWER_BY_MANAGER:
            return state.set('isGetProductLoading', true);

        case SUCCEED_INIT_PRODUCT_AR_VIEWER_BY_MANAGER:
            product = new Product(action.product);
            productOptions = product.get('options');
            centerOptionIndex = productOptions && Math.floor(productOptions.size / 2);
            optionIndex = productOptions && getOptionIndex(productOptions, centerOptionIndex);
            tempArMti = productOptions && productOptions.getIn([optionIndex, 'armtis', '0']);
            tempLightingEnv = productOptions && productOptions.getIn([optionIndex, 'lighting_env']);
            tempFaceEffectInfo = productOptions && productOptions.getIn([optionIndex, 'face_effect_info']);

            if (!tempArMti || !productOptions) {
                return state.set('isGetProductError', true)
                    .set('isGetProductLoading', false);
            }

            return state.set('isGetProductLoading', false)
                .set('shop', new Shop(action.shop))
                .set('product', product)
                .set('tempArMti', tempArMti)
                .set('tempLightingEnv', tempLightingEnv)
                .set('tempFaceEffectInfo', tempFaceEffectInfo)
                .set('selectedProductOptionIndex', optionIndex)
                .set('error', null);

        case FAIL_INIT_PRODUCT_AR_VIEWER_BY_MANAGER:
            return state.set('isGetProductLoading', false).set('error', new RestError(action.error));

        case REQUEST_GET_PRODUCT_BY_MANAGER:
            return state.set('isGetProductLoading', true);

        case SUCCEED_GET_PRODUCT_BY_MANAGER:
            product = new Product(action.result);
            productOptions = product.get('options');
            centerOptionIndex = Math.floor(productOptions.size / 2);
            optionIndex = getOptionIndex(productOptions, centerOptionIndex);
            tempArMti = productOptions.getIn([optionIndex, 'armtis', '0']);
            tempLightingEnv = productOptions && productOptions.getIn([optionIndex, 'lighting_env']);
            tempFaceEffectInfo = productOptions && productOptions.getIn([optionIndex, 'face_effect_info']);

            return state.set('isGetProductLoading', false)
                .set('product', product)
                .set('tempArMti', tempArMti)
                .set('tempLightingEnv', tempLightingEnv)
                .set('tempFaceEffectInfo', tempFaceEffectInfo)
                .set('selectedProductOptionIndex', optionIndex)
                .set('selectedModelDirection', ARMTI_LEFT_DIRECTION);

        case FAIL_GET_PRODUCT_BY_MANAGER:
            return state.set('isGetProductLoading', false).set('error', new RestError(action.error));

        case REQUEST_UPDATE_AR_MODEL_TRANSFORM_INFO_BY_MANAGER:
            return state.set('isUpdateArMtiLoading', true)
                .set('isSucceedUpdateArMti', false)
                .set('isFailUpdateArMti', false);

        case SUCCEED_UPDATE_AR_MODEL_TRANSFORM_INFO_BY_MANAGER:
            arMti = new ArModelTransformInfo(action.arMti);
            product = state.get('product');
            optionIndex = product.get('options').findIndex(item => item.get('id') === action.productOptionId);
            arMtiIndex = product.getIn(['options', optionIndex, 'armtis'])
                .findIndex(item => item.get('id') === action.armtiId);

            product = product.setIn(['options', optionIndex, 'armtis', arMtiIndex], arMti);

            return state.set('isUpdateArMtiLoading', false)
                .set('isSucceedUpdateArMti', true)
                .set('product', product);

        case FAIL_UPDATE_AR_MODEL_TRANSFORM_INFO_BY_MANAGER:
            return state.set('isUpdateArMtiLoading', false)
                .set('isFailUpdateArMti', true)
                .set('error', new RestError(action.error));

        case REQUEST_UPDATE_THREE_MODEL_TRANSFORM_INFO_BY_MANAGER:
            return state.set('isUpdateArMtiLoading', true)
                .set('isSucceedUpdateArMti', false)
                .set('isFailUpdateArMti', false);

        case SUCCEED_UPDATE_THREE_MODEL_TRANSFORM_INFO_BY_MANAGER:
            product = state.get('product');
            optionIndex = product.get('options').findIndex(item => item.get('id') === action.result.id);

            if (optionIndex >= 0) {
                product = product.setIn(['options', optionIndex], new ProductOption(action.result));
            } else {
                state = state.set('isUpdateArMtiLoading', false)
                    .set('isFailUpdateArMti', true);
            }

            return state.set('isUpdateArMtiLoading', false)
                .set('isSucceedUpdateArMti', true)
                .set('product', product);

        case FAIL_UPDATE_THREE_MODEL_TRANSFORM_INFO_BY_MANAGER:
            return state.set('isUpdateArMtiLoading', false)
                .set('isFailUpdateArMti', true)
                .set('error', new RestError(action.error));

        case REQUEST_GET_ENV_IMAGE_PRESETS_BY_MANAGER:
            return state.set('isGetProductLoading', true);

        case SUCCEED_GET_ENV_IMAGE_PRESETS_BY_MANAGER:
            return state.set('isGetProductLoading', false)
                .set('envImagePresets', List(action.results.map(item => new EnvImagePreset(item))))
                .set('error', null);

        case FAIL_GET_ENV_IMAGE_PRESETS_BY_MANAGER:
            return state.set('isUpdateArMtiLoading', false)
                .set('isFailUpdateArMti', true)
                .set('error', new RestError(action.error));

        case REQUEST_GET_FACE_EFFECTS_BY_MANAGER:
            return state.set('isGetProductLoading', true)

        case SUCCEED_GET_FACE_EFFECTS_BY_MANAGER:
            return state.set('isGetProductLoading', false)
                .set('faceEffects', List(action.results.map(item => new FaceEffect(item))))
                .set('error', null);

        case FAIL_GET_FACE_EFFECTS_BY_MANAGER:
            return state.set('isUpdateArMtiLoading', false)
                .set('isFailUpdateArMti', true)
                .set('error', new RestError(action.error));

        case ADMIN_CONTROLLER_GET_SELECTED_PRODUCT_OPTION:
            productOptions = state.getIn(['product', 'options']);

            const productOptionIndex = productOptions.findIndex(item => item.get('id') === action.productOptionId);
            let index = 0;

            if (productOptionIndex >= 0) {
                index = productOptionIndex;
                tempArMti = productOptions.getIn([productOptionIndex, 'armtis', '0']);
                tempLightingEnv = productOptions && productOptions.getIn([productOptionIndex, 'lighting_env']);
                tempFaceEffectInfo = productOptions && productOptions.getIn([productOptionIndex, 'face_effect_info']);
            }

            return state.set('selectedProductOptionIndex', index)
                .set('tempArMti', tempArMti ? tempArMti : null)
                .set('tempLightingEnv', tempLightingEnv ? tempLightingEnv : null)
                .set('tempFaceEffectInfo', tempFaceEffectInfo ? tempFaceEffectInfo : null)
                .set('selectedModelDirection', ARMTI_LEFT_DIRECTION);

        case ADMIN_CONTROLLER_CHANGE_MODEL_TRANSFORM:
            selectedModelDirection = state.get('selectedModelDirection');
            tempArMti = state.get('tempArMti');

            if (action.key === 'uv_map_pos' || action.key === 'uv_map_pos_2nd') {
                selectedModelDirection === ARMTI_LEFT_DIRECTION
                    ? setArModelTramsformUvMapPosition(
                        +action.value,
                        tempArMti.get('uv_map_pos_2nd') ? tempArMti.get('uv_map_pos_2nd') : -1
                    )
                    : setArModelTramsformUvMapPosition(
                        tempArMti.get('uv_map_pos') ? tempArMti.get('uv_map_pos') : -1,
                        +action.value
                    );
            } else {
                selectedModelDirection === ARMTI_LEFT_DIRECTION
                    ? changeArModelTransform(action.key, action.value, null)
                    : changeArModelTransform(action.key, null, action.value);
            }

            return state.setIn(['tempArMti', action.key], parseFloat(action.value));

        case ADMIN_CONTROLLER_CHANGE_ENV_IMAGE_PRESETS:
            const envImagePresetId = action.envImagePresets ? action.envImagePresets.get('id') : 0;
            const envImagePresetUrl = action.envImagePresets ? action.envImagePresets.get('hdr_image') : '';

            changeProductOptionLightingEnvUrl(envImagePresetUrl);
            tempLightingEnv = state.get('tempLightingEnv');
            exposure = tempLightingEnv.get('exposure');
            rotationY = tempLightingEnv.getIn(['rotation', 'y']);
            return state.setIn(
                ['tempLightingEnv', 'env_image_preset_id'], envImagePresetId
            )
                .setIn(['tempLightingEnv', 'exposure'], envImagePresetId === 0 ? 0 : parseFloat(exposure))
                .setIn(['tempLightingEnv', 'rotation', 'y'], envImagePresetId === 0 ? 0 : parseFloat(rotationY));

        case ADMIN_CONTROLLER_CHANGE_LIGHTING_ENV_INPUTS:
            if (action.key === PRODUCT_OPTION_LIGHTING_ENV_ROTATION_Y_KEY) {

                changeProductOptionLightingEnvRotationY(action.value);
                state = state.setIn(['tempLightingEnv', 'rotation', action.key], parseFloat(action.value))
            } else if (action.key === PRODUCT_OPTION_LIGHTING_ENV_EXPOSURE_KEY) {

                changeProductOptionLightingEnvExposure(action.value);
                state = state.setIn(['tempLightingEnv', action.key], parseFloat(action.value))
            }

            return state;

        case ADMIN_CONTROLLER_CHANGE_FACE_EFFECT_EXTRA_TOP_AREA:
            changeProductOptionFaceEffectExtraTopArea(action.value);

            return state.setIn(['tempFaceEffectInfo', action.key], parseFloat(action.value));

        case ADMIN_CONTROLLER_CHANGE_FACE_EFFECT_IMAGE:
            const faceEffectId = action.faceEffect ? action.faceEffect.get('id') : 0;
            const faceEffectImage = action.faceEffect ? action.faceEffect.get('image') : '';

            tempFaceEffectInfo = state.get('tempFaceEffectInfo');
            extraTopArea = tempFaceEffectInfo.get('extra_top_area');

            changeProductOptionFaceEffect(faceEffectImage, extraTopArea);
            return state.setIn(
                ['tempFaceEffectInfo', 'face_effect_id'], faceEffectId
            )
                .setIn(['tempFaceEffectInfo', 'extra_top_area'], faceEffectId === 0 ? 0 : parseFloat(extraTopArea));

        case ADMIN_CONTROLLER_CHANGE_ARMTI_INDEX:
            tempArMti = state.get('tempArMti');
            armtis = state.getIn(['product', 'armtis']);
            arMti = armtis.find(item => item.get('id') === action.arMtiId);

            return state.set('tempArMti', arMti ? arMti : armtis.get('0'));

        case ADMIN_CONTROLLER_RESET_MODEL_TRANSFORM:
            selectedProductOptionIndex = state.get('selectedProductOptionIndex');
            selectedModelDirection = state.get('selectedModelDirection');
            tempArMti = state.get('tempArMti');
            armtis = state.getIn(['product', 'options', selectedProductOptionIndex, 'armtis']);
            arMti = armtis.find(item => item.get('id') === tempArMti.get('id'));
            tempLightingEnv = state.getIn(['product', 'options', selectedProductOptionIndex, 'lighting_env']);
            faceEffectInfo = state.getIn(['product', 'options', selectedProductOptionIndex, 'face_effect_info']);

            tempLightingEnvRotation = tempLightingEnv.get('rotation');
            if (selectedModelDirection === ARMTI_LEFT_DIRECTION) {
                setAllArModelTransform(arMti, null);
                setArModelTramsformUvMapPosition(
                    arMti.get('uv_map_pos'),
                    arMti.get('uv_map_pos_2nd') ? arMti.get('uv_map_pos_2nd') : -1
                );
            } else {
                setAllArModelTransform(null, arMti);
                setArModelTramsformUvMapPosition(
                    arMti.get('uv_map_pos') ?  arMti.get('uv_map_pos') : -1,
                    arMti.get('uv_map_pos_2nd')
                );
                setProductOptionLightingEnvUrl(tempLightingEnv);
                setProductOptionLightingEnvExposure(tempLightingEnv);
                setProductOptionLightingEnvRotationY(tempLightingEnvRotation);
                setProductOptionFaceEffect(faceEffectInfo)
            }

            return state.set('tempArMti', arMti)
                .set('tempLightingEnv', tempLightingEnv)
                .set('tempFaceEffectInfo', faceEffectInfo);

        case ADMIN_CONTROLLER_CHANGE_MODEL_DIRECTION:
            return state.set('selectedModelDirection', parseInt(action.value));

        default:
            return state;
    }
}