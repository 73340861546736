import {request} from "universal-rxjs-ajax";
import {createManagerHeaders, getBaseUrl} from "./APIClient";
import {convertQueryParams2Str} from "../utils/UrlUtils";

const PATH_PRODUCT_CATEGORIES = '/product_categories';

export function getProductCategories(queryParams) {
    const path = PATH_PRODUCT_CATEGORIES;
    const url = `${getBaseUrl()}${path}${convertQueryParams2Str(queryParams)}`;

    return request({url, method: 'GET', headers: createManagerHeaders(path)});
}

export function getProductCategory(categoryId) {
    const path = `${PATH_PRODUCT_CATEGORIES}/${categoryId}`;

    return request({url: `${getBaseUrl()}${path}`, method: 'GET', headers: createManagerHeaders(path)});
}
