import {EntityMixin} from "./Entity";
import ImageData from "./ImageData";

const FaceEffectRecord = EntityMixin({
    name: '',
    image: null,
    imageData: null,
    uid: null,
});

class FaceEffect extends FaceEffectRecord {

    constructor(args = {}) {
        super({
            ...args,
            imageData: args.imageData ? new ImageData(args.imageData) : null,
        });
    }
}

export default FaceEffect;
