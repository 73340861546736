import {Record} from "immutable";
import ImageData from "./ImageData";

const EyewearImageItemRecord = Record({
    front: '',
    frontImageData: null,
    side: '',
    sideImageData: null,
    side_45: '',
    side45ImageData: null,
    // top: '',
    // topImageData: null,
});

class EyewearImageItem extends EyewearImageItemRecord {

    constructor(args = {}) {
        super({
            ...args,
            frontImageData: args.frontImageData ? new ImageData(args.frontImageData) : null,
            sideImageData: args.sideImageData ? new ImageData(args.sideImageData) : null,
            side45ImageData: args.side45ImageData ? new ImageData(args.side45ImageData) : null,
            // topImageData: args.topImageData ? new ImageData(args.topImageData) : null,
        });
    }

    equals(other) {
        if (!other) {
            return false;
        }
        return this.front === other.get('front')
            && this.frontImageData === other.get('frontImageData')
            && this.side === other.get('side')
            && this.sideImageData === other.get('sideImageData')
            && this.side_45 === other.get('side_45')
            && this.side45ImageData === other.get('side45ImageData')
            && this.top === other.get('top')
            // && this.topImageData === other.get('topImageData');
    }

    canAdd() {
        return (this.frontImageData || this.front)
            && (this.sideImageData || this.side)
            && (this.side45ImageData || this.side_45)
            // && (this.topImageData || this.top);
    }

    isSame(other) {
        if (!other) {
            return false;
        }

        return (this.front === other.get('front') && this.frontImageData === other.get('frontImageData'))
            && (this.side === other.get('side') && this.sideImageData === other.get('sideImageData'))
            && (this.side_45 === other.get('side_45') && this.side45ImageData === other.get('side45ImageData'))
            // && (this.top === other.get('top') && this.topImageData === other.get('topImageData'));
    }
}

const ProductImageItemRecord = Record({
    eyewear: new EyewearImageItem(),
});

export class ProductImageItem extends ProductImageItemRecord {

    constructor(args = {}) {
        super({
            ...args,
            eyewear: args.eyewear ? new EyewearImageItem(args.eyewear) : new EyewearImageItem(),
        });
    }

    equals(other) {
        if (!other) {
            return false;
        }
        return this.eyewear.equals(other.get('eyewear'));
    }

    canAdd(categoryUid) {
        return this.get(categoryUid).canAdd();
    }

    isSame(categoryUid, other) {
        if (!other) {
            return false;
        }
        return this.get(categoryUid).isSame(other);
    }
}

const EyewearSpecRecord = Record({
    front_width: 0,
    front_height: 0,
    side_width: 0,
    unit: 'cm',
});

class EyewearSpec extends EyewearSpecRecord {

    constructor(args = {}) {
        super({...args});
    }

    equals(other) {
        if (!other) {
            return false;
        }
        return this.front_width === other.get('front_width')
            && this.front_height === other.get('front_height')
            && this.side_width === other.get('side_width')
            && this.unit === other.get('unit');
    }

    canAdd() {
        return this.front_width > 0
            && this.front_height > 0
            && this.side_width > 0
            && Boolean(this.unit);
    }

    isSame(other) {
        if (!other) {
            return false;
        }

        return this.front_width === other.get('front_width')
            && this.front_height === other.get('front_height')
            && this.side_width === other.get('side_width')
            && this.unit === other.get('unit');
    }
}

const ProductSpecRecord = Record({
    eyewear: new EyewearSpec(),
});

export class ProductSpec extends ProductSpecRecord {

    constructor(args = {}) {
        super({
            ...args,
            eyewear: args.eyewear ? new EyewearSpec(args.eyewear) : new EyewearSpec(),
        });
    }

    equals(other) {
        if (!other) {
            return false;
        }
        return this.eyewear.equals(other.get('eyewear'));
    }

    canAdd(categoryUid) {
        return this.get(categoryUid).canAdd();
    }

    isSame(categoryUid, other) {
        if (!other) {
            return false;
        }
        return this.get(categoryUid).isSame(other);
    }
}
