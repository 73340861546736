import {fromJS} from "immutable";
import RestError from "../../../models/RestError";
import {SHOP_STAFF_SIGNIN_ENTER_EMAIL, SHOP_STAFF_SIGNIN_ENTER_PASSWORD} from "../../actions/UIActionTypes";
import {
    FAIL_SIGNIN_SHOP_STAFF,
    REQUEST_SIGNIN_SHOP_STAFF,
    SUCCEED_SIGNIN_SHOP_STAFF,
} from "../../actions/RESTActionTypes";
import ShopStaff from "../../../models/ShopStaff";
import {isEmailValid} from "../../../utils/RegexUtils";

export const initialShopStaffSignin = fromJS({
    email: '',
    isEmailValid: false,
    password: '',
    isPasswordValid: false,
    isLoading: false,
    shopStaff: null,
    error: null,
});

export function shopStaffSignin(state = initialShopStaffSignin, action) {
    switch (action.type) {
        case SHOP_STAFF_SIGNIN_ENTER_EMAIL:
            return state.set('email', action.email).set('isEmailValid', isEmailValid(action.email));

        case SHOP_STAFF_SIGNIN_ENTER_PASSWORD:
            return state.set('password', action.password).set('isPasswordValid', action.password.length > 0);

        case REQUEST_SIGNIN_SHOP_STAFF:
            return state.set('isLoading', true).set('error', null);

        case SUCCEED_SIGNIN_SHOP_STAFF:
            return state.set('isLoading', false).set('shopStaff', new ShopStaff(action.result)).set('error', null);

        case FAIL_SIGNIN_SHOP_STAFF:
            return state.set('isLoading', false).set('error', new RestError(action.error));

        default:
            return state;
    }
}
