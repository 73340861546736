import React, {useEffect} from "react";
import ReactDOM from 'react-dom';
import {wrapper} from "../redux/stores";
import {appWithTranslation} from "next-i18next";
import {useRouter, withRouter} from "next/router";
import {ThemeProvider} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import {CacheProvider} from "@emotion/react";
import {createEmotionCache} from "../utils/EmotionCacheUtils";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import theme from "../styles/Themes";
import Head from "next/head";
import {getTitle} from "../behaviors/SeoBehavior";
import PageProgress from "../components/PageProgress/PageProgress";
import Channel from "../configs/channel.config";

const clientSideEmotionCache = createEmotionCache();

function LolozemWebArCMSApp({Component, emotionCache = clientSideEmotionCache, pageProps}) {
    const router = useRouter();
    const asPath = router.asPath;

    useEffect(() => {
        const onRouterChangeStart = () => {
            ReactDOM.render(
                <PageProgress open={true} router={router}/>,
                document.getElementById("page-progress"),
            );
        };
        const onRouterChangeComplete = () => {
            window.scrollTo(0, 0);

            ReactDOM.unmountComponentAtNode(document.getElementById("page-progress"));
        };
        const onRouterChangeError = () => {
            ReactDOM.unmountComponentAtNode(document.getElementById("page-progress"));
        };

        router.events.on('routeChangeStart', onRouterChangeStart);
        router.events.on('routeChangeComplete', onRouterChangeComplete);
        router.events.on('routeChangeError', onRouterChangeError);

        // Remove the server-side injected CSS.
        const jssStyles = document.querySelector('#jss-server-side');

        if (jssStyles) {
            jssStyles.parentNode.removeChild(jssStyles);
        }

        return () => {
            router.events.off('routeChangeStart', onRouterChangeStart);
            router.events.off('routeChangeComplete', onRouterChangeComplete);
            router.events.off('routeChangeError', onRouterChangeError);
        };
    }, []);

    return (
        <CacheProvider value={emotionCache}>
            <Head>
                <title>{getTitle()}</title>
                <meta charSet="utf-8"/>
                <meta
                    name="viewport"
                    content="initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, width=device-width, shrink-to-fit=no, viewport-fit=cover"
                />
                <link rel="canonical" href={`https://webar-cms.lolozem.com${asPath}`}/>
                <link rel="alternate" hrefLang="x-default" href={`https://webar-cms.lolozem.com${asPath}`}/>
                <meta property="og:url" content={`https://webar-cms.lolozem.com${asPath}`}/>
                <Channel/>
            </Head>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <ThemeProvider theme={theme}>
                    <CssBaseline/>
                    <Component {...pageProps}/>
                </ThemeProvider>
            </LocalizationProvider>
        </CacheProvider>
    )
}

export default wrapper.withRedux(withRouter(appWithTranslation(LolozemWebArCMSApp)));
