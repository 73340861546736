import {EntityMixin} from "./Entity";
import {List} from "immutable";
import {PricingFeatureItem, ProductCategoryPrice} from "./PricingData";
import {LANG_ENGLISH, LANG_KOREAN, REPUBLIC_OF_KOREA} from "../utils/LocaleUtils";
import moment from "moment";

export const RECURRING_PERIOD_MONTHLY = 1;
export const RECURRING_PERIOD_ANNUAL = 2;

export const PRICING_PAYMENT_TYPE_PRODUCT_LABEL = 'product';
export const PRICING_PAYMENT_TYPE_RECURRING_LABEL = 'recurring';
export const PRICING_PAYMENT_TYPE_ANNUAL_LABEL = 'annual';

const PricingRecord = EntityMixin({
    country_code: '',
    name: '',
    name_en: '',
    product_count: 0,
    price_won: 0,
    discount_price_won: 0,
    price_dollar: 0,
    discount_price_dollar: 0,
    recurring_period: 0,
    recurring_period_days: 0,
    level: 0,
    product_price_by_category: new ProductCategoryPrice(),
    features_by_lang: new PricingFeatureItem(),
    is_trial: false,
    is_visible: false,
    is_activated: false,
});

class Pricing extends PricingRecord {

    constructor(args = {}) {
        super({
            ...args,
            product_price_by_category: args.product_price_by_category
                ? new ProductCategoryPrice(args.product_price_by_category) : new ProductCategoryPrice(),
            features_by_lang: args.features_by_lang
                ? new PricingFeatureItem(args.features_by_lang) : new PricingFeatureItem(),
        });
    }

    getNameByLanguageCode(languageCode) {
        if (languageCode === LANG_KOREAN || languageCode === REPUBLIC_OF_KOREA) {
            return this.name;
        } else {
            return this.name_en;
        }
    }

    getPriceByCountryCode(countryCode) {
        if (countryCode === REPUBLIC_OF_KOREA) {
            return this.discount_price_won > 0 ? this.discount_price_won : this.price_won;
        } else {
            return this.discount_price_dollar > 0 ? this.discount_price_dollar : this.price_dollar;
        }
    }

    getFeaturesByLanguageCode(languageCode) {
        if (!this.features_by_lang) {
            return List();
        }

        if (languageCode === LANG_KOREAN) {
            return this.features_by_lang.get(LANG_KOREAN);
        } else {
            return this.features_by_lang.get(LANG_ENGLISH);
        }
    }

    makeIamportMerchantUid(shopId, isProductCharge = false) {
        if (!shopId) {
            return;
        }

        let paymentType;

        if (this.recurring_period === RECURRING_PERIOD_MONTHLY) {
            paymentType = PRICING_PAYMENT_TYPE_RECURRING_LABEL;
        } else if (this.recurring_period === RECURRING_PERIOD_ANNUAL) {
            paymentType = PRICING_PAYMENT_TYPE_ANNUAL_LABEL;
        }

        if (isProductCharge) {
            paymentType = PRICING_PAYMENT_TYPE_PRODUCT_LABEL;
        }

        const date = moment().format('YYYYMMDDHHmmssSSSSSS')

        return `lwebar-${shopId}-${paymentType}-${date}`;
    }

    makeIamportCustomerUid(shopId){
        if (!shopId) {
            return;
        }

        const date = moment().format('YYYYMMDDHHmmssSSSSSS')

        return `lwebar-c_uid-${shopId}-${date}`
    }


    makeFormData(oldbie = null) {
        const formData = new FormData();

        return formData;
    }
}

export default Pricing;
