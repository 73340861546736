import {Record} from "immutable";

const ArThreeModelDataRecord = Record({
    enc_ar_glb_file: '',
    ar_glb_file: '',
    ar_usdz_file: '',
    enc_ar_pos_glb_file: '',
    ar_pos_glb_file: '',
    ar_pos_usdz_file: '',
});

class ArThreeModelData extends ArThreeModelDataRecord {

    constructor(args = {}) {
        super({...args});
    }

    isValid() {
        return Boolean(this.enc_ar_glb_file) && Boolean(this.ar_glb_file) && Boolean(this.ar_usdz_file);
    }

    makeModelViewerData(arrayBuffer) {
        return {bitte: this.ar_glb_file, menge: this.ar_usdz_file, haltbar: arrayBuffer};
    }
}

export default ArThreeModelData;
