import {QueryParameterMixin} from "./QueryParameter";
import {List} from "immutable";
import {WEB_BUILDER_CAFE24, WEB_BUILDER_OWN_MALL, WEB_BUILDER_SHOPIFY} from "../../constants/PluginConstants";
import {ALL} from "../../constants/CommonConstants";

export const SHOP_SEARCH_QUERY_TYPE_NAME = '1';
export const SHOP_SEARCH_QUERY_TYPES = List.of(
    SHOP_SEARCH_QUERY_TYPE_NAME,
);

export const SHOP_SEARCH_BUILDERS = List.of(
    WEB_BUILDER_OWN_MALL,
    WEB_BUILDER_CAFE24,
    WEB_BUILDER_SHOPIFY,
);

export const SHOP_SEARCH_PERIOD_TYPE_CREATED_DATE = '1';
export const SHOP_SEARCH_PERIOD_TYPE_UPDATED_DATE = '2';
export const SHOP_SEARCH_PERIOD_TYPES = List.of(
    SHOP_SEARCH_PERIOD_TYPE_CREATED_DATE, SHOP_SEARCH_PERIOD_TYPE_UPDATED_DATE
);

const ShopQueryParamRecord = QueryParameterMixin({
    builder: ALL,
    queryType: SHOP_SEARCH_QUERY_TYPE_NAME,
    periodType: SHOP_SEARCH_PERIOD_TYPE_CREATED_DATE,
})

class ShopQueryParam extends ShopQueryParamRecord {
    constructor(args = {}) {
        super({
            ...args,
            builder: SHOP_SEARCH_BUILDERS.includes(args.builder) ? args.builder : ALL,
            queryType: SHOP_SEARCH_QUERY_TYPES.includes(args.queryType)
                ? args.queryType : SHOP_SEARCH_QUERY_TYPE_NAME,
            periodType: SHOP_SEARCH_PERIOD_TYPES.includes(args.periodType)
                ? args.periodType : SHOP_SEARCH_PERIOD_TYPE_CREATED_DATE,
        });
    }

    toObject() {
        return {
            ...super.toObject(),
            builder: this.builder === ALL ? '' : this.builder,
        };
    }
}

export default ShopQueryParam;
