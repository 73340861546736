import {fromJS, List, Map} from 'immutable';
import {
    FAIL_CREATE_PRODUCT_BY_MANAGER,
    FAIL_INIT_ADMIN_PRODUCT_ADD,
    REQUEST_CREATE_PRODUCT_BY_MANAGER,
    REQUEST_INIT_ADMIN_PRODUCT_ADD,
    SUCCEED_CREATE_PRODUCT_BY_MANAGER,
    SUCCEED_INIT_ADMIN_PRODUCT_ADD,
} from "../../actions/RESTActionTypes";
import {
    ADMIN_PRODUCT_ADD_ADD_3D_FILE_DATA,
    ADMIN_PRODUCT_ADD_ADD_ALL_AR_MODEL_TRANSFORM_INFO,
    ADMIN_PRODUCT_ADD_ADD_AR_MODEL_TRANSFORM_INFO,
    ADMIN_PRODUCT_ADD_ADD_COUNTRY_CODE,
    ADMIN_PRODUCT_ADD_ADD_LANGUAGE_CODE,
    ADMIN_PRODUCT_ADD_ADD_OPTION,
    ADMIN_PRODUCT_ADD_ADD_PRODUCT_COLOR,
    ADMIN_PRODUCT_ADD_ADD_PRODUCT_SIZE,
    ADMIN_PRODUCT_ADD_ADD_TEMP_AR_MODEL_TRANSFORM_INFO,
    ADMIN_PRODUCT_ADD_CHANGE_ARMTI_ORDER,
    ADMIN_PRODUCT_ADD_CHANGE_CATEGORY,
    ADMIN_PRODUCT_ADD_CHANGE_COUNTRY_INFO,
    ADMIN_PRODUCT_ADD_CHANGE_ENV_ROTATION,
    ADMIN_PRODUCT_ADD_CHANGE_FACE_EFFECT_INFO_EXTRA_TOP_AREA,
    ADMIN_PRODUCT_ADD_CHANGE_FACE_EFFECT_INFO_IMAGE,
    ADMIN_PRODUCT_ADD_CHANGE_LANGUAGE_INFO,
    ADMIN_PRODUCT_ADD_CHANGE_LIGHTING_ENV_EXPOSURE,
    ADMIN_PRODUCT_ADD_CHANGE_LIGHTING_ENV_PRESET,
    ADMIN_PRODUCT_ADD_CHANGE_OPTION,
    ADMIN_PRODUCT_ADD_CHANGE_OPTION_COLOR,
    ADMIN_PRODUCT_ADD_CHANGE_OPTION_COLOR_INFO,
    ADMIN_PRODUCT_ADD_CHANGE_OPTION_CONTACT_LENS_BLENDING,
    ADMIN_PRODUCT_ADD_CHANGE_OPTION_CONTACT_LENS_INPUT,
    ADMIN_PRODUCT_ADD_CHANGE_OPTION_COUNTRY_INFO,
    ADMIN_PRODUCT_ADD_CHANGE_OPTION_LANGUAGE_INFO,
    ADMIN_PRODUCT_ADD_CHANGE_OPTION_LENS_IMAGE,
    ADMIN_PRODUCT_ADD_CHANGE_OPTION_LENS_SAME_SHAPE_CHECK, ADMIN_PRODUCT_ADD_CHANGE_OPTIONS_ORDER,
    ADMIN_PRODUCT_ADD_CHANGE_PRODUCT,
    ADMIN_PRODUCT_ADD_CHANGE_TEMP_AR_MODEL_TRANSFORM_INFO,
    ADMIN_PRODUCT_ADD_CHANGE_TEMP_ARMTIS_I18N_LANGUAGE_NAME,
    ADMIN_PRODUCT_ADD_CLEAR_CREATE_STATE,
    ADMIN_PRODUCT_ADD_CLEAR_IMPORT_BUILDER_PRODUCT_INFO_STATE,
    ADMIN_PRODUCT_ADD_DELETE_3D_FILE_DATA,
    ADMIN_PRODUCT_ADD_DELETE_AR_MODEL_TRANSFORM_INFO,
    ADMIN_PRODUCT_ADD_DELETE_COUNTRY_CODE,
    ADMIN_PRODUCT_ADD_DELETE_LANGUAGE_CODE,
    ADMIN_PRODUCT_ADD_DELETE_OPTION,
    ADMIN_PRODUCT_ADD_DELETE_OPTION_CONTACT_LENS_IMAGE,
    ADMIN_PRODUCT_ADD_DELETE_TEMP_AR_MODEL_TRANSFORM_INFO,
    ADMIN_PRODUCT_ADD_GET_PREV_OPTION_AR_MODEL_TRANSFORM_INFO,
    ADMIN_PRODUCT_ADD_INIT_DATA, ADMIN_PRODUCT_ADD_REORDER, ADMIN_PRODUCT_ADD_REORDER_LIST,
    ADMIN_PRODUCT_ADD_REQUIRED_ERRORS,
    ADMIN_PRODUCT_ADD_REQUIRED_I18N_PRODUCT_COUNTRIES_ERRORS,
    ADMIN_PRODUCT_ADD_REQUIRED_I18N_PRODUCT_LANGUAGE_ERRORS,
    ADMIN_PRODUCT_ADD_REQUIRED_OPTION_ERRORS,
    ADMIN_PRODUCT_ADD_SET_DEFAULT_COUNTRY,
    ADMIN_PRODUCT_ADD_SET_DEFAULT_LANGUAGE, ADMIN_PRODUCT_ADD_SET_IS_OPENED_CHANGE_ORDER_DIALOG,
    ADMIN_PRODUCT_ADD_TOGGLE_ADD_MODE,
} from '../../actions/UIActionTypes';
import {indexOfCountryCode, indexOfLanguageCode, updateListByKeyValue, updateListItem} from "../../../utils/ListUtils";
import {
    changeDefaultCountryInProduct,
    changeDefaultLanguageInProduct,
    deleteCountryInProduct,
    deleteLanguageInProduct,
    reduceCategories,
} from "../../../behaviors/ProductBehavior";
import {
    addCountry2Options,
    addLanguage2Options,
    changeDefaultCountryInOptions,
    changeDefaultLanguageInOptions,
    changeProductOptionColor,
    deleteCountryInOptions,
    deleteLanguageInOptions, getOptionsByChangeOrder,
} from '../../../behaviors/ProductOptionBehavior';
import RestError from "../../../models/RestError";
import Brand from "../../../models/Brand";
import Product from "../../../models/Product";
import ProductOption, {ArImageSetting, FaceEffectInfo, LightingEnv} from "../../../models/ProductOption";
import I18NLanguageProduct from "../../../models/I18NLanguageProduct";
import I18NCountryProduct from "../../../models/I18NCountryProduct";
import I18NLanguageProductOption from "../../../models/I18NLanguageProductOption";
import I18NCountryProductOption from "../../../models/I18NCountryProductOption";
import ProductSize from "../../../models/ProductSize";
import ProductColor from "../../../models/ProductColor";
import Shop from "../../../models/Shop";
import EnvImagePreset from "../../../models/EnvImagePreset";
import {ARMTI_ORDER_LEFT} from "../../../constants/ArMtiConstants";
import FaceEffect from "../../../models/FaceEffect";
import {
    ProductI18nCountriesError,
    ProductI18nLanguagesError,
    ProductOptionRequiredError,
    ProductRequiredError
} from "../../../models/ui/ProductRequiredError";

export const initialAdminProductAdd = fromJS({
    isInitialized: false,
    isLoading: false,
    isSaveAndAddAnotherMode: false,
    brands: List(),
    categories: List(),
    categoryRootUid: null,
    envImagePresets: List(),
    faceEffects: List(),
    productSizes: List(),
    productColors: List(),
    error: null,
    defaultLanguageCode: '',
    defaultCountryCode: '',
    product: new Product(),
    shop: new Shop(),
    productOptionColors: List(),
    isCreating: false,
    succeedCreate: false,
    errorCreate: null,
    tempArMtis: null,
    arMtisData: List(),
    arMtiOrder: 1,
    requiredErrors: new ProductRequiredError(),
    isOpenedChangeOptionDialog: false,
    isReordered: false,
    tempOptions: List(),
});

export function adminProductAdd(state = initialAdminProductAdd, action) {
    let index = -1;
    let product, i18nLanguages, i18nCountries;
    let options, option, lightingEnv, faceEffectInfo, arImageSettings;
    let arMtis;
    let productOptionColors;
    let isArImageSameShape, arImage2Data;
    let requiredErrors, requiredErrorOptions, requiredErrorOption;
    let tempOptions;

    switch (action.type) {
        case ADMIN_PRODUCT_ADD_INIT_DATA:
            return initialAdminProductAdd;

        case ADMIN_PRODUCT_ADD_CLEAR_CREATE_STATE:
            return state.set('isCreating', initialAdminProductAdd.get('isCreating'))
                .set('succeedCreate', initialAdminProductAdd.get('succeedCreate'))
                .set('errorCreate', initialAdminProductAdd.get('errorCreate'));

        case REQUEST_INIT_ADMIN_PRODUCT_ADD:
            return state.set('isInitialized', false).set('isLoading', true);

        case SUCCEED_INIT_ADMIN_PRODUCT_ADD:
            const shop = new Shop(action.shop);
            const languageCode = shop.get('language_code');
            const countryCode = shop.get('country_code');
            const defaultLanguage = {language_code: languageCode, is_default: true};
            const defaultCountry = {country_code: countryCode, is_default: true};

            product = state.get('product');
            option = new ProductOption();
            requiredErrors = state.get('requiredErrors');

            return state.set('isInitialized', true)
                .set('isLoading', false)
                .set('brands', List(action.brands.map(item => new Brand(item))))
                .set('categories', reduceCategories(action.categories, 1))
                .set('envImagePresets', List(action.env_image_presets.map(item => new EnvImagePreset(item))))
                .set('faceEffects', List(action.face_effects.map(item => new FaceEffect(item))))
                .set('productSizes', List(action.product_sizes.map(item => new ProductSize(item))))
                .set('productColors', List(action.product_colors.map(item => new ProductColor(item))))
                .set('defaultLanguageCode', languageCode)
                .set('defaultCountryCode', countryCode)
                .set(
                    'product',
                    product.set('shop_id', shop.get('id'))
                        .set('shop', shop)
                        .set('i18n_languages',
                            product.get('i18n_languages').push(new I18NLanguageProduct(defaultLanguage)))
                        .set(
                            'i18n_countries',
                            product.get('i18n_countries').push(new I18NCountryProduct(defaultCountry))
                        )
                        .set(
                            'options',
                            product.get('options').push(
                                option.set(
                                    'i18n_languages',
                                    option.get('i18n_languages').push(new I18NLanguageProductOption(defaultLanguage))
                                )
                                    .set(
                                        'i18n_countries',
                                        option.get('i18n_countries').push(new I18NCountryProductOption(defaultCountry))
                                    )
                            )
                        )
                )
                .set(
                    'requiredErrors',
                    requiredErrors.set(
                        'options', requiredErrors.get('options').push(new ProductOptionRequiredError())
                    )
                        .set(
                            'i18n_languages',
                            requiredErrors.get('i18n_languages').push(new ProductI18nLanguagesError())
                        )
                        .set(
                            'i18n_countries',
                            requiredErrors.get('i18n_countries').push(new ProductI18nCountriesError())
                        )
                )
                .set('error', null);

        case FAIL_INIT_ADMIN_PRODUCT_ADD:
            return state.set('isLoading', false)
                .set('brands', List())
                .set('categories', List())
                .set('envImagePresets', List())
                .set('faceEffects', List())
                .set('productSizes', List())
                .set('productColors', List())
                .set('product', new Product())
                .set('error', new RestError(action.error));

        case ADMIN_PRODUCT_ADD_ADD_PRODUCT_SIZE:
            return state.set('productSizes', state.get('productSizes').push(action.productSize));

        case ADMIN_PRODUCT_ADD_ADD_PRODUCT_COLOR:
            return state.set('productColors', state.get('productColors').push(action.productColor));

        // case ADMIN_PRODUCT_ADD_SET_DEFAULT_LANG_AND_COUNTRY:
        //     const defaultLanguage = {language_code: action.languageCode, is_default: true};
        //     const defaultCountry = {country_code: action.countryCode, is_default: true};
        //     product = state.get('product');
        //     option = new ProductOption();
        //
        //     return state.set('defaultLanguageCode', action.languageCode)
        //         .set('defaultCountryCode', action.countryCode)
        //         .set(
        //             'product',
        //             product.set(
        //                 'i18n_languages', product.get('i18n_languages').push(new I18NLanguageProduct(defaultLanguage))
        //             )
        //                 .set(
        //                     'i18n_countries',
        //                     product.get('i18n_countries').push(new I18NCountryProduct(defaultCountry))
        //                 )
        //                 .set(
        //                     'options',
        //                     product.get('options').push(
        //                         option.set(
        //                             'i18n_languages',
        //                             option.get('i18n_languages').push(new I18NLanguageProductOption(defaultLanguage))
        //                         )
        //                             .set(
        //                                 'i18n_countries',
        //                                 option.get('i18n_countries').push(new I18NCountryProductOption(defaultCountry))
        //                             )
        //                     )
        //                 )
        //         );

        case ADMIN_PRODUCT_ADD_ADD_LANGUAGE_CODE:
            product = state.get('product');
            i18nLanguages = product.get('i18n_languages');
            index = indexOfLanguageCode(i18nLanguages, action.languageCode);

            if (index >= 0) {
                return state;
            }

            options = product.get('options');

            product = product.set(
                'i18n_languages', i18nLanguages.push(new I18NLanguageProduct({language_code: action.languageCode}))
            )
                .set('options', addLanguage2Options(options, action.languageCode));

            return state.set('product', product);

        case ADMIN_PRODUCT_ADD_DELETE_LANGUAGE_CODE:
            product = state.get('product');
            i18nLanguages = product.get('i18n_languages');
            index = indexOfLanguageCode(i18nLanguages, action.languageCode);

            if (index >= 0) {
                const {i18nLanguages: newI18nLanguages} = deleteLanguageInProduct(index, i18nLanguages);
                const {newOptions} = deleteLanguageInOptions(product.get('options'), action.languageCode);

                product = product.set('i18n_languages', newI18nLanguages).set('options', newOptions);

                state = state.set('product', product)
            }

            return state;

        case ADMIN_PRODUCT_ADD_SET_DEFAULT_LANGUAGE:
            product = state.get('product');

            return state.set(
                'product',
                product.set(
                    'i18n_languages',
                    changeDefaultLanguageInProduct(product.get('i18n_languages'), action.languageCode)
                )
                    .set('options', changeDefaultLanguageInOptions(product.get('options'), action.languageCode))
            )
                .set('defaultLanguageCode', action.languageCode);

        case ADMIN_PRODUCT_ADD_ADD_COUNTRY_CODE:
            product = state.get('product');
            i18nCountries = product.get('i18n_countries');
            index = indexOfCountryCode(i18nCountries, action.countryCode);

            if (index >= 0) {
                return state;
            }

            options = product.get('options');

            product = product.set(
                'i18n_countries', i18nCountries.push(new I18NCountryProduct({country_code: action.countryCode}))
            )
                .set('options', addCountry2Options(options, action.countryCode));

            return state.set('product', product);

        case ADMIN_PRODUCT_ADD_DELETE_COUNTRY_CODE:
            product = state.get('product');
            i18nCountries = product.get('i18n_countries');
            index = indexOfCountryCode(i18nCountries, action.countryCode);

            if (index >= 0) {
                const {i18nCountries: newI18nCountries} = deleteCountryInProduct(index, i18nCountries);
                const {newOptions} = deleteCountryInOptions(product.get('options'), action.countryCode);

                product = product.set('i18n_countries', newI18nCountries).set('options', newOptions);

                state = state.set('product', product)
            }

            return state;

        case ADMIN_PRODUCT_ADD_SET_DEFAULT_COUNTRY:
            product = state.get('product');

            return state.set(
                'product',
                product.set(
                    'i18n_countries',
                    changeDefaultCountryInProduct(product.get('i18n_countries'), action.countryCode)
                )
                    .set('options', changeDefaultCountryInOptions(product.get('options'), action.countryCode))
            )
                .set('defaultCountryCode', action.countryCode);

        case ADMIN_PRODUCT_ADD_CHANGE_PRODUCT:
            if (action.key === 'uid') {
                state = state.setIn(['requiredErrors', action.key], !action.value);
            } else if (action.key === 'repImageData') {
                state = state.setIn(['requiredErrors', action.key], !action.value);
            }

            return state.set('product', state.get('product').set(action.key, action.value));

        case ADMIN_PRODUCT_ADD_CHANGE_LANGUAGE_INFO:
            product = state.get('product');

            if (action.key === 'name') {
                state = state.setIn(['requiredErrors', 'i18n_languages', action.index, action.key], !action.value);
            }

            return state.set(
                'product',
                product.set(
                    'i18n_languages',
                    updateListByKeyValue(product.get('i18n_languages'), action.index, action.key, action.value)
                )
            );

        case ADMIN_PRODUCT_ADD_CHANGE_COUNTRY_INFO:
            product = state.get('product');

            if (action.key === 'price') {
                state = state.setIn(
                    ['requiredErrors', 'i18n_countries', action.index, action.key], !parseFloat(action.value)
                );
            }

            return state.set(
                'product',
                product.set(
                    'i18n_countries',
                    updateListByKeyValue(product.get('i18n_countries'), action.index, action.key, action.value)
                )
            );

        case ADMIN_PRODUCT_ADD_CHANGE_CATEGORY:
            options = state.getIn(['product', 'options']);
            const categoryRootUid = action.category.getRootCategoryUid();
            options = options.map(option => {
                arMtis = option.get('armtis');
                if (arMtis.size > 0) {
                     arMtis = arMtis.map(item => item.set('px_2nd', 0).set('py_2nd', 0).set('rx_2nd', 0).set('ry_2nd', 0)
                          .set('rz_2nd', 0).set('scale_2nd', 100).set('uv_map_pos_2nd', -1));
                }
                return option.set('glbFile2ndData', null).set('arPosGlbFile2ndData', null).set('armtis', arMtis)
                    .set('isArImageSameShape', true)
                    .set('ar_image_1', null).set('ar_image_2', null)
                    .set('arImage1Data', null).set('arImage2Data', null)
                    .set('ar_image_settings', new ArImageSetting());
            });

            return state.setIn(['product', 'category_id'], action.category.get('id'))
                .setIn(['product', 'category'], action.category)
                .setIn(['product', 'options'], options)
                .set('categoryRootUid',categoryRootUid)
                .setIn(['requiredErrors', 'categoryRootUid'], false);

        case ADMIN_PRODUCT_ADD_ADD_OPTION:
            product = state.get('product');
            options = product.get('options');
            option = ProductOption.makeNewbie(product).set('order', options.size);

            requiredErrors = state.get('requiredErrors');
            requiredErrorOptions = requiredErrors.get('options');

            requiredErrorOption = requiredErrorOptions.push(
                new ProductOptionRequiredError()
            )

            return state.set('product', product.set('options', options.push(option)))
                .setIn(['requiredErrors', 'options'], requiredErrorOption);

        case ADMIN_PRODUCT_ADD_CHANGE_OPTION:
            product = state.get('product');

            if (action.key === 'uid') {
                state = state.setIn(['requiredErrors', 'options', action.index, action.key], !action.value);
            }

            return state.set(
                'product',
                product.set(
                    'options', updateListByKeyValue(product.get('options'), action.index, action.key, action.value)
                )
            );

        case ADMIN_PRODUCT_ADD_CHANGE_OPTION_COLOR:
            product = state.get('product');

            const [newbieOptions, newProductOptionColors] = changeProductOptionColor(
                product.get('options'), state.get('productOptionColors'), action.index, action.productColor
            );

            return state.set('product', product.set('options', newbieOptions))
                .set('productOptionColors', newProductOptionColors)
                .setIn(['requiredErrors', 'options', action.index, 'color'], !action.productColor);

        case ADMIN_PRODUCT_ADD_DELETE_OPTION:
            product = state.get('product');
            options = product.get('options');

            requiredErrors = state.get('requiredErrors');
            requiredErrorOptions = requiredErrors.get('options');


            const deletedOption = options.get(action.index);
            const newOptions = options.delete(action.index);
            const hasSameColor = newOptions.some(item => item.get('color_id') === deletedOption.get('color_id'));

            if (!hasSameColor) {
                productOptionColors = state.get('productOptionColors').reduce((prev, cur) => {
                    if (cur.get('id') === deletedOption.get('color_id')) {
                        return prev;
                    } else {
                        return prev.push(cur);
                    }
                }, List());

                state = state.set('productOptionColors', productOptionColors);
            }

            return state.set('product', product.set('options', newOptions))
                .set('requiredErrors', requiredErrors.set('options', requiredErrorOptions.delete(action.index)));

        case ADMIN_PRODUCT_ADD_CHANGE_OPTION_LANGUAGE_INFO:
            product = state.get('product');
            options = product.get('options');
            option = options.get(action.optionIndex);
            option = option.set(
                'i18n_languages',
                updateListByKeyValue(option.get('i18n_languages'), action.langIndex, action.key, action.value)
            );

            return state.set('product', product.set('options', updateListItem(options, action.optionIndex, option)));

        case ADMIN_PRODUCT_ADD_CHANGE_OPTION_COUNTRY_INFO:
            product = state.get('product');
            options = product.get('options');
            option = options.get(action.optionIndex);
            option = option.set(
                'i18n_countries',
                updateListByKeyValue(option.get('i18n_countries'), action.countryIndex, action.key, action.value)
            );

            return state.set('product', product.set('options', updateListItem(options, action.optionIndex, option)));

        case ADMIN_PRODUCT_ADD_CHANGE_OPTION_COLOR_INFO:
            if (action.key === 'imageData') {
                state = state.setIn(['requiredErrors', 'options', action.index, action.key], !action.value);
            }

            return state.set(
                'productOptionColors',
                updateListByKeyValue(state.get('productOptionColors'), action.index, action.key, action.value)
            );

        case ADMIN_PRODUCT_ADD_CHANGE_LIGHTING_ENV_PRESET:
            lightingEnv = state.getIn(['product', 'options', `${action.index}`, action.name]);
            if (!lightingEnv) {
                lightingEnv = new LightingEnv();
            }

            return state.setIn(
                ['product', 'options' ,`${action.index}`, action.name],
                lightingEnv.set('env_image_preset_id', action.envImagePreset ? action.envImagePreset.get('id') : 0)
                    .set('envImagePreset', action.envImagePreset ? action.envImagePreset : null)
            );

        case ADMIN_PRODUCT_ADD_CHANGE_LIGHTING_ENV_EXPOSURE:
            lightingEnv = state.getIn(['product', 'options' ,`${action.index}`, action.name]);
            if (!lightingEnv) {
                lightingEnv = new LightingEnv();
            }

            return state.setIn(
                ['product', 'options' ,`${action.index}`, action.name],
                lightingEnv.set('exposure', action.exposure)
            )

        case ADMIN_PRODUCT_ADD_CHANGE_FACE_EFFECT_INFO_IMAGE:
            faceEffectInfo = state.getIn(['product', 'options', `${action.index}`, 'face_effect_info']);

            if (!faceEffectInfo) {
                faceEffectInfo = new FaceEffectInfo();
            }

            return state.setIn(
                ['product', 'options', `${action.index}`, 'face_effect_info'],
                faceEffectInfo.set('face_effect_id', action.faceEffect ? action.faceEffect.get('id') : 0)
            )

        case ADMIN_PRODUCT_ADD_CHANGE_FACE_EFFECT_INFO_EXTRA_TOP_AREA:
            faceEffectInfo = state.getIn(['product', 'options' ,`${action.index}`, 'face_effect_info']);

            if (!faceEffectInfo) {
                faceEffectInfo = new FaceEffectInfo();
            }

            return state.setIn(
                ['product', 'options' ,`${action.index}`, 'face_effect_info'],
                faceEffectInfo.set('extra_top_area', action.extraTopArea)
            )

        case ADMIN_PRODUCT_ADD_ADD_3D_FILE_DATA:
            return state.setIn(['product', 'options', `${action.index}`, `${action.key}`], action.fileData);

        case ADMIN_PRODUCT_ADD_DELETE_3D_FILE_DATA:
            return state.setIn(['product', 'options', `${action.index}`, `${action.imgDataKey}`], null);

        case ADMIN_PRODUCT_ADD_ADD_AR_MODEL_TRANSFORM_INFO:
            arMtis = state.getIn(['product', 'options', action.optionIndex, 'armtis']);
            arMtis = arMtis.set(action.arMtisIndex,  action.arMtis);
            return state.setIn(['product', 'options', action.optionIndex, 'armtis'], arMtis)
                .set('arMtiOrder', ARMTI_ORDER_LEFT);

        case ADMIN_PRODUCT_ADD_DELETE_AR_MODEL_TRANSFORM_INFO:
            arMtis = state.getIn(['product', 'options', action.optionIndex, 'armtis']);

            return state.setIn(['product', 'options', action.optionIndex, 'armtis'], arMtis.delete(action.arMtisIndex));

        case REQUEST_CREATE_PRODUCT_BY_MANAGER:
            return state.set('isCreating', true).set('succeedCreate', false).set('errorCreate', null);

        case SUCCEED_CREATE_PRODUCT_BY_MANAGER:
            return state.set('isCreating', false).set('succeedCreate', true).set('errorCreate', null);

        case FAIL_CREATE_PRODUCT_BY_MANAGER:
            return state.set('isCreating', false)
                .set('succeedCreate', false)
                .set('errorCreate', new RestError(action.error));

        case ADMIN_PRODUCT_ADD_CLEAR_IMPORT_BUILDER_PRODUCT_INFO_STATE:
            return state.set('errorImportBuilderProduct', null);

        case ADMIN_PRODUCT_ADD_CHANGE_TEMP_AR_MODEL_TRANSFORM_INFO:
            return state.setIn(['tempArMtis', action.key], action.value);

        case ADMIN_PRODUCT_ADD_ADD_TEMP_AR_MODEL_TRANSFORM_INFO:
            return state.set('tempArMtis', action.arMtis);

        case ADMIN_PRODUCT_ADD_CHANGE_TEMP_ARMTIS_I18N_LANGUAGE_NAME:
            return state.setIn(['tempArMtis', 'i18n_languages', action.i18nIndex, 'name'], action.value);

        case ADMIN_PRODUCT_ADD_DELETE_TEMP_AR_MODEL_TRANSFORM_INFO:
            return state.set('tempArMtis', null);

        case ADMIN_PRODUCT_ADD_GET_PREV_OPTION_AR_MODEL_TRANSFORM_INFO:
            return state.set('arMtisData', action.arMtisData);

        case ADMIN_PRODUCT_ADD_ADD_ALL_AR_MODEL_TRANSFORM_INFO:
            return state.setIn(['product', 'options', action.optionIndex, 'armtis', '0'], action.arMtis.get('0'));

        case ADMIN_PRODUCT_ADD_CHANGE_ARMTI_ORDER:
            return state.set('arMtiOrder', action.threeDFileOrder);

        case ADMIN_PRODUCT_ADD_CHANGE_ENV_ROTATION:
            return state.setIn(['product', 'options', action.optionIndex, action.name, 'rotation', action.key], action.value);

        case ADMIN_PRODUCT_ADD_CHANGE_OPTION_LENS_SAME_SHAPE_CHECK:
            product = state.get('product');

            isArImageSameShape = product.getIn(['options', action.optionIndex, 'isArImageSameShape']);
            arImage2Data = product.getIn(['options', action.optionIndex, 'arImage2Data']);

            if (!isArImageSameShape && arImage2Data) {
                state = state.setIn(['product', 'options', action.optionIndex, 'arImage2Data'], null);
            }

            return state.setIn(['product', 'options', action.optionIndex, 'isArImageSameShape'], action.value);

        case ADMIN_PRODUCT_ADD_CHANGE_OPTION_LENS_IMAGE:
            return state = state.setIn(['product', 'options', action.optionIndex, action.key], action.value)
                .setIn(['product', 'options', action.optionIndex, action.imgDataKey], null);

        case ADMIN_PRODUCT_ADD_DELETE_OPTION_CONTACT_LENS_IMAGE:

            return state.setIn(['product', 'options', action.optionIndex, action.imgDataKey], null);

        case ADMIN_PRODUCT_ADD_CHANGE_OPTION_CONTACT_LENS_BLENDING:
            arImageSettings = state.getIn(['product', 'options', action.optionIndex, 'ar_image_settings']);

            if (!arImageSettings) {
                arImageSettings = new ArImageSetting();
            }

            return state.setIn(
                ['product', 'options', action.optionIndex, 'ar_image_settings'],
                arImageSettings.set('blending_mode', action.blending ? action.blending.get('value') : '')
            );

        case ADMIN_PRODUCT_ADD_CHANGE_OPTION_CONTACT_LENS_INPUT:
            return state.setIn(['product', 'options', action.optionIndex, 'ar_image_settings', action.key], action.value);

        case ADMIN_PRODUCT_ADD_TOGGLE_ADD_MODE:
            return state.set('isSaveAndAddAnotherMode', action.isSaveAndAddAnotherMode);

        case ADMIN_PRODUCT_ADD_REQUIRED_ERRORS:
            return state.setIn(['requiredErrors', action.key], action.value);

        case ADMIN_PRODUCT_ADD_REQUIRED_I18N_PRODUCT_LANGUAGE_ERRORS:
            return state.setIn(['requiredErrors', 'i18n_languages', action.index, action.key], action.value);

        case ADMIN_PRODUCT_ADD_REQUIRED_I18N_PRODUCT_COUNTRIES_ERRORS:
            return state.setIn(['requiredErrors', 'i18n_countries', action.index, action.key], action.value);

        case ADMIN_PRODUCT_ADD_REQUIRED_OPTION_ERRORS:
            return state.setIn(['requiredErrors', 'options', action.index, action.key], action.value);

        case ADMIN_PRODUCT_ADD_SET_IS_OPENED_CHANGE_ORDER_DIALOG:
            if (action.isOpenedChangeOptionDialog) {
                options = state.getIn(['product', 'options']);
                state = state.set('tempOptions', options);
            }

            return state.set('isOpenedChangeOptionDialog', action.isOpenedChangeOptionDialog);

        case ADMIN_PRODUCT_ADD_REORDER:
            tempOptions = state.get('tempOptions');

            if (action.startIndex === action.endIndex) {
                return state;
            }

            tempOptions = getOptionsByChangeOrder(tempOptions, action.startIndex, action.endIndex);

            return state.set('tempOptions', tempOptions)
            .set('isReordered', true);

        case ADMIN_PRODUCT_ADD_REORDER_LIST:
            return state.set('isReordered', false);

        case ADMIN_PRODUCT_ADD_CHANGE_OPTIONS_ORDER:
            tempOptions = state.get('tempOptions');

            return state.setIn(['product', 'options'], tempOptions)
            .set('isOpenedChangeOptionDialog', false);

        default:
            return state;
    }
}
