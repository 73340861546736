import {EntityMixin} from "./Entity";

export const I18NCountryMixin = defaultValues => class extends EntityMixin({
    country_code: '',
    is_default: false,
    ...defaultValues
}) {
    constructor(args = {}) {
        super({...args});
    }

    equals(other) {
        if (!other) {
            return false;
        }
        return this.country_code === other.get('country_code');
    }
};

export default class I18NCountry extends I18NCountryMixin({}) {

    constructor(args = {}) {
        super({...args});
    }
}
