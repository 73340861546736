import {fromJS, List, Set} from "immutable";
import {
    FAIL_GET_WORK_REQUEST_HISTORIES_BY_MANAGER,
    FAIL_INIT_ADMIN_PRODUCT_EDIT,
    FAIL_UPDATE_PRODUCT_BY_MANAGER,
    REQUEST_GET_WORK_REQUEST_HISTORIES_BY_MANAGER,
    REQUEST_INIT_ADMIN_PRODUCT_EDIT,
    REQUEST_UPDATE_PRODUCT_BY_MANAGER,
    SUCCEED_GET_WORK_REQUEST_HISTORIES_BY_MANAGER,
    SUCCEED_INIT_ADMIN_PRODUCT_EDIT,
    SUCCEED_UPDATE_PRODUCT_BY_MANAGER,
} from "../../actions/RESTActionTypes";
import {
    ADMIN_PRODUCT_ADD_CLEAR_IMPORT_BUILDER_PRODUCT_INFO_STATE,
    ADMIN_PRODUCT_EDIT_ADD_3D_FILE_DATA,
    ADMIN_PRODUCT_EDIT_ADD_ALL_AR_MODEL_TRANSFORM_INFO,
    ADMIN_PRODUCT_EDIT_ADD_AR_MODEL_TRANSFORM_INFO,
    ADMIN_PRODUCT_EDIT_ADD_COUNTRY_CODE,
    ADMIN_PRODUCT_EDIT_ADD_LANGUAGE_CODE,
    ADMIN_PRODUCT_EDIT_ADD_OPTION,
    ADMIN_PRODUCT_EDIT_ADD_PRODUCT_COLOR,
    ADMIN_PRODUCT_EDIT_ADD_PRODUCT_SIZE,
    ADMIN_PRODUCT_EDIT_ADD_TEMP_AR_MODEL_TRANSFORM_INFO,
    ADMIN_PRODUCT_EDIT_ADD_TEMP_WORK_REQUEST,
    ADMIN_PRODUCT_EDIT_ADD_WORK_REQUEST,
    ADMIN_PRODUCT_EDIT_CHANGE_ARMTI_ORDER,
    ADMIN_PRODUCT_EDIT_CHANGE_CATEGORY,
    ADMIN_PRODUCT_EDIT_CHANGE_COUNTRY_INFO,
    ADMIN_PRODUCT_EDIT_CHANGE_ENV_ROTATION,
    ADMIN_PRODUCT_EDIT_CHANGE_FACE_EFFECT_INFO_EXTRA_TOP_AREA,
    ADMIN_PRODUCT_EDIT_CHANGE_FACE_EFFECT_INFO_IMAGE,
    ADMIN_PRODUCT_EDIT_CHANGE_LANGUAGE_INFO,
    ADMIN_PRODUCT_EDIT_CHANGE_LIGHTING_ENV_EXPOSURE,
    ADMIN_PRODUCT_EDIT_CHANGE_LIGHTING_ENV_PRESET,
    ADMIN_PRODUCT_EDIT_CHANGE_OPTION,
    ADMIN_PRODUCT_EDIT_CHANGE_OPTION_COLOR,
    ADMIN_PRODUCT_EDIT_CHANGE_OPTION_COLOR_INFO,
    ADMIN_PRODUCT_EDIT_CHANGE_OPTION_CONTACT_LENS_BLENDING,
    ADMIN_PRODUCT_EDIT_CHANGE_OPTION_CONTACT_LENS_IMAGE,
    ADMIN_PRODUCT_EDIT_CHANGE_OPTION_CONTACT_LENS_INPUT,
    ADMIN_PRODUCT_EDIT_CHANGE_OPTION_COUNTRY_INFO,
    ADMIN_PRODUCT_EDIT_CHANGE_OPTION_LANGUAGE_INFO,
    ADMIN_PRODUCT_EDIT_CHANGE_OPTION_LENS_SAME_SHAPE_CHECK, ADMIN_PRODUCT_EDIT_CHANGE_OPTIONS_ORDER,
    ADMIN_PRODUCT_EDIT_CHANGE_PRODUCT,
    ADMIN_PRODUCT_EDIT_CHANGE_TEMP_AR_MODEL_TRANSFORM_INFO,
    ADMIN_PRODUCT_EDIT_CHANGE_TEMP_ARMTIS_I18N_LANGUAGE_NAME,
    ADMIN_PRODUCT_EDIT_CHANGE_TEMP_WORK_REQUEST_COMMENT,
    ADMIN_PRODUCT_EDIT_CHANGE_TEMP_WORK_REQUEST_STATUS,
    ADMIN_PRODUCT_EDIT_CLEAR_EDIT_STATE,
    ADMIN_PRODUCT_EDIT_DELETE_3D_FILE_DATA,
    ADMIN_PRODUCT_EDIT_DELETE_AR_MODEL_TRANSFORM_INFO,
    ADMIN_PRODUCT_EDIT_DELETE_COUNTRY_CODE,
    ADMIN_PRODUCT_EDIT_DELETE_LANGUAGE_CODE,
    ADMIN_PRODUCT_EDIT_DELETE_OPTION,
    ADMIN_PRODUCT_EDIT_DELETE_OPTION_CONTACT_LENS_IMAGE,
    ADMIN_PRODUCT_EDIT_DELETE_TEMP_AR_MODEL_TRANSFORM_INFO,
    ADMIN_PRODUCT_EDIT_DELETE_TEMP_WORK_REQUEST,
    ADMIN_PRODUCT_EDIT_GET_PREV_OPTION_AR_MODEL_TRANSFORM_INFO,
    ADMIN_PRODUCT_EDIT_INIT_DATA, ADMIN_PRODUCT_EDIT_REORDER, ADMIN_PRODUCT_EDIT_REORDER_LIST,
    ADMIN_PRODUCT_EDIT_REQUIRED_ERRORS,
    ADMIN_PRODUCT_EDIT_REQUIRED_I18N_PRODUCT_COUNTRIES_ERRORS,
    ADMIN_PRODUCT_EDIT_REQUIRED_I18N_PRODUCT_LANGUAGE_ERRORS,
    ADMIN_PRODUCT_EDIT_REQUIRED_OPTION_ERRORS,
    ADMIN_PRODUCT_EDIT_SET_CLOSE_HISTORY,
    ADMIN_PRODUCT_EDIT_SET_DEFAULT_COUNTRY,
    ADMIN_PRODUCT_EDIT_SET_DEFAULT_LANGUAGE,
    ADMIN_PRODUCT_EDIT_SET_IS_OPEN_WORK_REQUEST_HISTORIES, ADMIN_PRODUCT_EDIT_SET_IS_OPENED_CHANGE_ORDER_DIALOG,
    ADMIN_PRODUCT_EDIT_SET_OPEN_HISTORY,
    ADMIN_PRODUCT_EDIT_TOGGLE_EDIT_MODE,
} from '../../actions/UIActionTypes';
import {
    addExclusively,
    indexOfCountryCode,
    indexOfLanguageCode,
    updateListByKeyValue,
    updateListItem
} from "../../../utils/ListUtils";
import {
    changeDefaultCountryInProduct,
    changeDefaultLanguageInProduct,
    deleteCountryInProduct,
    deleteLanguageInProduct,
    reduceCategories,
} from "../../../behaviors/ProductBehavior";
import {
    addCountry2Options,
    addLanguage2Options,
    changeDefaultCountryInOptions,
    changeDefaultLanguageInOptions,
    changeProductOptionColor,
    deleteCountryInOptions,
    deleteLanguageInOptions, getOptionsByChangeOrder,
} from '../../../behaviors/ProductOptionBehavior';
import RestError from "../../../models/RestError";
import Product from "../../../models/Product";
import ProductOption, {ArImageSetting} from "../../../models/ProductOption";
import I18NLanguageProduct from "../../../models/I18NLanguageProduct";
import I18NCountryProduct from "../../../models/I18NCountryProduct";
import Brand from "../../../models/Brand";
import ProductSize from "../../../models/ProductSize";
import ProductColor from "../../../models/ProductColor";
import EnvImagePreset from "../../../models/EnvImagePreset";
import HistoryData from "../../../models/HistoryData";
import WorkRequestHistory from "../../../models/WorkRequestHistory";
import WorkRequest from "../../../models/WorkRequest";
import Shop from "../../../models/Shop";
import FaceEffect from "../../../models/FaceEffect";
import {ProductOptionRequiredError, ProductRequiredError} from "../../../models/ui/ProductRequiredError";

export const initialAdminProductEdit = fromJS({
    isLoading: false,
    brands: List(),
    categories: List(),
    categoryRootUid: null,
    envImagePresets: List(),
    faceEffects: List(),
    productSizes: List(),
    productColors: List(),
    product: new Product(),
    error: null,
    newbie: new Product(),
    productOptionColors: List(),
    defaultLanguageCode: '',
    defaultCountryCode: '',
    deleteLanguageCodes: Set(),
    deleteCountryCodes: Set(),
    deleteOptionIds: Set(),
    deleteOptionArMtiIds: Set(),
    deleteOptionImageIds: Set(),
    isEditing: false,
    succeedEdit: false,
    errorEdit: null,
    isBuilderProductImported: false,
    errorImportBuilderProduct: null,
    isOpenHistories: false,
    tempArMtis: null,
    arMtisData: List(),
    historyData: new HistoryData(),
    isOpenHistory: false,
    isGetHistoryLoading: false,
    arMtiOrder: 1,
    tempWorkRequest: new WorkRequest(),
    isSaveAndEditContinueEditingMode: false,
    isEnableEdit: false,
    requiredErrors: new ProductRequiredError(),
    isOpenedChangeOptionDialog: false,
    tempOptions: List(),
});

export function adminProductEdit(state = initialAdminProductEdit, action) {
    let index = -1;
    let product, newbie, i18nLanguages, i18nCountries;
    let options, option;
    let arMtis;
    let productOptionColors;
    let categoryRootUid;
    let deleteOptionArMtiIds;
    let histories, historyData, historyPage;
    let tempWorkRequest;
    let image, imageData;
    let isArImageSameShape, arImageSettings, arImage, arImage2, arImage2Data;
    let requiredErrors, requiredErrorOptions, requiredErrorOption;
    let tempOptions;

    switch (action.type) {
        case ADMIN_PRODUCT_EDIT_INIT_DATA:
            return initialAdminProductEdit;

        case ADMIN_PRODUCT_EDIT_CLEAR_EDIT_STATE:
            return state.set('isEditing', initialAdminProductEdit.get('isEditing'))
                .set('succeedEdit', initialAdminProductEdit.get('succeedEdit'))
                .set('errorEdit', initialAdminProductEdit.get('errorEdit'));

        case REQUEST_INIT_ADMIN_PRODUCT_EDIT:
            return state.set('isLoading', true);

        case SUCCEED_INIT_ADMIN_PRODUCT_EDIT:
            product = new Product(action.product);
            requiredErrors = state.get('requiredErrors');
            options = product.get('options');

            const shop = new Shop(action.shop);
            const isEnableEdit = shop.enableEditByShopId();
            const categoryId = product.getIn(['category', 'id']);
            const categories = reduceCategories(action.categories, 1);
            const category = categories.find(item => item.get('id') === categoryId);

            if (category) {
                product = product.set('category', category).set('category_id', category.get('id'));
                categoryRootUid = category.getRootCategoryUid();
            }

            if (isEnableEdit) {
                state = state.set(
                    'requiredErrors',
                    requiredErrors.set('options', List(options.map(() => new ProductOptionRequiredError())))
                );
            }

            return state.set('isLoading', false)
                .set('categoryRootUid', categoryRootUid ? categoryRootUid : null)
                .set('brands', List(action.brands.map(item => new Brand(item))))
                .set('categories', categories)
                .set('envImagePresets', List(action.env_image_presets.map(item => new EnvImagePreset(item))))
                .set('faceEffects', List(action.face_effects.map(item => new FaceEffect(item))))
                .set('productSizes', List(action.product_sizes.map(item => new ProductSize(item))))
                .set('productColors', List(action.product_colors.map(item => new ProductColor(item))))
                .set('product', product)
                .set('newbie', product)
                .set('productOptionColors', product.getOptionColors())
                .set('defaultLanguageCode', product.findDefaultLanguageCode())
                .set('defaultCountryCode', product.findDefaultCountryCode())
                .set('isEnableEdit', isEnableEdit)
                .set('error', null);

        case FAIL_INIT_ADMIN_PRODUCT_EDIT:
            return state.set('isLoading', false)
                .set('brands', List())
                .set('categories', List())
                .set('envImagePresets', List())
                .set('faceEffects', List())
                .set('productSizes', List())
                .set('productColors', List())
                .set('product', new Product())
                .set('newbie', new Product())
                .set('error', new RestError(action.error));

        case ADMIN_PRODUCT_EDIT_ADD_PRODUCT_SIZE:
            return state.set('productSizes', state.get('productSizes').push(action.productSize));

        case ADMIN_PRODUCT_EDIT_ADD_PRODUCT_COLOR:
            return state.set('productColors', state.get('productColors').push(action.productColor));

        case ADMIN_PRODUCT_EDIT_ADD_LANGUAGE_CODE:
            newbie = state.get('newbie');
            i18nLanguages = newbie.get('i18n_languages');
            index = indexOfLanguageCode(i18nLanguages, action.languageCode);

            if (index >= 0) {
                return state;
            }

            options = newbie.get('options');

            newbie = newbie.set(
                'i18n_languages',
                i18nLanguages.push(new I18NLanguageProduct({language_code: action.languageCode}))
            )
                .set('options', addLanguage2Options(options, action.languageCode));

            return state.set('newbie', newbie);

        case ADMIN_PRODUCT_EDIT_DELETE_LANGUAGE_CODE:
            newbie = state.get('newbie');
            i18nLanguages = newbie.get('i18n_languages');
            index = indexOfLanguageCode(i18nLanguages, action.languageCode);

            if (index >= 0) {
                const {i18nLanguages: newI18nLanguages, deleteI18nLanguageCodes} = deleteLanguageInProduct(
                    index, i18nLanguages, state.get('deleteLanguageCodes')
                );
                const {newOptions} = deleteLanguageInOptions(newbie.get('options'), action.languageCode);

                newbie = newbie.set('i18n_languages', newI18nLanguages).set('options', newOptions);

                state = state.set('newbie', newbie).set('deleteLanguageCodes', deleteI18nLanguageCodes);
            }

            return state;

        case ADMIN_PRODUCT_EDIT_SET_DEFAULT_LANGUAGE:
            newbie = state.get('newbie');

            return state.set(
                'newbie',
                newbie.set(
                    'i18n_languages', changeDefaultLanguageInProduct(newbie.get('i18n_languages'), action.languageCode)
                )
                    .set('options', changeDefaultLanguageInOptions(newbie.get('options'), action.languageCode))
            )
                .set('defaultLanguageCode', action.languageCode);

        case ADMIN_PRODUCT_EDIT_ADD_COUNTRY_CODE:
            newbie = state.get('newbie');
            i18nCountries = newbie.get('i18n_countries');
            index = indexOfCountryCode(i18nCountries, action.countryCode);

            if (index >= 0) {
                return state;
            }

            options = newbie.get('options');

            newbie = newbie.set(
                'i18n_countries', i18nCountries.push(new I18NCountryProduct({country_code: action.countryCode}))
            )
                .set('options', addCountry2Options(options, action.countryCode));

            return state.set('newbie', newbie);

        case ADMIN_PRODUCT_EDIT_DELETE_COUNTRY_CODE:
            newbie = state.get('newbie');
            i18nCountries = newbie.get('i18n_countries');
            index = indexOfCountryCode(i18nCountries, action.countryCode);

            if (index >= 0) {
                const {i18nCountries: newI18nCountries, deleteI18nCountryCodes} = deleteCountryInProduct(
                    index, i18nCountries, state.get('deleteCountryCodes')
                );
                const {newOptions} = deleteCountryInOptions(newbie.get('options'), action.countryCode);

                newbie = newbie.set('i18n_countries', newI18nCountries).set('options', newOptions);

                state = state.set('newbie', newbie).set('deleteCountryCodes', deleteI18nCountryCodes);
            }

            return state;

        case ADMIN_PRODUCT_EDIT_SET_DEFAULT_COUNTRY:
            newbie = state.get('newbie');

            return state.set(
                'newbie',
                newbie.set(
                    'i18n_countries', changeDefaultCountryInProduct(newbie.get('i18n_countries'), action.countryCode)
                )
                    .set('options', changeDefaultCountryInOptions(newbie.get('options'), action.countryCode))
            )
                .set('defaultCountryCode', action.countryCode);

        case ADMIN_PRODUCT_EDIT_CHANGE_PRODUCT:
            if (action.key === 'uid') {
                state = state.setIn(['requiredErrors', action.key], !action.value);
            }

            return state.set('newbie', state.get('newbie').set(action.key, action.value));

        case ADMIN_PRODUCT_EDIT_CHANGE_LANGUAGE_INFO:
            newbie = state.get('newbie');

            if (action.key === 'name') {
                state = state.setIn(['requiredErrors', 'i18n_languages', action.index, action.key], !action.value);
            }

            return state.set(
                'newbie',
                newbie.set(
                    'i18n_languages',
                    updateListByKeyValue(newbie.get('i18n_languages'), action.index, action.key, action.value)
                )
            );

        case ADMIN_PRODUCT_EDIT_CHANGE_COUNTRY_INFO:
            newbie = state.get('newbie');

            if (action.key === 'price') {
                state = state.setIn(
                    ['requiredErrors', 'i18n_countries', action.index, action.key], !parseFloat(action.value)
                );
            }

            return state.set(
                'newbie',
                newbie.set(
                    'i18n_countries',
                    updateListByKeyValue(newbie.get('i18n_countries'), action.index, action.key, action.value)
                )
            );

        case ADMIN_PRODUCT_EDIT_CHANGE_CATEGORY:
            options = state.getIn(['newbie', 'options']);
            categoryRootUid = action.category.getRootCategoryUid();

            options = options.map(option => {
                arMtis = option.get('armtis');

                if (arMtis.size > 0) {
                    arMtis = arMtis.map(item => item.set('px_2nd', 0).set('py_2nd', 0).set('rx_2nd', 0).set('ry_2nd', 0)
                        .set('rz_2nd', 0).set('scale_2nd', 100).set('uv_map_pos_2nd', -1));
                }
                return option.set('glbFile2ndData', null).set('arPosGlbFile2ndData', null).set('armtis', arMtis)
                    .set('ar_pos_glb_file_2nd', null).set('glb_file_2nd', null)
                    .set('isArImageSameShape', true)
                    .set('ar_image_1', null).set('ar_image_2', null)
                    .set('arImage1Data', null).set('arImage2Data', null)
            });

            return state.setIn(['newbie', 'category_id'], action.category.get('id'))
                .setIn(['newbie', 'category'], action.category)
                .setIn(['newbie', 'options'], options)
                .set('categoryRootUid', categoryRootUid);

        case ADMIN_PRODUCT_EDIT_ADD_OPTION:
            newbie = state.get('newbie');
            options = newbie.get('options');
            option = ProductOption.makeNewbie(newbie);

            requiredErrors = state.get('requiredErrors');
            requiredErrorOptions = requiredErrors.get('options');

            requiredErrorOption = requiredErrorOptions.push(
                new ProductOptionRequiredError()
            )

            return state.set('newbie', newbie.set('options', options.push(option)))
                .setIn(['requiredErrors', 'options'], requiredErrorOption);

        case ADMIN_PRODUCT_EDIT_CHANGE_OPTION:
            newbie = state.get('newbie');

            if (action.key === 'uid') {
                state = state.setIn(['requiredErrors', 'options', action.index, action.key], !action.value);
            }

            return state.set(
                'newbie',
                newbie.set(
                    'options', updateListByKeyValue(newbie.get('options'), action.index, action.key, action.value)
                )
            );

        case ADMIN_PRODUCT_EDIT_CHANGE_OPTION_COLOR:
            newbie = state.get('newbie');

            const [newbieOptions, newProductOptionColors] = changeProductOptionColor(
                newbie.get('options'), state.get('productOptionColors'), action.index, action.productColor
            );

            return state.set('newbie', newbie.set('options', newbieOptions))
                .set('productOptionColors', newProductOptionColors)
                .setIn(['requiredErrors', 'options', action.index, 'color'], !action.productColor);

        case ADMIN_PRODUCT_EDIT_DELETE_OPTION:
            newbie = state.get('newbie');
            options = newbie.get('options');
            const deletedOption = options.get(action.index);
            const newOptions = options.delete(action.index);

            requiredErrors = state.get('requiredErrors');
            requiredErrorOptions = requiredErrors.get('options');

            if (deletedOption.get('id') > 0) {
                state = state.set('deleteOptionIds', state.get('deleteOptionIds').add(deletedOption.get('id')));
            }

            const hasSameColor = newOptions.some(item => item.get('color_id') === deletedOption.get('color_id'));

            if (!hasSameColor) {
                productOptionColors = state.get('productOptionColors').reduce((prev, cur) => {
                    if (cur.get('id') === deletedOption.get('color_id')) {
                        return prev;
                    } else {
                        return prev.push(cur);
                    }
                }, List());

                state = state.set('productOptionColors', productOptionColors);
            }

            return state.set('newbie', newbie.set('options', newOptions))
                .set('requiredErrors', requiredErrors.set('options', requiredErrorOptions.delete(action.index)));


        case ADMIN_PRODUCT_EDIT_CHANGE_OPTION_LANGUAGE_INFO:
            newbie = state.get('newbie');
            options = newbie.get('options');
            option = options.get(action.optionIndex);
            option = option.set(
                'i18n_languages',
                updateListByKeyValue(option.get('i18n_languages'), action.langIndex, action.key, action.value)
            );

            return state.set('newbie', newbie.set('options', updateListItem(options, action.optionIndex, option)));

        case ADMIN_PRODUCT_EDIT_CHANGE_OPTION_COUNTRY_INFO:
            newbie = state.get('newbie');
            options = newbie.get('options');
            option = options.get(action.optionIndex);
            option = option.set(
                'i18n_countries',
                updateListByKeyValue(option.get('i18n_countries'), action.countryIndex, action.key, action.value)
            );

            return state.set('newbie', newbie.set('options', updateListItem(options, action.optionIndex, option)));

        case ADMIN_PRODUCT_EDIT_CHANGE_OPTION_COLOR_INFO:
            if (action.key === 'imageData') {
                state = state.setIn(['requiredErrors', 'options', action.index, action.key], !action.value);
            }

            return state.set(
                'productOptionColors',
                updateListByKeyValue(state.get('productOptionColors'), action.index, action.key, action.value)
            );

        case ADMIN_PRODUCT_EDIT_CHANGE_LIGHTING_ENV_PRESET:
            let lightingEnv = state.getIn(['newbie', 'options', `${action.index}`, action.name]);

            if (!action.envImagePreset && lightingEnv.get('environment_image')) {
                lightingEnv = lightingEnv.set('environment_image', null);
            }

            return state.setIn(
                ['newbie', 'options' ,`${action.index}`, action.name],
                lightingEnv.set('env_image_preset_id', action.envImagePreset ? action.envImagePreset.get('id') : 0)
                    .set('envImagePreset', action.envImagePreset ? action.envImagePreset : null)
            );

        case ADMIN_PRODUCT_EDIT_CHANGE_LIGHTING_ENV_EXPOSURE:
            return state.setIn(['newbie', 'options', `${action.index}`, action.name, 'exposure'], action.exposure);

        case ADMIN_PRODUCT_EDIT_CHANGE_FACE_EFFECT_INFO_IMAGE:
            let faceEffectInfo = state.getIn(['newbie', 'options', `${action.index}`, 'face_effect_info']);

            return state.setIn(
                ['newbie', 'options', `${action.index}`, 'face_effect_info'],
                faceEffectInfo.set('face_effect_id', action.faceEffect ? action.faceEffect.get('id') : 0)
            );

        case ADMIN_PRODUCT_EDIT_CHANGE_FACE_EFFECT_INFO_EXTRA_TOP_AREA:
            return state.setIn(
                ['newbie', 'options', `${action.index}`, 'face_effect_info', 'extra_top_area'], action.extraTopArea
            );

        case ADMIN_PRODUCT_EDIT_ADD_3D_FILE_DATA:
            return state.setIn(['newbie', 'options', `${action.index}`, `${action.key}`], action.fileData);

        case ADMIN_PRODUCT_EDIT_DELETE_3D_FILE_DATA:
        case ADMIN_PRODUCT_EDIT_DELETE_OPTION_CONTACT_LENS_IMAGE:
            imageData = state.getIn(
                ['newbie', 'options', `${action.optionIndex}`, `${action.imgDataKey}`]
            );

            if (imageData) {
                return state.setIn(['newbie', 'options', `${action.optionIndex}`, `${action.imgDataKey}`], null);
            }

            image = state.getIn(['newbie', 'options', `${action.optionIndex}`, `${action.imgKey}`]);

            if (image) {
                return state.setIn(['newbie', 'options', `${action.optionIndex}`, `${action.imgKey}`], null);
            }

            return state;

        case ADMIN_PRODUCT_EDIT_ADD_AR_MODEL_TRANSFORM_INFO:
            arMtis = state.getIn(['newbie', 'options', action.optionIndex, 'armtis']);
            arMtis = arMtis.set(action.arMtisIndex,  action.arMtis);
            return state.setIn(['newbie', 'options', action.optionIndex, 'armtis'], arMtis);

        case ADMIN_PRODUCT_EDIT_DELETE_AR_MODEL_TRANSFORM_INFO:

            const arMtiId = action.arMti.get('id');
            deleteOptionArMtiIds = state.get('deleteOptionArMtiIds');

            if (arMtiId > 0) {
                state = state.set('deleteOptionArMtiIds', deleteOptionArMtiIds.add(arMtiId))
            }

            arMtis = state.getIn(['newbie', 'options', action.optionIndex, 'armtis']);

            return state.setIn(['newbie', 'options', action.optionIndex, 'armtis'], arMtis.delete(action.arMtisIndex));


        case REQUEST_UPDATE_PRODUCT_BY_MANAGER:
            return state.set('isEditing', true).set('succeedEdit', false).set('errorEdit', null);

        case SUCCEED_UPDATE_PRODUCT_BY_MANAGER:
            return state.set('isEditing', false).set('succeedEdit', true).set('errorEdit', null);

        case FAIL_UPDATE_PRODUCT_BY_MANAGER:
            return state.set('isEditing', false)
                .set('succeedEdit', false)
                .set('errorEdit', new RestError(action.error));

        case ADMIN_PRODUCT_ADD_CLEAR_IMPORT_BUILDER_PRODUCT_INFO_STATE:
            return state.set('errorImportBuilderProduct', null);

        case ADMIN_PRODUCT_EDIT_SET_IS_OPEN_WORK_REQUEST_HISTORIES:
            return state.set('isOpenHistories', action.isOpenHistories);

        case ADMIN_PRODUCT_EDIT_CHANGE_TEMP_AR_MODEL_TRANSFORM_INFO:
            return state.setIn(['tempArMtis', action.key], action.value);

        case ADMIN_PRODUCT_EDIT_ADD_TEMP_AR_MODEL_TRANSFORM_INFO:
            return state.set('tempArMtis', action.arMtis);

        case ADMIN_PRODUCT_EDIT_CHANGE_TEMP_ARMTIS_I18N_LANGUAGE_NAME:
            return state.setIn(['tempArMtis', 'i18n_languages', action.i18nIndex, 'name'], action.value);

        case ADMIN_PRODUCT_EDIT_DELETE_TEMP_AR_MODEL_TRANSFORM_INFO:
            return state.set('tempArMtis', null);

        case ADMIN_PRODUCT_EDIT_GET_PREV_OPTION_AR_MODEL_TRANSFORM_INFO:
            return state.set('arMtisData', action.arMtisData);

        case ADMIN_PRODUCT_EDIT_ADD_ALL_AR_MODEL_TRANSFORM_INFO:
            arMtis = state.getIn(['newbie', 'options', action.optionIndex, 'armtis']);
            deleteOptionArMtiIds = state.get('deleteOptionArMtiIds');

            if (arMtis.size > 0) {
                arMtis.forEach(item => (
                    deleteOptionArMtiIds = deleteOptionArMtiIds.add(item.get('id'))
                ))

                state = state.set('deleteOptionArMtiIds', deleteOptionArMtiIds);
            }

            let newArMtis = action.arMtis;

            if (newArMtis) {
                action.arMtis.forEach((_, index) => (
                    newArMtis = newArMtis.update(index, (prev) => prev.set('id', 0))
                ))
            }

            return state.setIn(['newbie', 'options', action.optionIndex, 'armtis', '0'],  newArMtis.get('0'));

        case REQUEST_GET_WORK_REQUEST_HISTORIES_BY_MANAGER:
            return state.set('isGetHistoryLoading', true);

        case SUCCEED_GET_WORK_REQUEST_HISTORIES_BY_MANAGER:
            histories = List(action.results.map(item => new WorkRequestHistory(item)));
            historyData = state.get('historyData');

            historyPage = historyData.get('page');
            if (histories.size > 0) {
                historyData = historyData.set(
                    'histories',
                    addExclusively(historyData.get('histories'), histories, historyPage + 1)
                )
                    .set('hasMore', action.hasMore)
                    .set('count', action.count)
                    .set('page', historyPage + 1)
            }

            return state.set('historyData', historyData)
                .set('isOpenHistory', true)
                .set('isGetHistoryLoading', false);

        case FAIL_GET_WORK_REQUEST_HISTORIES_BY_MANAGER:
            return state.set('error', new RestError(action.error))
                .set('isGetHistoryLoading', false);

        case ADMIN_PRODUCT_EDIT_SET_OPEN_HISTORY:
            return state.set('isOpenHistory', true);

        case ADMIN_PRODUCT_EDIT_SET_CLOSE_HISTORY:
            return state.set('isOpenHistory', false)
                .set('historyData', new HistoryData());

        case ADMIN_PRODUCT_EDIT_CHANGE_ARMTI_ORDER:
            return state.set('arMtiOrder', action.threeDFileOrder);

        case ADMIN_PRODUCT_EDIT_ADD_TEMP_WORK_REQUEST:
            return state.set('tempWorkRequest', action.workRequest);

        case ADMIN_PRODUCT_EDIT_CHANGE_TEMP_WORK_REQUEST_STATUS:
            return state.setIn(['tempWorkRequest', 'status'], action.status);

        case ADMIN_PRODUCT_EDIT_CHANGE_TEMP_WORK_REQUEST_COMMENT:
            return state.setIn(['tempWorkRequest', 'comment'], action.comment);

        case ADMIN_PRODUCT_EDIT_DELETE_TEMP_WORK_REQUEST:
            return state.set('tempWorkRequest', new WorkRequest());

        case ADMIN_PRODUCT_EDIT_ADD_WORK_REQUEST:
            tempWorkRequest = state.get('tempWorkRequest');
            return state.setIn(['newbie', 'options', action.optionIndex, 'work_request'], tempWorkRequest)
                .set('tempWorkRequest', new WorkRequest());

        case ADMIN_PRODUCT_EDIT_CHANGE_ENV_ROTATION:
            return state.setIn(['newbie', 'options', action.optionIndex, action.name, 'rotation', action.key], action.value);

        case ADMIN_PRODUCT_EDIT_CHANGE_OPTION_LENS_SAME_SHAPE_CHECK:
            newbie = state.get('newbie');

            isArImageSameShape = newbie.getIn(['options', action.optionIndex, 'isArImageSameShape']);
            arImage = newbie.getIn(['options', action.optionIndex, 'ar_image_1']);
            arImage2 = newbie.getIn(['options', action.optionIndex, 'ar_image_2']);
            arImage2Data = newbie.getIn(['options', action.optionIndex, 'arImage2Data']);

            if (
                (!isArImageSameShape && !arImage && (arImage2Data || arImage2))
                || (isArImageSameShape && (arImage2Data || arImage2))
            ) {
                state = state.setIn(['newbie', 'options', action.optionIndex, 'ar_image_2'], null)
                    .setIn(['newbie', 'options', action.optionIndex, 'arImage2Data'], null)
            }

            return state.setIn(['newbie', 'options', action.optionIndex, 'isArImageSameShape'], action.value)

        case ADMIN_PRODUCT_EDIT_CHANGE_OPTION_CONTACT_LENS_IMAGE:

            return state.setIn(['newbie', 'options', action.optionIndex, action.key], action.value);

        case ADMIN_PRODUCT_EDIT_CHANGE_OPTION_CONTACT_LENS_BLENDING:
            arImageSettings = state.getIn(['newbie', 'options', action.optionIndex, 'ar_image_settings']);

            if (!arImageSettings) {
                arImageSettings = new ArImageSetting();
            }

            return state.setIn(
                ['newbie', 'options', action.optionIndex, 'ar_image_settings'],
                arImageSettings.set('blending_mode', action.blending ? action.blending.get('value') : '')
            );

        case ADMIN_PRODUCT_EDIT_CHANGE_OPTION_CONTACT_LENS_INPUT:
            return state.setIn(['newbie', 'options', action.optionIndex, 'ar_image_settings', action.key], action.value);

        case ADMIN_PRODUCT_EDIT_TOGGLE_EDIT_MODE:
            return state.set('isSaveAndEditContinueEditingMode', action.isSaveAndEditContinueEditingMode)

        case ADMIN_PRODUCT_EDIT_REQUIRED_ERRORS:
            return state.setIn(['requiredErrors', action.key], action.value);

        case ADMIN_PRODUCT_EDIT_REQUIRED_OPTION_ERRORS:
            return state.setIn(['requiredErrors', 'options', action.index, action.key], action.value);

        case ADMIN_PRODUCT_EDIT_REQUIRED_I18N_PRODUCT_LANGUAGE_ERRORS:
            return state.setIn(['requiredErrors', 'i18n_languages', action.index, action.key], action.value);

        case ADMIN_PRODUCT_EDIT_REQUIRED_I18N_PRODUCT_COUNTRIES_ERRORS:
            return state.setIn(['requiredErrors', 'i18n_countries', action.index, action.key], action.value);

        case ADMIN_PRODUCT_EDIT_SET_IS_OPENED_CHANGE_ORDER_DIALOG:
            if (action.isOpenedChangeOptionDialog) {
                options = state.getIn(['newbie', 'options']);
                state = state.set('tempOptions', options);
            }

            return state.set('isOpenedChangeOptionDialog', action.isOpenedChangeOptionDialog);

        case ADMIN_PRODUCT_EDIT_REORDER:
            tempOptions = state.get('tempOptions');

            if (action.startIndex === action.endIndex) {
                return state;
            }

            tempOptions = getOptionsByChangeOrder(tempOptions, action.startIndex, action.endIndex);

            return state.set('tempOptions', tempOptions)
            .set('isReordered', true);

        case ADMIN_PRODUCT_EDIT_REORDER_LIST:
            return state.set('isReordered', false);

        case ADMIN_PRODUCT_EDIT_CHANGE_OPTIONS_ORDER:
            tempOptions = state.get('tempOptions');

            return state.setIn(['newbie', 'options'], tempOptions)
            .set('isOpenedChangeOptionDialog', false);
        default:
            return state;
    }
}
