import {EntityMixin} from "./Entity";
import Shop from "./Shop";

const ShopStaffRecord = EntityMixin({
    name: null,
    email: null,
    uid: null,
    shop_id: 0,
    shop: null,
    auth_token: null,
    signin_date: null,
    is_email_verified: false,
});

class ShopStaff extends ShopStaffRecord {

    constructor(args = {}) {
        super({
            ...args,
            shop: args.shop ? new Shop(args.shop) : null,
        });
    }

    canEdit(oldbie) {
        if (!oldbie) {
            return false;
        }

        return this.name !== oldbie.get('name');
    }
}

export default ShopStaff;
