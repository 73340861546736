import {fromJS} from "immutable";
import {
    PRODUCT_AR_VIEWER_CLEAR,
    PRODUCT_AR_VIEWER_SET_INITIALIZED,
    PRODUCT_AR_VIEWER_SET_IS_WEB_AR_POPUP_SHOWN
} from "../actions/UIActionTypes";
import {
    FAIL_INIT_PRODUCT_AR_VIEWER_BY_MANAGER,
    FAIL_INIT_PRODUCT_AR_VIEWER_BY_SHOP_STAFF,
    REQUEST_INIT_PRODUCT_AR_VIEWER_BY_MANAGER,
    REQUEST_INIT_PRODUCT_AR_VIEWER_BY_SHOP_STAFF,
    SUCCEED_INIT_PRODUCT_AR_VIEWER_BY_MANAGER,
    SUCCEED_INIT_PRODUCT_AR_VIEWER_BY_SHOP_STAFF,
} from "../actions/RESTActionTypes";
import Shop from "../../models/Shop";
import Product from "../../models/Product";
import RestError from "../../models/RestError";

export const initialProductArViewer = fromJS({
    isLoading: false,
    shop: null,
    product: null,
    error: null,
    isInitialized: false,
    isWebArPopupShown: false,
});

export function productArViewer(state = initialProductArViewer, action) {
    switch (action.type) {
        case PRODUCT_AR_VIEWER_CLEAR:
            return initialProductArViewer;

        case REQUEST_INIT_PRODUCT_AR_VIEWER_BY_SHOP_STAFF:
        case REQUEST_INIT_PRODUCT_AR_VIEWER_BY_MANAGER:
            return initialProductArViewer.set('isLoading', true);

        case SUCCEED_INIT_PRODUCT_AR_VIEWER_BY_SHOP_STAFF:
        case SUCCEED_INIT_PRODUCT_AR_VIEWER_BY_MANAGER:
            return state.set('isLoading', false)
                .set('shop', new Shop(action.shop))
                .set('product', new Product(action.product))
                .set('error', null);

        case FAIL_INIT_PRODUCT_AR_VIEWER_BY_SHOP_STAFF:
        case FAIL_INIT_PRODUCT_AR_VIEWER_BY_MANAGER:
            return state.set('isLoading', false).set('error', new RestError(action.error));

        case PRODUCT_AR_VIEWER_SET_INITIALIZED:
            return state.set('isInitialized', true);

        case PRODUCT_AR_VIEWER_SET_IS_WEB_AR_POPUP_SHOWN:
            return state.set('isWebArPopupShown', action.isWebArPopupShown);

        default:
            return state;
    }
}
