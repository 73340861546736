import {List} from "immutable";

export const ARMTI_UV_MAP_POS_KEY = 'uv_map_pos';
export const ARMTI_POSITION_X_KEY = 'px';
export const ARMTI_POSITION_Y_KEY = 'py';
export const ARMTI_POSITION_Z_KEY = 'pz';
export const ARMTI_ROTATE_X_KEY = 'rx';
export const ARMTI_ROTATE_Y_KEY = 'ry';
export const ARMTI_ROTATE_Z_KEY = 'rz';
export const ARMTI_SCALE_KEY = 'scale';

export const ARMTI_UV_MAP_POS_2ND_KEY = 'uv_map_pos_2nd';
export const ARMTI_POSITION_X_2ND_KEY = 'px_2nd';
export const ARMTI_POSITION_Y_2ND_KEY = 'py_2nd';
export const ARMTI_POSITION_Z_2ND_KEY = 'pz_2nd';
export const ARMTI_ROTATE_X_2ND_KEY = 'rx_2nd';
export const ARMTI_ROTATE_Y_2ND_KEY = 'ry_2nd';
export const ARMTI_ROTATE_Z_2ND_KEY = 'rz_2nd';
export const ARMTI_SCALE_2ND_KEY = 'scale_2nd';

export const ARMTI_LEFT_DIRECTION = 1;
export const ARMTI_RIGHT_DIRECTION = 2;

export const AR_VIEWER_AR_MODEL_TRANSFORM_INFO_LIST_UV_MAP = List([
    {key: ARMTI_UV_MAP_POS_KEY, name: 'product_model_transform_info_uv_map_pos_label'},
])

export const AR_VIEWER_AR_MODEL_TRANSFORM_INFO_LIST_UV_MAP_2ND = List([
    {key: ARMTI_UV_MAP_POS_2ND_KEY, name: 'product_model_transform_info_uv_map_pos_label'},
])

export const AR_VIEWER_AR_MODEL_TRANSFORM_INFO_LIST_POSITION = List([
    {key: ARMTI_POSITION_X_KEY, name: 'product_model_transform_info_position_x_label'},
    {key: ARMTI_POSITION_Y_KEY, name: 'product_model_transform_info_position_y_label'},
    {key: ARMTI_POSITION_Z_KEY, name: 'product_model_transform_info_position_z_label'},
]);

export const AR_VIEWER_AR_MODEL_TRANSFORM_INFO_LIST_POSITION_2ND = List([
    {key: ARMTI_POSITION_X_2ND_KEY, name: 'product_model_transform_info_position_x_label'},
    {key: ARMTI_POSITION_Y_2ND_KEY, name: 'product_model_transform_info_position_y_label'},
    {key: ARMTI_POSITION_Z_2ND_KEY, name: 'product_model_transform_info_position_z_label'},
]);

export const AR_VIEWER_AR_MODEL_TRANSFORM_INFO_LIST_ROTATE = List([
    {key: ARMTI_ROTATE_X_KEY, name: 'product_model_transform_info_rotate_x_label'},
    {key: ARMTI_ROTATE_Y_KEY, name: 'product_model_transform_info_rotate_y_label'},
    {key: ARMTI_ROTATE_Z_KEY, name: 'product_model_transform_info_rotate_z_label'},
]);

export const AR_VIEWER_AR_MODEL_TRANSFORM_INFO_LIST_ROTATE_2ND = List([
    {key: ARMTI_ROTATE_X_2ND_KEY, name: 'product_model_transform_info_rotate_x_label'},
    {key: ARMTI_ROTATE_Y_2ND_KEY, name: 'product_model_transform_info_rotate_y_label'},
    {key: ARMTI_ROTATE_Z_2ND_KEY, name: 'product_model_transform_info_rotate_z_label'},
]);

export const AR_VIEWER_AR_MODEL_TRANSFORM_INFO_LIST_SCALE = List([
    {key: ARMTI_SCALE_KEY, name: 'product_model_transform_info_asset_scale_label'},
]);

export const AR_VIEWER_AR_MODEL_TRANSFORM_INFO_LIST_SCALE_2ND = List([
    {key: ARMTI_SCALE_2ND_KEY, name: 'product_model_transform_info_asset_scale_label'},
]);
export const AR_VIEWER_2ND_AR_MODEL_TRANSFORM_INFO_LIST = List.of(
    ARMTI_UV_MAP_POS_2ND_KEY,
    ARMTI_POSITION_X_2ND_KEY,
    ARMTI_POSITION_Y_2ND_KEY,
    ARMTI_POSITION_Z_2ND_KEY,
    ARMTI_ROTATE_X_2ND_KEY,
    ARMTI_ROTATE_Y_2ND_KEY,
    ARMTI_ROTATE_Z_2ND_KEY,
    ARMTI_SCALE_2ND_KEY,
);

export const ARMTI_UV_MAP_POS_LIST = List.of(
    ARMTI_UV_MAP_POS_KEY,
    ARMTI_UV_MAP_POS_2ND_KEY
);

export const ARMTI_ORDER_LEFT = 1;
export const ARMTI_ORDER_RIGHT = 2;

export const ARMTI_UP_MAP_POS_MIN = 0;
export const ARMTI_UP_MAP_POS_MAX = 467;
export const ARMTI_POSITION_MIN = -20;
export const ARMTI_POSITION_MAX = 20;
export const ARMTI_ROTATE_MIN = 0;
export const ARMTI_ROTATE_MAX = 360;
export const ARMTI_SCALE_MIN = 0;
export const ARMTI_SCALE_MAX = 150;