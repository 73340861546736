import {fromJS, List, Set} from "immutable";
import {
    FAIL_CREATE_BUILDER_SCRIPTTAG,
    FAIL_DELETE_BUILDER_SCRIPTTAG,
    FAIL_INIT_PLUGIN_SETTINGS_BY_SHOP_STAFF,
    FAIL_UPDATE_SHOP_PLUGIN_BY_SHOP_STAFF,
    REQUEST_CREATE_BUILDER_SCRIPTTAG,
    REQUEST_DELETE_BUILDER_SCRIPTTAG,
    REQUEST_INIT_PLUGIN_SETTINGS_BY_SHOP_STAFF,
    REQUEST_UPDATE_SHOP_PLUGIN_BY_SHOP_STAFF,
    SUCCEED_CREATE_BUILDER_SCRIPTTAG,
    SUCCEED_DELETE_BUILDER_SCRIPTTAG,
    SUCCEED_INIT_PLUGIN_SETTINGS_BY_SHOP_STAFF,
    SUCCEED_UPDATE_SHOP_PLUGIN_BY_SHOP_STAFF,
} from "../../actions/RESTActionTypes";
import {
    SHOP_STAFF_PLUGIN_SETTINGS_CHANGE_PLUGIN,
    SHOP_STAFF_PLUGIN_SETTINGS_CHANGE_PLUGIN_TEMPLATE,
    SHOP_STAFF_PLUGIN_SETTINGS_CLEAR,
    SHOP_STAFF_PLUGIN_SETTINGS_CLEAR_BUILDER_SCRIPTTAG_STATE,
    SHOP_STAFF_PLUGIN_SETTINGS_CLEAR_EDIT_STATE,
    SHOP_STAFF_PLUGIN_SETTINGS_DELETE_PLUGIN_TEMPLATE_FLOATING_IMAGE,
} from "../../actions/UIActionTypes";
import RestError from "../../../models/RestError";
import Plugin from "../../../models/Plugin";
import Category, {
    CATEGORY_UID_EYEWEAR,
    CATEGORY_UID_HEADWEAR,
    CATEGORY_UID_JEWELRY,
    CATEGORY_UID_MASK,
} from "../../../models/Category";
import {collectRootCategoryUids} from "../../../behaviors/CategoryBehavior";

export const initialShopStaffPluginSettings = fromJS({
    isLoading: false,
    categories: List(),
    rootCategoryUids: Set(),
    plugin: new Plugin(),
    isCategoryValid: false,
    isScriptInstalled: false,
    error: null,
    newbie: new Plugin(),
    isEditing: false,
    succeedEdit: false,
    errorEdit: null,
    isBuilderScripttagTasking: false,
    succeedBuilderScripttagTask: false,
    errorBuilderScripttagTask: null,
});

export function shopStaffPluginSettings(state = initialShopStaffPluginSettings, action) {
    let newbie;

    switch (action.type) {
        case SHOP_STAFF_PLUGIN_SETTINGS_CLEAR:
            return initialShopStaffPluginSettings;

        case SHOP_STAFF_PLUGIN_SETTINGS_CLEAR_EDIT_STATE:
            return state.set('isEditing', initialShopStaffPluginSettings.get('isEditing'))
                .set('succeedEdit', initialShopStaffPluginSettings.get('succeedEdit'))
                .set('errorEdit', initialShopStaffPluginSettings.get('errorEdit'));

        case REQUEST_INIT_PLUGIN_SETTINGS_BY_SHOP_STAFF:
            return state.set('isLoading', true);

        case SUCCEED_INIT_PLUGIN_SETTINGS_BY_SHOP_STAFF:
            const categories = List(action.categories.map(item => new Category(item)));
            const rootCategoryUids = collectRootCategoryUids(categories);
            const plugin = new Plugin(action.plugin);
            const isCategoryValid = rootCategoryUids.includes(CATEGORY_UID_EYEWEAR)
                || rootCategoryUids.includes(CATEGORY_UID_JEWELRY)
                || rootCategoryUids.includes(CATEGORY_UID_HEADWEAR)
                || rootCategoryUids.includes(CATEGORY_UID_MASK);
            const isScriptInstalled = plugin.isBuilderScripttagInstalled(CATEGORY_UID_EYEWEAR)
                || plugin.isBuilderScripttagInstalled(CATEGORY_UID_JEWELRY)
                || plugin.isBuilderScripttagInstalled(CATEGORY_UID_HEADWEAR)
                || plugin.isBuilderScripttagInstalled(CATEGORY_UID_MASK);

            return state.set('isLoading', false)
                .set('categories', categories)
                .set('rootCategoryUids', rootCategoryUids)
                .set('plugin', plugin)
                .set('isCategoryValid', isCategoryValid)
                .set('isScriptInstalled', isScriptInstalled)
                .set('newbie', plugin)
                .set('error', null);

        case FAIL_INIT_PLUGIN_SETTINGS_BY_SHOP_STAFF:
            return state.set('isLoading', false)
                .set('categories', List())
                .set('rootCategoryUids', Set())
                .set('shop', new Plugin())
                .set('newbie', new Plugin())
                .set('error', new RestError(action.error));

        case SHOP_STAFF_PLUGIN_SETTINGS_CHANGE_PLUGIN:
            return state.set('newbie', state.get('newbie').set(action.key, action.value));

        case SHOP_STAFF_PLUGIN_SETTINGS_CHANGE_PLUGIN_TEMPLATE:
            newbie = state.get('newbie');

            return state.set('newbie', newbie.setIn(['template'].concat(action.key.split('__')), action.value));

        case REQUEST_UPDATE_SHOP_PLUGIN_BY_SHOP_STAFF:
            return state.set('isEditing', true).set('succeedEdit', false).set('errorEdit', null);

        case SUCCEED_UPDATE_SHOP_PLUGIN_BY_SHOP_STAFF:
            return state.set('isEditing', false).set('succeedEdit', true).set('errorEdit', null);

        case FAIL_UPDATE_SHOP_PLUGIN_BY_SHOP_STAFF:
            return state.set('isEditing', false)
                .set('succeedEdit', false)
                .set('errorEdit', new RestError(action.error));

        case REQUEST_CREATE_BUILDER_SCRIPTTAG:
        case REQUEST_DELETE_BUILDER_SCRIPTTAG:
            return state.set('isBuilderScripttagTasking', true)
                .set('succeedBuilderScripttagTask', false)
                .set('errorBuilderScripttagTask', null);

        case SUCCEED_CREATE_BUILDER_SCRIPTTAG:
        case SUCCEED_DELETE_BUILDER_SCRIPTTAG:
            return state.set('isBuilderScripttagTasking', false)
                .set('succeedBuilderScripttagTask', true)
                .set('errorBuilderScripttagTask', null);

        case FAIL_CREATE_BUILDER_SCRIPTTAG:
        case FAIL_DELETE_BUILDER_SCRIPTTAG:
            return state.set('isBuilderScripttagTasking', false)
                .set('succeedBuilderScripttagTask', false)
                .set('errorBuilderScripttagTask', new RestError(action.error));

        case SHOP_STAFF_PLUGIN_SETTINGS_CLEAR_BUILDER_SCRIPTTAG_STATE:
            return state.set(
                'isBuilderScripttagTasking',
                initialShopStaffPluginSettings.get('isBuilderScripttagTasking')
            )
                .set('succeedBuilderScripttagTask', initialShopStaffPluginSettings.get('succeedBuilderScripttagTask'))
                .set('errorBuilderScripttagTask', initialShopStaffPluginSettings.get('errorBuilderScripttagTask'));

        case SHOP_STAFF_PLUGIN_SETTINGS_DELETE_PLUGIN_TEMPLATE_FLOATING_IMAGE:
            return state.setIn(['newbie', 'template', 'floating_button_style', 'image'], null)
                .setIn(['newbie', 'template', 'floating_button_style', 'imageData'], null);

        default:
            return state;
    }
}
