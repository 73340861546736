import moment from "moment";

export const DATE_FORMAT_YYYYMMDDHHmmss = "YYYY-MM-DD HH:mm:ss";
export const DATE_FORMAT_YYYYMMDDHHMM = "YYYY-MM-DD HH:mm";
export const DATE_FORMAT_YYYYMMDD = "YYYY-MM-DD";
export const DAY_MILLIS = 86400 * 1000;

export const NONE_QUARTER = 0;
export const FIRST_QUARTER = 1;
export const SECOND_QUARTER = 2;
export const THIRD_QUARTER = 3;
export const FOURTH_QUARTER = 4;
export const FIRST_HALF_QUARTER = 5;
export const SECOND_HALF_QUARTER = 6;

export const UTC_OFFSET_KR = '+0900';

// http://momentjs.com/docs/#/parsing/string-format/
export function convertDateFormat(date, format = null) {
    if (!date) {
        return null;
    }

    return moment(date).format(format || DATE_FORMAT_YYYYMMDDHHmmss);
}

export function getQuarter(startDate, endDate) {
    const startMonth = startDate.month();
    const endMonth = endDate.month();

    if (startDate.year() === endDate.year()) {
        if (startMonth === 0 && endMonth === 2) {
            return FIRST_QUARTER;
        } else if (startMonth === 3 && endMonth === 5) {
            return SECOND_QUARTER;
        } else if (startMonth === 6 && endMonth === 8) {
            return THIRD_QUARTER;
        } else if (startMonth === 9 && endMonth === 11) {
            return FOURTH_QUARTER;
        } else if (startMonth === 0 && endMonth === 5) {
            return FIRST_HALF_QUARTER;
        } else if (startMonth === 6 && endMonth === 11) {
            return SECOND_HALF_QUARTER;
        }
    }
    return NONE_QUARTER;
}

export function getDefaultStartDate() {
    return moment().subtract(3, 'months').startOf('day').format();
}

export function getDefaultEndDate() {
    return moment().endOf('day').format();
}

export function parseStartAndEndDateFromQuery(query) {
    let {startDate, endDate} = query;

    if (!startDate || !endDate) {
        return {startDate: getDefaultStartDate(), endDate: getDefaultEndDate()};
    }

    const sd = moment(startDate);
    const ed = moment(endDate);

    if ((!sd.isValid() || !ed.isValid()) || sd > ed) {
        startDate = getDefaultStartDate();
        endDate = getDefaultEndDate();
    }

    return {startDate, endDate};
}
