import {fromJS, List} from "immutable";
import {
    FAIL_GET_SHOPS_BY_MANAGER,
    REQUEST_GET_SHOPS_BY_MANAGER,
    SUCCEED_GET_SHOPS_BY_MANAGER
} from "../../actions/RESTActionTypes";
import RestError from "../../../models/RestError";
import Shop from "../../../models/Shop";
import {
    ADMIN_SHOPS_CHANGE_PERIOD,
    ADMIN_SHOPS_CHANGE_QUERY_PARAMS,
    ADMIN_SHOPS_INIT_DATA,
    ADMIN_SHOPS_RESET_QUERY_PARAMS,
} from "../../actions/UIActionTypes";
import ShopQueryParam from "../../../models/ui/ShopQueryParam";
import {parseStartAndEndDateFromQuery} from "../../../utils/DateUtils";

export const initializeAdminShops = fromJS({
    isInitialized: false,
    isLoading: false,
    count: 0,
    error: null,
    queryParams: new ShopQueryParam(),
    shops: List(),
})

export function adminShops(state = initializeAdminShops, action) {
    switch (action.type) {
        case ADMIN_SHOPS_INIT_DATA:
            return state.set('queryParams', action.queryParams);

        case REQUEST_GET_SHOPS_BY_MANAGER:
            return state.set('isLoading', true)

        case SUCCEED_GET_SHOPS_BY_MANAGER:
            return state.set('isLoading', false)
                .set('isInitialized', true)
                .set('shops' , List(action.results.map(item => new Shop(item))))
                .set('count', action.count);

        case FAIL_GET_SHOPS_BY_MANAGER:
            return state.set('isLoading', false).set('error', new RestError(action.error));

        case ADMIN_SHOPS_CHANGE_QUERY_PARAMS:
            return state.set('queryParams', state.get('queryParams').set(action.key, action.value));

        case ADMIN_SHOPS_CHANGE_PERIOD:
            return state.set(
                'queryParams',
                state.get('queryParams').set('startDate', action.startDate).set('endDate', action.endDate)
            );

        case ADMIN_SHOPS_RESET_QUERY_PARAMS:
            const queryParams = state.get('queryParams');
            const {startDate, endDate} = parseStartAndEndDateFromQuery({});

            return state.set(
                'queryParams',
                initializeAdminShops.get('queryParams')
                    .set('page', queryParams.get('page'))
                    .set('startDate', startDate)
                    .set('endDate', endDate)
            )

        default:
            return state;
    }
}