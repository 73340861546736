import {fromJS, List, Map, Set} from "immutable";
import {
    FAIL_GET_WORK_REQUEST_HISTORIES_BY_SHOP_STAFF,
    FAIL_IMPORT_BUILDER_PRODUCT_INFO_IN_PRODUCT_EDIT,
    FAIL_INIT_SHOP_STAFF_PRODUCT_EDIT,
    FAIL_UPDATE_PRODUCT_BY_SHOP_STAFF,
    REQUEST_GET_WORK_REQUEST_HISTORIES_BY_SHOP_STAFF,
    REQUEST_IMPORT_BUILDER_PRODUCT_INFO_IN_PRODUCT_EDIT,
    REQUEST_INIT_SHOP_STAFF_PRODUCT_EDIT,
    REQUEST_UPDATE_PRODUCT_BY_SHOP_STAFF,
    SUCCEED_GET_WORK_REQUEST_HISTORIES_BY_SHOP_STAFF,
    SUCCEED_IMPORT_BUILDER_PRODUCT_INFO_IN_PRODUCT_EDIT,
    SUCCEED_INIT_SHOP_STAFF_PRODUCT_EDIT,
    SUCCEED_UPDATE_PRODUCT_BY_SHOP_STAFF,
} from "../../actions/RESTActionTypes";
import {
    SHOP_STAFF_PRODUCT_ADD_CLEAR_IMPORT_BUILDER_PRODUCT_INFO_STATE,
    SHOP_STAFF_PRODUCT_EDIT_ADD_COUNTRY_CODE,
    SHOP_STAFF_PRODUCT_EDIT_ADD_DELETED_OPTION_IMAGE_ID,
    SHOP_STAFF_PRODUCT_EDIT_ADD_LANGUAGE_CODE,
    SHOP_STAFF_PRODUCT_EDIT_ADD_OPTION,
    SHOP_STAFF_PRODUCT_EDIT_ADD_OPTIONS_SPEC_DATA,
    SHOP_STAFF_PRODUCT_EDIT_ADD_PRODUCT_COLOR,
    SHOP_STAFF_PRODUCT_EDIT_ADD_PRODUCT_SIZE,
    SHOP_STAFF_PRODUCT_EDIT_ADD_REQUIRED_ERRORS,
    SHOP_STAFF_PRODUCT_EDIT_ADD_REQUIRED_I18N_PRODUCT_LANGUAGE_ERRORS,
    SHOP_STAFF_PRODUCT_EDIT_ADD_REQUIRED_OPTION_ERRORS,
    SHOP_STAFF_PRODUCT_EDIT_ADD_REQUIRED_I18N_PRODUCT_COUNTRY_ERRORS,
    SHOP_STAFF_PRODUCT_EDIT_ADD_REQUIRED_TEMP_WORK_REQUEST_ERRORS,
    SHOP_STAFF_PRODUCT_EDIT_ADD_TEMP_WORK_REQUEST,
    SHOP_STAFF_PRODUCT_EDIT_ADD_WORK_REQUEST,
    SHOP_STAFF_PRODUCT_EDIT_CHANGE_CATEGORY,
    SHOP_STAFF_PRODUCT_EDIT_CHANGE_COUNTRY_INFO,
    SHOP_STAFF_PRODUCT_EDIT_CHANGE_LANGUAGE_INFO,
    SHOP_STAFF_PRODUCT_EDIT_CHANGE_OPTION,
    SHOP_STAFF_PRODUCT_EDIT_CHANGE_OPTION_COLOR,
    SHOP_STAFF_PRODUCT_EDIT_CHANGE_OPTION_COLOR_INFO,
    SHOP_STAFF_PRODUCT_EDIT_CHANGE_OPTION_COUNTRY_INFO,
    SHOP_STAFF_PRODUCT_EDIT_CHANGE_OPTION_LANGUAGE_INFO,
    SHOP_STAFF_PRODUCT_EDIT_CHANGE_PRODUCT,
    SHOP_STAFF_PRODUCT_EDIT_CHANGE_RESOURCE_FILE_DATA,
    SHOP_STAFF_PRODUCT_EDIT_CHANGE_TEMP_WORK_REQUEST_IMAGE,
    SHOP_STAFF_PRODUCT_EDIT_CHANGE_TEMP_WORK_REQUEST_REQUEST_MESSAGE,
    SHOP_STAFF_PRODUCT_EDIT_CHANGE_TEMP_WORK_REQUEST_SPEC,
    SHOP_STAFF_PRODUCT_EDIT_CHANGE_TEMP_WORK_REQUEST_SPEC_UNIT,
    SHOP_STAFF_PRODUCT_EDIT_CLEAR_EDIT_STATE,
    SHOP_STAFF_PRODUCT_EDIT_DELETE_COUNTRY_CODE,
    SHOP_STAFF_PRODUCT_EDIT_DELETE_LANGUAGE_CODE,
    SHOP_STAFF_PRODUCT_EDIT_DELETE_OPTION,
    SHOP_STAFF_PRODUCT_EDIT_DELETE_TEMP_WORK_REQUEST,
    SHOP_STAFF_PRODUCT_EDIT_IMPORT_TEMP_WORK_REQUEST_SPEC,
    SHOP_STAFF_PRODUCT_EDIT_INIT_DATA,
    SHOP_STAFF_PRODUCT_EDIT_SET_CLOSE_HISTORY,
    SHOP_STAFF_PRODUCT_EDIT_SET_DEFAULT_COUNTRY,
    SHOP_STAFF_PRODUCT_EDIT_SET_DEFAULT_LANGUAGE,
    SHOP_STAFF_PRODUCT_EDIT_SET_OPEN_HISTORY,
    SHOP_STAFF_PRODUCT_EDIT_DELETE_TEMP_WORK_REQUEST_IMAGE,
    SHOP_STAFF_PRODUCT_EDIT_CHANGE_TEMP_WORK_REQUEST_ALL_IMAGES,
    SHOP_STAFF_PRODUCT_EDIT_CHANGE_TEMP_WORK_REQUEST_ALL_SPECS,
    SHOP_STAFF_PRODUCT_EDIT_TOGGLE_EDIT_MODE,
    SHOP_STAFF_PRODUCT_EDIT_SET_IS_OPENED_CHANGE_ORDER_DIALOG,
    SHOP_STAFF_PRODUCT_EDIT_REORDER,
    SHOP_STAFF_PRODUCT_EDIT_REORDER_LIST, SHOP_STAFF_PRODUCT_EDIT_CHANGE_OPTIONS_ORDER,
} from '../../actions/UIActionTypes';
import {
    addExclusively,
    indexOfCountryCode,
    indexOfLanguageCode,
    updateListByKeyValue,
    updateListItem,
} from "../../../utils/ListUtils";
import {
    changeDefaultCountryInProduct,
    changeDefaultLanguageInProduct,
    deleteCountryInProduct,
    deleteLanguageInProduct,
    mergeBuilderProduct2Product,
    reduceCategories
} from "../../../behaviors/ProductBehavior";
import {
    addCountry2Options,
    addLanguage2Options,
    changeDefaultCountryInOptions,
    changeDefaultLanguageInOptions,
    changeProductOptionColor,
    deleteCountryInOptions,
    deleteLanguageInOptions, getOptionsByChangeOrder,
} from '../../../behaviors/ProductOptionBehavior';
import RestError from "../../../models/RestError";
import Product from "../../../models/Product";
import ProductOption from "../../../models/ProductOption";
import I18NLanguageProduct from "../../../models/I18NLanguageProduct";
import I18NCountryProduct from "../../../models/I18NCountryProduct";
import Brand from "../../../models/Brand";
import ProductSize from "../../../models/ProductSize";
import ProductColor from "../../../models/ProductColor";
import {WORK_REQUEST_STATUS_REQUEST} from "../../../constants/WorkRequestConstants";
import HistoryData from "../../../models/HistoryData";
import WorkRequestHistory from "../../../models/WorkRequestHistory";
import WorkRequest from "../../../models/WorkRequest";
import {
    ProductOptionRequiredError,
    ProductRequiredError
} from "../../../models/ui/ProductRequiredError";
import Shop from "../../../models/Shop";

export const initialShopStaffProductEdit = fromJS({
    isLoading: false,
    brands: List(),
    categories: List(),
    productSizes: List(),
    productColors: List(),
    product: new Product(),
    shop: new Shop(),
    error: null,
    newbie: new Product(),
    categoryRootUid: null,
    productOptionColors: List(),
    defaultLanguageCode: '',
    defaultCountryCode: '',
    deleteLanguageCodes: Set(),
    deleteCountryCodes: Set(),
    deleteOptionIds: Set(),
    deleteOptionImageIds: Set(),
    isSaveAndEditContinueEditingMode: false,
    isEditing: false,
    succeedEdit: false,
    errorEdit: null,
    isBuilderProductImported: false,
    errorImportBuilderProduct: null,
    historyData: new HistoryData(),
    isOpenHistory: false,
    isGetHistoryLoading: false,
    tempWorkRequest: new WorkRequest(),
    optionsSpecData: null,
    requiredErrors: new ProductRequiredError(),
    isOpenedChangeOptionDialog: false,
    tempOptions: List(),
});

export function shopStaffProductEdit(state = initialShopStaffProductEdit, action) {
    let index = -1;
    let product, newbie, i18nLanguages, i18nCountries, shop;
    let options, option;
    let productOptionColors;
    let categoryRootUid;
    let histories, historyData, historyPage;
    let tempWorkRequest;
    let requiredErrors, requiredErrorOptions, requiredErrorOption;
    let tempOptions;

    switch (action.type) {
        case SHOP_STAFF_PRODUCT_EDIT_INIT_DATA:
            return initialShopStaffProductEdit;

        case SHOP_STAFF_PRODUCT_EDIT_CLEAR_EDIT_STATE:
            return state.set('isEditing', initialShopStaffProductEdit.get('isEditing'))
                .set('succeedEdit', initialShopStaffProductEdit.get('succeedEdit'))
                .set('errorEdit', initialShopStaffProductEdit.get('errorEdit'));

        case REQUEST_INIT_SHOP_STAFF_PRODUCT_EDIT:
            return state.set('isLoading', true);

        case SUCCEED_INIT_SHOP_STAFF_PRODUCT_EDIT:
            product = new Product(action.product);
            shop = new Shop(action.shop);
            requiredErrors = state.get('requiredErrors');
            options = product.get('options');

            const categoryId = product.getIn(['category', 'id']);
            const categories = reduceCategories(action.categories, 1);
            const category = categories.find(item => item.get('id') === categoryId);

            if (category) {
                product = product.set('category', category).set('category_id', category.get('id'));
                categoryRootUid = category.getRootCategoryUid();
            }

            return state.set('isLoading', false)
                .set('categoryRootUid', categoryRootUid ? categoryRootUid : null)
                .set('brands', List(action.brands.map(item => new Brand(item))))
                .set('categories', categories)
                .set('productSizes', List(action.product_sizes.map(item => new ProductSize(item))))
                .set('productColors', List(action.product_colors.map(item => new ProductColor(item))))
                .set('product', product)
                .set('shop', shop)
                .set('newbie', product)
                .set(
                    'requiredErrors',
                    requiredErrors.set(
                        'options', List(options.map(() => new ProductOptionRequiredError())))
                )
                .set('productOptionColors', product.getOptionColors())
                .set('defaultLanguageCode', product.findDefaultLanguageCode())
                .set('defaultCountryCode', product.findDefaultCountryCode())
                .set('error', null);

        case FAIL_INIT_SHOP_STAFF_PRODUCT_EDIT:
            return state.set('isLoading', false)
                .set('brands', List())
                .set('categories', List())
                .set('productSizes', List())
                .set('productColors', List())
                .set('product', new Product())
                .set('newbie', new Product())
                .set('shop', new Shop())
                .set('error', new RestError(action.error));

        case SHOP_STAFF_PRODUCT_EDIT_ADD_PRODUCT_SIZE:
            return state.set('productSizes', state.get('productSizes').push(action.productSize));

        case SHOP_STAFF_PRODUCT_EDIT_ADD_PRODUCT_COLOR:
            return state.set('productColors', state.get('productColors').push(action.productColor));

        case SHOP_STAFF_PRODUCT_EDIT_ADD_LANGUAGE_CODE:
            newbie = state.get('newbie');
            i18nLanguages = newbie.get('i18n_languages');
            index = indexOfLanguageCode(i18nLanguages, action.languageCode);

            if (index >= 0) {
                return state;
            }

            options = newbie.get('options');

            newbie = newbie.set(
                'i18n_languages',
                i18nLanguages.push(new I18NLanguageProduct({language_code: action.languageCode}))
            )
                .set('options', addLanguage2Options(options, action.languageCode));

            return state.set('newbie', newbie);

        case SHOP_STAFF_PRODUCT_EDIT_DELETE_LANGUAGE_CODE:
            newbie = state.get('newbie');
            i18nLanguages = newbie.get('i18n_languages');
            index = indexOfLanguageCode(i18nLanguages, action.languageCode);

            if (index >= 0) {
                const {i18nLanguages: newI18nLanguages, deleteI18nLanguageCodes} = deleteLanguageInProduct(
                    index, i18nLanguages, state.get('deleteLanguageCodes')
                );
                const {newOptions} = deleteLanguageInOptions(newbie.get('options'), action.languageCode);

                newbie = newbie.set('i18n_languages', newI18nLanguages).set('options', newOptions);

                state = state.set('newbie', newbie).set('deleteLanguageCodes', deleteI18nLanguageCodes);
            }

            return state;

        case SHOP_STAFF_PRODUCT_EDIT_SET_DEFAULT_LANGUAGE:
            newbie = state.get('newbie');

            return state.set(
                'newbie',
                newbie.set(
                    'i18n_languages', changeDefaultLanguageInProduct(newbie.get('i18n_languages'), action.languageCode)
                )
                    .set('options', changeDefaultLanguageInOptions(newbie.get('options'), action.languageCode))
            )
                .set('defaultLanguageCode', action.languageCode);

        case SHOP_STAFF_PRODUCT_EDIT_ADD_COUNTRY_CODE:
            newbie = state.get('newbie');
            i18nCountries = newbie.get('i18n_countries');
            index = indexOfCountryCode(i18nCountries, action.countryCode);

            if (index >= 0) {
                return state;
            }

            options = newbie.get('options');

            newbie = newbie.set(
                'i18n_countries', i18nCountries.push(new I18NCountryProduct({country_code: action.countryCode}))
            )
                .set('options', addCountry2Options(options, action.countryCode));

            return state.set('newbie', newbie)

        case SHOP_STAFF_PRODUCT_EDIT_DELETE_COUNTRY_CODE:
            newbie = state.get('newbie');
            i18nCountries = newbie.get('i18n_countries');
            index = indexOfCountryCode(i18nCountries, action.countryCode);

            if (index >= 0) {
                const {i18nCountries: newI18nCountries, deleteI18nCountryCodes} = deleteCountryInProduct(
                    index, i18nCountries, state.get('deleteCountryCodes')
                );
                const {newOptions} = deleteCountryInOptions(newbie.get('options'), action.countryCode);

                newbie = newbie.set('i18n_countries', newI18nCountries).set('options', newOptions);

                state = state.set('newbie', newbie).set('deleteCountryCodes', deleteI18nCountryCodes);
            }

            return state;

        case SHOP_STAFF_PRODUCT_EDIT_SET_DEFAULT_COUNTRY:
            newbie = state.get('newbie');

            return state.set(
                'newbie',
                newbie.set(
                    'i18n_countries', changeDefaultCountryInProduct(newbie.get('i18n_countries'), action.countryCode)
                )
                    .set('options', changeDefaultCountryInOptions(newbie.get('options'), action.countryCode))
            )
                .set('defaultCountryCode', action.countryCode);

        case SHOP_STAFF_PRODUCT_EDIT_CHANGE_PRODUCT:
            if (action.key === 'uid') {
                state = state.setIn(['requiredErrors', action.key], !action.value);
            }

            return state.set('newbie', state.get('newbie').set(action.key, action.value));

        case SHOP_STAFF_PRODUCT_EDIT_CHANGE_LANGUAGE_INFO:
            newbie = state.get('newbie');

            if (action.key === 'name') {
                state = state.setIn(['requiredErrors', 'i18n_languages', action.index, action.key], !action.value);
            }

            return state.set(
                'newbie',
                newbie.set(
                    'i18n_languages',
                    updateListByKeyValue(newbie.get('i18n_languages'), action.index, action.key, action.value)
                )
            );

        case SHOP_STAFF_PRODUCT_EDIT_CHANGE_COUNTRY_INFO:
            newbie = state.get('newbie');

            if (action.key === 'price') {
                state = state.setIn(
                    ['requiredErrors', 'i18n_countries', action.index, action.key], !parseFloat(action.value)
                );
            }

            return state.set(
                'newbie',
                newbie.set(
                    'i18n_countries',
                    updateListByKeyValue(newbie.get('i18n_countries'), action.index, action.key, action.value)
                )
            );

        case SHOP_STAFF_PRODUCT_EDIT_ADD_OPTION:
            newbie = state.get('newbie');
            options = newbie.get('options');
            option = ProductOption.makeNewbie(newbie).set('order', options.size);

            requiredErrors = state.get('requiredErrors');
            requiredErrorOptions = requiredErrors.get('options');

            requiredErrorOption = requiredErrorOptions.push(
                new ProductOptionRequiredError()
            )

            return state.set('newbie', newbie.set('options', options.push(option)))
                .setIn(['requiredErrors', 'options'], requiredErrorOption);

        case SHOP_STAFF_PRODUCT_EDIT_CHANGE_OPTION:
            newbie = state.get('newbie');

            if (action.key === 'uid') {
                state = state.setIn(['requiredErrors', 'options', action.index, action.key], !action.value);
            }

            return state.set(
                'newbie',
                newbie.set(
                    'options', updateListByKeyValue(newbie.get('options'), action.index, action.key, action.value)
                )
            );

        case SHOP_STAFF_PRODUCT_EDIT_CHANGE_OPTION_COLOR:
            newbie = state.get('newbie');
            options = newbie.get('options');
            option = options.get(action.index);

            const [newbieOptions, newProductOptionColors] = changeProductOptionColor(
                newbie.get('options'), state.get('productOptionColors'), action.index, action.productColor
            );

            return state.set('newbie', newbie.set('options', newbieOptions))
                .set('productOptionColors', newProductOptionColors)
                .setIn(['requiredErrors', 'options', action.index, 'color'], !action.productColor);

        case SHOP_STAFF_PRODUCT_EDIT_ADD_DELETED_OPTION_IMAGE_ID:
            return state.set('deleteOptionImageIds', state.get('deleteOptionImageIds').add(action.optionImageId));

        case SHOP_STAFF_PRODUCT_EDIT_DELETE_OPTION:
            newbie = state.get('newbie');
            options = newbie.get('options');
            const deletedOption = options.get(action.index);
            const newOptions = options.delete(action.index);

            requiredErrors = state.get('requiredErrors');
            requiredErrorOptions = requiredErrors.get('options');

            if (deletedOption.get('id') > 0) {
                state = state.set('deleteOptionIds', state.get('deleteOptionIds').add(deletedOption.get('id')));
            }

            const hasSameColor = newOptions.some(item => item.get('color_id') === deletedOption.get('color_id'));

            if (!hasSameColor) {
                productOptionColors = state.get('productOptionColors').reduce((prev, cur) => {
                    if (cur.get('id') === deletedOption.get('color_id')) {
                        return prev;
                    } else {
                        return prev.push(cur);
                    }
                }, List());

                state = state.set('productOptionColors', productOptionColors);
            }

            return state.set('newbie', newbie.set('options', newOptions))
                .set('requiredErrors', requiredErrors.set('options', requiredErrorOptions.delete(action.index)));


        case SHOP_STAFF_PRODUCT_EDIT_CHANGE_OPTION_LANGUAGE_INFO:
            newbie = state.get('newbie');
            options = newbie.get('options');
            option = options.get(action.optionIndex);
            option = option.set(
                'i18n_languages',
                updateListByKeyValue(option.get('i18n_languages'), action.langIndex, action.key, action.value)
            );

            return state.set('newbie', newbie.set('options', updateListItem(options, action.optionIndex, option)));

        case SHOP_STAFF_PRODUCT_EDIT_CHANGE_OPTION_COUNTRY_INFO:
            newbie = state.get('newbie');
            options = newbie.get('options');
            option = options.get(action.optionIndex);
            option = option.set(
                'i18n_countries',
                updateListByKeyValue(option.get('i18n_countries'), action.countryIndex, action.key, action.value)
            );

            return state.set('newbie', newbie.set('options', updateListItem(options, action.optionIndex, option)));

        case SHOP_STAFF_PRODUCT_EDIT_CHANGE_OPTION_COLOR_INFO:
            if (action.key === 'imageData') {
                state = state.setIn(['requiredErrors', 'options', action.index, action.key], !action.value);
            }

            return state.set(
                'productOptionColors',
                updateListByKeyValue(state.get('productOptionColors'), action.index, action.key, action.value)
            );
        case SHOP_STAFF_PRODUCT_EDIT_ADD_REQUIRED_ERRORS:
            return state.setIn(['requiredErrors', action.key], action.value);

        case SHOP_STAFF_PRODUCT_EDIT_ADD_REQUIRED_OPTION_ERRORS:
            return state.setIn(['requiredErrors', 'options', action.index, action.key], action.value);

        case SHOP_STAFF_PRODUCT_EDIT_ADD_REQUIRED_I18N_PRODUCT_LANGUAGE_ERRORS:
            return state.setIn(['requiredErrors', 'i18n_languages', action.index, action.key], action.value);

        case SHOP_STAFF_PRODUCT_EDIT_ADD_REQUIRED_I18N_PRODUCT_COUNTRY_ERRORS:
            return state.setIn(['requiredErrors', 'i18n_countries', action.index, action.key], action.value);

        case SHOP_STAFF_PRODUCT_EDIT_ADD_REQUIRED_TEMP_WORK_REQUEST_ERRORS:
            return state.setIn(
                ['requiredErrors', 'options', action.index, 'tempWorkRequest', action.key, action.categoryRootUid], action.value
            );

        case REQUEST_UPDATE_PRODUCT_BY_SHOP_STAFF:
            return state.set('isEditing', true).set('succeedEdit', false).set('errorEdit', null);

        case SUCCEED_UPDATE_PRODUCT_BY_SHOP_STAFF:
            return state.set('isEditing', false).set('succeedEdit', true).set('errorEdit', null);

        case FAIL_UPDATE_PRODUCT_BY_SHOP_STAFF:
            return state.set('isEditing', false)
                .set('succeedEdit', false)
                .set('errorEdit', new RestError(action.error));

        case REQUEST_IMPORT_BUILDER_PRODUCT_INFO_IN_PRODUCT_EDIT:
            return state.set('isLoading', true).set('errorImportBuilderProduct', null);

        case SUCCEED_IMPORT_BUILDER_PRODUCT_INFO_IN_PRODUCT_EDIT:
            newbie = state.get('newbie');
            options = newbie.get('options');

            let defaultLanguageCode, defaultCountryCode;
            const builderProduct = new Product(action.product);
            const builderOptions = List(action.options.map(item => new ProductOption(item)));

            [product, defaultLanguageCode, defaultCountryCode] = mergeBuilderProduct2Product(
                newbie,
                state.get('defaultLanguageCode'),
                state.get('defaultCountryCode'),
                builderProduct,
                builderOptions
            );

            return state.set('isLoading', false)
                .set('defaultLanguageCode', defaultLanguageCode)
                .set('defaultCountryCode', defaultCountryCode)
                .set('newbie', product)
                .set('isBuilderProductImported', true)
                .set('errorImportBuilderProduct', null)
                .setIn(
                    ['requiredErrors', 'options'], product.get('options').map(() => new ProductOptionRequiredError())
                );

        case FAIL_IMPORT_BUILDER_PRODUCT_INFO_IN_PRODUCT_EDIT:
            return state.set('isLoading', false).set('errorImportBuilderProduct', new RestError(action.error));

        case SHOP_STAFF_PRODUCT_ADD_CLEAR_IMPORT_BUILDER_PRODUCT_INFO_STATE:
            return state.set('errorImportBuilderProduct', null);

        case SHOP_STAFF_PRODUCT_EDIT_CHANGE_RESOURCE_FILE_DATA:
            return state.setIn(['newbie', 'work_request', 'resourceFileData'], action.resourceFileData);

        case SHOP_STAFF_PRODUCT_EDIT_CHANGE_CATEGORY:
            categoryRootUid = action.category.getRootCategoryUid();

            return state.setIn(['newbie', 'category_id'], action.category.get('id'))
                .setIn(['newbie', 'category'], action.category)
                .set('categoryRootUid', categoryRootUid);

        case REQUEST_GET_WORK_REQUEST_HISTORIES_BY_SHOP_STAFF:
            return state.set('isGetHistoryLoading', true);

        case SUCCEED_GET_WORK_REQUEST_HISTORIES_BY_SHOP_STAFF:
            histories = List(action.results.map(item => new WorkRequestHistory(item)));
            historyData = state.get('historyData');

            historyPage = historyData.get('page');
            if (histories.size > 0) {
                historyData = historyData.set(
                    'histories',
                    addExclusively(historyData.get('histories'), histories, historyPage + 1)
                )
                    .set('hasMore', action.hasMore)
                    .set('count', action.count)
                    .set('page', historyPage + 1)
            }

            return state.set('historyData', historyData)
                .set('isOpenHistory', true)
                .set('isGetHistoryLoading', false);

        case FAIL_GET_WORK_REQUEST_HISTORIES_BY_SHOP_STAFF:
            return state.set('error', new RestError(action.error))
                .set('isGetHistoryLoading', false);

        case SHOP_STAFF_PRODUCT_EDIT_SET_OPEN_HISTORY:
            return state.set('isOpenHistory', true);

        case SHOP_STAFF_PRODUCT_EDIT_SET_CLOSE_HISTORY:
            return state.set('isOpenHistory', false)
                .set('historyData', new HistoryData());

        case SHOP_STAFF_PRODUCT_EDIT_ADD_TEMP_WORK_REQUEST:
            return state.set('tempWorkRequest', action.workRequest);

        case SHOP_STAFF_PRODUCT_EDIT_DELETE_TEMP_WORK_REQUEST:
            return state.set('tempWorkRequest', new WorkRequest());

        case SHOP_STAFF_PRODUCT_EDIT_CHANGE_TEMP_WORK_REQUEST_IMAGE:
            categoryRootUid = state.get('categoryRootUid');
            return state.setIn(['tempWorkRequest', 'product_image_item', categoryRootUid, action.key], action.value);

        case SHOP_STAFF_PRODUCT_EDIT_CHANGE_TEMP_WORK_REQUEST_ALL_IMAGES:
        case SHOP_STAFF_PRODUCT_EDIT_CHANGE_TEMP_WORK_REQUEST_ALL_SPECS:

            return state.setIn(
                [
                    'requiredErrors',
                    'options',
                    action.index,
                    'tempWorkRequest',
                    action.key,
                    action.categoryRootUid
                ],
                action.value
            )

        case SHOP_STAFF_PRODUCT_EDIT_DELETE_TEMP_WORK_REQUEST_IMAGE:
            let productImageItem;
            categoryRootUid = state.get('categoryRootUid');

            productImageItem = state.getIn(['tempWorkRequest', 'product_image_item']);
            productImageItem = productImageItem.setIn([categoryRootUid, action.imgKey], "")
                .setIn([categoryRootUid, action.imgDataKey], null)

            return state.setIn(['tempWorkRequest', 'product_image_item'], productImageItem)
                .setIn(
                    [
                        'requiredErrors',
                        'options',
                        action.index,
                        'tempWorkRequest',
                        'product_image_item',
                        action.categoryRootUid
                    ],
                    true
                );

        case SHOP_STAFF_PRODUCT_EDIT_CHANGE_TEMP_WORK_REQUEST_REQUEST_MESSAGE:
            return state.setIn(['tempWorkRequest', 'request_message'], action.message)
                .setIn(['tempWorkRequest', 'status'], WORK_REQUEST_STATUS_REQUEST);

        case SHOP_STAFF_PRODUCT_EDIT_CHANGE_TEMP_WORK_REQUEST_SPEC:
            categoryRootUid = state.get('categoryRootUid');

            if (action.value === parseFloat('0')) {
                state = state .setIn(
                    [
                        'requiredErrors',
                        'options',
                        action.index,
                        'tempWorkRequest',
                        'product_spec',
                        action.categoryRootUid
                    ],
                    true
                );
            }

            return state.setIn(['tempWorkRequest', 'product_spec', categoryRootUid, action.key], action.value)
                .setIn(['tempWorkRequest', , 'status'], WORK_REQUEST_STATUS_REQUEST);

        case SHOP_STAFF_PRODUCT_EDIT_CHANGE_TEMP_WORK_REQUEST_SPEC_UNIT:
            categoryRootUid = state.get('categoryRootUid')
            return state.setIn(['tempWorkRequest', 'product_spec', categoryRootUid, 'unit'], action.unit)
                .setIn(['tempWorkRequest', 'status'], WORK_REQUEST_STATUS_REQUEST);

        case SHOP_STAFF_PRODUCT_EDIT_ADD_WORK_REQUEST:
            tempWorkRequest = state.get('tempWorkRequest');

            if (action.optionIndex === 0 || action.optionIndex) {
                state = state.setIn(['requiredErrors', 'options', action.optionIndex, 'work_request'], false)
            }

            return state.setIn(['newbie', 'options', action.optionIndex, 'work_request'], tempWorkRequest)
                .set('tempWorkRequest', new WorkRequest());

        case SHOP_STAFF_PRODUCT_EDIT_ADD_OPTIONS_SPEC_DATA:
            return state.set('optionsSpecData', action.optionsSpecData);

        case SHOP_STAFF_PRODUCT_EDIT_IMPORT_TEMP_WORK_REQUEST_SPEC:
            categoryRootUid = state.get('categoryRootUid');

            return state.setIn(['tempWorkRequest', 'product_spec', categoryRootUid], action.specData)
                .set('optionsSpecData', null);

        case SHOP_STAFF_PRODUCT_EDIT_TOGGLE_EDIT_MODE:
            return state.set('isSaveAndEditContinueEditingMode', action.isSaveAndEditContinueEditingMode);

        case SHOP_STAFF_PRODUCT_EDIT_SET_IS_OPENED_CHANGE_ORDER_DIALOG:
            if (action.isOpenedChangeOptionDialog) {
                options = state.getIn(['newbie', 'options']);
                state = state.set('tempOptions', options);
            }

            return state.set('isOpenedChangeOptionDialog', action.isOpenedChangeOptionDialog);

        case SHOP_STAFF_PRODUCT_EDIT_REORDER:
            tempOptions = state.get('tempOptions');

            if (action.startIndex === action.endIndex) {
                return state;
            }

            tempOptions = getOptionsByChangeOrder(tempOptions, action.startIndex, action.endIndex);

            return state.set('tempOptions', tempOptions)
            .set('isReordered', true);

        case SHOP_STAFF_PRODUCT_EDIT_REORDER_LIST:
            return state.set('isReordered', false);

        case SHOP_STAFF_PRODUCT_EDIT_CHANGE_OPTIONS_ORDER:
            tempOptions = state.get('tempOptions');

            return state.setIn(['newbie', 'options'], tempOptions)
            .set('isOpenedChangeOptionDialog', false);

        default:
            return state;
    }
}
