import React from "react";
import PropTypes from "prop-types";
import {styled} from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import LinearProgress from "@mui/material/LinearProgress";
import {PRIMARY_COLOR} from "../../constants/CssConstants";
import {List} from "immutable";

const DialogProgress = styled(Dialog)(({theme}) => ({
    overflow: "hidden",
    "& .MuiBackdrop-root": {
        background: "rgba(0, 0, 0, .3)",
    },
    "& .MuiDialog-paper": {
        background: "none",
        overflow: "hidden",
        boxShadow: "none",
    },
}));

const Bar = styled(LinearProgress)(({theme}) => ({
    position: "fixed",
    width: "100vw",
    top: "env(safe-area-inset-top, 0px)",
    left: "env(safe-area-inset-left, 0px)",
    right: "env(safe-area-inset-right, 0px)",
    backgroundColor: "rgba(255, 255, 255, .15)",
    "& .MuiLinearProgress-barColorPrimary": {
        backgroundColor: PRIMARY_COLOR,
        animation: `PageProgress 10s ${theme.transitions.easing.easeOut} infinite`,
        width: "1%"
    },
    "@keyframes PageProgress": {
        "0%": {
            width: "1%"
        },
        "100%": {
            width: "100%"
        }
    },
}));

const ProgressCircle = styled(CircularProgress)(({theme}) => ({
    display: "flex",
    "& > * + *": {
        marginLeft: theme.spacing(2),
    },
    color: PRIMARY_COLOR,
    zIndex: 100,
}));

function PageProgress({open, router}) {
    const exceptPageList = List();
    const exceptPage = exceptPageList.includes(router.pathname);

    return (
        <DialogProgress open={open}>
            <Bar variant={"determinate"} value={100}/>
            <ProgressCircle/>
        </DialogProgress>
    );
}

PageProgress.propTypes = {
    open: PropTypes.bool.isRequired,
    router: PropTypes.object,
};

PageProgress.defaultProps = {
    open: false,
    router: {},
};

export default PageProgress;
