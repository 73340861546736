import {List, Map} from "immutable";

export const PRODUCT_OPTION_LIGHTING_ENV_ROTATION_MIN = 0;
export const PRODUCT_OPTION_LIGHTING_ENV_ROTATION_MAX = 360;
export const PRODUCT_OPTION_LIGHTING_ENV_EXPOSURE_MIN = 0;
export const PRODUCT_OPTION_LIGHTING_ENV_EXPOSURE_MAX = 10;
export const PRODUCT_OPTION_FACE_EFFECT_EXTRA_TOP_AREA_MIN = 0;
export const PRODUCT_OPTION_FACE_EFFECT_EXTRA_TOP_AREA_MAX = 10;

export const PRODUCT_OPTION_AR_UPLOAD_IMAGE_MINIMUM_SIZE = 50;

export const PRODUCT_OPTION_LIGHTING_ENV_ROTATION_Y_KEY = 'y';
export const PRODUCT_OPTION_LIGHTING_ENV_EXPOSURE_KEY = 'exposure';
export const PRODUCT_OPTION_FACE_EFFECT_EXTRA_TOP_AREA_KEY = 'extra_top_area';

export const PRODUCT_OPTION_AR_IMAGE_SETTING_BLENDING_SELECT = 0;
export const PRODUCT_OPTION_AR_IMAGE_SETTING_BLENDING_SOURCE_OVER = 'source_over';
export const PRODUCT_OPTION_AR_IMAGE_SETTING_BLENDING_SOURCE_IN = 'source_in';
export const PRODUCT_OPTION_AR_IMAGE_SETTING_BLENDING_SOURCE_OUT = 'source_out';
export const PRODUCT_OPTION_AR_IMAGE_SETTING_BLENDING_SOURCE_ATOP = 'source_atop';
export const PRODUCT_OPTION_AR_IMAGE_SETTING_BLENDING_DESTINATION_OVER = 'destination-over';
export const PRODUCT_OPTION_AR_IMAGE_SETTING_BLENDING_DESTINATION_IN = 'destination-in';
export const PRODUCT_OPTION_AR_IMAGE_SETTING_BLENDING_DESTINATION_OUT = 'destination-out';
export const PRODUCT_OPTION_AR_IMAGE_SETTING_BLENDING_DESTINATION_ATOP = 'destination-atop';
export const PRODUCT_OPTION_AR_IMAGE_SETTING_BLENDING_LIGHTER = 'lighter';
export const PRODUCT_OPTION_AR_IMAGE_SETTING_BLENDING_COPY = 'copy';
export const PRODUCT_OPTION_AR_IMAGE_SETTING_BLENDING_XOR = 'xor';
export const PRODUCT_OPTION_AR_IMAGE_SETTING_BLENDING_MULTIPLY = 'multiply';
export const PRODUCT_OPTION_AR_IMAGE_SETTING_BLENDING_SCREEN = 'screen';
export const PRODUCT_OPTION_AR_IMAGE_SETTING_BLENDING_OVERLAY = 'overlay';
export const PRODUCT_OPTION_AR_IMAGE_SETTING_BLENDING_DARKEN = 'darken';
export const PRODUCT_OPTION_AR_IMAGE_SETTING_BLENDING_LIGHTEN = 'lighten';
export const PRODUCT_OPTION_AR_IMAGE_SETTING_BLENDING_COLOR_DODGE = 'color-dodge';
export const PRODUCT_OPTION_AR_IMAGE_SETTING_BLENDING_COLOR_BURN = 'color-burn';
export const PRODUCT_OPTION_AR_IMAGE_SETTING_BLENDING_HARD_LIGHT = 'hard-light';
export const PRODUCT_OPTION_AR_IMAGE_SETTING_BLENDING_SOFT_LIGHT = 'soft-light';
export const PRODUCT_OPTION_AR_IMAGE_SETTING_BLENDING_DIFFERENCE = 'difference';
export const PRODUCT_OPTION_AR_IMAGE_SETTING_BLENDING_EXCLUSION = 'exclusion';
export const PRODUCT_OPTION_AR_IMAGE_SETTING_BLENDING_HUS = 'hue';
export const PRODUCT_OPTION_AR_IMAGE_SETTING_BLENDING_SATURATION = 'saturation';
export const PRODUCT_OPTION_AR_IMAGE_SETTING_BLENDING_COLOR = 'color';
export const PRODUCT_OPTION_AR_IMAGE_SETTING_BLENDING_LUMINOSITY = 'luminosity';

export const PRODUCT_OPTION_AR_IMAGE_SETTING_BLENDING_LISTS = List([
    Map({value: PRODUCT_OPTION_AR_IMAGE_SETTING_BLENDING_SELECT, name: 'select'}),
    Map({value: PRODUCT_OPTION_AR_IMAGE_SETTING_BLENDING_SOURCE_OVER, name: 'Source'}),
    Map({value: PRODUCT_OPTION_AR_IMAGE_SETTING_BLENDING_SOURCE_IN, name: 'Source In'}),
    Map({value: PRODUCT_OPTION_AR_IMAGE_SETTING_BLENDING_SOURCE_OUT, name: 'Source Out'}),
    Map({value: PRODUCT_OPTION_AR_IMAGE_SETTING_BLENDING_SOURCE_ATOP, name: 'Source Atop'}),
    Map({value: PRODUCT_OPTION_AR_IMAGE_SETTING_BLENDING_DESTINATION_OVER, name: 'Destination Over'}),
    Map({value: PRODUCT_OPTION_AR_IMAGE_SETTING_BLENDING_DESTINATION_IN, name: 'Destination In'}),
    Map({value: PRODUCT_OPTION_AR_IMAGE_SETTING_BLENDING_DESTINATION_OUT, name: 'Destination Out'}),
    Map({value: PRODUCT_OPTION_AR_IMAGE_SETTING_BLENDING_DESTINATION_ATOP, name: 'Destination Atop'}),
    Map({value: PRODUCT_OPTION_AR_IMAGE_SETTING_BLENDING_LIGHTER, name: 'Lighter'}),
    Map({value: PRODUCT_OPTION_AR_IMAGE_SETTING_BLENDING_COPY, name: 'Copy'}),
    Map({value: PRODUCT_OPTION_AR_IMAGE_SETTING_BLENDING_XOR, name: 'Xor'}),
    Map({value: PRODUCT_OPTION_AR_IMAGE_SETTING_BLENDING_MULTIPLY, name: 'Multiply'}),
    Map({value: PRODUCT_OPTION_AR_IMAGE_SETTING_BLENDING_SCREEN, name: 'Screen'}),
    Map({value: PRODUCT_OPTION_AR_IMAGE_SETTING_BLENDING_OVERLAY, name: 'Overlay'}),
    Map({value: PRODUCT_OPTION_AR_IMAGE_SETTING_BLENDING_DARKEN, name: 'Darken'}),
    Map({value: PRODUCT_OPTION_AR_IMAGE_SETTING_BLENDING_LIGHTEN, name: 'Lighten'}),
    Map({value: PRODUCT_OPTION_AR_IMAGE_SETTING_BLENDING_COLOR_DODGE, name: 'Color Dodge'}),
    Map({value: PRODUCT_OPTION_AR_IMAGE_SETTING_BLENDING_COLOR_BURN, name: 'Color Burn'}),
    Map({value: PRODUCT_OPTION_AR_IMAGE_SETTING_BLENDING_HARD_LIGHT, name: 'Hard Light'}),
    Map({value: PRODUCT_OPTION_AR_IMAGE_SETTING_BLENDING_SOFT_LIGHT, name: 'Soft Light'}),
    Map({value: PRODUCT_OPTION_AR_IMAGE_SETTING_BLENDING_DIFFERENCE, name: 'Difference'}),
    Map({value: PRODUCT_OPTION_AR_IMAGE_SETTING_BLENDING_EXCLUSION, name: 'Exclusion'}),
    Map({value: PRODUCT_OPTION_AR_IMAGE_SETTING_BLENDING_HUS, name: 'Hue'}),
    Map({value: PRODUCT_OPTION_AR_IMAGE_SETTING_BLENDING_SATURATION, name: 'Saturation'}),
    Map({value: PRODUCT_OPTION_AR_IMAGE_SETTING_BLENDING_COLOR, name: 'Color'}),
    Map({value: PRODUCT_OPTION_AR_IMAGE_SETTING_BLENDING_LUMINOSITY, name: 'Luminosity'}),
]);
