import {I18NCountryMixin} from "./I18NCountry";
import {indexOfCountryCode} from "../utils/ListUtils";
import {COUNTRIES_KEY, OPTIONS_KEY, UPDATED_PREFIX} from "../constants/CommonConstants";

export const I18NCountryProductOptionMixin = defaultValues => class extends I18NCountryMixin({
    additional_price: 0,
    additional_price_text: '',
    ...defaultValues
}) {
    constructor(args = {}) {
        super({...args});
    }

    canAdd() {
        return Boolean(this.country_code) && parseInt(this.additional_price) >= 0;
    }

    isSame(other) {
        if (!other) {
            return false;
        }

        return this.country_code === other.get('country_code')
            && parseInt(this.additional_price) === other.get('additional_price')
            && this.is_default === other.get('is_default');
    }

    makeFormData(optionIndex, formData, oldbieProductOption = null) {
        const additionalPrice = parseInt(this.additional_price);
        let oldbieItem = null;

        if (oldbieProductOption !== null) {
            const oldbieItems = oldbieProductOption.get('i18n_countries');
            const indexOfOld = indexOfCountryCode(oldbieItems, this.country_code);

            oldbieItem = indexOfOld >= 0 ? oldbieItems.get(indexOfOld) : null;
        }

        const shouldUpdate = oldbieItem != null;
        const keyPrefix = shouldUpdate
            ? `${UPDATED_PREFIX}${OPTIONS_KEY}${optionIndex}-${COUNTRIES_KEY}${this.country_code}-`
            : `${OPTIONS_KEY}${optionIndex}-${COUNTRIES_KEY}${this.country_code}-`;
        let shouldAddItem = false;

        if (shouldUpdate) {
            if (!this.isSame(oldbieItem)) {
                shouldAddItem = true;
            }
        } else {
            shouldAddItem = true;
        }

        if (shouldAddItem) {
            formData.append(`${keyPrefix}is_default`, this.is_default ? '1' : '0');
            formData.append(`${keyPrefix}additional_price`, `${additionalPrice}`);
            formData.append(`${keyPrefix}country_code`, this.country_code);
        }
    }
};

export default class I18NCountryProductOption extends I18NCountryProductOptionMixin({}) {

    constructor(args = {}) {
        super({...args});
    }
}
