export function changeProductOptionLightingEnvUrl(url) {
    if (window.LolozemMVController && typeof window.LolozemMVController.changeEnvUrl === 'function') {
        window.LolozemMVController.changeEnvUrl(url);
    }
}

export function changeProductOptionLightingEnvExposure(value) {
    if (window.LolozemMVController && typeof window.LolozemMVController.changeEnvExposure === 'function') {
        window.LolozemMVController.changeEnvExposure(parseFloat(value));
    }
}

export function changeProductOptionLightingEnvRotationY(value) {
    if (window.LolozemMVController && typeof window.LolozemMVController.changeEnvRotationY === 'function') {
        window.LolozemMVController.changeEnvRotationY(parseFloat(value));
    }
}

export function setProductOptionLightingEnvUrl(info) {
    if(
        typeof window !== 'undefined'
        && window.LolozemMVController
        && typeof window.LolozemMVController.changeEnvUrl === 'function'
    ) {
        if (!info) {
            return;
        }

        if (info) {
            window.LolozemMVController.changeEnvUrl({
                env_image_preset_id: info.get('env_image_preset_id'),
            }, null)
        }
    }
}

export function setProductOptionLightingEnvExposure(info) {
    if(
        typeof window !== 'undefined'
        && window.LolozemMVController
        && typeof window.LolozemMVController.changeEnvExposure === 'function'
    ) {
        if (!info) {
            return;
        }

        if (info) {
            window.LolozemMVController.changeEnvExposure({
                exposure: info.get('exposure')
            }, null)
        }
    }
}

export function setProductOptionLightingEnvRotationY(info) {
    if(
        typeof window !== 'undefined'
        && window.LolozemMVController
        && typeof window.LolozemMVController.changeEnvRotationY === 'function'
    ) {
        if (!info) {
            return;
        }

        if (info) {
            window.LolozemMVController.changeEnvRotationY({
                y: info.get('y'),
            }, null)
        }
    }
}