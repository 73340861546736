import {ofType} from "redux-observable";
import {
    FAIL_CREATE_PRODUCT_SIZE_BY_SHOP_STAFF,
    FAIL_DELETE_PRODUCT_SIZES_BY_SHOP_STAFF,
    FAIL_GET_PRODUCT_SIZE_BY_SHOP_STAFF,
    FAIL_UPDATE_PRODUCT_SIZE_BY_SHOP_STAFF,
    REQUEST_CREATE_PRODUCT_SIZE_BY_SHOP_STAFF,
    REQUEST_DELETE_PRODUCT_SIZES_BY_SHOP_STAFF,
    REQUEST_GET_PRODUCT_SIZE_BY_SHOP_STAFF,
    REQUEST_GET_PRODUCT_SIZES_BY_SHOP_STAFF,
    REQUEST_UPDATE_PRODUCT_SIZE_BY_SHOP_STAFF,
    SUCCEED_CREATE_PRODUCT_SIZE_BY_SHOP_STAFF,
    SUCCEED_DELETE_PRODUCT_SIZES_BY_SHOP_STAFF,
    SUCCEED_GET_PRODUCT_SIZE_BY_SHOP_STAFF,
    SUCCEED_UPDATE_PRODUCT_SIZE_BY_SHOP_STAFF,
} from "../../actions/RESTActionTypes";
import {catchError, map, mergeMap} from "rxjs/operators";
import {
    createProductSize,
    deleteProductSizes,
    getProductSize,
    getProductSizes,
    updateProductSize,
} from "../../../rest/ShopStaffsAPI";
import {loadResult, loadResults, occurError} from "../../actions/RESTAction";
import {of} from "rxjs";

export const getProductSizesEpic = action$ => action$.pipe(
    ofType(REQUEST_GET_PRODUCT_SIZES_BY_SHOP_STAFF),
    mergeMap(action => getProductSizes(action.shopStaffInfo, action.queryParams).pipe(
        map(res => loadResults(action.succeedType, res.response, action.queryParams.page)),
        catchError(error => of(occurError(action.failType, error.xhr)))
    ))
);

export const createProductSizeEpic = action$ => action$.pipe(
    ofType(REQUEST_CREATE_PRODUCT_SIZE_BY_SHOP_STAFF),
    mergeMap(action => createProductSize(action.shopStaffInfo, action.formData).pipe(
        map(res => loadResult(SUCCEED_CREATE_PRODUCT_SIZE_BY_SHOP_STAFF, res.response)),
        catchError(error => of(occurError(FAIL_CREATE_PRODUCT_SIZE_BY_SHOP_STAFF, error.xhr)))
    ))
);

export const getProductSizeEpic = action$ => action$.pipe(
    ofType(REQUEST_GET_PRODUCT_SIZE_BY_SHOP_STAFF),
    mergeMap(action => getProductSize(action.shopStaffInfo, action.productSizeId).pipe(
        map(res => loadResult(SUCCEED_GET_PRODUCT_SIZE_BY_SHOP_STAFF, res.response)),
        catchError(error => of(occurError(FAIL_GET_PRODUCT_SIZE_BY_SHOP_STAFF, error.xhr)))
    ))
);

export const updateProductSizeEpic = action$ => action$.pipe(
    ofType(REQUEST_UPDATE_PRODUCT_SIZE_BY_SHOP_STAFF),
    mergeMap(action => updateProductSize(action.shopStaffInfo, action.productSizeId, action.formData).pipe(
        map(res => loadResult(SUCCEED_UPDATE_PRODUCT_SIZE_BY_SHOP_STAFF, res.response)),
        catchError(error => of(occurError(FAIL_UPDATE_PRODUCT_SIZE_BY_SHOP_STAFF, error.xhr)))
    ))
);

export const deleteProductSizesEpic = action$ => action$.pipe(
    ofType(REQUEST_DELETE_PRODUCT_SIZES_BY_SHOP_STAFF),
    mergeMap(action => deleteProductSizes(action.shopStaffInfo, action.productSizeIds).pipe(
        map(res => loadResult(SUCCEED_DELETE_PRODUCT_SIZES_BY_SHOP_STAFF, res.response)),
        catchError(error => of(occurError(FAIL_DELETE_PRODUCT_SIZES_BY_SHOP_STAFF, error.xhr)))
    ))
);
