import {EntityMixin} from "./Entity";
import {List} from "immutable";
import I18NLanguageArModelTransformInfo from "./I18NLanguageArModelTransformInfo";
import {findIndexById} from "../utils/ListUtils";
import {AR_MTIS_KEY, OPTIONS_KEY, UPDATED_PREFIX} from "../constants/CommonConstants";

const ArModelTransformInfoRecord = EntityMixin({
    uv_map_pos: 0,
    px: 0,
    py: 0,
    pz: 0,
    rx: 0,
    ry: 0,
    rz: 0,
    scale: 100,
    uv_map_pos_2nd: -1,
    px_2nd: 0,
    py_2nd: 0,
    pz_2nd: 0,
    rx_2nd: 0,
    ry_2nd: 0,
    rz_2nd: 0,
    scale_2nd: 100,
    i18n_languages: List(),
});

class ArModelTransformInfo extends ArModelTransformInfoRecord {

    constructor(args = {}) {
        super({
            ...args,
            i18n_languages: args.i18n_languages
                ? List(args.i18n_languages.map(item => new I18NLanguageArModelTransformInfo(item))) : List(),
        });
    }

    static makeNewbie(product) {
        return new ArModelTransformInfo().set(
            'i18n_languages',
            List(product.get('i18n_languages').map(item =>
                new I18NLanguageArModelTransformInfo({
                    language_code: item.get('language_code'), is_default: item.get('is_default'), name: 'Default',
                })
            ))
        );
    }

    canAdd() {
        return (this.uv_map_pos >= 0 && this.uv_map_pos < 468)
            && this.scale > 0
            && this.scale_2nd > 0
            && this.i18n_languages.size > 0 && this.i18n_languages.every(item => item.canAdd());
    }

    isSame(other) {
        if (!other) {
            return false;
        }

        return this.id === other.get('id')
            && this.uv_map_pos === other.get('uv_map_pos')
            && this.px === other.get('px')
            && this.py === other.get('py')
            && this.pz === other.get('pz')
            && this.rx === other.get('rx')
            && this.ry === other.get('ry')
            && this.rz === other.get('rz')
            && this.scale === other.get('scale')
            && this.uv_map_pos_2nd === other.get('uv_map_pos_2nd')
            && this.px_2nd === other.get('px_2nd')
            && this.py_2nd === other.get('py_2nd')
            && this.pz_2nd === other.get('pz_2nd')
            && this.rx_2nd === other.get('rx_2nd')
            && this.ry_2nd === other.get('ry_2nd')
            && this.rz_2nd === other.get('rz_2nd')
            && this.scale_2nd === other.get('scale_2nd')
            && (
                this.i18n_languages.size === other.i18n_languages.size
                && this.i18n_languages.every((item, index) => item.isSame(other.get('i18n_languages').get(index)))
            );
    }

    makeShouldUpdateData(oldbie, ar_mti_id) {
        if (!oldbie) {
            return ;
        }
        let payload = {
            ar_mti_id,
        };

        if (this.uv_map_pos !== oldbie.uv_map_pos) {
            payload.uv_map_pos = this.uv_map_pos;
        }

        if (this.px !== oldbie.px) {
            payload.px = this.px;
        }

        if (this.py !== oldbie.py) {
            payload.py = this.py;
        }

        if (this.pz !== oldbie.pz) {
            payload.pz = this.pz;
        }

        if (this.rx !== oldbie.rx) {
            payload.rx = this.rx;
        }

        if (this.ry !== oldbie.ry) {
            payload.ry = this.ry;
        }

        if (this.rz !== oldbie.rz) {
            payload.rz = this.rz;
        }

        if (this.scale !== oldbie.scale) {
            payload.scale = this.scale;
        }

        if (this.uv_map_pos_2nd !== oldbie.uv_map_pos_2nd) {
            payload.uv_map_pos_2nd = this.uv_map_pos_2nd;
        }

        if (this.px_2nd !== oldbie.px_2nd) {
            payload.px_2nd = this.px_2nd;
        }

        if (this.py_2nd !== oldbie.py_2nd) {
            payload.py_2nd = this.py_2nd;
        }

        if (this.pz_2nd !== oldbie.pz_2nd) {
            payload.pz_2nd = this.pz_2nd;
        }

        if (this.rx_2nd !== oldbie.rx_2nd) {
            payload.rx_2nd = this.rx_2nd;
        }

        if (this.ry_2nd !== oldbie.ry_2nd) {
            payload.ry_2nd = this.ry_2nd;
        }

        if (this.rz_2nd !== oldbie.rz_2nd) {
            payload.rz_2nd = this.rz_2nd;
        }

        if (this.scale_2nd !== oldbie.scale_2nd) {
            payload.scale_2nd = this.scale_2nd;
        }

        return payload;
    }

    makeFormData(optionIndex, index, formData, oldbieProductOption = null) {
        let oldbieItem = null;

        if (oldbieProductOption !== null) {
            const oldbieItems = oldbieProductOption.get('armtis');
            const indexOfOld = findIndexById(oldbieItems, this);

            oldbieItem = indexOfOld >= 0 ? oldbieItems.get(indexOfOld) : null;
        }

        const shouldUpdate = oldbieItem != null;
        const arMtiIndex = shouldUpdate ? this.id : index;
        const keyPrefix = shouldUpdate
            ? `${UPDATED_PREFIX}${OPTIONS_KEY}${optionIndex}-${AR_MTIS_KEY}${arMtiIndex}-`
            : `${OPTIONS_KEY}${optionIndex}-${AR_MTIS_KEY}${arMtiIndex}-`;
        let isItemAdded = false;

        if (shouldUpdate && oldbieItem) {
            if (this.uv_map_pos !== oldbieItem.get('uv_map_pos')) {
                formData.append(`${keyPrefix}uv_map_pos`, `${this.uv_map_pos}`);
            }
        } else {
            isItemAdded = true;
            formData.append(`${keyPrefix}uv_map_pos`, `${this.uv_map_pos}`);
        }

        if (shouldUpdate && oldbieItem) {
            if (this.px !== oldbieItem.get('px')) {
                formData.append(`${keyPrefix}px`, `${this.px}`);
            }
        } else {
            isItemAdded = true;
            formData.append(`${keyPrefix}px`, `${this.px}`);
        }

        if (shouldUpdate && oldbieItem) {
            if (this.py !== oldbieItem.get('py')) {
                formData.append(`${keyPrefix}py`, `${this.py}`);
            }
        } else {
            isItemAdded = true;
            formData.append(`${keyPrefix}py`, `${this.py}`);
        }

        if (shouldUpdate && oldbieItem) {
            if (this.pz !== oldbieItem.get('pz')) {
                formData.append(`${keyPrefix}pz`, `${this.pz}`);
            }
        } else {
            isItemAdded = true;
            formData.append(`${keyPrefix}pz`, `${this.pz}`);
        }

        if (shouldUpdate && oldbieItem) {
            if (this.rx !== oldbieItem.get('rx')) {
                formData.append(`${keyPrefix}rx`, `${this.rx}`);
            }
        } else {
            isItemAdded = true;
            formData.append(`${keyPrefix}rx`, `${this.rx}`);
        }

        if (shouldUpdate && oldbieItem) {
            if (this.ry !== oldbieItem.get('ry')) {
                formData.append(`${keyPrefix}ry`, `${this.ry}`);
            }
        } else {
            isItemAdded = true;
            formData.append(`${keyPrefix}ry`, `${this.ry}`);
        }

        if (shouldUpdate && oldbieItem) {
            if (this.rz !== oldbieItem.get('rz')) {
                formData.append(`${keyPrefix}rz`, `${this.rz}`);
            }
        } else {
            isItemAdded = true;
            formData.append(`${keyPrefix}rz`, `${this.rz}`);
        }

        if (shouldUpdate && oldbieItem) {
            if (this.scale !== oldbieItem.get('scale')) {
                formData.append(`${keyPrefix}scale`, `${this.scale}`);
            }
        } else {
            isItemAdded = true;
            formData.append(`${keyPrefix}scale`, `${this.scale}`);
        }

        if (shouldUpdate && oldbieItem) {
            if (this.uv_map_pos_2nd !== oldbieItem.get('uv_map_pos_2nd')) {
                formData.append(`${keyPrefix}uv_map_pos_2nd`, `${this.uv_map_pos_2nd}`);
            }
        } else {
            isItemAdded = true;
            formData.append(`${keyPrefix}uv_map_pos_2nd`, `${this.uv_map_pos_2nd}`);
        }

        if (shouldUpdate && oldbieItem) {
            if (this.px_2nd !== oldbieItem.get('px_2nd')) {
                formData.append(`${keyPrefix}px_2nd`, `${this.px_2nd}`);
            }
        } else {
            isItemAdded = true;
            formData.append(`${keyPrefix}px_2nd`, `${this.px_2nd}`);
        }

        if (shouldUpdate && oldbieItem) {
            if (this.py_2nd !== oldbieItem.get('py_2nd')) {
                formData.append(`${keyPrefix}py_2nd`, `${this.py_2nd}`);
            }
        } else {
            isItemAdded = true;
            formData.append(`${keyPrefix}py_2nd`, `${this.py_2nd}`);
        }

        if (shouldUpdate && oldbieItem) {
            if (this.pz_2nd !== oldbieItem.get('pz_2nd')) {
                formData.append(`${keyPrefix}pz_2nd`, `${this.pz_2nd}`);
            }
        } else {
            isItemAdded = true;
            formData.append(`${keyPrefix}pz_2nd`, `${this.pz_2nd}`);
        }

        if (shouldUpdate && oldbieItem) {
            if (this.rx_2nd !== oldbieItem.get('rx_2nd')) {
                formData.append(`${keyPrefix}rx_2nd`, `${this.rx_2nd}`);
            }
        } else {
            isItemAdded = true;
            formData.append(`${keyPrefix}rx_2nd`, `${this.rx_2nd}`);
        }

        if (shouldUpdate && oldbieItem) {
            if (this.ry_2nd !== oldbieItem.get('ry_2nd')) {
                formData.append(`${keyPrefix}ry_2nd`, `${this.ry_2nd}`);
            }
        } else {
            isItemAdded = true;
            formData.append(`${keyPrefix}ry_2nd`, `${this.ry_2nd}`);
        }

        if (shouldUpdate && oldbieItem) {
            if (this.rz_2nd !== oldbieItem.get('rz_2nd')) {
                formData.append(`${keyPrefix}rz_2nd`, `${this.rz_2nd}`);
            }
        } else {
            isItemAdded = true;
            formData.append(`${keyPrefix}rz_2nd`, `${this.rz_2nd}`);
        }

        if (shouldUpdate && oldbieItem) {
            if (this.scale_2nd !== oldbieItem.get('scale_2nd')) {
                formData.append(`${keyPrefix}scale_2nd`, `${this.scale_2nd}`);
            }
        } else {
            isItemAdded = true;
            formData.append(`${keyPrefix}scale_2nd`, `${this.scale_2nd}`);
        }

        this.i18n_languages.forEach(item => {
            item.makeFormData(optionIndex, arMtiIndex, formData, oldbieItem);
        });

        return !shouldUpdate && isItemAdded;
    }
}

export default ArModelTransformInfo;
