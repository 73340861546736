import {EntityMixin} from "./Entity";
import {List} from "immutable";
import ImageData from "./ImageData";
import {LANG_KOREAN, REPUBLIC_OF_KOREA} from "../utils/LocaleUtils";
import I18NLanguageShop from "./I18NLanguageShop";
import Plugin from "./Plugin";
import Category from "./Category";
import PricingPaymentHistory from "./PricingPaymentHistory";

export const ENABLE_EDIT_SHOP_ID_LIST_ON_ADMIN_PRODUCT_EDIT = List([2, 5, 7, 10, 11, 15]);

const ShopRecord = EntityMixin({
    name: '',
    image: null,
    imageData: null,
    path: '',
    uid: '',
    country_code: REPUBLIC_OF_KOREA,
    language_code: LANG_KOREAN,
    plugin: null,
    i18n_languages: List(),
    categories: List(),
    marketing_usage: List(),
    current_pricing_payment_history: new PricingPaymentHistory(),
    last_pricing_payment_history: new PricingPaymentHistory(),
});

class Shop extends ShopRecord {

    constructor(args = {}) {
        super({
            ...args,
            imageData: args.imageData ? new ImageData(args.imageData) : null,
            plugin: args.plugin ? new Plugin(args.plugin) : null,
            i18n_languages: args.i18n_languages
                ? List(args.i18n_languages.map(item => new I18NLanguageShop(item))) : List(),
            categories: args.categories ? List(args.categories.map(item => new Category(item))) : List(),
            marketing_usage: args.marketing_usage ? List(args.marketing_usage) : List(),
            current_pricing_payment_history: args.current_pricing_payment_history
                ? new PricingPaymentHistory(args.current_pricing_payment_history) : new PricingPaymentHistory(),
            last_pricing_payment_history: args.last_pricing_payment_history
                ? new PricingPaymentHistory(args.last_pricing_payment_history) : new PricingPaymentHistory(),
        });
    }

    getDefaultLanguageCode() {
        let result = LANG_KOREAN;

        this.i18n_languages.some(item => {
            if (item.get('is_default')) {
                result = item.get('language_code');
                return true;
            } else {
                return false;
            }
        });

        return result;
    }

    canEdit(oldbie) {
        if (!oldbie) {
            return false;
        }

        let canI18nLanguagesEdit = false;
        const oldbieI18nLanguages = oldbie.get('i18n_languages');

        if (this.i18n_languages.equals(oldbieI18nLanguages)) {
            let canAllAdd = true;

            for (let i=0; i < this.i18n_languages.size; i++) {
                const item = this.i18n_languages.get(i);

                if (!item.canAdd()) {
                    canAllAdd = false;
                    break;
                }

                if (!canI18nLanguagesEdit) {
                    canI18nLanguagesEdit = !item.isSame(oldbieI18nLanguages.get(i));
                }
            }

            if (!canAllAdd) {
                canI18nLanguagesEdit = false;
            }
        } else {
            canI18nLanguagesEdit = this.i18n_languages.every(item => item.canAdd());
        }

        return (this.country_code && this.country_code !== oldbie.get('country_code'))
            || this.imageData
            || canI18nLanguagesEdit;
    }

    makeFormData(oldbie = null) {
        const formData = new FormData();

        if (this.country_code) {
            if (!oldbie || this.country_code !== oldbie.get('country_code')) {
                formData.append('country_code', this.country_code);
            }
        }
        if (this.imageData) {
            formData.append('image', this.imageData.get('file'));
        }

        this.i18n_languages.forEach(item => {
            item.makeFormData(formData, oldbie)
        });

        return formData;
    }

    enableEditByShopId() {
        return ENABLE_EDIT_SHOP_ID_LIST_ON_ADMIN_PRODUCT_EDIT.includes(this.id);
    }
}

export default Shop;
