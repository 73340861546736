import {applyMiddleware, createStore} from "redux";
import {createWrapper} from "next-redux-wrapper";
import {createEpicMiddleware} from "redux-observable";
import {rootReducer} from "../reducers";
import {deserialize} from "../reducers/deserializer";
import rootEpic from "../epics";
import {isProduction} from "../../utils/EnvironmentUtils";
import {composeWithDevTools} from "redux-devtools-extension/developmentOnly";

function initStore(context) {
    const epicMiddleware = createEpicMiddleware();
    let reduxMiddleware;

    if (isProduction()) {
        reduxMiddleware = applyMiddleware(epicMiddleware);
    } else {
        reduxMiddleware = applyMiddleware(epicMiddleware);
        // reduxMiddleware = applyMiddleware(epicMiddleware, createLogger({collapsed: true}));
    }

    const store = createStore(rootReducer, composeWithDevTools(reduxMiddleware));

    epicMiddleware.run(rootEpic);

    return store;
}

export const wrapper = createWrapper(initStore, {
    serializeState: state => state.toJS(),
    deserializeState: state => deserialize(state),
});
