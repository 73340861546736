import {List, Record} from "immutable";
import {EntityMixin} from "./Entity";
import {REPUBLIC_OF_KOREA} from "../utils/LocaleUtils";

const ProductPricingItemRecord = EntityMixin({
    price_won: 0,
    price_dollar: 0,
    discount_price_won: 0,
    discount_price_dollar: 0,
})

export class ProductPricingItem extends ProductPricingItemRecord {

    constructor(args = {}) {
        super({...args});
    }

    getPriceByCountryCode(countryCode) {
        if (countryCode === REPUBLIC_OF_KOREA) {
            return this.discount_price_won > 0 ? this.discount_price_won : this.price_won;
        } else {
            return this.discount_price_dollar > 0 ? this.discount_price_dollar : this.price_dollar;
        }
    }
}

const ProductCategoryPriceRecord = Record({
    eyewear: new ProductPricingItem(),
    jewelry: new ProductPricingItem(),
})

export class ProductCategoryPrice extends ProductCategoryPriceRecord {

    constructor(args = {}) {
        super({
            ...args,
            eyewear: args.eyewear ? new ProductPricingItem(args.eyewear): new ProductPricingItem(),
            jewelry: args.jewelry ? new ProductPricingItem(args.jewelry): new ProductPricingItem(),
        });
    }
}

const PricingFeatureItemRecord = Record({
    ko: List(),
    en: List(),
});

export class PricingFeatureItem extends PricingFeatureItemRecord {
    constructor(args = {}) {
        super({...args});
    }
}
