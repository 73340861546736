import {List, Map, Set} from "immutable";
import SelectItem from "../models/ui/SelectItem";
import Category from "../models/Category";

export function reduceCategories(categories, level = 0) {
    if (!categories) {
        return List();
    }

    return categories.reduce((prev, cur) => {
        if (cur.level === level) {
            return prev.push(new Category(cur));
        } else {
            return prev;
        }
    }, List());
}

export function deleteLanguageInProduct(index, i18nLanguages, deleteI18nLanguageCodes = null) {
    if (index >= 0) {
        const languageCode = i18nLanguages.get(index).get('language_code');

        if (deleteI18nLanguageCodes !== null) {
            deleteI18nLanguageCodes = deleteI18nLanguageCodes.add(languageCode);
        }

        i18nLanguages = i18nLanguages.delete(index);
    }
    return {i18nLanguages, deleteI18nLanguageCodes};
}

export function changeDefaultLanguageInProduct(i18nLanguages, defaultLanguageCode) {
    return List(i18nLanguages.map(item => item.set('is_default', item.get('language_code') === defaultLanguageCode)));
}

export function deleteCountryInProduct(index, i18nCountries, deleteI18nCountryCodes = null) {
    if (index >= 0) {
        const countryCode = i18nCountries.get(index).get('country_code');

        if (deleteI18nCountryCodes !== null) {
            deleteI18nCountryCodes = deleteI18nCountryCodes.add(countryCode);
        }

        i18nCountries = i18nCountries.delete(index);
    }
    return {i18nCountries, deleteI18nCountryCodes};
}

export function changeDefaultCountryInProduct(i18nCountries, defaultCountryCode) {
    return List(i18nCountries.map(item => item.set('is_default', item.get('country_code') === defaultCountryCode)));
}

export function makeProductAttrs(product) {
    if (!product) {
        return Map();
    }

    return product.get('options').reduce(
        (previousValue, currentValue) => {
            let sizes = previousValue.get('sizes');
            let colors = previousValue.get('colors');
            const currentSize = currentValue.get('size');
            const currentColor = currentValue.get('color');
            const sizeItem = new SelectItem({value: `${currentSize.get('id')}`, name: currentSize.get('name')});
            const colorItem = new SelectItem({value: `${currentColor.get('id')}`, name: currentColor.get('name')});

            if (!sizes.includes(sizeItem)) {
                sizes = sizes.push(sizeItem);
            }

            if (!colors.includes(colorItem)) {
                colors = colors.push(colorItem);
            }

            return previousValue.set('sizes', sizes).set('colors', colors);
        },
        Map({'sizes': List(), 'colors': List()})
    );
}

export function mergeBuilderProduct2Product(
    product,
    defaultLanguageCode,
    defaultCountryCode,
    builderProduct,
    builderOptions,
    deleteLanguageCodes = null,
    deleteCountryCodes = null,
) {
    deleteLanguageCodes = product.get('i18n_languages').reduce((prev, cur) => {
        const languageCode = cur.get('language_code');

        if (prev) {
            return prev.add(languageCode);
        } else {
            return Set([languageCode]);
        }
    }, deleteLanguageCodes);

    deleteCountryCodes = product.get('i18n_countries').reduce((prev, cur) => {
        const countryCode = cur.get('country_code');

        if (prev) {
            return prev.add(countryCode);
        } else {
            return Set([countryCode]);
        }
    }, deleteCountryCodes);

    const builderI18nLanguages = builderProduct.get('i18n_languages');
    builderI18nLanguages.forEach(item => {
        const languageCode = item.get('language_code');

        if (item.get('is_default')) {
            defaultLanguageCode = languageCode;
        }

        deleteLanguageCodes = deleteLanguageCodes.delete(languageCode);
    });

    const builderI18nCountries = builderProduct.get('i18n_countries');
    builderI18nCountries.forEach(item => {
        const countryCode = item.get('country_code');

        if (item.get('is_default')) {
            defaultCountryCode = countryCode;
        }

        deleteCountryCodes = deleteCountryCodes.delete(countryCode);
    });

    product = product.set('uid', builderProduct.get('uid'))
        .set('rep_image', builderProduct.get('rep_image_url'))
        .set('rep_image_url', builderProduct.get('rep_image_url'))
        .set('repImageData', null)
        .set('i18n_languages', builderI18nLanguages)
        .set('i18n_countries', builderI18nCountries);

    if (builderOptions.size > 0) {
        const options = product.get('options');
        let newOptions = options.filter((option, index) => {
            return option.get('uid') && options.findIndex(option2 => {
                return option.get('uid') === option2.get('uid')
            }) === index
        });

        builderOptions.forEach(builderOption => {
            const indexOfOption = newOptions.findIndex(option => option.get('uid') === builderOption.get('uid'));

            if (indexOfOption >= 0) {
                newOptions = newOptions.update(indexOfOption, option =>
                    option.set('uid', builderOption.get('uid'))
                        .set('quantity', builderOption.get('quantity'))
                        .set('i18n_languages', builderOption.get('i18n_languages'))
                        .set('i18n_countries', builderOption.get('i18n_countries'))
                );
            } else {
                newOptions = newOptions.push(builderOption);
            }
        });

        product = product.set('options', newOptions);
    }

    return [product, defaultLanguageCode, defaultCountryCode, deleteLanguageCodes, deleteCountryCodes];
}
