import {ofType} from "redux-observable";
import {
    FAIL_CREATE_PRODUCT_COLOR_BY_SHOP_STAFF,
    FAIL_DELETE_PRODUCT_COLORS_BY_SHOP_STAFF,
    FAIL_GET_PRODUCT_COLOR_BY_SHOP_STAFF,
    FAIL_INIT_SHOP_STAFF_PRODUCT_COLOR_ADD,
    FAIL_UPDATE_PRODUCT_COLOR_BY_SHOP_STAFF,
    REQUEST_CREATE_PRODUCT_COLOR_BY_SHOP_STAFF,
    REQUEST_DELETE_PRODUCT_COLORS_BY_SHOP_STAFF,
    REQUEST_GET_PRODUCT_COLOR_BY_SHOP_STAFF,
    REQUEST_GET_PRODUCT_COLORS_BY_SHOP_STAFF,
    REQUEST_INIT_SHOP_STAFF_PRODUCT_COLOR_ADD,
    REQUEST_UPDATE_PRODUCT_COLOR_BY_SHOP_STAFF,
    SUCCEED_CREATE_PRODUCT_COLOR_BY_SHOP_STAFF,
    SUCCEED_DELETE_PRODUCT_COLORS_BY_SHOP_STAFF,
    SUCCEED_GET_PRODUCT_COLOR_BY_SHOP_STAFF,
    SUCCEED_UPDATE_PRODUCT_COLOR_BY_SHOP_STAFF,
} from "../../actions/RESTActionTypes";
import {catchError, map, mergeMap} from "rxjs/operators";
import {
    createProductColor,
    deleteProductColors,
    getProductColor,
    getProductColors,
    getShop,
    updateProductColor,
} from "../../../rest/ShopStaffsAPI";
import {loadResult, loadResults, occurError} from "../../actions/RESTAction";
import {of, zip} from "rxjs";
import {loadInitProductColorAdd} from "../../actions/ShopStaff/ProductColorAPIAction";

export const getProductColorsEpic = action$ => action$.pipe(
    ofType(REQUEST_GET_PRODUCT_COLORS_BY_SHOP_STAFF),
    mergeMap(action => getProductColors(action.shopStaffInfo, action.queryParams).pipe(
        map(res => loadResults(action.succeedType, res.response, action.queryParams.page)),
        catchError(error => of(occurError(action.failType, error.xhr)))
    ))
);

export const initShopStaffProductColorAddEpic = action$ => action$.pipe(
    ofType(REQUEST_INIT_SHOP_STAFF_PRODUCT_COLOR_ADD),
    mergeMap(action => zip(
        getShop(action.shopStaffInfo),
    ).pipe(
        map(([shopResponse]) =>
            loadInitProductColorAdd(shopResponse)
        ),
        catchError(error => of(occurError(FAIL_INIT_SHOP_STAFF_PRODUCT_COLOR_ADD, error.xhr)))
    ))
);

export const createProductColorEpic = action$ => action$.pipe(
    ofType(REQUEST_CREATE_PRODUCT_COLOR_BY_SHOP_STAFF),
    mergeMap(action => createProductColor(action.shopStaffInfo, action.formData).pipe(
        map(res => loadResult(SUCCEED_CREATE_PRODUCT_COLOR_BY_SHOP_STAFF, res.response)),
        catchError(error => of(occurError(FAIL_CREATE_PRODUCT_COLOR_BY_SHOP_STAFF, error.xhr)))
    ))
);

export const getProductColorEpic = action$ => action$.pipe(
    ofType(REQUEST_GET_PRODUCT_COLOR_BY_SHOP_STAFF),
    mergeMap(action => getProductColor(action.shopStaffInfo, action.productColorId).pipe(
        map(res => loadResult(SUCCEED_GET_PRODUCT_COLOR_BY_SHOP_STAFF, res.response)),
        catchError(error => of(occurError(FAIL_GET_PRODUCT_COLOR_BY_SHOP_STAFF, error.xhr)))
    ))
);

export const updateProductColorEpic = action$ => action$.pipe(
    ofType(REQUEST_UPDATE_PRODUCT_COLOR_BY_SHOP_STAFF),
    mergeMap(action => updateProductColor(action.shopStaffInfo, action.productColorId, action.formData).pipe(
        map(res => loadResult(SUCCEED_UPDATE_PRODUCT_COLOR_BY_SHOP_STAFF, res.response)),
        catchError(error => of(occurError(FAIL_UPDATE_PRODUCT_COLOR_BY_SHOP_STAFF, error.xhr)))
    ))
);

export const deleteProductColorsEpic = action$ => action$.pipe(
    ofType(REQUEST_DELETE_PRODUCT_COLORS_BY_SHOP_STAFF),
    mergeMap(action => deleteProductColors(action.shopStaffInfo, action.productColorIds).pipe(
        map(res => loadResult(SUCCEED_DELETE_PRODUCT_COLORS_BY_SHOP_STAFF, res.response)),
        catchError(error => of(occurError(FAIL_DELETE_PRODUCT_COLORS_BY_SHOP_STAFF, error.xhr)))
    ))
);
