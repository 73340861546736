import {EntityMixin} from "./Entity";
import {List, Map, Record} from "immutable";
import PluginTemplate from "./PluginTemplate";
import {WEB_BUILDER_CAFE24, WEB_BUILDER_SHOPIFY} from "../constants/PluginConstants";

const SkinSmoothingFilterRecord = Record({
    kernel_size: 0.1,
    exponent: 20,
    color_adj_brightness: 0.08,
    color_adj_contrast: 0.0,
    color_adj_saturation: 0.0,
    color_adj_vibrance: 0.0,
});

export class SkinSmoothingFilter extends SkinSmoothingFilterRecord {

    constructor(args = {}) {
        super({...args});
    }

    equals(other) {
        if (!other) {
            return false;
        }

        return this.kernel_size === other.get('kernel_size')
            && this.exponent === other.get('exponent')
            && this.color_adj_brightness === other.get('color_adj_brightness')
            && this.color_adj_contrast === other.get('color_adj_contrast')
            && this.color_adj_saturation === other.get('color_adj_saturation')
            && this.color_adj_vibrance === other.get('color_adj_vibrance')
    }

    makeShouldUpdateData(oldbie) {
        if (!oldbie) {
            return;
        }

        let payload = {};

        if (this.kernel_size !== oldbie.kernel_size) {
            payload.kernel_size = this.kernel_size;
        }

        if (this.exponent !== oldbie.exponent) {
            payload.exponent = this.exponent;
        }

        if (this.color_adj_brightness !== oldbie.color_adj_brightness) {
            payload.color_adj_brightness = this.color_adj_brightness;
        }

        if (this.color_adj_contrast !== oldbie.color_adj_contrast) {
            payload.color_adj_contrast = this.color_adj_contrast;
        }

        if (this.color_adj_saturation !== oldbie.color_adj_saturation) {
            payload.color_adj_saturation = this.color_adj_saturation;
        }

        if (this.color_adj_vibrance !== oldbie.color_adj_vibrance) {
            payload.color_adj_vibrance = this.color_adj_vibrance;
        }

        return payload;
    }
}

const PluginRecord = EntityMixin({
    shop_id: 0,
    is_ind_server_ready: false,
    is_test_mode: true,
    app_id: '',
    builder: '',
    builder_mall_id: '',
    builder_shop_no: '',
    gtm_container_id: '',
    included_paths: List(),
    builder_script_id_items: Map(),
    builder_webhook_id_items: Map(),
    skin_smoothing_filter: new SkinSmoothingFilter(),
    template: new PluginTemplate(),
});

class Plugin extends PluginRecord {

    constructor(args = {}) {
        super({
            ...args,
            included_paths: args.included_paths ? List(args.included_paths) : List(),
            builder_script_id_items: args.builder_script_id_items ? Map(args.builder_script_id_items) : Map(),
            builder_webhook_id_items: args.builder_webhook_id_items ? Map(args.builder_webhook_id_items) : Map(),
            skin_smoothing_filter: args.skin_smoothing_filter
                ? new SkinSmoothingFilter(args.skin_smoothing_filter) : new SkinSmoothingFilter(),
            template: args.template ? new PluginTemplate(args.template) : new PluginTemplate(),
        });
    }

    isBuilderScripttagInstalled(categoryUid) {
        if (this.builder !== WEB_BUILDER_CAFE24) {
            return true;
        } else if (this.builder === WEB_BUILDER_CAFE24 && Boolean(this.builder_script_id_items.get(categoryUid))) {
            return true;
        } else {
            return false;
        }
    }

    isSameShop(builder, mallId, shopNo = null) {
        if (!this.builder || !this.builder_mall_id) {
            return false;
        }

        const isSame = this.builder === builder && this.builder_mall_id === mallId;

        if (this.builder === WEB_BUILDER_CAFE24) {
            return isSame && `${this.builder_shop_no}` === `${shopNo}`;
        } else {
            return isSame;
        }
    }

    canEdit(oldbie) {
        if (!oldbie) {
            return false;
        }

        return this.is_test_mode !== oldbie.get('is_test_mode') || this.template.canEdit(oldbie.get('template'));
    }

    makeFormData(oldbie = null) {
        const formData = new FormData();

        if (!oldbie || this.is_test_mode !== oldbie.get('is_test_mode')) {
            formData.append('is_test_mode', this.is_test_mode ? '1' : '0');
        }

        if (this.gtm_container_id) {
            if (!oldbie || this.gtm_container_id !== oldbie.get('gtm_container_id')) {
                formData.append('gtm_container_id', this.gtm_container_id);
            }
        }

        if (this.included_paths.size > 0) {
            formData.append('included_paths', this.included_paths.join('\n'));
        } else {
            formData.append('included_paths', '');
        }

        this.template.makeFormData(formData, oldbie);

        return formData;
    }
}

export default Plugin;
