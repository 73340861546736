import {fromJS, List, Set} from "immutable";
import {
    SHOP_STAFF_PRODUCT_COLORS_CLEAR_TASK_STATE,
    SHOP_STAFF_PRODUCT_COLORS_INIT_DATA,
    SHOP_STAFF_PRODUCT_COLORS_SET_SELECTED_ITEM,
} from "../../actions/UIActionTypes";
import {
    FAIL_DELETE_PRODUCT_COLORS_BY_SHOP_STAFF,
    FAIL_GET_PRODUCT_COLORS_BY_SHOP_STAFF,
    REQUEST_DELETE_PRODUCT_COLORS_BY_SHOP_STAFF,
    REQUEST_GET_PRODUCT_COLORS_BY_SHOP_STAFF,
    SUCCEED_DELETE_PRODUCT_COLORS_BY_SHOP_STAFF,
    SUCCEED_GET_PRODUCT_COLORS_BY_SHOP_STAFF,
} from "../../actions/RESTActionTypes";
import RestError from "../../../models/RestError";
import {QueryParameter} from "../../../models/ui/QueryParameter";
import ProductColor from "../../../models/ProductColor";

export const initialShopStaffProductColors = fromJS({
    isLoading: false,
    isInitialized: false,
    productColors: List(),
    count: 0,
    error: null,
    queryParams: new QueryParameter(),
    selectedItemIds: Set(),
    isTaskWorking: false,
    succeedTask: false,
    succeedDelete: false,
    errorTask: null,
});

export function shopStaffProductColors(state = initialShopStaffProductColors, action) {
    switch (action.type) {
        case SHOP_STAFF_PRODUCT_COLORS_CLEAR_TASK_STATE:
            return state.set('isTaskWorking', initialShopStaffProductColors.get('isTaskWorking'))
                .set('succeedTask', initialShopStaffProductColors.get('succeedTask'))
                .set('succeedDelete', initialShopStaffProductColors.get('succeedDelete'))
                .set('errorTask', initialShopStaffProductColors.get('errorTask'));

        case SHOP_STAFF_PRODUCT_COLORS_INIT_DATA:
            return state.set('queryParams', action.queryParams);

        case REQUEST_GET_PRODUCT_COLORS_BY_SHOP_STAFF:
            return state.set('isLoading', true)
                .set('selectedItemIds', initialShopStaffProductColors.get('selectedItemIds'))
                .set('isTaskWorking', initialShopStaffProductColors.get('isTaskWorking'))
                .set('succeedTask', initialShopStaffProductColors.get('succeedTask'))
                .set('succeedDelete', initialShopStaffProductColors.get('succeedDelete'))
                .set('errorTask', initialShopStaffProductColors.get('errorTask'));

        case SUCCEED_GET_PRODUCT_COLORS_BY_SHOP_STAFF:
            return state.set('isLoading', false)
                .set('isInitialized', true)
                .set('productColors', List(action.results.map(item => new ProductColor(item))))
                .set('count', action.count)
                .set('error', null);

        case FAIL_GET_PRODUCT_COLORS_BY_SHOP_STAFF:
            return state.set('isLoading', false)
                .set('productColors', List())
                .set('error', new RestError(action.error));

        case SHOP_STAFF_PRODUCT_COLORS_SET_SELECTED_ITEM:
            return state.set('selectedItemIds', action.selectedItemIds);

        case REQUEST_DELETE_PRODUCT_COLORS_BY_SHOP_STAFF:
            return state.set('isTaskWorking', true)
                .set('succeedTask', false)
                .set('succeedDelete', false)
                .set('errorTask', null);

        case SUCCEED_DELETE_PRODUCT_COLORS_BY_SHOP_STAFF:
            return state.set('isTaskWorking', false).set('succeedDelete', true).set('errorTask', null);

        case FAIL_DELETE_PRODUCT_COLORS_BY_SHOP_STAFF:
            return state.set('isTaskWorking', false)
                .set('succeedTask', false)
                .set('succeedDelete', false)
                .set('errorTask', new RestError(action.error));

        default:
            return state;
    }
}
