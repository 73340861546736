import {createHmac} from "../utils/CryptoUtils";
import {getLanguageAndCountryCode} from "../utils/LocaleUtils";

export const CONTENT_TYPE_JSON = 'application/json';
export const CONTENT_TYPE_FORM = 'application/x-www-form-urlencoded';

export function getBaseUrl() {
    return process.env.API_BASE_URL;
}

export function createBasicHeader(path, acceptLanguage = null) {
    const timestamp = Date.now();

    return {
        'X-Authorization': createHmac(path + timestamp),
        'X-Timestamp': timestamp.toString(),
        'Accept-Language': getAcceptLanguage(acceptLanguage),
    };
}

export function createManagerHeaders(path, managerInfo = null, contentType = null, acceptLanguage = null) {
    const headers = {
        ...createBasicHeader(path, acceptLanguage),
        'X-Manager-Id': managerInfo ? managerInfo.managerId || '' : '',
        'X-Manager-Auth-Token': managerInfo ? managerInfo.managerAuthToken || '' : '',
    };

    if (contentType) {
        return Object.assign(headers, {'Content-Type': contentType})
    } else {
        return headers;
    }
}

export function createShopStaffHeaders(path, shopStaffInfo = null, contentType = null, acceptLanguage = null) {
    const headers = {
        ...createBasicHeader(path, acceptLanguage),
        'X-Shop-Staff-Id': shopStaffInfo ? shopStaffInfo.shopStaffId || '' : '',
        'X-Shop-Staff-Auth-Token': shopStaffInfo ? shopStaffInfo.shopStaffAuthToken || '' : '',
        'X-Shop-Id': shopStaffInfo ? shopStaffInfo.shopStaffShopId || '' : '',
    };

    if (contentType) {
        return Object.assign(headers, {'Content-Type': contentType})
    } else {
        return headers;
    }
}

function getAcceptLanguage(acceptLanguage) {
    const [languageCode, countryCode] = getLanguageAndCountryCode();

    return `${languageCode}-${countryCode}`;
    // const defaultValue = acceptLanguage || 'ko-KR';
    //
    // if (typeof window === 'undefined') {
    //     return defaultValue;
    // }
    //
    // const navLanguage = window.navigator.language;
    //
    // return navLanguage ? navLanguage : defaultValue;
}
