import {createTheme} from "@mui/material/styles";
import {ACCENT_COLOR, CAFE24_BTN_COLOR, PRIMARY_COLOR, SECONDARY_COLOR} from "../constants/CssConstants";
import {LARGE_SIZE, MEDIUM_SIZE, SMALL_SIZE, XLARGE_SIZE} from "./BreakPoint";

const enFontFamilyList = [
    'Helvetica',
    '"Noto Sans KR"',
    'Roboto',
    'Arial',
    'sans-serif',
].join(',');

const koFontFamilyList = [
    '"Noto Sans KR"',
    'Roboto',
    'Helvetica',
    'Arial',
    'sans-serif',
].join(',');

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: SMALL_SIZE,
            md: MEDIUM_SIZE,
            lg: LARGE_SIZE,
            xl: XLARGE_SIZE,
        },
    },
    palette: {
        primary: {
            main: PRIMARY_COLOR,
            contrastText: "#ffffff"
        },
        secondary: {
            main: SECONDARY_COLOR,
            contrastText: "#ffffff",
        },
        accent: {
            main: ACCENT_COLOR,
        },
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
                * {
                    box-sizing: border-box;
                    -webkit-tap-highlight-color: transparent;
                }
                html {
                    height: 100%;
                }
              
                body {
                    margin: 0;
                    padding: env(safe-area-inset-top, 0px) env(safe-area-inset-right, 0px) 0px env(safe-area-inset-left, 0px);
                    font-weight: normal;
                    word-break: break-all;
                    white-space: pre-wrap;
                    background-color: #ffffff;
                    color: #000000;
                    height: 100%;
                }
                a {
                    text-decoration: none;
                    color: inherit;
                }
                img {
                    vertical-align: top;
                }
                button {
                    text-transform: none !important;
                }
                ul, ol {
                    list-style: none;
                    margin: 0;
                    padding: 0;
                }
                input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }
                input[type=number] {
                    -moz-appearance: textfield;
                }
                ::-ms-clear {
                    display: none;
                }
                #__next {
                    height: 100%;
                }
            `,
        },
        MuiToolbar: {
            styleOverrides: {
                gutters: {
                    [`@media (min-width: ${SMALL_SIZE}px)`]: {
                        paddingLeft: "24px",
                        paddingRight: "24px",
                    },
                    paddingLeft: "16px",
                    paddingRight: "16px",
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                contained: {
                    boxShadow: "unset",
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
            },
        },
        MuiMenu: {
            styleOverrides: {
                paper: {
                    maxHeight: '550px',
                    overflowY: 'scroll',
                },
            },
        },
    },
    spacing: factor => `${factor / 16}rem`,
});

export default theme;
