import {fromJS, Set} from "immutable";
import {
    FAIL_GET_PRODUCT_COLOR_BY_SHOP_STAFF,
    FAIL_UPDATE_PRODUCT_COLOR_BY_SHOP_STAFF,
    REQUEST_GET_PRODUCT_COLOR_BY_SHOP_STAFF,
    REQUEST_UPDATE_PRODUCT_COLOR_BY_SHOP_STAFF,
    SUCCEED_GET_PRODUCT_COLOR_BY_SHOP_STAFF,
    SUCCEED_UPDATE_PRODUCT_COLOR_BY_SHOP_STAFF,
} from "../../actions/RESTActionTypes";
import {
    SHOP_STAFF_PRODUCT_COLOR_EDIT_ADD_LANGUAGE_CODE,
    SHOP_STAFF_PRODUCT_COLOR_EDIT_CHANGE_LANGUAGE_INFO,
    SHOP_STAFF_PRODUCT_COLOR_EDIT_CLEAR_EDIT_STATE,
    SHOP_STAFF_PRODUCT_COLOR_EDIT_DELETE_LANGUAGE_CODE,
    SHOP_STAFF_PRODUCT_COLOR_EDIT_SET_DEFAULT_LANGUAGE,
    SHOP_STAFF_PRODUCT_COLOR_EDIT_CHANGE_PRODUCT_COLOR,
    SHOP_STAFF_PRODUCT_COLOR_EDIT_TOGGLE_EDIT_MODE,
} from "../../actions/UIActionTypes";
import {indexOfLanguageCode, updateListByKeyValue} from "../../../utils/ListUtils";
import {changeDefaultLanguageInProduct, deleteLanguageInProduct} from "../../../behaviors/ProductBehavior";
import RestError from "../../../models/RestError";
import Product from "../../../models/Product";
import I18NLanguageProduct from "../../../models/I18NLanguageProduct";
import ProductColor from "../../../models/ProductColor";

export const initialShopStaffProductColorEdit = fromJS({
    isLoading: false,
    isSaveAndEditContinueEditingMode: false,
    productColor: new ProductColor(),
    error: null,
    newbie: new ProductColor(),
    defaultLanguageCode: '',
    deleteLanguageCodes: Set(),
    isEditing: false,
    succeedEdit: false,
    errorEdit: null,
});

export function shopStaffProductColorEdit(state = initialShopStaffProductColorEdit, action) {
    let index = -1;
    let productColor, newbie, i18nLanguages;

    switch (action.type) {
        case SHOP_STAFF_PRODUCT_COLOR_EDIT_CLEAR_EDIT_STATE:
            return state.set('isEditing', initialShopStaffProductColorEdit.get('isEditing'))
                .set('succeedEdit', initialShopStaffProductColorEdit.get('succeedEdit'))
                .set('errorEdit', initialShopStaffProductColorEdit.get('errorEdit'));

        case REQUEST_GET_PRODUCT_COLOR_BY_SHOP_STAFF:
            return state.set('isLoading', true);

        case SUCCEED_GET_PRODUCT_COLOR_BY_SHOP_STAFF:
            productColor = new ProductColor(action.result);

            return state.set('isLoading', false)
                .set('productColor', productColor)
                .set('newbie', productColor)
                .set('defaultLanguageCode', productColor.findDefaultLanguageCode())
                .set('error', null);

        case FAIL_GET_PRODUCT_COLOR_BY_SHOP_STAFF:
            return state.set('isLoading', false)
                .set('productColor', new ProductColor())
                .set('newbie', new Product())
                .set('error', new RestError(action.error));

        case SHOP_STAFF_PRODUCT_COLOR_EDIT_CHANGE_PRODUCT_COLOR:
            return state.set('newbie', state.get('newbie').set(action.key, action.value));

        case SHOP_STAFF_PRODUCT_COLOR_EDIT_ADD_LANGUAGE_CODE:
            newbie = state.get('newbie');
            i18nLanguages = newbie.get('i18n_languages');
            index = indexOfLanguageCode(i18nLanguages, action.languageCode);

            if (index >= 0) {
                return state;
            }

            newbie = newbie.set(
                'i18n_languages',
                i18nLanguages.push(new I18NLanguageProduct({language_code: action.languageCode}))
            );

            return state.set('newbie', newbie);

        case SHOP_STAFF_PRODUCT_COLOR_EDIT_DELETE_LANGUAGE_CODE:
            newbie = state.get('newbie');
            i18nLanguages = newbie.get('i18n_languages');
            index = indexOfLanguageCode(i18nLanguages, action.languageCode);

            if (index >= 0) {
                const {i18nLanguages: newI18nLanguages, deleteI18nLanguageCodes} = deleteLanguageInProduct(
                    index, i18nLanguages, state.get('deleteLanguageCodes')
                );

                newbie = newbie.set('i18n_languages', newI18nLanguages);

                state = state.set('newbie', newbie).set('deleteLanguageCodes', deleteI18nLanguageCodes);
            }

            return state;

        case SHOP_STAFF_PRODUCT_COLOR_EDIT_SET_DEFAULT_LANGUAGE:
            newbie = state.get('newbie');

            return state.set(
                'newbie',
                newbie.set(
                    'i18n_languages', changeDefaultLanguageInProduct(newbie.get('i18n_languages'), action.languageCode)
                )
            )
                .set('defaultLanguageCode', action.languageCode);

        case SHOP_STAFF_PRODUCT_COLOR_EDIT_CHANGE_LANGUAGE_INFO:
            newbie = state.get('newbie');

            return state.set(
                'newbie',
                newbie.set(
                    'i18n_languages',
                    updateListByKeyValue(newbie.get('i18n_languages'), action.index, action.key, action.value)
                )
            );

        case REQUEST_UPDATE_PRODUCT_COLOR_BY_SHOP_STAFF:
            return state.set('isEditing', true).set('succeedEdit', false).set('errorEdit', null);

        case SUCCEED_UPDATE_PRODUCT_COLOR_BY_SHOP_STAFF:
            return state.set('isEditing', false).set('succeedEdit', true).set('errorEdit', null);

        case FAIL_UPDATE_PRODUCT_COLOR_BY_SHOP_STAFF:
            return state.set('isEditing', false)
                .set('succeedEdit', false)
                .set('errorEdit', new RestError(action.error));

        case SHOP_STAFF_PRODUCT_COLOR_EDIT_TOGGLE_EDIT_MODE:
            return state.set('isSaveAndEditContinueEditingMode', action.isSaveAndEditContinueEditingMode);
        default:
            return state;
    }
}
