export function loadResults(succeededType, response, page) {
    const {results, count, previous, next} = response;
    // let page = 1;
    //
    // if (previous) {
    //     const previousToken = previous.split('?');
    //     const queryParams = convertQueryParamStr2Map(previousToken);
    //
    //     page = queryParams.get('page') || 1;
    // }

    return {
        type: succeededType,
        results: results,
        count: count,
        hasMore: next !== null,
        page,
    };
}

export function loadResult(succeededType, response) {
    return {
        type: succeededType,
        result: response.result,
    };
}

export function occurError(type, errorXhr) {
    const {status, response} = errorXhr;

    if (status && response) {
        response.statusCode = status;

        return {type, error: response};
    } else {
        return {type, error: {statusCode: 500, code: '-1', message: 'A server error occurred.'}}
    }
}

export function occurCommonError(failedType, errorXhr) {
    let response = {code: -1, message: 'Error'};

    if (errorXhr) {
        response = errorXhr.response;
    }

    return {
        type: failedType,
        error: response,
    }
}

export function loadCmsAPI(succeedType, response) {
    return {
        type: succeedType,
        response: response,
    }
}