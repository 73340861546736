import {List} from "immutable";

export const WEB_BUILDER_OWN_MALL = 'own_mall';
export const WEB_BUILDER_CAFE24 = 'cafe24';
export const WEB_BUILDER_MAKESHOP = 'makeshop';
export const WEB_BUILDER_SIXSHOP = 'sixshop';
export const WEB_BUILDER_IMWEB = 'imweb';
export const WEB_BUILDER_WIX = 'wix';
export const WEB_BUILDER_SHOPIFY = 'shopify';
export const WEB_BUILDER_GODOMALL = 'godomall';
export const WEB_BUILDERS = List([
    WEB_BUILDER_CAFE24,
    WEB_BUILDER_MAKESHOP,
    WEB_BUILDER_SIXSHOP,
    WEB_BUILDER_IMWEB,
    WEB_BUILDER_WIX,
    WEB_BUILDER_SHOPIFY,
    WEB_BUILDER_GODOMALL,
]);

export const WEB_BUILDERS_FOR_IMPORT = List([
    WEB_BUILDER_CAFE24,
    WEB_BUILDER_SHOPIFY
])

export const TEMPLATE_FLOATING_BUTTON_IMAGE_WIDTH = 200;
export const TEMPLATE_FLOATING_BUTTON_IMAGE_HEIGHT = 200;
