import {fromJS, List} from "immutable";
import {
    FAIL_CREATE_IAMPORT_PAYMENT,
    FAIL_DELETE_PAYMENT_HISTORY_BY_SHOP_STAFF,
    FAIL_GET_SHOP_BY_SHOP_STAFF,
    FAIL_INITIALIZE_PRICING_BY_SHOP_STAFF,
    FAIL_PAY_IAMPORT_PRODUCT_CHARGE_BY_SHOP_STAFF,
    FAIL_PAY_IAMPORT_SCHEDULE_CHARGE_BY_SHOP_STAFF,
    FAIL_PAY_SHOPIFY_ANNUAL_CHARGE_BY_SHOP_STAFF,
    FAIL_PAY_SHOPIFY_PRODUCT_CHARGE_BY_SHOP_STAFF,
    FAIL_PAY_SHOPIFY_RECURRING_CHARGE_BY_SHOP_STAFF,
    FAIL_PAY_SHOPIFY_TRIAL_BY_SHOP_STAFF,
    FAIL_PAY_TRIAL_BY_SHOP_STAFF,
    FAIL_UPDATE_PAYMENT_HISTORY_BY_SHOP_STAFF,
    REQUEST_CREATE_IAMPORT_PAYMENT,
    REQUEST_DELETE_PAYMENT_HISTORY_BY_SHOP_STAFF,
    REQUEST_GET_SHOP_BY_SHOP_STAFF,
    REQUEST_INITIALIZE_PRICING_BY_SHOP_STAFF,
    REQUEST_PAY_IAMPORT_PRODUCT_CHARGE_BY_SHOP_STAFF,
    REQUEST_PAY_IAMPORT_SCHEDULE_CHARGE_BY_SHOP_STAFF,
    REQUEST_PAY_SHOPIFY_ANNUAL_CHARGE_BY_SHOP_STAFF,
    REQUEST_PAY_SHOPIFY_PRODUCT_CHARGE_BY_SHOP_STAFF,
    REQUEST_PAY_SHOPIFY_RECURRING_CHARGE_BY_SHOP_STAFF,
    REQUEST_PAY_SHOPIFY_TRIAL_BY_SHOP_STAFF,
    REQUEST_PAY_TRIAL_BY_SHOP_STAFF,
    REQUEST_UPDATE_PAYMENT_HISTORY_BY_SHOP_STAFF,
    SUCCEED_CREATE_IAMPORT_PAYMENT,
    SUCCEED_DELETE_PAYMENT_HISTORY_BY_SHOP_STAFF,
    SUCCEED_GET_SHOP_BY_SHOP_STAFF,
    SUCCEED_INITIALIZE_PRICING_BY_SHOP_STAFF,
    SUCCEED_PAY_IAMPORT_PRODUCT_CHARGE_BY_SHOP_STAFF,
    SUCCEED_PAY_IAMPORT_SCHEDULE_CHARGE_BY_SHOP_STAFF,
    SUCCEED_PAY_SHOPIFY_ANNUAL_CHARGE_BY_SHOP_STAFF,
    SUCCEED_PAY_SHOPIFY_PRODUCT_CHARGE_BY_SHOP_STAFF,
    SUCCEED_PAY_SHOPIFY_RECURRING_CHARGE_BY_SHOP_STAFF,
    SUCCEED_PAY_SHOPIFY_TRIAL_BY_SHOP_STAFF,
    SUCCEED_PAY_TRIAL_BY_SHOP_STAFF,
    SUCCEED_UPDATE_PAYMENT_HISTORY_BY_SHOP_STAFF
} from "../actions/RESTActionTypes";
import Shop from "../../models/Shop";
import RestError from "../../models/RestError";
import {
    PRICING_CHANGE_PRODUCT_SIZE,
    PRICING_CLEAR_HANDLER_STATE,
    PRICING_CLEAR_PAYMENT_HISTORY_STATE,
    PRICING_SELECT_SELECTED_PRODUCT_CATEGORY_UID,
} from "../actions/UIActionTypes";
import Pricing from "../../models/Pricing";
import PricingPaymentHistory from "../../models/PricingPaymentHistory";

export const initializeShopStaffPricing = fromJS({
    isLoading: false,
    shop: null,
    error: null,
    pricingList: List(),
    selectedCategoryUid: '',
    productSize: 1,
    isLoadingPayCharge: false,
    isSucceedPayCharge: false,
    isFailPayCharge: false,
    shopifyConfirmationUrl: null,
    isLoadingIamportCharge: false,
    isSucceedCreateIamportPayment: false,
    isErrorCreateIamportPayment: false,
    isSucceedCreatePaymentHistory: false,
    isErrorCreatePaymentHistory: false,
    isSucceedTrialPlan: false,
    isErrorTrialPlan: false,
    paymentHtml: null,
    createdPaymentHistory: null,
    isSucceedByIamportModule: false,
    isErrorByIamportModule: false,
});

export function pricingReducer(state = initializeShopStaffPricing, action) {
    let pricingList;
    let shopifyAppCharge;
    let createdPaymentHistory;

    switch (action.type) {

        case REQUEST_GET_SHOP_BY_SHOP_STAFF:
            return state.set('isLoading', true);

        case SUCCEED_GET_SHOP_BY_SHOP_STAFF:
            const shop = new Shop(action.result);
            return state.set('shop', shop)
                .set('isLoading', false);

        case FAIL_GET_SHOP_BY_SHOP_STAFF:
            return state.set('error', new RestError(action.error))
                .set('isLoading', false);

        case REQUEST_INITIALIZE_PRICING_BY_SHOP_STAFF:
            return state.set('isLoading', true);

        case SUCCEED_INITIALIZE_PRICING_BY_SHOP_STAFF:
            const shopCategory = state.getIn(['shop', 'categories', '0']);
            pricingList = List(action.results.map(item => new Pricing(item)));

            return state.set('isLoading', false)
                .set('pricingList', pricingList)
                .set('selectedCategoryUid', shopCategory.get('uid'));

        case FAIL_INITIALIZE_PRICING_BY_SHOP_STAFF:
            return state.set('isLoading', false)
                .set('error', new RestError(action.error));

        case REQUEST_PAY_SHOPIFY_TRIAL_BY_SHOP_STAFF:
            return state.set('isLoadingPayCharge', true);

        case SUCCEED_PAY_SHOPIFY_TRIAL_BY_SHOP_STAFF:
            return state.set('isLoadingPayCharge', false)
                .set('isSucceedPayCharge', true)
                .set('shopifyConfirmationUrl', null);

        case FAIL_PAY_SHOPIFY_TRIAL_BY_SHOP_STAFF:
            return state.set('isLoadingPayCharge', false)
                .set('error', new RestError(action.error))
                .set('isFailPayCharge', true);

        case REQUEST_PAY_SHOPIFY_RECURRING_CHARGE_BY_SHOP_STAFF:
            return state.set('isLoadingPayCharge', true);

        case SUCCEED_PAY_SHOPIFY_RECURRING_CHARGE_BY_SHOP_STAFF:
            const {shopify_recurring_app_charge} = action.result;

            return state.set('isLoadingPayCharge', false)
                .set('isSucceedPayCharge', true)
                .set('shopifyConfirmationUrl', shopify_recurring_app_charge.confirmation_url || '');

        case FAIL_PAY_SHOPIFY_RECURRING_CHARGE_BY_SHOP_STAFF:
            return state.set('isLoadingPayCharge', false)
                .set('error', new RestError(action.error))
                .set('isFailPayCharge', true);

        case REQUEST_PAY_SHOPIFY_ANNUAL_CHARGE_BY_SHOP_STAFF:
            return state.set('isLoadingPayCharge', true);

        case SUCCEED_PAY_SHOPIFY_ANNUAL_CHARGE_BY_SHOP_STAFF:
            shopifyAppCharge = action.result.shopify_app_charge;

            return state.set('isLoadingPayCharge', false)
                .set('isSucceedPayCharge', true)
                .set('shopifyConfirmationUrl', shopifyAppCharge ? shopifyAppCharge.confirmation_url || '' : '');

        case FAIL_PAY_SHOPIFY_ANNUAL_CHARGE_BY_SHOP_STAFF:
            return state.set('isLoadingPayCharge', false)
                .set('error', new RestError(action.error))
                .set('isFailPayCharge', true);

        case REQUEST_PAY_SHOPIFY_PRODUCT_CHARGE_BY_SHOP_STAFF:
            return state.set('isLoadingPayCharge', true);

        case SUCCEED_PAY_SHOPIFY_PRODUCT_CHARGE_BY_SHOP_STAFF:
            shopifyAppCharge = action.result.shopify_app_charge;

            return state.set('isLoadingPayCharge', false)
                .set('isSucceedPayCharge', true)
                .set('shopifyConfirmationUrl', shopifyAppCharge ? shopifyAppCharge.confirmation_url || '' : '');

        case FAIL_PAY_SHOPIFY_PRODUCT_CHARGE_BY_SHOP_STAFF:
            return state.set('isLoadingPayCharge', false)
                .set('error', new RestError(action.error))
                .set('isFailPayCharge', true);

        case PRICING_SELECT_SELECTED_PRODUCT_CATEGORY_UID:
            return state.set('selectedCategoryUid', action.categoryUid);

        case PRICING_CHANGE_PRODUCT_SIZE:
            return state.set('productSize', action.value);

        case PRICING_CLEAR_HANDLER_STATE:
            return state.set('isLoadingPayCharge', false)
                .set('isSucceedPayCharge', false)
                .set('isFailPayCharge', false);

        case PRICING_CLEAR_PAYMENT_HISTORY_STATE:
            return state.set('isErrorCreatePaymentHistory', false)
                .set('isSucceedCreatePaymentHistory', false)
                .set('isSucceedCreateIamportPayment', false)
                .set('isErrorCreatePaymentHistory', false)
                .set('createdPaymentHistory', null)
                .set('paymentHtml', null)
                .set('isSucceedTrialPlan', false)
                .set('isErrorTrialPlan', false)
                .set('isErrorByIamportModule', false)
                .set('isSucceedByIamportModule', false);

        case REQUEST_PAY_TRIAL_BY_SHOP_STAFF:
            return state.set('isLoadingIamportCharge', true);

        case SUCCEED_PAY_TRIAL_BY_SHOP_STAFF:
            return state.set('isLoadingIamportCharge', false)
            .set('isSucceedTrialPlan', true);

        case FAIL_PAY_TRIAL_BY_SHOP_STAFF:
            return state.set('isLoadingIamportCharge', false)
                .set('isErrorTrialPlan', true)
                .set('error', new RestError(action.error));

        case REQUEST_CREATE_IAMPORT_PAYMENT:
            return state.set('isLoadingIamportCharge', true);

        case SUCCEED_CREATE_IAMPORT_PAYMENT:
            return state.set('isLoadingIamportCharge', false)
                .set('paymentHtml', action.response)
                .set('isSucceedCreateIamportPayment', true)

        case FAIL_CREATE_IAMPORT_PAYMENT:
            return state.set('isLoadingIamportCharge', false)
                .set('isErrorCreatePaymentHistory', true)

        case REQUEST_DELETE_PAYMENT_HISTORY_BY_SHOP_STAFF:
            return state.set('isLoading', true);

        case SUCCEED_DELETE_PAYMENT_HISTORY_BY_SHOP_STAFF:
            return state.set('isLoading', false);

        case FAIL_DELETE_PAYMENT_HISTORY_BY_SHOP_STAFF:
            return state.set('isLoading', false)
                .set('error', new RestError(action.error));

        case REQUEST_UPDATE_PAYMENT_HISTORY_BY_SHOP_STAFF:
            return state.set('isLoadingIamportCharge', true);

        case SUCCEED_UPDATE_PAYMENT_HISTORY_BY_SHOP_STAFF:
            return state.set('isLoadingIamportCharge', false)
                .set('isSucceedByIamportModule', true);

        case FAIL_UPDATE_PAYMENT_HISTORY_BY_SHOP_STAFF:
            return state.set('isLoadingIamportCharge', false)
                .set('error', new RestError(action.error))
                .set('isErrorByIamportModule', true);

        case REQUEST_PAY_IAMPORT_SCHEDULE_CHARGE_BY_SHOP_STAFF:
            return state.set('isLoadingIamportCharge', true);

        case SUCCEED_PAY_IAMPORT_SCHEDULE_CHARGE_BY_SHOP_STAFF:
            createdPaymentHistory = new PricingPaymentHistory(action.result);

            return state.set('isLoadingIamportCharge', false)
                .set('isSucceedCreatePaymentHistory', true)
                .set('createdPaymentHistory', createdPaymentHistory)

        case FAIL_PAY_IAMPORT_SCHEDULE_CHARGE_BY_SHOP_STAFF:
            return state.set('isLoadingIamportCharge', false)
                .set('isErrorCreatePaymentHistory', true)
                .set('error', new RestError(action.error));

        case REQUEST_PAY_IAMPORT_PRODUCT_CHARGE_BY_SHOP_STAFF:
            return state.set('isLoadingIamportCharge', true);

        case SUCCEED_PAY_IAMPORT_PRODUCT_CHARGE_BY_SHOP_STAFF:
            createdPaymentHistory = new PricingPaymentHistory(action.result);

            return state.set('isLoadingIamportCharge', false)
                .set('isSucceedCreatePaymentHistory', true)
                .set('createdPaymentHistory', createdPaymentHistory)

        case FAIL_PAY_IAMPORT_PRODUCT_CHARGE_BY_SHOP_STAFF:
            return state.set('isLoadingIamportCharge', false)
                .set('isErrorCreatePaymentHistory', true)
                .set('error', new RestError(action.error));

        default:
            return state;
    }
}
