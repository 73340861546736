import {ofType} from "redux-observable";
import {
    FAIL_CHANGE_PRODUCTS_BULK_DATA_BY_SHOP_STAFF,
    FAIL_CHANGE_PRODUCTS_ORDER_BY_SHOP_STAFF,
    FAIL_CREATE_PRODUCT_BY_SHOP_STAFF,
    FAIL_DELETE_PRODUCTS_BY_SHOP_STAFF,
    FAIL_GET_WORK_REQUEST_HISTORIES_BY_SHOP_STAFF,
    FAIL_INIT_PRODUCT_THREE_VIEWER_BY_SHOP_STAFF,
    FAIL_INIT_SHOP_STAFF_PRODUCT_ADD,
    FAIL_INIT_SHOP_STAFF_PRODUCT_EDIT,
    FAIL_UPDATE_PRODUCT_BY_SHOP_STAFF,
    REQUEST_CHANGE_PRODUCTS_BULK_DATA_BY_SHOP_STAFF,
    REQUEST_CHANGE_PRODUCTS_ORDER_BY_SHOP_STAFF,
    REQUEST_CREATE_PRODUCT_BY_SHOP_STAFF,
    REQUEST_DELETE_PRODUCTS_BY_SHOP_STAFF,
    REQUEST_GET_PRODUCT_BY_SHOP_STAFF,
    REQUEST_GET_PRODUCT_BY_SHOP_STAFF_FOR_AR_VIEWER,
    REQUEST_GET_PRODUCTS_BY_SHOP_STAFF,
    REQUEST_GET_PRODUCTS_BY_SHOP_STAFF_IN_CHANGE_ORDER,
    REQUEST_GET_PRODUCTS_BY_SHOP_STAFF_IN_SKIN_FILTER_VIEWER,
    REQUEST_GET_SHOP_STAFF_BRANDS_CATEGORIES_SHOP_IN_PRODUCTS,
    REQUEST_GET_WORK_REQUEST_HISTORIES_BY_SHOP_STAFF,
    REQUEST_IMPORT_BUILDER_PRODUCT_INFO_IN_PRODUCT_ADD,
    REQUEST_IMPORT_BUILDER_PRODUCT_INFO_IN_PRODUCT_EDIT,
    REQUEST_INIT_PRODUCT_AR_VIEWER_BY_SHOP_STAFF,
    REQUEST_INIT_PRODUCT_THREE_VIEWER_BY_SHOP_STAFF,
    REQUEST_INIT_SHOP_STAFF_PRODUCT_ADD,
    REQUEST_INIT_SHOP_STAFF_PRODUCT_EDIT,
    REQUEST_UPDATE_PRODUCT_BY_SHOP_STAFF,
    SUCCEED_CHANGE_PRODUCTS_BULK_DATA_BY_SHOP_STAFF,
    SUCCEED_CHANGE_PRODUCTS_ORDER_BY_SHOP_STAFF,
    SUCCEED_CREATE_PRODUCT_BY_SHOP_STAFF,
    SUCCEED_DELETE_PRODUCTS_BY_SHOP_STAFF,
    SUCCEED_GET_WORK_REQUEST_HISTORIES_BY_SHOP_STAFF,
    SUCCEED_UPDATE_PRODUCT_BY_SHOP_STAFF,
    REQUEST_CREATE_BATCH_FILE_DATA_BY_SHOP_STAFF,
    SUCCEED_CREATE_BATCH_FILE_DATA_BY_SHOP_STAFF,
    FAIL_CREATE_BATCH_FILE_DATA_BY_SHOP_STAFF,
    REQUEST_COPY_SAMPLE_PRODUCTS_BY_SHOP_STAFF,
    SUCCEED_COPY_SAMPLE_PRODUCTS_BY_SHOP_STAFF,
    FAIL_COPY_SAMPLE_PRODUCTS_BY_SHOP_STAFF,
    REQUEST_GET_SHOP_STAFF_BRANDS_CATEGORIES_SHOP_IN_WHATS_NEWS,
    REQUEST_GET_WHATS_NEWS_BY_SHOP_STAFF,
    REQUEST_GET_WHATS_NEWS_BY_SHOP_STAFF_IN_CHANGE_ORDER,
    REQUEST_CHANGE_WHATS_NEWS_ORDER_BY_SHOP_STAFF,
    SUCCEED_CHANGE_WHATS_NEWS_ORDER_BY_SHOP_STAFF,
    FAIL_CHANGE_WHATS_NEWS_ORDER_BY_SHOP_STAFF,
    REQUEST_DELETE_WHATS_NEWS_BY_SHOP_STAFF,
    SUCCEED_DELETE_WHATS_NEWS_BY_SHOP_STAFF,
    FAIL_DELETE_WHATS_NEWS_BY_SHOP_STAFF,
    REQUEST_GET_PRODUCTS_BY_SHOP_STAFF_IN_WHATS_NEWS,
    REQUEST_CREATE_WHATS_NEWS_BY_SHOP_STAFF,
    SUCCEED_CREATE_WHATS_NEWS_BY_SHOP_STAFF, FAIL_CREATE_WHATS_NEWS_BY_SHOP_STAFF,
} from "../../actions/RESTActionTypes";
import {catchError, map, mergeMap} from "rxjs/operators";
import {of, zip} from "rxjs";
import {
    changeProductsBulkData,
    changeProductsOrder,
    createBatchFileData,
    createProduct,
    copySampleProducts,
    deleteProducts,
    getBrands,
    getProduct,
    getProductCategories,
    getProducts,
    getShop,
    getWorkRequestHistories,
    importBuilderProductInfo,
    readyProductData,
    updateProduct, getWhatsNews, changeWhatsNewsOrder, deleteWhatsNews, createWhatsNews,
} from "../../../rest/ShopStaffsAPI";
import {loadResult, loadResults, occurError} from "../../actions/RESTAction";
import {
    loadedImportBuilderProductInfo,
    loadShopStaffBrandsAndCategories,
    loadShopStaffProductAdd,
    loadShopStaffProductEdit,
    loadShopStaffProductThreeViewer,
} from "../../actions/ShopStaff/ProductAPIAction";

export const getShopStaffBrandsAndCategoriesAndShopEpic = action$ => action$.pipe(
    ofType(
        REQUEST_GET_SHOP_STAFF_BRANDS_CATEGORIES_SHOP_IN_PRODUCTS,
        REQUEST_GET_SHOP_STAFF_BRANDS_CATEGORIES_SHOP_IN_WHATS_NEWS
    ),
    mergeMap(action => zip(
        getBrands(action.shopStaffInfo, {disable_paging: '1'}),
        getProductCategories(action.shopStaffInfo, {disable_paging: '1'}),
        getShop(action.shopStaffInfo),
    ).pipe(
        map(([brandsResponse, categoriesResponse, shopResponse]) =>
            loadShopStaffBrandsAndCategories(action.succeedType, brandsResponse, categoriesResponse, shopResponse)
        ),
        catchError(error => of(occurError(action.failType, error.xhr)))
    ))
);

export const getProductsEpic = action$ => action$.pipe(
    ofType(
        REQUEST_GET_PRODUCTS_BY_SHOP_STAFF,
        REQUEST_GET_PRODUCTS_BY_SHOP_STAFF_IN_CHANGE_ORDER,
        REQUEST_GET_PRODUCTS_BY_SHOP_STAFF_IN_SKIN_FILTER_VIEWER,
        REQUEST_GET_PRODUCTS_BY_SHOP_STAFF_IN_WHATS_NEWS
    ),
    mergeMap(action => getProducts(action.shopStaffInfo, action.queryParams).pipe(
        map(res => loadResults(action.succeedType, res.response, action.queryParams.page)),
        catchError(error => of(occurError(action.failType, error.xhr)))
    ))
);

export const getWhatsNewsEpic = action$ => action$.pipe(
    ofType(REQUEST_GET_WHATS_NEWS_BY_SHOP_STAFF, REQUEST_GET_WHATS_NEWS_BY_SHOP_STAFF_IN_CHANGE_ORDER),
    mergeMap(action => getWhatsNews(action.shopStaffInfo, action.queryParams).pipe(
        map(res => loadResults(action.succeedType, res.response, action.queryParams.page)),
        catchError(error => of(occurError(action.failType, error.xhr)))
    ))
);

export const initShopStaffProductAddEpic = action$ => action$.pipe(
    ofType(REQUEST_INIT_SHOP_STAFF_PRODUCT_ADD),
    mergeMap(action => zip(
        readyProductData(action.shopStaffInfo),
    ).pipe(
        map(([readyProductDataResponse]) =>
            loadShopStaffProductAdd(readyProductDataResponse)
        ),
        catchError(error => of(occurError(FAIL_INIT_SHOP_STAFF_PRODUCT_ADD, error.xhr)))
    ))
);

export const initShopStaffProductEditEpic = action$ => action$.pipe(
    ofType(REQUEST_INIT_SHOP_STAFF_PRODUCT_EDIT),
    mergeMap(action => zip(
        readyProductData(action.shopStaffInfo),
        getProduct(action.shopStaffInfo, action.productId),
    ).pipe(
        map(([readyProductDataResponse, productResponse]) =>
            loadShopStaffProductEdit(readyProductDataResponse, productResponse)
        ),
        catchError(error => of(occurError(FAIL_INIT_SHOP_STAFF_PRODUCT_EDIT, error.xhr)))
    ))
);

export const importBuilderProductEpic = action$ => action$.pipe(
    ofType(REQUEST_IMPORT_BUILDER_PRODUCT_INFO_IN_PRODUCT_ADD, REQUEST_IMPORT_BUILDER_PRODUCT_INFO_IN_PRODUCT_EDIT),
    mergeMap(action => importBuilderProductInfo(
        action.shopStaffInfo, action.categoryUid, action.productUid,
    ).pipe(
        map(res => loadedImportBuilderProductInfo(action.succeedType, res.response)),
        catchError(error => of(occurError(action.failType, error.xhr)))
    ))
);

export const createProductEpic = action$ => action$.pipe(
    ofType(REQUEST_CREATE_PRODUCT_BY_SHOP_STAFF),
    mergeMap(action => createProduct(action.shopStaffInfo, action.formData).pipe(
        map(res => loadResult(SUCCEED_CREATE_PRODUCT_BY_SHOP_STAFF, res.response)),
        catchError(error => of(occurError(FAIL_CREATE_PRODUCT_BY_SHOP_STAFF, error.xhr)))
    ))
);

export const createWhatsNewsEpic = action$ => action$.pipe(
    ofType(REQUEST_CREATE_WHATS_NEWS_BY_SHOP_STAFF),
    mergeMap(action => createWhatsNews(action.shopStaffInfo, action.productIds).pipe(
        map(res => loadResult(SUCCEED_CREATE_WHATS_NEWS_BY_SHOP_STAFF, res.response)),
        catchError(error => of(occurError(FAIL_CREATE_WHATS_NEWS_BY_SHOP_STAFF, error.xhr)))
    ))
);

export const copySampleProductsEpic = action$ => action$.pipe(
    ofType(REQUEST_COPY_SAMPLE_PRODUCTS_BY_SHOP_STAFF),
    mergeMap(action => copySampleProducts(action.shopStaffInfo, action.categoryUid).pipe(
        map(res => loadResult(SUCCEED_COPY_SAMPLE_PRODUCTS_BY_SHOP_STAFF, res.response)),
        catchError(error => of(occurError(FAIL_COPY_SAMPLE_PRODUCTS_BY_SHOP_STAFF, error.xhr)))
    ))
);

export const createBatchFileDataEpic = action$ => action$.pipe(
    ofType(REQUEST_CREATE_BATCH_FILE_DATA_BY_SHOP_STAFF),
    mergeMap(action => createBatchFileData(
        action.shopStaffInfo, action.formData,
    ).pipe(
        map(res => loadResult(SUCCEED_CREATE_BATCH_FILE_DATA_BY_SHOP_STAFF, res.response)),
        catchError(error => of(occurError(FAIL_CREATE_BATCH_FILE_DATA_BY_SHOP_STAFF, error.xhr)))
    ))
);

export const getProductEpic = action$ => action$.pipe(
    ofType(REQUEST_GET_PRODUCT_BY_SHOP_STAFF, REQUEST_GET_PRODUCT_BY_SHOP_STAFF_FOR_AR_VIEWER),
    mergeMap(action => getProduct(action.shopStaffInfo, action.productId).pipe(
        map(res => loadResult(action.succeedType, res.response)),
        catchError(error => of(occurError(action.failType, error.xhr)))
    ))
);

export const updateProductEpic = action$ => action$.pipe(
    ofType(REQUEST_UPDATE_PRODUCT_BY_SHOP_STAFF),
    mergeMap(action => updateProduct(action.shopStaffInfo, action.productId, action.formData).pipe(
        map(res => loadResult(SUCCEED_UPDATE_PRODUCT_BY_SHOP_STAFF, res.response)),
        catchError(error => of(occurError(FAIL_UPDATE_PRODUCT_BY_SHOP_STAFF, error.xhr)))
    ))
);

export const deleteProductsEpic = action$ => action$.pipe(
    ofType(REQUEST_DELETE_PRODUCTS_BY_SHOP_STAFF),
    mergeMap(action => deleteProducts(action.shopStaffInfo, action.productIds).pipe(
        map(res => loadResult(SUCCEED_DELETE_PRODUCTS_BY_SHOP_STAFF, res.response)),
        catchError(error => of(occurError(FAIL_DELETE_PRODUCTS_BY_SHOP_STAFF, error.xhr)))
    ))
);

export const deleteWhatsNewsEpic = action$ => action$.pipe(
    ofType(REQUEST_DELETE_WHATS_NEWS_BY_SHOP_STAFF),
    mergeMap(action => deleteWhatsNews(action.shopStaffInfo, action.whatsNewIds).pipe(
        map(res => loadResult(SUCCEED_DELETE_WHATS_NEWS_BY_SHOP_STAFF, res.response)),
        catchError(error => of(occurError(FAIL_DELETE_WHATS_NEWS_BY_SHOP_STAFF, error.xhr)))
    ))
);

export const changeProductsBulkDataEpic = action$ => action$.pipe(
    ofType(REQUEST_CHANGE_PRODUCTS_BULK_DATA_BY_SHOP_STAFF),
    mergeMap(action => changeProductsBulkData(
        action.shopStaffInfo, action.productIds, action.key, action.value
    ).pipe(
        map(res => loadResult(SUCCEED_CHANGE_PRODUCTS_BULK_DATA_BY_SHOP_STAFF, res.response)),
        catchError(error => of(occurError(FAIL_CHANGE_PRODUCTS_BULK_DATA_BY_SHOP_STAFF, error.xhr)))
    ))
);

export const changeProductsOrderEpic = action$ => action$.pipe(
    ofType(REQUEST_CHANGE_PRODUCTS_ORDER_BY_SHOP_STAFF),
    mergeMap(action => changeProductsOrder(action.shopStaffInfo, action.orderItems).pipe(
        map(res => loadResult(SUCCEED_CHANGE_PRODUCTS_ORDER_BY_SHOP_STAFF, res.response)),
        catchError(error => of(occurError(FAIL_CHANGE_PRODUCTS_ORDER_BY_SHOP_STAFF, error.xhr)))
    ))
);

export const changeWhatsNewsOrderEpic = action$ => action$.pipe(
    ofType(REQUEST_CHANGE_WHATS_NEWS_ORDER_BY_SHOP_STAFF),
    mergeMap(action => changeWhatsNewsOrder(action.shopStaffInfo, action.orderItems).pipe(
        map(res => loadResult(SUCCEED_CHANGE_WHATS_NEWS_ORDER_BY_SHOP_STAFF, res.response)),
        catchError(error => of(occurError(FAIL_CHANGE_WHATS_NEWS_ORDER_BY_SHOP_STAFF, error.xhr)))
    ))
)

export const initProductThreeViewerEpic = action$ => action$.pipe(
    ofType(REQUEST_INIT_PRODUCT_THREE_VIEWER_BY_SHOP_STAFF, REQUEST_INIT_PRODUCT_AR_VIEWER_BY_SHOP_STAFF),
    mergeMap(action => zip(
        getShop(action.shopStaffInfo),
        getProduct(action.shopStaffInfo, action.productId),
    ).pipe(
        map(([shopResponse, productResponse]) =>
            loadShopStaffProductThreeViewer(action.succeedType, shopResponse, productResponse)
        ),
        catchError(error => of(occurError(FAIL_INIT_PRODUCT_THREE_VIEWER_BY_SHOP_STAFF, error.xhr)))
    ))
);

export const getWorkRequestHistoriesByShopStaffEpic = action$ => action$.pipe(
    ofType(REQUEST_GET_WORK_REQUEST_HISTORIES_BY_SHOP_STAFF),
    mergeMap(action => getWorkRequestHistories(action.shopStaffInfo, action.workRequestId, action.page).pipe(
        map(res => loadResults(SUCCEED_GET_WORK_REQUEST_HISTORIES_BY_SHOP_STAFF, res.response, action.page)),
        catchError(error => of(occurError(FAIL_GET_WORK_REQUEST_HISTORIES_BY_SHOP_STAFF, error.xhr)))
    ))
);
