import {ofType} from "redux-observable";
import {
    FAIL_GET_SHOP_BY_SHOP_STAFF,
    FAIL_GET_SHOP_PLUGIN_BY_SHOP_STAFF,
    FAIL_INIT_PLUGIN_SETTINGS_BY_SHOP_STAFF,
    FAIL_UPDATE_SHOP_BY_SHOP_STAFF,
    FAIL_UPDATE_SHOP_PLUGIN_BY_SHOP_STAFF,
    REQUEST_GET_PAYMENT_HISTORIES_BY_SHOP_STAFF,
    REQUEST_GET_SHOP_BY_SHOP_STAFF,
    REQUEST_GET_SHOP_PLUGIN_BY_SHOP_STAFF,
    REQUEST_INIT_PLUGIN_SETTINGS_BY_SHOP_STAFF,
    REQUEST_INIT_PLUGIN_SKIN_FILTER_VIEWER_BY_SHOP_STAFF,
    REQUEST_UPDATE_SKIN_FILTER_VIEWER_AR_MODEL_TRANSFORM_INFO_BY_SHOP_STAFF,
    REQUEST_INITIALIZE_PAYMENT_HISTORIES_BY_SHOP_STAFF,
    REQUEST_UPDATE_SHOP_BY_SHOP_STAFF,
    REQUEST_UPDATE_SHOP_PLUGIN_BY_SHOP_STAFF,
    SUCCEED_GET_SHOP_BY_SHOP_STAFF,
    SUCCEED_GET_SHOP_PLUGIN_BY_SHOP_STAFF,
    SUCCEED_UPDATE_SHOP_BY_SHOP_STAFF,
    SUCCEED_UPDATE_SHOP_PLUGIN_BY_SHOP_STAFF,
} from "../../actions/RESTActionTypes";
import {catchError, map, mergeMap} from "rxjs/operators";
import {
    getPaymentHistories,
    getProductCategories,
    getShop,
    getShopPlugin,
    updateShop,
    updateShopPlugin,
    updateSkinFilterViewerArModelTransformInfo,
} from "../../../rest/ShopStaffsAPI";
import {loadResult, loadResults, occurError} from "../../actions/RESTAction";
import {of, zip} from "rxjs";
import {loadInitializePaymentHistories, loadInitPluginSettings} from "../../actions/ShopStaff/ShopAPIAction";

export const getShopEpic = action$ => action$.pipe(
    ofType(REQUEST_GET_SHOP_BY_SHOP_STAFF),
    mergeMap(action => getShop(action.shopStaffInfo).pipe(
        map(res => loadResult(SUCCEED_GET_SHOP_BY_SHOP_STAFF, res.response)),
        catchError(error => of(occurError(FAIL_GET_SHOP_BY_SHOP_STAFF, error.xhr)))
    ))
);

export const updateShopEpic = action$ => action$.pipe(
    ofType(REQUEST_UPDATE_SHOP_BY_SHOP_STAFF),
    mergeMap(action => updateShop(action.shopStaffInfo, action.formData).pipe(
        map(res => loadResult(SUCCEED_UPDATE_SHOP_BY_SHOP_STAFF, res.response)),
        catchError(error => of(occurError(FAIL_UPDATE_SHOP_BY_SHOP_STAFF, error.xhr)))
    ))
);

export const initPluginSettingsEpic = action$ => action$.pipe(
    ofType(REQUEST_INIT_PLUGIN_SETTINGS_BY_SHOP_STAFF),
    mergeMap(action => zip(
        getProductCategories(action.shopStaffInfo, {disable_paging: '1'}),
        getShopPlugin(action.shopStaffInfo),
    ).pipe(
        map(([categoriesResponse, pluginResponse]) => loadInitPluginSettings(categoriesResponse, pluginResponse)),
        catchError(error => of(occurError(FAIL_INIT_PLUGIN_SETTINGS_BY_SHOP_STAFF, error.xhr)))
    ))
);

export const getShopPluginEpic = action$ => action$.pipe(
    ofType(REQUEST_GET_SHOP_PLUGIN_BY_SHOP_STAFF),
    mergeMap(action => getShopPlugin(action.shopStaffInfo).pipe(
        map(res => loadResult(SUCCEED_GET_SHOP_PLUGIN_BY_SHOP_STAFF, res.response)),
        catchError(error => of(occurError(FAIL_GET_SHOP_PLUGIN_BY_SHOP_STAFF, error.xhr)))
    ))
);

export const updateShopPluginEpic = action$ => action$.pipe(
    ofType(REQUEST_UPDATE_SHOP_PLUGIN_BY_SHOP_STAFF),
    mergeMap(action => updateShopPlugin(action.shopStaffInfo, action.pluginId, action.formData).pipe(
        map(res => loadResult(SUCCEED_UPDATE_SHOP_PLUGIN_BY_SHOP_STAFF, res.response)),
        catchError(error => of(occurError(FAIL_UPDATE_SHOP_PLUGIN_BY_SHOP_STAFF, error.xhr)))
    ))
);

export const getPaymentHistoriesEpic = action$ => action$.pipe(
    ofType(REQUEST_GET_PAYMENT_HISTORIES_BY_SHOP_STAFF),
    mergeMap(action => getPaymentHistories(action.shopStaffInfo, action.queryParams).pipe(
        map(res => loadResults(action.succeedType, res.response, action.queryParams.page)),
        catchError(error => of(occurError(action.failType, error.xhr)))
    ))
);

export const initPluginSkinFilterViewerEpic = action$ => action$.pipe(
    ofType(REQUEST_INIT_PLUGIN_SKIN_FILTER_VIEWER_BY_SHOP_STAFF),
    mergeMap(action => getShopPlugin(action.shopStaffInfo).pipe(
        map(res => loadResult(action.succeedType, res.response)),
        catchError(error => of(occurError(action.failType, error.xhr)))
    ))
);

export const updatePluginSkinFilterViewerModelTransformInfoEpic = action$ => action$.pipe(
    ofType(REQUEST_UPDATE_SKIN_FILTER_VIEWER_AR_MODEL_TRANSFORM_INFO_BY_SHOP_STAFF),
    mergeMap(action => updateSkinFilterViewerArModelTransformInfo(
        action.shopStaffInfo,
        action.pluginId,
        action.skinSmoothingFilter
    ).pipe(
        map(res => loadResult(action.succeedType, res.response)),
        catchError(error => of(occurError(action.failType, error.xhr)))
    ))
)

export const initializePaymentHistoriesEpic = action$ => action$.pipe(
    ofType(REQUEST_INITIALIZE_PAYMENT_HISTORIES_BY_SHOP_STAFF),
    mergeMap(action => zip(
        getShop(action.shopStaffInfo),
        getPaymentHistories(action.shopStaffInfo, action.queryParams),
    ).pipe(
        map(([shopResponse, paymentHistoryResponse]) =>
            loadInitializePaymentHistories(action.succeedType, shopResponse, paymentHistoryResponse)),
        catchError(error => of(occurError(action.failType, error.xhr)))
    ))
);
