export const SMALL = 'small';
export const MEDIUM = 'medium';
export const LARGE = 'large';
export const XLARGE = 'xlarge';

export const SMALL_SIZE = 480;
export const MEDIUM_SIZE = 768;
export const LARGE_SIZE = 1024;
export const XLARGE_SIZE = 1200;

export function mediaQuery(theme, value) {
    let minWidth;

    if (value === SMALL) {
        minWidth = SMALL_SIZE;
    } else if (value === MEDIUM) {
        minWidth = MEDIUM_SIZE;
    } else if (value === LARGE) {
        minWidth = LARGE_SIZE;
    } else if (value === XLARGE) {
        minWidth = XLARGE_SIZE;
    } else {
        minWidth = value;
    }

    return `@media (min-width: ${theme.spacing(minWidth)})`;
}

export const MediaQuery = (theme, width) => `@media (min-width: ${theme.spacing(width)})`;
