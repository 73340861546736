import {fromJS, List} from "immutable";
import {
    SHOP_STAFF_PRODUCT_COLOR_ADD_ADD_LANGUAGE_CODE,
    SHOP_STAFF_PRODUCT_COLOR_ADD_CHANGE_LANGUAGE_INFO,
    SHOP_STAFF_PRODUCT_COLOR_ADD_CHANGE_PRODUCT_COLOR,
    SHOP_STAFF_PRODUCT_COLOR_ADD_CLEAR_CREATE_STATE,
    SHOP_STAFF_PRODUCT_COLOR_ADD_DELETE_LANGUAGE_CODE,
    SHOP_STAFF_PRODUCT_COLOR_ADD_SET_DEFAULT_LANGUAGE,
    SHOP_STAFF_PRODUCT_COLOR_ADD_TOGGLE_ADD_MODE,
} from "../../actions/UIActionTypes";
import {
    FAIL_CREATE_PRODUCT_COLOR_BY_SHOP_STAFF,
    FAIL_INIT_SHOP_STAFF_PRODUCT_COLOR_ADD,
    REQUEST_CREATE_PRODUCT_COLOR_BY_SHOP_STAFF,
    REQUEST_INIT_SHOP_STAFF_PRODUCT_COLOR_ADD,
    SUCCEED_CREATE_PRODUCT_COLOR_BY_SHOP_STAFF,
    SUCCEED_INIT_SHOP_STAFF_PRODUCT_COLOR_ADD,
} from "../../actions/RESTActionTypes";
import RestError from "../../../models/RestError";
import Shop from "../../../models/Shop";
import {indexOfLanguageCode, updateListByKeyValue} from "../../../utils/ListUtils";
import {changeDefaultLanguageInProduct, deleteLanguageInProduct} from "../../../behaviors/ProductBehavior";
import ProductColor from "../../../models/ProductColor";
import I18NLanguageProductColor from "../../../models/I18NLanguageProductColor";

export const initialShopStaffProductColorAdd = fromJS({
    isLoading: false,
    isSaveAndAddAnotherMode: false,
    shop: new Shop(),
    error: null,
    productColor: new ProductColor(),
    defaultLanguageCode: '',
    isCreating: false,
    succeedCreate: false,
    errorCreate: null,
});

export function shopStaffProductColorAdd(state = initialShopStaffProductColorAdd, action) {
    let index, productColor, i18nLanguages;

    switch (action.type) {
        case SHOP_STAFF_PRODUCT_COLOR_ADD_CLEAR_CREATE_STATE:
            return state.set('isCreating', initialShopStaffProductColorAdd.get('isCreating'))
                .set('succeedCreate', initialShopStaffProductColorAdd.get('succeedCreate'))
                .set('errorCreate', initialShopStaffProductColorAdd.get('errorCreate'));

        case REQUEST_INIT_SHOP_STAFF_PRODUCT_COLOR_ADD:
            return state.set('isLoading', true).set('shop', null).set('error', null);

        case SUCCEED_INIT_SHOP_STAFF_PRODUCT_COLOR_ADD:
            const shop = new Shop(action.shop);
            const defaultLanguageCode = shop.getDefaultLanguageCode();

            return state.set('isLoading', false)
                .set('shop', shop)
                .set(
                    'productColor',
                    new ProductColor().set(
                        'i18n_languages',
                        List([new I18NLanguageProductColor({language_code: defaultLanguageCode, is_default: true})])
                    )
                )
                .set('defaultLanguageCode', defaultLanguageCode);

        case FAIL_INIT_SHOP_STAFF_PRODUCT_COLOR_ADD:
            return state.set('isLoading', false).set('shop', null).set('error', new RestError(action.error));

        case SHOP_STAFF_PRODUCT_COLOR_ADD_CHANGE_PRODUCT_COLOR:
            return state.set('productColor', state.get('productColor').set(action.key, action.value));

        case SHOP_STAFF_PRODUCT_COLOR_ADD_ADD_LANGUAGE_CODE:
            productColor = state.get('productColor');
            i18nLanguages = productColor.get('i18n_languages');
            index = indexOfLanguageCode(i18nLanguages, action.languageCode);

            if (index >= 0) {
                return state;
            }

            return state.set(
                'productColor',
                productColor.set(
                    'i18n_languages',
                    i18nLanguages.push(new I18NLanguageProductColor({language_code: action.languageCode}))
                )
            );

        case SHOP_STAFF_PRODUCT_COLOR_ADD_DELETE_LANGUAGE_CODE:
            productColor = state.get('productColor');
            i18nLanguages = productColor.get('i18n_languages');
            index = indexOfLanguageCode(i18nLanguages, action.languageCode);

            if (index >= 0) {
                const {i18nLanguages: newI18nLanguages} = deleteLanguageInProduct(index, i18nLanguages);

                state = state.set('productColor', productColor.set('i18n_languages', newI18nLanguages))
            }

            return state;

        case SHOP_STAFF_PRODUCT_COLOR_ADD_SET_DEFAULT_LANGUAGE:
            productColor = state.get('productColor');

            return state.set(
                'productColor',
                productColor.set(
                    'i18n_languages',
                    changeDefaultLanguageInProduct(productColor.get('i18n_languages'), action.languageCode)
                )
            )
                .set('defaultLanguageCode', action.languageCode);

        case SHOP_STAFF_PRODUCT_COLOR_ADD_CHANGE_LANGUAGE_INFO:
            productColor = state.get('productColor');

            return state.set(
                'productColor',
                productColor.set(
                    'i18n_languages',
                    updateListByKeyValue(productColor.get('i18n_languages'), action.index, action.key, action.value)
                )
            );

        case REQUEST_CREATE_PRODUCT_COLOR_BY_SHOP_STAFF:
            return state.set('isCreating', true).set('succeedCreate', false).set('errorCreate', null);

        case SUCCEED_CREATE_PRODUCT_COLOR_BY_SHOP_STAFF:
            return state.set('isCreating', false)
                .set('productColor', new ProductColor(action.result))
                .set('succeedCreate', true)
                .set('errorCreate', null);

        case FAIL_CREATE_PRODUCT_COLOR_BY_SHOP_STAFF:
            return state.set('isCreating', false)
                .set('succeedCreate', false)
                .set('errorCreate', new RestError(action.error));

        case SHOP_STAFF_PRODUCT_COLOR_ADD_TOGGLE_ADD_MODE:
            return state.set('isSaveAndAddAnotherMode', action.isSaveAndAddAnotherMode);
        default:
            return state;
    }
}
