import {EntityMixin} from "./Entity";
import {List, Record} from "immutable";
import I18NCountryProductOption from "./I18NCountryProductOption";
import I18NLanguageProductOption from "./I18NLanguageProductOption";
import {
    LIGHTING_ENV_ROTATION_VIEWER_KEY,
    LIGHTING_ENV_ROTATION_KEY,
    OPTIONS_KEY,
    UPDATED_PREFIX,
} from "../constants/CommonConstants";
import {findIndexById} from "../utils/ListUtils";
import ImageData from "./ImageData";
import ProductSize from "./ProductSize";
import ProductColor from "./ProductColor";
import ProductOptionImage from "./ProductOptionImage";
import Product from "./Product";
import FileData from "./FileData";
import EnvImagePreset from "./EnvImagePreset";
import ThreeModelData from "./ThreeModelData";
import ArThreeModelData from "./ArThreeModelData";
import {decryptAesFromHex} from "../utils/CryptoUtils";
import ArModelTransformInfo from "./ArModelTransformInfo";
import WorkRequest from "./WorkRequest";
import {WORK_REQUEST_ABLE_CATEGORIES} from "../constants/WorkRequestConstants";
import {CATEGORY_CONTACT_LENS} from "../constants/CategoryConstants";
import {generateOrderedUUID} from '../utils/UUIDUtils';

const EnvRotationRecord = Record({
    x: 0,
    y: 0,
    z: 0,
})

export class EnvRotation extends EnvRotationRecord {
    constructor(args = {}) {
        super({...args});
    }

    equals(other) {
        if (!other) {
            return false;
        }

        return this.x === other.get('x')
            && this.y === other.get('y')
            && this.z === other.get('z')
    }

    makeShouldUpdateData(oldbie) {
        if (!oldbie) {
            return;
        }

        let payload = {};

        if (this.y !== oldbie.y) {
            payload.y = this.y;
        }

        return payload;
    }

    makeFormData(formData, optionIndex, oldbieLightingEnv = null) {
        let oldbieItem = null;

        if (oldbieLightingEnv !== null) {
            oldbieItem = oldbieLightingEnv.get('rotation');
        }

        const shouldUpdate = oldbieItem != null;

        const keyPrefix = shouldUpdate
            ? `${UPDATED_PREFIX}${OPTIONS_KEY}${optionIndex}-${LIGHTING_ENV_ROTATION_KEY}`
            : `${OPTIONS_KEY}${optionIndex}-${LIGHTING_ENV_ROTATION_KEY}`;

        if (!shouldUpdate && this.x === 0 && this.y === 0 && this.z === 0) {
            return;
        }

        if (shouldUpdate) {
            if (!this.equals(oldbieItem)) {
                formData.append(`${keyPrefix}`, JSON.stringify(this));
            }
        } else {

            formData.append(`${keyPrefix}`, JSON.stringify(this))
        }
    }

    makeFormDataViewer(formData, optionIndex, oldbieLightingEnvViewer = null) {
        let oldbieItem = null;

        if (oldbieLightingEnvViewer !== null) {
            oldbieItem = oldbieLightingEnvViewer.get('rotation');
        }

        const shouldUpdate = oldbieItem != null;

        const keyPrefix = shouldUpdate
            ? `${UPDATED_PREFIX}${OPTIONS_KEY}${optionIndex}-${LIGHTING_ENV_ROTATION_VIEWER_KEY}`
            : `${OPTIONS_KEY}${optionIndex}-${LIGHTING_ENV_ROTATION_VIEWER_KEY}`;

        if (!shouldUpdate && this.x === 0 && this.y === 0 && this.z === 0) {
            return;
        }

        if (shouldUpdate) {
            if (!this.equals(oldbieItem)) {
                formData.append(`${keyPrefix}`, JSON.stringify(this));
            }
        } else {

            formData.append(`${keyPrefix}`, JSON.stringify(this))
        }
    }
}

const LightingEnvRecord = Record({
    env_image_preset_id: 0,
    envImagePreset: null,
    environment_image: null,
    exposure: 1.0,
    rotation: new EnvRotation(),
});

export class LightingEnv extends LightingEnvRecord {

    constructor(args = {}) {
        super({
            ...args,
            envImagePreset: args.envImagePreset ? new EnvImagePreset(args.envImagePreset) : new EnvImagePreset(),
            rotation: args.rotation ? new EnvRotation(args.rotation) : new EnvRotation(),
        });
    }

    equals(other) {
        if (!other) {
            return false;
        }

        return this.env_image_preset_id === other.get('env_image_preset_id')
            && this.exposure === other.get('exposure')
            && this.rotation.equals(other.get('rotation'))
    }

    makeShouldUpdateData(oldbie) {
        if (!oldbie) {
            return;
        }

        let payload = {};

        payload.env_image_preset_id = this.env_image_preset_id;

        if (this.exposure !== oldbie.get('exposure')) {
            payload.exposure = this.exposure;
        }

        if (this.rotation !== oldbie.get('rotation')) {
            payload.rotation = this.rotation.toJS();
        }

        return payload;
    }
}

const FaceEffectInfoRecord = Record({
    face_effect_id: 0,
    extra_top_area: 0,
})

export class FaceEffectInfo extends FaceEffectInfoRecord {
    constructor(args= {}) {
        super({
            ...args,
        });
    }

    equals(other) {
        if (!other) {
            return false;
        }

        return this.face_effect_id === other.get('face_effect_id')
            && this.extra_top_area === other.get('extra_top_area')
    }

    makeShouldUpdateData() {
        let payload = {};

        payload.face_effect_id = this.face_effect_id;
        payload.extra_top_area = this.extra_top_area;

        return payload;
    }

    toJsonString() {
        return JSON.stringify({
            face_effect_id: this.face_effect_id,
            extra_top_area: this.extra_top_area,
        })
    }
}

const ArImageSettingRecord = Record({
    blending_mode: '',
    alpha: 1.0,
    scale: 1.0,
})

export class ArImageSetting extends ArImageSettingRecord{
    constructor(args = {}) {
        super({...args});
    }

    equals(other) {
        if (!other) {
            return false;
        }

        return this.blending_mode === other.get('blending_mode')
            && this.alpha === other.get('alpha')
            && this.scale === other.get('scale')
    }

    toJsonString() {
        return JSON.stringify({
            blending_mode: this.blending_mode,
            alpha: this.alpha,
            scale: this.scale,
        })
    }

}

const ProductOptionRecord = EntityMixin({
    product_id: 0,
    product: null,
    size_id: 0,
    size: null,
    color_id: 0,
    color: null,
    uid: '',
    rep_image: null,
    repImageData: null,
    rep_image_width: 0,
    rep_image_height: 0,
    quantity: 0,
    enc_glb_file: null,
    glb_file: null,
    glbFileData: null,
    usdz_file: null,
    usdzFileData: null,
    enc_glb_file_2nd: null,
    glb_file_2nd: null,
    glbFile2ndData: null,
    usdz_file_2nd: null,
    usdzFile2ndData: null,
    lighting_env: null,
    lighting_env_viewer: null,
    face_effect_info: new FaceEffectInfo(),
    model_token: null,
    threeModelData: null,
    enc_ar_glb_file: null,
    ar_glb_file: null,
    arGlbFileData: null,
    ar_usdz_file: null,
    arUsdzFileData: null,
    enc_ar_pos_glb_file: null,
    ar_pos_glb_file: null,
    arPosGlbFileData: null,
    ar_pos_usdz_file: null,
    arPosUsdzFileData: null,
    zepeto_three_file: null,
    zepetoThreeFileData: null,
    instagram_three_file: null,
    instagramThreeFileData: null,
    enc_ar_pos_glb_file_2nd: null,
    ar_pos_glb_file_2nd: null,
    arPosGlbFile2ndData: null,
    ar_pos_usdz_file_2nd: null,
    arPosUsdzFile2ndData: null,
    ar_model_token: null,
    arThreeModelData: null,
    ar_image_1: null,
    arImage1Data: null,
    ar_image_2: null,
    arImage2Data: null,
    ar_image_settings: new ArImageSetting(),
    isArImageSameShape: true,
    name: '',
    additional_price: 0,
    additional_price_text: '',
    order: 0,
    armtis: List(),
    images: List(),
    i18n_countries: List(),
    i18n_languages: List(),
    work_request: null,
    tempId: '',
});

class ProductOption extends ProductOptionRecord {

    constructor(args = {}) {
        super({
            ...args,
            product: args.product ? new Product(args.product) : null,
            size: args.size ? new ProductSize(args.size) : null,
            color: args.color ? new ProductColor(args.color) : null,
            repImageData: args.repImageData ? new ImageData(args.repImageData) : null,
            glbFileData: args.glbFileData ? new FileData(args.glbFileData) : null,
            usdzFileData: args.usdzFileData ? new FileData(args.usdzFileData) : null,
            glbFile2ndData: args.glbFile2ndData ? new FileData(args.glbFile2ndData) : null,
            usdzFile2ndData: args.usdzFile2ndData ? new FileData(args.usdzFile2ndData) : null,
            lighting_env: args.lighting_env ? new LightingEnv(args.lighting_env) : new LightingEnv(),
            lighting_env_viewer: args.lighting_env_viewer ? new LightingEnv(args.lighting_env_viewer) : new LightingEnv(),
            face_effect_info: args.face_effect_info ? new FaceEffectInfo(args.face_effect_info) : new FaceEffectInfo(),
            threeModelData: args.threeModelData ? new ThreeModelData(args.threeModelData) : null,
            arGlbFileData: args.arGlbFileData ? new FileData(args.arGlbFileData) : null,
            arUsdzFileData: args.arUsdzFileData ? new FileData(args.arUsdzFileData) : null,
            arPosGlbFileData: args.arPosGlbFileData ? new FileData(args.arPosGlbFileData) : null,
            arPosUsdzFileData: args.arPosUsdzFileData ? new FileData(args.arPosUsdzFileData) : null,
            zepetoThreeFileData: args.zepetoThreeFileData ? new FileData(args.zepetoThreeFileData) : null,
            instagramThreeFileData : args.instagramThreeFileData ? new FileData(args.instagramThreeFileData) : null,
            arPosGlbFile2ndData: args.arPosGlbFile2ndData ? new FileData(args.arPosGlbFile2ndData) : null,
            arPosUsdzFile2ndData: args.arPosUsdzFile2ndData ? new FileData(args.arPosUsdzFile2ndData) : null,
            arThreeModelData: args.arThreeModelData ? new ArThreeModelData(args.arThreeModelData) : null,
            arImage1Data: args.arImage1Data ? new ImageData(args.arImage1Data) : null,
            arImage2Data: args.arImage2Data ? new ImageData(args.arImage2Data): null,
            ar_image_settings: args.ar_image_settings ? new ArImageSetting(args.ar_image_settings) : new ArImageSetting(),
            isArImageSameShape: !(args.ar_image_1 && args.ar_image_2),
            armtis: args.armtis ? List(args.armtis.map(item => new ArModelTransformInfo(item))) : List(),
            images: args.images ? List(args.images.map(item => new ProductOptionImage(item))) : List(),
            i18n_countries: args.i18n_countries
                ? List(args.i18n_countries.map(item => new I18NCountryProductOption(item))) : List(),
            i18n_languages: args.i18n_languages
                ? List(args.i18n_languages.map(item => new I18NLanguageProductOption(item))) : List(),
            work_request: args.work_request ? new WorkRequest(args.work_request) : null,
            tempId: args.tempId ? args.tempId : generateOrderedUUID(),
        });
    }

    async decryptModelToken() {
        if (!this.model_token) {
            return null;
        }

        try {
            const result = await decryptAesFromHex(this.model_token);

            return new ThreeModelData(JSON.parse(result));
        } catch (e) {
            return null;
        }
    }

    async decryptArModelToken() {
        if (!this.ar_model_token) {
            return null;
        }

        try {
            const result = await decryptAesFromHex(this.ar_model_token);

            return new ArThreeModelData(JSON.parse(result));
        } catch (e) {
            return null;
        }
    }

    getCurrentFileName(field, dataField) {
        if (this[field]) {
            return this[field].substring(this[field].lastIndexOf("/") + 1);
        } else if (this[dataField]) {
            return this[dataField].get('file').name;
        } else {
            return "";
        }
    }

    static makeNewbie(product) {
        return new ProductOption()
            .set(
                'i18n_countries',
                List(product.get('i18n_countries').map(item =>
                    new I18NCountryProductOption({
                        country_code: item.get('country_code'), is_default: item.get('is_default')
                    })
                ))
            )
            .set(
                'i18n_languages',
                List(product.get('i18n_languages').map(item =>
                    new I18NLanguageProductOption({
                        language_code: item.get('language_code'), is_default: item.get('is_default')
                    })
                ))
            )
    }

    canAdd(categoryUid, isAdmin = false) {
        let canWorkRequestAdd = true;

        if (!isAdmin && WORK_REQUEST_ABLE_CATEGORIES.includes(categoryUid)) {
            canWorkRequestAdd = (this.work_request && this.work_request.canAdd(categoryUid));
        }

        return this.color_id > 0
            && Boolean(this.uid)
            // && (this.rep_image || this.repImageData)
            // && this.images.size > 0 && this.images.every(item => item.canAdd())
            && this.i18n_countries.size > 0 && this.i18n_countries.every(item => item.canAdd())
            && this.i18n_languages.size > 0 && this.i18n_languages.every(item => item.canAdd())
            && canWorkRequestAdd
            && (CATEGORY_CONTACT_LENS.includes(categoryUid) && !this.isArImageSameShape
                ? (
                    Boolean((this.ar_image_1 || this.arImage1Data)
                        && (this.ar_image_2 || this.arImage2Data))
                )
                : true)
        // && this.armtis.size > 0 && this.armtis.every(item => item.canAdd());
        // && (this.glbFileData || this.glb_file)
        // && (this.ar_glb_file || this.arGlbFileData)
        // && (this.ar_pos_glb_file || this.arPosGlbFileData)
    }

    canAdminEdit(other, categoryUid) {
        let canContactLensEdit = false;

        if (CATEGORY_CONTACT_LENS.includes(categoryUid)) {
            if (this.isArImageSameShape) {
                canContactLensEdit = this.ar_image_1 !== other.get('ar_image_1') || Boolean(this.arImage1Data);
            } else {
                canContactLensEdit = this.ar_image_1 !== other.get('ar_image_1')
                    || Boolean(this.arImage1Data)
                    || this.ar_image_2 !== other.get('ar_image_2')
                    || Boolean(this.arImage2Data);

                if (canContactLensEdit) {
                    canContactLensEdit = (this.ar_image_1 || this.arImage1Data)
                        && (this.ar_image_2 || this.arImage2Data);
                }
            }
        }

        return !this.lighting_env.equals(other.get('lighting_env'))
            || !this.lighting_env_viewer.equals(other.get('lighting_env_viewer'))
            || !this.face_effect_info.equals(other.get('face_effect_info'))
            || this.glbFileData
            || this.arGlbFileData
            || this.arPosGlbFileData
            || this.glbFile2ndData
            || this.arPosGlbFile2ndData
            || this.zepetoThreeFileData
            || this.instagramThreeFileData
            || this.glb_file !== other.get('glb_file')
            || this.ar_glb_file !== other.get('ar_glb_file')
            || this.ar_pos_glb_file !== other.get('ar_pos_glb_file')
            || this.glb_file_2nd !== other.get('glb_file_2nd')
            || this.ar_pos_glb_file_2nd !== other.get('ar_pos_glb_file_2nd')
            || canContactLensEdit
            || !this.ar_image_settings.equals(other.get('ar_image_settings'))
            || this.zepeto_three_file !== other.get('zepeto_three_file')
            || this.instagram_three_file !== other.get('instagram_three_file')
            || !this.armtis.size === other.armtis.size
            || !this.armtis.every((item, index) => item.isSame(other.get('armtis').get(index)))
            || (this.work_request && !this.work_request.isSame(other.get('work_request')));
    }

    isSame(other, categoryUid) {
        if (!other) {
            return false;
        }

        let isLensSame = true;

        if (CATEGORY_CONTACT_LENS.includes(categoryUid)) {
            isLensSame = Boolean(
                this.ar_image_1 === other.get('ar_image_1')
                && this.arImage1Data === other.get('arImage1Data')
            );

            if (!this.isArImageSameShape) {
                isLensSame = isLensSame && Boolean(
                    this.ar_image_2 === other.get('ar_image_2')
                    && this.arImage2Data === other.get('arImage2Data')
                );
            }
        }

        return this.id === other.get('id')
            && this.size_id === other.get('size_id')
            && this.color_id === other.get('color_id')
            && this.uid === other.get('uid')
            // && this.repImageData === other.get('repImageData')
            // && (
            //     this.images.size === other.images.size
            //     && this.images.every((item, index) => item.isSame(other.get('images').get(index)))
            // )
            && this.glbFileData === other.get('glbFileData')
            && this.usdzFileData === other.get('usdzFileData')
            && this.lighting_env.equals(other.get('lighting_env'))
            && this.lighting_env_viewer.equals(other.get('lighting_env_viewer'))
            && this.face_effect_info.equals(other.get('face_effect_info'))
            && this.arGlbFileData === other.get('arGlbFileData')
            && this.arUsdzFileData === other.get('arUsdzFileData')
            && this.arPosGlbFileData === other.get('arPosGlbFileData')
            && this.arPosUsdzFileData === other.get('arPosUsdzFileData')
            && this.glbFile2ndData === other.get('glbFile2ndData')
            && this.arPosGlbFile2ndData === other.get('arPosGlbFile2ndData')
            && this.zepetoThreeFileData === other.get('zepetoThreeFileData')
            && this.instagramThreeFileData === other.get('instagramThreeFileData')
            && this.glb_file === other.get('glb_file')
            && this.ar_glb_file === other.get('ar_glb_file')
            && this.ar_pos_glb_file === other.get('ar_pos_glb_file')
            && this.glb_file_2nd === other.get('glb_file_2nd')
            && this.ar_pos_glb_file_2nd === other.get('ar_pos_glb_file_2nd')
            && isLensSame
            && this.ar_image_settings.equals(other.get('ar_image_settings'))
            && this.zepeto_three_file === other.get('zepeto_three_file')
            && this.instagram_three_file === other.get('instagram_three_file')
            && (
                this.i18n_countries.size === other.i18n_countries.size
                && this.i18n_countries.every((item, index) => item.isSame(other.get('i18n_countries').get(index)))
            )
            && (
                this.i18n_languages.size === other.i18n_languages.size
                && this.i18n_languages.every((item, index) => item.isSame(other.get('i18n_languages').get(index)))
            )
            && (
                this.armtis.size === other.armtis.size
                && this.armtis.every((item, index) => item.isSame(other.get('armtis').get(index)))
            )
            && (this.work_request ? this.work_request.isSame(other.get('work_request')) : true)
    }

    makeFormData(categoryUid, index, formData, oldbieProduct = null) {
        const shouldUpdate = this.id > 0;
        const optionIndex = shouldUpdate ? this.id : index;
        const keyPrefix = shouldUpdate
            ? `${UPDATED_PREFIX}${OPTIONS_KEY}${optionIndex}-` : `${OPTIONS_KEY}${optionIndex}-`;
        let isItemAdded = false;
        let oldbieItem = null;
        let addIndex;

        if (shouldUpdate && oldbieProduct !== null) {
            const oldbieItems = oldbieProduct.get('options');
            const indexOfOld = findIndexById(oldbieItems, this);

            oldbieItem = indexOfOld >= 0 ? oldbieItems.get(indexOfOld) : null;
        }

        if (this.size_id) {
            if (shouldUpdate && oldbieItem) {
                if (this.size_id !== oldbieItem.get('size_id')) {
                    formData.append(`${keyPrefix}size_id`, this.size_id);
                }
            } else {
                isItemAdded = true;
                formData.append(`${keyPrefix}size_id`, this.size_id);
            }
        }

        if (this.color_id) {
            if (shouldUpdate && oldbieItem) {
                if (this.color_id !== oldbieItem.get('color_id')) {
                    formData.append(`${keyPrefix}color_id`, this.color_id);
                }
            } else {
                isItemAdded = true;
                formData.append(`${keyPrefix}color_id`, this.color_id);
            }
        }

        if (this.uid) {
            if (shouldUpdate && oldbieItem) {
                if (this.uid !== oldbieItem.get('uid')) {
                    formData.append(`${keyPrefix}uid`, this.uid);
                }
            } else {
                isItemAdded = true;
                formData.append(`${keyPrefix}uid`, this.uid);
            }
        }

        if (this.glbFileData) {
            if (!shouldUpdate) {
                isItemAdded = true;
            }
            formData.append(`${keyPrefix}glb_file`, this.glbFileData.get('file'));
        } else if (!this.glb_file) {
            if (oldbieItem && oldbieItem.get('glb_file')) {
                if (!shouldUpdate) {
                    isItemAdded = true;
                }
                formData.append(`${keyPrefix}glb_file`, "delete");
            }
        }
        if (this.usdzFileData) {
            if (!shouldUpdate) {
                isItemAdded = true;
            }
            formData.append(`${keyPrefix}usdz_file`, this.usdzFileData.get('file'));
        }

        if (this.lighting_env) {
            if (shouldUpdate && oldbieItem) {
                if (!this.lighting_env.equals(oldbieItem.get('lighting_env'))) {
                    formData.append(
                        `${keyPrefix}lighting_env__env_image_preset_id`,
                        `${this.lighting_env.get('env_image_preset_id')}`
                    );
                    formData.append(`${keyPrefix}lighting_env__exposure`, `${this.lighting_env.get('exposure')}`);
                    this.lighting_env.get('rotation').makeFormData(formData, optionIndex, oldbieItem.get('lighting_env'));
                }
            } else {
                isItemAdded = true;

                formData.append(
                    `${keyPrefix}lighting_env__env_image_preset_id`,
                    `${this.lighting_env.get('env_image_preset_id')}`
                );
                formData.append(`${keyPrefix}lighting_env__exposure`, `${this.lighting_env.get('exposure')}`);
                this.lighting_env.get('rotation').makeFormData(formData, optionIndex);
            }
        }

        if (this.lighting_env_viewer) {
            if (shouldUpdate && oldbieItem) {
                if (!this.lighting_env_viewer.equals(oldbieItem.get('lighting_env_viewer'))) {
                    formData.append(
                        `${keyPrefix}lighting_env_viewer__env_image_preset_id`,
                        `${this.lighting_env_viewer.get('env_image_preset_id')}`
                    );
                    formData.append(`${keyPrefix}lighting_env_viewer__exposure`, `${this.lighting_env_viewer.get('exposure')}`);
                    this.lighting_env_viewer.get('rotation').makeFormDataViewer(formData, optionIndex, oldbieItem.get('lighting_env_viewer'));
                }
            } else {
                isItemAdded = true;

                formData.append(
                    `${keyPrefix}lighting_env_viewer__env_image_preset_id`,
                    `${this.lighting_env_viewer.get('env_image_preset_id')}`
                );
                formData.append(`${keyPrefix}lighting_env_viewer__exposure`, `${this.lighting_env_viewer.get('exposure')}`);
                this.lighting_env_viewer.get('rotation').makeFormDataViewer(formData, optionIndex);
            }
        }

        if (this.face_effect_info) {
            if (!shouldUpdate) {
                isItemAdded = true;
            }

            formData.append(`${keyPrefix}face_effect_info`, this.face_effect_info.toJsonString());
        }

        if (this.arGlbFileData) {
            if (!shouldUpdate) {
                isItemAdded = true;
            }
            formData.append(`${keyPrefix}ar_glb_file`, this.arGlbFileData.get('file'));
        } else if (!this.ar_glb_file) {
            if (oldbieItem && oldbieItem.get('ar_glb_file')) {
                if (!shouldUpdate) {
                    isItemAdded = true;
                }
                formData.append(`${keyPrefix}ar_glb_file`, "delete");
            }
        }
        if (this.arUsdzFileData) {
            if (!shouldUpdate) {
                isItemAdded = true;
            }
            formData.append(`${keyPrefix}ar_usdz_file`, this.arUsdzFileData.get('file'));
        }
        if (this.arPosGlbFileData) {
            if (!shouldUpdate) {
                isItemAdded = true;
            }
            formData.append(`${keyPrefix}ar_pos_glb_file`, this.arPosGlbFileData.get('file'));
        } else if (!this.ar_pos_glb_file) {
            if (oldbieItem && oldbieItem.get('ar_pos_glb_file')) {
                if (!shouldUpdate) {
                    isItemAdded = true;
                }
                formData.append(`${keyPrefix}ar_pos_glb_file`, "delete");
            }
        }

        if (this.arPosUsdzFileData) {
            if (!shouldUpdate) {
                isItemAdded = true;
            }
            formData.append(`${keyPrefix}ar_pos_usdz_file`, this.arPosUsdzFileData.get('file'));
        }

        if (this.glbFile2ndData) {
            if (!shouldUpdate) {
                isItemAdded = true;
            }
            formData.append(`${keyPrefix}glb_file_2nd`, this.glbFile2ndData.get('file'));
        } else if (!this.glb_file_2nd) {
            if (oldbieItem && oldbieItem.get('glb_file_2nd')) {
                if (!shouldUpdate) {
                    isItemAdded = true;
                }
                formData.append(`${keyPrefix}glb_file_2nd`, "delete");
            }
        }

        if (this.arPosGlbFile2ndData) {
            if (!shouldUpdate) {
                isItemAdded = true;
            }
            formData.append(`${keyPrefix}ar_pos_glb_file_2nd`, this.arPosGlbFile2ndData.get('file'));
        } else if (!this.ar_pos_glb_file_2nd) {
            if (oldbieItem && oldbieItem.get('ar_pos_glb_file_2nd')) {
                if (!shouldUpdate) {
                    isItemAdded = true;
                }
                formData.append(`${keyPrefix}ar_pos_glb_file_2nd`, "delete");
            }
        }

        if (this.zepetoThreeFileData) {
            if (!shouldUpdate) {
                isItemAdded = true;
            }
            formData.append(`${keyPrefix}zepeto_three_file`, this.zepetoThreeFileData.get('file'));
        } else if (!this.zepeto_three_file) {
            if (oldbieItem && oldbieItem.get('zepeto_three_file')) {
                if (!shouldUpdate) {
                    isItemAdded = true;
                }
                formData.append(`${keyPrefix}zepeto_three_file`, "delete");
            }
        }

        if (this.instagramThreeFileData) {
            if (!shouldUpdate) {
                isItemAdded = true;
            }
            formData.append(`${keyPrefix}instagram_three_file`, this.instagramThreeFileData.get('file'));
        } else if (!this.instagram_three_file) {
            if (oldbieItem && oldbieItem.get('instagram_three_file')) {
                if (!shouldUpdate) {
                    isItemAdded = true;
                }
                formData.append(`${keyPrefix}instagram_three_file`, "delete");
            }
        }

        if (this.arImage1Data) {
            if (!shouldUpdate) {
                isItemAdded = true;
            }

            formData.append(`${keyPrefix}ar_image_1`, this.arImage1Data.get('file'));
        } else if (!this.ar_image_1) {
            if (oldbieItem && oldbieItem.get('ar_image_1')) {
                if (!shouldUpdate) {
                    isItemAdded = true;
                }
                formData.append(`${keyPrefix}ar_image_1`, "delete");
            }
        }

        if (this.arImage2Data) {
            if (!shouldUpdate) {
                isItemAdded = true;
            }

            formData.append(`${keyPrefix}ar_image_2`, this.arImage2Data.get('file'));
        } else if (!this.ar_image_2) {
            if (oldbieItem && oldbieItem.get('ar_image_2')) {
                if (!shouldUpdate) {
                    isItemAdded = true;
                }
                formData.append(`${keyPrefix}ar_image_2`, "delete");
            }
        }

        if (this.ar_image_settings) {
            if (!shouldUpdate) {
                isItemAdded = true;
            }

            formData.append(`${keyPrefix}ar_image_settings`, this.ar_image_settings.toJsonString());
        }

        addIndex = 1;
        this.armtis.forEach(item => {
            const isAdded = item.makeFormData(optionIndex, addIndex, formData, oldbieItem);

            if (isAdded) {
                addIndex++;
            }
        });

        this.i18n_languages.forEach(item => {
            item.makeFormData(optionIndex, formData, oldbieItem);
        });

        this.i18n_countries.forEach(item => {
            item.makeFormData(optionIndex, formData, oldbieItem);
        });

        if (this.work_request && WORK_REQUEST_ABLE_CATEGORIES.includes(categoryUid)) {
            this.work_request.makeFormData(formData, categoryUid, optionIndex, oldbieItem);
        }

        if (this.order >= 0) {
            if (shouldUpdate && oldbieItem) {
                if (this.order !== oldbieItem.get('order')) {
                    formData.append(`${keyPrefix}order`, this.order);
                }
            } else {
                isItemAdded = true;
                formData.append(`${keyPrefix}order`, this.order);
            }
        }

        return !shouldUpdate && isItemAdded;
    }
}

export default ProductOption;
