import {EntityMixin} from "./Entity";
import ImageData from "./ImageData";
import {IMAGES_KEY, OPTIONS_KEY, UPDATED_PREFIX} from "../constants/CommonConstants";

const ProductOptionImageRecord = EntityMixin({
    product_option_id: 0,
    image: null,
    imageData: null,
    width: 0,
    height: 0,
    order: 0,
});

class ProductOptionImage extends ProductOptionImageRecord {

    constructor(args = {}) {
        super({
            ...args,
            imageData: args.imageData ? new ImageData(args.imageData) : null,
        });
    }

    canAdd() {
        return this.imageData;
    }

    isSame(other) {
        if (!other) {
            return false;
        }

        return this.id === other.get('id')
            && this.product_option_id === other.get('product_option_id')
            && this.imageData === other.get('imageData');
    }

    makeFormData(optionIndex, index, formData) {
        const shouldUpdate = this.id > 0;
        const keyPrefix = shouldUpdate
            ? `${UPDATED_PREFIX}${OPTIONS_KEY}${optionIndex}-${IMAGES_KEY}${this.id}-`
            : `${OPTIONS_KEY}${optionIndex}-${IMAGES_KEY}${index}-`;
        let isItemAdded = false;

        if (this.imageData) {
            if (!shouldUpdate) {
                isItemAdded = true;
            }
            formData.append(`${keyPrefix}image`, this.imageData.get('file'));
        }

        return !shouldUpdate && isItemAdded;
    }
}

export default ProductOptionImage;
