import {List, Map} from "immutable";

export function convertQueryParams2Str(queryObj, excludeKeys = List(), hasPrefix = true) {
    if (!queryObj) {
        return '';
    }

    const result = Object.keys(queryObj).reduce(function (previousValue, currentValue) {
        if (excludeKeys.includes(currentValue)) {
            return previousValue;
        }

        return [
            previousValue, previousValue && '&', currentValue, '=', encodeURIComponent(queryObj[currentValue])
        ].join('');
    }, '');

    if (!result) {
        return '';
    }

    return hasPrefix ? `?${result}` : result;
}

export function convertQueryParamStr2Map(queryParams) {
    let params = Map();

    `?${queryParams}`.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(str, key, value) {
        params = params.set(key, value);
    });

    return params;
}

export function convertUrlParamsStr2Map() {
    let params = Map();

    if (typeof window === 'undefined') {
        return params;
    }

    window.location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(str, key, value) {
        params = params.set(key, value);
    });

    return params;
}
