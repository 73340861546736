export const ERROR_1001_COMMON = '1001';
export const ERROR_1002_INVALID_DATA = '1002';
export const ERROR_1003_DUPLICATE_UID = '1003';

export const ERROR_1101_ALREADY_IN_USE_EMAIL = '1101';
export const ERROR_1102_FAIL_TO_SIGNIN = '1102';
export const ERROR_1103_USER_NOT_EXISTS = '1103';
export const ERROR_1104_CHANGE_PERSON_CREDENTIAL = '1104';
export const ERROR_1105_INVALID_SHOP_PATH = '1105';
export const ERROR_1106_EXPIRED_EMAIL_VERIFY_TOKEN = '1106';

export const ERROR_1201_DUPLICATE_UID = '1201';
export const ERROR_1202_CAN_NOT_REQUEST_TO_BUILDER_PRODUCT = '1202';

export const ERROR_3001_FAIL_TO_CAFE24_API = '3001';
export const ERROR_3002_FAIL_TO_SHOPIFY_API = '3002';
