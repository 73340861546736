import {Record} from "immutable";
import ImageData from "./ImageData";

export const DEFAULT_HORZ_SPACING = 30;
export const DEFAULT_VERT_SPACING = 40;
export const DEFAULT_M_HORZ_SPACING = 18;
export const DEFAULT_M_VERT_SPACING = 30;
export const DEFAULT_FLOATING_BUTTON_SIZE = 60;

const FloatingButtonPositionRecord = Record({
    horz_spacing: DEFAULT_HORZ_SPACING,
    vert_spacing: DEFAULT_VERT_SPACING,
    m_horz_spacing: DEFAULT_M_HORZ_SPACING,
    m_vert_spacing: DEFAULT_M_VERT_SPACING,
});

export class FloatingButtonPosition extends FloatingButtonPositionRecord {

    constructor(args = {}) {
        super({...args});
    }

    equals(other) {
        if (!other) {
            return false;
        }
        return this.horz_spacing === other.get('horz_spacing')
            && this.vert_spacing === other.get('vert_spacing')
            && this.m_horz_spacing === other.get('m_horz_spacing')
            && this.m_vert_spacing === other.get('m_vert_spacing');
    }
}

const FloatingButtonSizeRecord = Record({
    m: DEFAULT_FLOATING_BUTTON_SIZE,
    pc: DEFAULT_FLOATING_BUTTON_SIZE,
});

export class FloatingButtonSize extends FloatingButtonSizeRecord {

    constructor(args = {}) {
        super({...args});
    }

    equals(other) {
        if (!other) {
            return false;
        }
        return this.m === other.get('m') && this.pc === other.get('pc');
    }
}

const FloatingButtonStyleRecord = Record({
    is_visible: false,
    position: new FloatingButtonPosition(),
    image: null,
    imageData: null,
    size: new FloatingButtonSize(),
});

export class FloatingButtonStyle extends FloatingButtonStyleRecord {

    constructor(args = {}) {
        super({
            ...args,
            position: args.position ? new FloatingButtonPosition(args.position) : new FloatingButtonPosition(),
            imageData: args.imageData ? new ImageData(args.imageData) : null,
            size: args.size ? new FloatingButtonSize(args.size) : new FloatingButtonSize(),
        });
    }

    equals(other) {
        if (!other) {
            return false;
        }
        return this.is_visible === other.get('is_visible')
            && this.position.equals(other.get('position'))
            && this.image === other.get('image')
            && this.imageData === other.get('imageData')
            && this.size.equals(other.get('size'));
    }

    toJsonString() {
        return JSON.stringify({
            is_visible: this.is_visible,
            position: this.position.toJS(),
            size: this.size.toJS(),
        });
    }
}

const DetailArButtonStyleRecord = Record({
    bg_color: '#000000',
    text_color: '#ffffff',
    btn_id: '',
});

export class DetailArButtonStyle extends DetailArButtonStyleRecord {

    constructor(args = {}) {
        super({...args});
    }

    equals(other) {
        if (!other) {
            return false;
        }
        return this.bg_color === other.get('bg_color')
            && this.text_color === other.get('text_color')
            && this.btn_id === other.get('btn_id');
    }
}

const DetailMvButtonStyleRecord = Record({
    bg_color: '#000000',
    text_color: '#ffffff',
    btn_id: '',
});

export class DetailMvButtonStyle extends DetailMvButtonStyleRecord {

    constructor(args = {}) {
        super({...args});
    }

    equals(other) {
        if (!other) {
            return false;
        }
        return this.bg_color === other.get('bg_color')
            && this.text_color === other.get('text_color')
            && this.btn_id === other.get('btn_id');
    }
}
