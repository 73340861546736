import {ofType} from "redux-observable";
import {
    FAIL_GET_PRODUCT_CATEGORIES,
    FAIL_GET_PRODUCT_CATEGORY,
    REQUEST_GET_PRODUCT_CATEGORIES,
    REQUEST_GET_PRODUCT_CATEGORY,
    SUCCEED_GET_PRODUCT_CATEGORIES,
    SUCCEED_GET_PRODUCT_CATEGORY,
} from "../actions/RESTActionTypes";
import {catchError, map, mergeMap} from "rxjs/operators";
import {getProductCategories, getProductCategory} from "../../rest/ProductsAPI";
import {loadResult, loadResults, occurError} from "../actions/RESTAction";
import {of} from "rxjs";

export const getProductCategoriesEpic = action$ => action$.pipe(
    ofType(REQUEST_GET_PRODUCT_CATEGORIES),
    mergeMap(action => getProductCategories(action.queryParams).pipe(
        map(res => loadResults(SUCCEED_GET_PRODUCT_CATEGORIES, res.response, action.queryParams.page)),
        catchError(error => of(occurError(FAIL_GET_PRODUCT_CATEGORIES, error.xhr)))
    ))
);

export const getProductCategoryEpic = action$ => action$.pipe(
    ofType(REQUEST_GET_PRODUCT_CATEGORY),
    mergeMap(action => getProductCategory(action.categoryId).pipe(
        map(res => loadResult(SUCCEED_GET_PRODUCT_CATEGORY, res.response)),
        catchError(error => of(occurError(FAIL_GET_PRODUCT_CATEGORY, error.xhr)))
    ))
);
