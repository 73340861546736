import {
    FAIL_GET_PRODUCT_BY_MANAGER,
    FAIL_GET_PRODUCT_FOR_WORK_REQUEST_BY_MANAGER,
    FAIL_GET_PRODUCTS_BY_MANAGER,
    FAIL_INIT_PRODUCT_THREE_VIEWER_BY_MANAGER,
    REQUEST_CHANGE_PRODUCTS_BULK_DATA_BY_MANAGER,
    REQUEST_CREATE_PRODUCT_BY_MANAGER,
    REQUEST_DELETE_PRODUCTS_BY_MANAGER,
    REQUEST_GET_PRODUCT_BY_MANAGER,
    REQUEST_GET_PRODUCT_FOR_WORK_REQUEST_BY_MANAGER,
    REQUEST_GET_PRODUCTS_BY_MANAGER,
    REQUEST_GET_WORK_REQUEST_HISTORIES_BY_MANAGER,
    REQUEST_INIT_ADMIN_PRODUCT_ADD,
    REQUEST_INIT_ADMIN_PRODUCT_EDIT,
    REQUEST_INIT_PRODUCT_THREE_VIEWER_BY_MANAGER,
    REQUEST_UPDATE_PRODUCT_BY_MANAGER,
    REQUEST_GET_ENV_IMAGE_PRESETS_BY_MANAGER,
    SUCCEED_GET_PRODUCT_BY_MANAGER,
    SUCCEED_GET_PRODUCT_FOR_WORK_REQUEST_BY_MANAGER,
    SUCCEED_GET_PRODUCTS_BY_MANAGER,
    SUCCEED_INIT_ADMIN_PRODUCT_ADD,
    SUCCEED_INIT_ADMIN_PRODUCT_EDIT,
    SUCCEED_INIT_PRODUCT_THREE_VIEWER_BY_MANAGER,
    REQUEST_GET_FACE_EFFECTS_BY_MANAGER,
    SUCCEED_GET_FACE_EFFECTS_BY_MANAGER,
    FAIL_GET_FACE_EFFECTS_BY_MANAGER,
    SUCCEED_GET_ENV_IMAGE_PRESETS_BY_MANAGER,
    FAIL_GET_ENV_IMAGE_PRESETS_BY_MANAGER,
} from "../RESTActionTypes";

export function getProducts(
    managerInfo,
    queryParams,
    type = REQUEST_GET_PRODUCTS_BY_MANAGER,
    succeedType = SUCCEED_GET_PRODUCTS_BY_MANAGER,
    failType = FAIL_GET_PRODUCTS_BY_MANAGER,
) {
    return {type, managerInfo, queryParams, succeedType, failType};
}

export function initAdminProductAdd(managerInfo, shopId) {
    return {type: REQUEST_INIT_ADMIN_PRODUCT_ADD, managerInfo, shopId};
}

export function loadAdminProductAdd(readyProductDataResponse) {
    const {
        shop,
        brands,
        categories,
        env_image_presets,
        face_effects,
        product_sizes,
        product_colors,
    } = readyProductDataResponse.response.result;

    return {
        type: SUCCEED_INIT_ADMIN_PRODUCT_ADD,
        shop,
        brands,
        categories,
        env_image_presets,
        face_effects,
        product_sizes,
        product_colors,
    };
}

export function initAdminProductEdit(managerInfo, shopId, productId) {
    return {type: REQUEST_INIT_ADMIN_PRODUCT_EDIT, managerInfo, shopId, productId};
}

export function loadAdminProductEdit(readyProductDataResponse, productResponse) {
    const {
        shop,
        brands,
        categories,
        env_image_presets,
        face_effects,
        product_sizes,
        product_colors,
    } = readyProductDataResponse.response.result;

    return {
        type: SUCCEED_INIT_ADMIN_PRODUCT_EDIT,
        shop,
        brands,
        categories,
        env_image_presets,
        face_effects,
        product_sizes,
        product_colors,
        product: productResponse.response.result,
    };
}

export function createProduct(managerInfo, formData) {
    return {type: REQUEST_CREATE_PRODUCT_BY_MANAGER, managerInfo, formData};
}

export function getProduct(
    managerInfo,
    productId,
    type = REQUEST_GET_PRODUCT_BY_MANAGER,
    succeedType = SUCCEED_GET_PRODUCT_BY_MANAGER,
    failType = FAIL_GET_PRODUCT_BY_MANAGER,
) {
    return {type, managerInfo, productId, succeedType, failType};
}

export function updateProduct(managerInfo, productId, formData) {
    return {type: REQUEST_UPDATE_PRODUCT_BY_MANAGER, managerInfo, productId, formData};
}

export function deleteProducts(managerInfo, productIds) {
    return {type: REQUEST_DELETE_PRODUCTS_BY_MANAGER, managerInfo, productIds};
}

export function changeProductsBulkData(managerInfo, productIds, key, value) {
    return {type: REQUEST_CHANGE_PRODUCTS_BULK_DATA_BY_MANAGER, managerInfo, productIds, key, value};
}

export function initManagerProductThreeViewer(
    managerInfo,
    shopId,
    productId,
    type = REQUEST_INIT_PRODUCT_THREE_VIEWER_BY_MANAGER,
    succeedType = SUCCEED_INIT_PRODUCT_THREE_VIEWER_BY_MANAGER,
    failType = FAIL_INIT_PRODUCT_THREE_VIEWER_BY_MANAGER,
) {
    return {type, managerInfo, shopId, productId, succeedType, failType};
}

export function loadManagerProductThreeViewer(succeedType, shopResponse, productResponse) {
    return {
        type: succeedType,
        shop: shopResponse.response.result,
        product: productResponse.response.result,
    };
}

export function getWorkRequestHistories(page, managerInfo, workRequestId) {
    return {
        type: REQUEST_GET_WORK_REQUEST_HISTORIES_BY_MANAGER,
        page,
        managerInfo,
        workRequestId
    };
}

export function getProduct4WorkRequest(
    managerInfo,
    productId,
    type= REQUEST_GET_PRODUCT_FOR_WORK_REQUEST_BY_MANAGER,
    succeedType= SUCCEED_GET_PRODUCT_FOR_WORK_REQUEST_BY_MANAGER,
    failType= FAIL_GET_PRODUCT_FOR_WORK_REQUEST_BY_MANAGER,
) {
    return {managerInfo, productId, type, succeedType, failType};
}

export function getFaceEffects(
    managerInfo,
    queryParams,
    type = REQUEST_GET_FACE_EFFECTS_BY_MANAGER,
    succeedType = SUCCEED_GET_FACE_EFFECTS_BY_MANAGER,
    failType = FAIL_GET_FACE_EFFECTS_BY_MANAGER,
) {
    return {type, managerInfo, queryParams, succeedType, failType};
}

export function getEnvImagePresets(
    managerInfo,
    queryParams,
    type = REQUEST_GET_ENV_IMAGE_PRESETS_BY_MANAGER,
    succeedType = SUCCEED_GET_ENV_IMAGE_PRESETS_BY_MANAGER,
    failType = FAIL_GET_ENV_IMAGE_PRESETS_BY_MANAGER,
) {
    return {type, managerInfo, queryParams, succeedType, failType};
}