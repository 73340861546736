import {EntityMixin} from "./Entity";
import ImageData from "./ImageData";
import {List} from "immutable";

export const CATEGORY_UID_JEWELRY = "jewelry";
export const CATEGORY_UID_EARRING = "earring";
export const CATEGORY_UID_EYEWEAR = "eyewear";
export const CATEGORY_UID_HEADWEAR = "headwear";
export const CATEGORY_UID_MASK = "mask";
export const CATEGORY_UID_CONTACT_LENS = 'contact_lens';
export const CATEGORY_UIDS = List.of(CATEGORY_UID_JEWELRY, CATEGORY_UID_EYEWEAR);

const CategoryRecord = EntityMixin({
    uid: '',
    name: '',
    image: null,
    imageData: null,
    image_width: 0,
    image_height: 0,
    order: 0,
    lft: 0,
    rght: 0,
    tree_id: 0,
    level: 0,
    parent_id: null,
});

class Category extends CategoryRecord {

    constructor(args = {}) {
        super({
            ...args,
            imageData: args.imageData ? new ImageData(args.imageData) : null,
        });
    }

    getRootCategoryUid() {
        if (!this.uid) {
            return '';
        }
        const token = this.uid.split('__');

        return token.length > 0 ? token[0] : '';
    }

    canAdd() {
        return this.name;
    }

    canEdit(oldbie) {
        if (!oldbie) {
            return false;
        }

        return (this.name && this.name !== oldbie.get('name'))
            || this.imageData;
    }

    makeFormData(oldbie = null) {
        const formData = new FormData();

        if (this.name) {
            if (!oldbie || this.name !== oldbie.get('name')) {
                formData.append('name', this.name);
            }
        }

        if (this.imageData) {
            formData.append('image', this.imageData.get('file'));
        }

        return formData;
    }
}

export default Category;
