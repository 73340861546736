import {fromJS, isImmutable, List, Map, Set} from "immutable";
import RestError from "../../models/RestError";
import ShopStaff from "../../models/ShopStaff";
import ProductQueryParam from "../../models/ui/ProductQueryParam";
import Brand from "../../models/Brand";
import Category from "../../models/Category";
import Product from "../../models/Product";
import OrderItem from "../../models/ui/OrderItem";
import Manager from "../../models/Manager";
import Shop from "../../models/Shop";
import Plugin, {SkinSmoothingFilter} from "../../models/Plugin";
import ProductSize from "../../models/ProductSize";
import ProductColor from "../../models/ProductColor";
import EnvImagePreset from "../../models/EnvImagePreset";
import {QueryParameter} from "../../models/ui/QueryParameter";
import HistoryData from "../../models/HistoryData";
import ArModelTransformInfo from "../../models/ArModelTransformInfo";
import WorkRequest from "../../models/WorkRequest";
import Pricing from "../../models/Pricing";
import {ProductRequiredError} from "../../models/ui/ProductRequiredError";
import PricingPaymentHistory from "../../models/PricingPaymentHistory";
import PaymentHistoryQueryParam from "../../models/ui/PaymentHistoryQueryParam";
import FaceEffect from "../../models/FaceEffect";
import ShopQueryParam from "../../models/ui/ShopQueryParam";
import ProductOption, {FaceEffectInfo, LightingEnv} from "../../models/ProductOption";
import AulWhatsNew from "../../models/AulWhatsNew";

export function deserialize(state) {
    if (state === undefined || state === null) {
        return fromJS({});
    }

    if (isImmutable(state)) {
        state = state.toJS();
    }

    return fromJS({
        gnbManage: deserializeGnbManage(state.gnbManage),
        productThreeViewer: deserializeProductThreeViewer(state.productThreeViewer),
        productArViewer: deserializeProductArViewer(state.productArViewer),
        pluginArViewer: deserializePluginArViewer(state.pluginArViewer),
        sampleWebAr: deserializeSampleWebAr(state.sampleWebAr),
        sampleThreeViewer: deserializeSampleThreeViewer(state.sampleThreeViewer),

        shopStaffSignup: deserializeShopStaffSignup(state.shopStaffSignup),
        shopStaffSignin: deserializeShopStaffSignin(state.shopStaffSignin),
        shopStaffAccountSetting: deserializeShopStaffAccountSetting(state.shopStaffAccountSetting),
        shopStaffProductColors: deserializeShopStaffProductColors(state.shopStaffProductColors),
        shopStaffProductColorAdd: deserializeShopStaffProductColorAdd(state.shopStaffProductColorAdd),
        shopStaffProductColorEdit: deserializeShopStaffProductColorEdit(state.shopStaffProductColorEdit),
        shopStaffProducts: deserializeShopStaffProducts(state.shopStaffProducts),
        shopStaffWhatsNews: deserializeShopStaffWhatsNews(state.shopStaffWhatsNews),
        shopStaffProductsChangeOrder: deserializeShopStaffProductsChangeOrder(state.shopStaffProductsChangeOrder),
        shopStaffWhatsNewsChangeOrder: deserializeShopStaffWhatsNewsChangeOrder(state.shopStaffWhatsNewsChangeOrder),
        shopStaffProductAdd: deserializeShopStaffProductAdd(state.shopStaffProductAdd),
        shopStaffProductEdit: deserializeShopStaffProductEdit(state.shopStaffProductEdit),
        shopStaffController: deserializeShopStaffController(state.shopStaffController),

        shopStaffShopInfo: deserializeShopStaffShopInfo(state.shopStaffShopInfo),
        shopStaffPluginSettings: deserializeShopStaffPluginSettings(state.shopStaffPluginSettings),
        shopStaffPaymentHistories: deserializeShopStaffPaymentHistories(state.shopStaffPaymentHistories),
        pricingReducer: deserializeShopStaffPricing(state.pricingReducer),
        shopStaffPluginSkinFilterViewer: deserializeShopStaffPluginSkinFilterViewer(state.shopStaffPluginSkinFilterViewer),
        verifyEmail: deserializeShopStaffVerifyEmail(state.verifyEmail),

        adminSignin: deserializeAdminSignin(state.adminSignin),
        adminProducts: deserializeAdminProducts(state.adminProducts),
        adminProductAdd: deserializeAdminProductAdd(state.adminProductAdd),
        adminProductEdit: deserializeAdminProductEdit(state.adminProductEdit),
        adminController: deserializeAdminController(state.adminController),
        adminPaymentHistories: deserializeAdminPaymentHistories(state.adminPaymentHistories),
        adminShops: deserializeAdminShops(state.adminShops),
        adminShopInfo: deserializeAdminShopInfo(state.adminShopInfo),
    });
}

function deserializeGnbManage(reducer) {
    return fromJS({
        isOpenTemporaryDrawer: reducer.isOpenTemporaryDrawer,
        isOpenPersistentDrawer: reducer.isOpenPersistentDrawer,
    });
}

function deserializeProductThreeViewer(reducer) {
    return fromJS({
        isGetProductLoading: reducer.isGetProductLoading,
        isGetProductError: reducer.isGetProductError,
        isUpdateThreeMtiLoading: reducer.isUpdateThreeMtiLoading,
        isSucceedUpdateThreeMti: reducer.isSucceedUpdateThreeMti,
        isFailUpdateThreeMti: reducer.isFailUpdateThreeMti,
        isOpenMobileDrawer: reducer.isOpenMobileDrawer,
        tempLightingEnvViewer: reducer.tempLightingEnvViewer ? new LightingEnv(reducer.tempLightingEnvViewer) : null,
        shop: reducer.shop ? new Shop(reducer.shop) : null,
        product: reducer.product ? new Product(reducer.product) : null,
        envImagePresets: reducer.envImagePresets ?
            List(reducer.envImagePresets.map(item => new EnvImagePreset(item))) : List(),
        error: reducer.error ? new RestError(reducer.error) : null,
        isOptionContainerOpened: reducer.isOptionContainerOpened,
        selectedOptionIndex: reducer.selectedOptionIndex,
        isModelLoading: reducer.isModelLoading,
        errorModel: reducer.errorModel ? Map(reducer.errorModel) : Map({isModelReady: null, failToLoad: null}),
        isInitialized: reducer.isInitialized,
        isModelViewerPopupShown: reducer.isModelViewerPopupShown,
    });
}

function deserializeProductArViewer(reducer) {
    return fromJS({
        isLoading: reducer.isLoading,
        shop: reducer.shop ? new Shop(reducer.shop) : null,
        product: reducer.product ? new Product(reducer.product) : null,
        error: reducer.error ? new RestError(reducer.error) : null,
        isInitialized: reducer.isInitialized,
        isWebArPopupShown: reducer.isWebArPopupShown,
    });
}

function deserializePluginArViewer(reducer) {
    return fromJS({
        isLoading: reducer.isLoading,
        plugin: reducer.plugin ? new Plugin(reducer.plugin) : null,
        error: reducer.error ? new RestError(reducer.error) : null,
        isInitialized: reducer.isInitialized,
        isWebArPopupShown: reducer.isWebArPopupShown,
    });
}

function deserializeSampleWebAr(reducer) {
    return fromJS({
        isInitialized: reducer.isInitialized,
        isWebArPopupShown: reducer.isWebArPopupShown,
    });
}

function deserializeSampleThreeViewer(reducer) {
    return fromJS({
        isInitialized: reducer.isInitialized,
        isModelViewerPopupShown: reducer.isModelViewerPopupShown,
    });
}

function deserializeShopStaffSignup(reducer) {
    return fromJS({
        email: reducer.email,
        isEmailValid: reducer.isEmailValid,
        password: reducer.password,
        isPasswordValid: reducer.isPasswordValid,
        passwordCheck: reducer.passwordCheck,
        name: reducer.name,
        phoneNumber: reducer.phoneNumber,
        shopName: reducer.shopName,
        shopPath: reducer.shopPath,
        selectedCategory: reducer.selectedCategory,
        isValidCategory: reducer.isValidCategory,
        isLoading: reducer.isLoading,
        shopStaff: reducer.shopStaff ? new ShopStaff(reducer.shopStaff) : null,
        error: reducer.error ? new RestError(reducer.error) : null,
    });
}

function deserializeShopStaffSignin(reducer) {
    return fromJS({
        email: reducer.email,
        isEmailValid: reducer.isEmailValid,
        password: reducer.password,
        isPasswordValid: reducer.isPasswordValid,
        isLoading: reducer.isLoading,
        shopStaff: reducer.shopStaff ? new ShopStaff(reducer.shopStaff) : null,
        error: reducer.error ? new RestError(reducer.error) : null,
    });
}

function deserializeShopStaffAccountSetting(reducer) {
    return fromJS({
        shopStaff: reducer.shopStaff ? new ShopStaff(reducer.shopStaff) : null,
        newbie: reducer.newbie ? new ShopStaff(reducer.newbie) : null,
        isLoading: reducer.isLoading,
        isEditing: reducer.isEditing,
        error: reducer.error ? new RestError(reducer.error) : null,
        errorMessage: reducer.errorMessage,
        oldPassword: reducer.oldPassword,
        newPassword: reducer.newPassword,
        isDialogOpen: reducer.isDialogOpen,
        isFailChangePassword: reducer.isFailChangePassword,
        isFailEditShopStaff: reducer.isFailEditShopStaff,
        isSucceedChangePassword: reducer.isSucceedChangePassword,
        isSucceedEditShopStaff: reducer.isSucceedEditShopStaff,
        isSucceedSendVerificationEmail: reducer.isSucceedSendVerificationEmail,
        errorSendVerificationEmail: reducer.errorSendVerificationEmail ? new RestError(reducer.errorSendVerificationEmail) : null,
    })
}

function deserializeShopStaffProductColors(reducer) {
    return fromJS({
        isLoading: reducer.isLoading,
        isInitialized: reducer.isInitialized,
        productColors: reducer.productColors
            ? List(reducer.productColors.map(item => new ProductColor(item))) : List(),
        count: reducer.count,
        error: reducer.error ? new RestError(reducer.error) : null,
        queryParams: reducer.queryParams ? reducer.queryParams : new QueryParameter(),
        selectedItemIds: reducer.selectedItemIds ? Set(reducer.selectedItemIds) : Set(),
        isTaskWorking: reducer.isTaskWorking,
        succeedTask: reducer.succeedTask,
        succeedDelete: reducer.succeedDelete,
        errorTask: reducer.errorTask ? new RestError(reducer.errorTask) : null,
    });
}

function deserializeShopStaffProductColorAdd(reducer) {
    return fromJS({
        isLoading: reducer.isLoading,
        isSaveAndAddAnotherMode: reducer.isSaveAndAddAnotherMode,
        shop: reducer.shop ? new Shop(reducer.shop) : new Shop(),
        error: reducer.error ? new RestError(reducer.error) : null,
        productColor: reducer.productColor ? new ProductColor(reducer.productColor) : new ProductColor(),
        defaultLanguageCode: reducer.defaultLanguageCode,
        isCreating: reducer.isCreating,
        succeedCreate: reducer.succeedCreate,
        errorCreate: reducer.errorCreate ? new RestError(reducer.errorCreate) : null,
    });
}

function deserializeShopStaffProductColorEdit(reducer) {
    return fromJS({
        isLoading: reducer.isLoading,
        isSaveAndEditContinueEditingMode: reducer.isSaveAndEditContinueEditingMode,
        productColor: reducer.productColor ? new ProductColor(reducer.productColor) : new ProductColor(),
        error: reducer.error ? new RestError(reducer.error) : null,
        newbie: reducer.newbie ? new ProductColor(reducer.newbie) : new ProductColor(),
        defaultLanguageCode: reducer.defaultLanguageCode,
        deleteLanguageCodes: reducer.deleteLanguageCodes ? Set(reducer.deleteLanguageCodes) : Set(),
        isEditing: reducer.isEditing,
        succeedEdit: reducer.succeedEdit,
        errorEdit: reducer.errorEdit ? new RestError(reducer.errorEdit) : null,
    });
}

function deserializeShopStaffProducts(reducer) {
    return fromJS({
        isLoading: reducer.isLoading,
        isInitialized: reducer.isInitialized,
        brands: reducer.brands ? List(reducer.brands.map(item => new Brand(item))) : List(),
        categories: reducer.categories ? List(reducer.categories.map(item => new Category(item))) : List(),
        products: reducer.products ? List(reducer.products.map(item => new Product(item))) : List(),
        count: reducer.count,
        error: reducer.error ? new RestError(reducer.error) : null,
        queryParams: reducer.queryParams ? reducer.queryParams : new ProductQueryParam(),
        selectedItemIds: reducer.selectedItemIds ? Set(reducer.selectedItemIds) : Set(),
        isTaskWorking: reducer.isTaskWorking,
        succeedTask: reducer.succeedTask,
        succeedDelete: reducer.succeedDelete,
        errorTask: reducer.errorTask ? new RestError(reducer.errorTask) : null,
        isGetProductInfoLoading: reducer.isGetProductInfoLoading,
        isOpenWorkRequest: reducer.isOpenWorkRequest,
        isOpenArViewer: reducer.isOpenArViewer,
        shop: reducer.shop ? new Shop(reducer.shop) : null,
        product: reducer.product ? new Product(reducer.product) : null,
        isArViewerError: reducer.isArViewerError,
        isArViewerLoading: reducer.isArViewerLoading,
        batchFile: reducer.batchFile ? Set(reducer.batchFile) : Set(),
        copySampleProductCategory: reducer.copySampleProductCategory
            ? new Category(reducer.copySampleProductCategory)
            : null,
        isSuccessCopySampleProducts: reducer.isSuccessCopySampleProducts,
        errorCopySampleProducts: reducer.errorCopySampleProducts ? new RestError(reducer.errorCopySampleProducts) : null ,
        openCopySampleProductDialog: reducer.openCopySampleProductDialog,
        openBatchFileUploadDialog: reducer.openBatchFileUploadDialog,
    });
}

function deserializeShopStaffWhatsNews(reducer) {
    return fromJS({
        isLoading: reducer.isLoading,
        isInitialized: reducer.isInitialized,
        brands: reducer.brands ? List(reducer.brands.map(item => new Brand(item))) : List(),
        categories: reducer.categories ? List(reducer.categories.map(item => new Category(item))) : List(),
        products: reducer.products ? List(reducer.products.map(item => new Product(item))) : List(),
        selectedProductIds: reducer.selectedProductIds ? Set(reducer.selectedProductIds) : Set(),
        whatsNews: reducer.whatsNews ? List(reducer.whatsNews.map(item => new AulWhatsNew(item))) : List(),
        selectedWhatsNewIds: reducer.selectedWhatsNewIds ? Set(reducer.selectedWhatsNewIds) : Set(),
        count: reducer.count,
        error: reducer.error ? new RestError(reducer.error) : null,
        queryParams: reducer.queryParams ? reducer.queryParams : new ProductQueryParam(),
        isTaskWorking: reducer.isTaskWorking,
        succeedTask: reducer.succeedTask,
        succeedDelete: reducer.succeedDelete,
        errorTask: reducer.errorTask ? new RestError(reducer.errorTask) : null,
        isWhatsNewLoading: reducer.isWhatsNewLoading,
        whatsNewPage: reducer.whatsNewPage,
        whatsNewCount: reducer.whatsNewCount,
        whatsNewHasMore: reducer.whatsNewHasMore,
        whatsNewError: reducer.whatsNewError ? new RestError(reducer.whatsNewError) : null,
        openWhatsNewsDialog: reducer.openWhatsNewsDialog,
        searchWhatsNewQuery: reducer.searchWhatsNewQuery,
        shop: reducer.shop ? new Shop(reducer.shop) : null,
        copySampleProductCategory: reducer.copySampleProductCategory
            ? new Category(reducer.copySampleProductCategory)
            : null,
    });
}

function deserializeOrderData(reducer) {
    return reducer.orderData
        ? Object.keys(reducer.orderData).reduce((previousValue, currentValue) => {
            const item = reducer.orderData[currentValue];

            return item ? previousValue.set(
                parseInt(currentValue),
                new OrderItem({
                    id: item.id ? parseInt(item.id) : 0,
                    to_order: item.to_order ? parseInt(item.to_order) : -1,
                })
            ) : previousValue;
        }, Map())
        : Map();
}

function deserializeShopStaffProductsChangeOrder(reducer) {
    return fromJS({
        isOpened: reducer.isOpened,
        brand: reducer.brand ? new Brand(reducer.brand) : null,
        category: reducer.category ? new Category(reducer.category) : null,
        isLoading: reducer.isLoading,
        products: reducer.products ? List(reducer.products.map(item => new Product(item))) : List(),
        count: reducer.count,
        hasMore: reducer.hasMore,
        page: reducer.page,
        error: reducer.error ? new RestError(reducer.error) : null,
        isReordered: reducer.isReordered,
        orderData: deserializeOrderData(reducer),
        isChanging: reducer.isChanging,
        succeedChange: reducer.succeedChange,
        errorChange: reducer.errorChange ? new RestError(reducer.errorChange) : null,
    });
}

function deserializeShopStaffWhatsNewsChangeOrder(reducer) {
    return fromJS({
        isOpened: reducer.isOpened,
        brand: reducer.brand ? new Brand(reducer.brand) : null,
        category: reducer.category ? new Category(reducer.category) : null,
        isLoading: reducer.isLoading,
        whatsNews: reducer.whatsNews ? List(reducer.whatsNews.map(item => new AulWhatsNew(item))) : List(),
        count: reducer.count,
        hasMore: reducer.hasMore,
        page: reducer.page,
        error: reducer.error ? new RestError(reducer.error) : null,
        isReordered: reducer.isReordered,
        orderData: deserializeOrderData(reducer),
        isChanging: reducer.isChanging,
        succeedChange: reducer.succeedChange,
        errorChange: reducer.errorChange ? new RestError(reducer.errorChange) : null,
    });
}

function deserializeShopStaffProductAdd(reducer) {
    return fromJS({
        isInitialized: reducer.isInitialized,
        isLoading: reducer.isLoading,
        isSaveAndAddAnotherMode: reducer.isSaveAndAddAnotherMode,
        brands: reducer.brands ? List(reducer.brands.map(item => new Brand(item))) : List(),
        categories: reducer.categories ? List(reducer.categories.map(item => new Category(item))) : List(),
        productSizes: reducer.productSizes ? List(reducer.productSizes.map(item => new ProductSize(item))) : List(),
        productColors: reducer.productColors
            ? List(reducer.productColors.map(item => new ProductColor(item))) : List(),
        error: reducer.error ? new RestError(reducer.error) : null,
        defaultLanguageCode: reducer.defaultLanguageCode,
        defaultCountryCode: reducer.defaultCountryCode,
        product: reducer.product ? new Product(reducer.product) : new Product(),
        shop: reducer.shop ? new Shop(reducer.shop) : new Shop(),
        productOptionColors: reducer.productOptionColors
            ? List(reducer.productOptionColors.map(item => new ProductColor(item))) : List(),
        isCreating: reducer.isCreating,
        succeedCreate: reducer.succeedCreate,
        errorCreate: reducer.errorCreate ? new RestError(reducer.errorCreate) : null,
        isBuilderProductImported: reducer.isBuilderProductImported,
        errorImportBuilderProduct: reducer.errorImportBuilderProduct
            ? new RestError(reducer.errorImportBuilderProduct) : null,
        categoryRootUid: reducer.categoryRootUid,
        tempWorkRequest: reducer.tempWorkRequest ? new WorkRequest(reducer.tempWorkRequest) : new WorkRequest(),
        optionsSpecData: reducer.optionsSpecData,
        requiredErrors: reducer.requiredErrors ? new ProductRequiredError(reducer.requiredErrors) : new ProductRequiredError(),
        isOpenedChangeOptionDialog: reducer.isOpenedChangeOptionDialog,
        isReordered: reducer.isReordered,
        tempOptions: reducer.tempOptions ? List(reducer.tempOptions.map(item => new ProductOption(item))) : List(),
    });
}

function deserializeShopStaffProductEdit(reducer) {
    return fromJS({
        isLoading: reducer.isLoading,
        brands: reducer.brands ? List(reducer.brands.map(item => new Brand(item))) : List(),
        categories: reducer.categories ? List(reducer.categories.map(item => new Category(item))) : List(),
        productSizes: reducer.productSizes ? List(reducer.productSizes.map(item => new ProductSize(item))) : List(),
        productColors: reducer.productColors
            ? List(reducer.productColors.map(item => new ProductColor(item))) : List(),
        error: reducer.error ? new RestError(reducer.error) : null,
        defaultLanguageCode: reducer.defaultLanguageCode,
        defaultCountryCode: reducer.defaultCountryCode,
        product: reducer.product ? new Product(reducer.product) : new Product(),
        shop: reducer.shop ? new Shop(reducer.shop) : new Shop(),
        newbie: reducer.newbie ? new Product(reducer.newbie) : new Product(),
        productOptionColors: reducer.productOptionColors
            ? List(reducer.productOptionColors.map(item => new ProductColor(item))) : List(),
        deleteLanguageCodes: reducer.deleteLanguageCodes ? Set(reducer.deleteLanguageCodes) : Set(),
        deleteCountryCodes: reducer.deleteCountryCodes ? Set(reducer.deleteCountryCodes) : Set(),
        deleteOptionIds: reducer.deleteOptionIds ? Set(reducer.deleteOptionIds) : Set(),
        deleteOptionImageIds: reducer.deleteOptionImageIds ? Set(reducer.deleteOptionImageIds) : Set(),
        isEditing: reducer.isEditing,
        isSaveAndEditContinueEditingMode: reducer.isSaveAndEditContinueEditingMode,
        succeedEdit: reducer.succeedEdit,
        errorEdit: reducer.errorEdit ? new RestError(reducer.errorEdit) : null,
        isBuilderProductImported: reducer.isBuilderProductImported,
        errorImportBuilderProduct: reducer.errorImportBuilderProduct
            ? new RestError(reducer.errorImportBuilderProduct) : null,
        categoryRootUid: reducer.categoryRootUid,
        historyData: reducer.historyData ? new HistoryData(reducer.historyData) : new HistoryData(),
        isOpenHistory: reducer.isOpenHistory,
        isGetHistoryLoading: reducer.isGetHistoryLoading,
        tempWorkRequest: reducer.tempWorkRequest ? new WorkRequest(reducer.tempWorkRequest) : new WorkRequest(),
        optionsSpecData: reducer.optionsSpecData,
        requiredErrors: reducer.requiredErrors ? new ProductRequiredError(reducer.requiredErrors) : new ProductRequiredError(),
        isOpenedChangeOptionDialog: reducer.isOpenedChangeOptionDialog,
        tempOptions: reducer.tempOptions ? List(reducer.tempOptions.map(item => new ProductOption(item))) : List(),
    });
}

function deserializeShopStaffShopInfo(reducer) {
    return fromJS({
        isLoading: reducer.isLoading,
        shop: reducer.shop ? new Shop(reducer.shop) : new Shop(),
        error: reducer.error ? new RestError(reducer.error) : null,
        newbie: reducer.newbie ? new Shop(reducer.newbie) : new Shop(),
        defaultLanguageCode: reducer.defaultLanguageCode,
        deleteLanguageCodes: reducer.deleteLanguageCodes ? Set(reducer.deleteLanguageCodes) : Set(),
        isEditing: reducer.isEditing,
        succeedEdit: reducer.succeedEdit,
        errorEdit: reducer.errorEdit ? new RestError(reducer.errorEdit) : null,
    });
}

function deserializeShopStaffPluginSettings(reducer) {
    return fromJS({
        isLoading: reducer.isLoading,
        categories: reducer.categories ? List(reducer.categories.map(item => new Category(item))) : List(),
        rootCategoryUids: reducer.rootCategoryUids ? Set(reducer.rootCategoryUids) : Set(),
        plugin: reducer.plugin ? new Plugin(reducer.plugin) : new Plugin(),
        isCategoryValid: reducer.isCategoryValid,
        isScriptInstalled: reducer.isScriptInstalled,
        error: reducer.error ? new RestError(reducer.error) : null,
        newbie: reducer.newbie ? new Plugin(reducer.newbie) : new Plugin(),
        isEditing: reducer.isEditing,
        succeedEdit: reducer.succeedEdit,
        errorEdit: reducer.errorEdit ? new RestError(reducer.errorEdit) : null,
        isBuilderScripttagTasking: false,
        succeedBuilderScripttagTask: false,
        errorBuilderScripttagTask: reducer.errorBuilderScripttagTask
            ? new RestError(reducer.errorBuilderScripttagTask) : null,
    });
}

function deserializeShopStaffPaymentHistories(reducer) {
    return fromJS({
        shop: reducer.shop ? new Shop(reducer.shop) : null,
        isInitialized: reducer.isInitialized,
        isLoading: reducer.isLoading,
        count: reducer.count,
        error: reducer.error ? new RestError(reducer.error) : null,
        queryParams: reducer.queryParams ? reducer.queryParams : new PaymentHistoryQueryParam(),
        paymentHistories: reducer.paymentHistories ? List(reducer.paymentHistories.map(item => new PricingPaymentHistory(item))) : List(),
        selectedPaymentHistory: reducer.selectedPaymentHistory
            ? new PricingPaymentHistory(reducer.selectedPaymentHistory) : null
    });
}

function deserializeShopStaffPricing(reducer) {
    return fromJS({
        isLoading: reducer.isLoading,
        shop: reducer.shop ? new Shop(reducer.shop) : null,
        error: reducer.error ? new RestError(reducer.error) : null,
        pricingList: reducer.pricingList ? List(reducer.pricingList.map(item => new Pricing(item))) : List(),
        selectedCategoryUid: reducer.selectedCategoryUid,
        productSize: reducer.productSize,
        isLoadingPayCharge: reducer.isLoadingPayCharge,
        isSucceedPayCharge: reducer.isSucceedPayCharge,
        isFailPayCharge: reducer.isFailPayCharge,
        shopifyConfirmationUrl: reducer.shopifyConfirmationUrl,
        isSucceedCreateIamportPayment: reducer.isSucceedCreateIamportPayment,
        isErrorCreateIamportPayment: reducer.isErrorCreateIamportPayment,
        isSucceedCreatePaymentHistory: reducer.isSucceedCreatePaymentHistory,
        isErrorCreatePaymentHistory: reducer.isErrorCreatePaymentHistory,
        paymentHtml: reducer.paymentHtml,
        createdPaymentHistory: reducer.createdPaymentHistory
            ? new PricingPaymentHistory(reducer.createdPaymentHistory) : null,
        isSucceedTrialPlan: reducer.isSucceedTrialPlan,
        isErrorTrialPlan: reducer.isErrorTrialPlan,
        isLoadingIamportCharge: reducer.isLoadingIamportCharge,
        isSucceedByIamportModule: reducer.isSucceedByIamportModule,
        isErrorByIamportModule: reducer.isErrorByIamportModule,
    });
}

function deserializeShopStaffPluginSkinFilterViewer(reducer) {
    return fromJS({
        error: reducer.error ? new RestError(reducer.error) : null,
        isInitialized: reducer.isInitialized,
        isGetSkinSmoothingFilterLoading: reducer.isGetSkinSmoothingFilterLoading,
        isGetSkinSmoothingFilterError: reducer.isGetSkinSmoothingFilterError,
        isUpdateSkinSmoothingFilterLoading: reducer.isUpdateSkinSmoothingFilterLoading,
        isSucceedUpdateSkinSmoothingFilter: reducer.isSucceedUpdateSkinSmoothingFilter,
        isFailUpdateSkinSmoothingFilter: reducer.isFailUpdateSkinSmoothingFilter,
        isOpenMobileDrawer: reducer.isOpenMobileDrawer,
        plugin: reducer.plugin ? new Plugin(reducer.plugin) : new Plugin(),
        products: reducer.products ? reducer.products.map(item => new Product(item)) : List(),
        tempSkinSmoothingFilter: reducer.tempSkinSmoothingFilter ?
            new SkinSmoothingFilter(reducer.tempSkinSmoothingFilter) : new SkinSmoothingFilter(),
        newbie: reducer.newbie ? new Plugin(reducer.newbie) : new Plugin(),
    })
}

function deserializeShopStaffVerifyEmail(reducer) {
    return fromJS({
        isLoading: reducer.isLoading,
        error: reducer.error ? new RestError(reducer.error) : null,
        shopStaff: reducer.shopStaff ? new ShopStaff(reducer.shopStaff) : null,
        isValidToken: reducer.isValidToken,
        errorToken: reducer.errorToken ? new RestError(reducer.errorToken) : null,
    });
}

function deserializeShopStaffController(reducer) {
    return fromJS({
        error: reducer.error ? new RestError(reducer.error) : null,
        shop: reducer.shop ? new Shop(reducer.shop) : null,
        product: reducer.product ? new Product(reducer.product) : null,
        isInitialized: reducer.isInitialized,
        isGetProductLoading: reducer.isGetProductLoading,
        isOpenMobileDrawer: reducer.isOpenMobileDrawer,
        selectedProductOptionIndex: reducer.selectedProductOptionIndex,
        newbie: reducer.newbie ? new Product(reducer.newbie) : null,
        tempArMti: reducer.tempArMti ? new ArModelTransformInfo(reducer.tempArMti) : null,
        isSucceedUpdateArMti: reducer.isSucceedUpdateArMti,
        isFailUpdateArMti: reducer.isFailUpdateArMti,
        isGetProductError: reducer.isGetProductError,
        selectedModelDirection: reducer.selectedModelDirection,
    });
}

function deserializeAdminSignin(reducer) {
    return fromJS({
        email: reducer.email,
        isEmailValid: reducer.isEmailValid,
        password: reducer.password,
        isPasswordValid: reducer.isPasswordValid,
        isLoading: reducer.isLoading,
        manager: reducer.manager ? new Manager(reducer.manager) : null,
        error: reducer.error ? new RestError(reducer.error) : null,
    });
}

function deserializeAdminProducts(reducer) {
    return fromJS({
        isLoading: reducer.isLoading,
        isInitialized: reducer.isInitialized,
        shops: reducer.shops ? List(reducer.shops.map(item => new Shop(item))) : List(),
        products: reducer.products ? List(reducer.products.map(item => new Product(item))) : List(),
        count: reducer.count,
        error: reducer.error ? new RestError(reducer.error) : null,
        queryParams: reducer.queryParams ? reducer.queryParams : new ProductQueryParam(),
        selectedItemIds: reducer.selectedItemIds ? Set(reducer.selectedItemIds) : Set(),
        isTaskWorking: reducer.isTaskWorking,
        succeedTask: reducer.succeedTask,
        succeedDelete: reducer.succeedDelete,
        errorTask: reducer.errorTask ? new RestError(reducer.errorTask) : null,
        isGetProductInfoLoading: reducer.isGetProductInfoLoading,
        isOpenWorkRequest: reducer.isOpenWorkRequest,
    });
}

function deserializeAdminProductAdd(reducer) {
    return fromJS({
        isInitialized: reducer.isInitialized,
        isLoading: reducer.isLoading,
        isSaveAndAddAnotherMode: reducer.isSaveAndAddAnotherMode,
        brands: reducer.brands ? List(reducer.brands.map(item => new Brand(item))) : List(),
        categories: reducer.categories ? List(reducer.categories.map(item => new Category(item))) : List(),
        envImagePresets: reducer.envImagePresets ?
            List(reducer.envImagePresets.map(item => new EnvImagePreset(item))) : List(),
        faceEffects: reducer.faceEffects ?
            List(reducer.faceEffects.map(item => new FaceEffect(item))) : List(),
        productSizes: reducer.productSizes ? List(reducer.productSizes.map(item => new ProductSize(item))) : List(),
        productColors: reducer.productColors
            ? List(reducer.productColors.map(item => new ProductColor(item))) : List(),
        error: reducer.error ? new RestError(reducer.error) : null,
        defaultLanguageCode: reducer.defaultLanguageCode,
        defaultCountryCode: reducer.defaultCountryCode,
        product: reducer.product ? new Product(reducer.product) : new Product(),
        shop: reducer.shop ? new Shop(reducer.shop) : new Shop(),
        productOptionColors: reducer.productOptionColors
            ? List(reducer.productOptionColors.map(item => new ProductColor(item))) : List(),
        isCreating: reducer.isCreating,
        succeedCreate: reducer.succeedCreate,
        errorCreate: reducer.errorCreate ? new RestError(reducer.errorCreate) : null,
        categoryRootUid: reducer.categoryRootUid,
        isOpenWorkRequest: reducer.isOpenWorkRequest,
        tempArMtis: reducer.tempArMtis,
        arMtisData: reducer.arMtisData,
        arMtiOrder: reducer.arMtiOrder,
        requiredErrors: reducer.requiredErrors ? new ProductRequiredError(reducer.requiredErrors) : new ProductRequiredError(),
        isOpenedChangeOptionDialog: reducer.isOpenedChangeOptionDialog,
        isReordered: reducer.isReordered,
        tempOptions: reducer.tempOptions ? List(reducer.tempOptions.map(item => new ProductOption(item))) : List(),
    });
}

function deserializeAdminProductEdit(reducer) {
    return fromJS({
        isLoading: reducer.isLoading,
        brands: reducer.brands ? List(reducer.brands.map(item => new Brand(item))) : List(),
        categories: reducer.categories ? List(reducer.categories.map(item => new Category(item))) : List(),
        envImagePresets: reducer.envImagePresets ?
            List(reducer.envImagePresets.map(item => new EnvImagePreset(item))) : List(),
        faceEffects: reducer.faceEffects ?
            List(reducer.faceEffects.map(item => new FaceEffect(item))) : List(),
        productSizes: reducer.productSizes ? List(reducer.productSizes.map(item => new ProductSize(item))) : List(),
        productColors: reducer.productColors
            ? List(reducer.productColors.map(item => new ProductColor(item))) : List(),
        product: reducer.product ? new Product(reducer.product) : new Product(),
        error: reducer.error ? new RestError(reducer.error) : null,
        newbie: reducer.newbie ? new Product(reducer.newbie) : new Product(),
        productOptionColors: reducer.productOptionColors
            ? List(reducer.productOptionColors.map(item => new ProductColor(item))) : List(),
        defaultLanguageCode: reducer.defaultLanguageCode,
        defaultCountryCode: reducer.defaultCountryCode,
        deleteLanguageCodes: reducer.deleteLanguageCodes ? Set(reducer.deleteLanguageCodes) : Set(),
        deleteCountryCodes: reducer.deleteCountryCodes ? Set(reducer.deleteCountryCodes) : Set(),
        deleteOptionIds: reducer.deleteOptionIds ? Set(reducer.deleteOptionIds) : Set(),
        deleteOptionArMtiIds: reducer.deleteOptionArMtiIds ? Set(reducer.deleteOptionArMtiIds) : Set(),
        deleteOptionImageIds: reducer.deleteOptionImageIds ? Set(reducer.deleteOptionImageIds) : Set(),
        isEditing: reducer.isEditing,
        succeedEdit: reducer.succeedEdit,
        errorEdit: reducer.errorEdit ? new RestError(reducer.errorEdit) : null,
        isBuilderProductImported: reducer.isBuilderProductImported,
        errorImportBuilderProduct: reducer.errorImportBuilderProduct
            ? new RestError(reducer.errorImportBuilderProduct) : null,
        categoryRootUid: reducer.categoryRootUid,
        isOpenHistories: reducer.isOpenHistories,
        tempArMtis: reducer.tempArMtis,
        arMtisData: reducer.arMtisData,
        historyData: reducer.historyData ? new HistoryData(reducer.historyData) : new HistoryData(),
        isOpenHistory: reducer.isOpenHistory,
        isGetHistoryLoading: reducer.isGetHistoryLoading,
        arMtiOrder: reducer.arMtiOrder,
        tempWorkRequest: reducer.tempWorkRequest ? new WorkRequest(reducer.tempWorkRequest) : new WorkRequest(),
        isSaveAndEditContinueEditingMode: reducer.isSaveAndEditContinueEditingMode,
        isEnableEdit: reducer.isEnableEdit,
        requiredErrors: reducer.requiredErrors ? new ProductRequiredError(reducer.requiredErrors) : new ProductRequiredError(),
        isOpenedChangeOptionDialog: reducer.isOpenedChangeOptionDialog,
        tempOptions:reducer.tempOptions ? List(reducer.tempOptions.map(item => new ProductOption(item))) : List(),
    });
}

function deserializeAdminController(reducer) {
    return fromJS({
        error: reducer.error ? new RestError(reducer.error) : null,
        shop: reducer.shop ? new Shop(reducer.shop) : null,
        product: reducer.product ? new Product(reducer.product) : null,
        isInitialized: reducer.isInitialized,
        isGetProductLoading: reducer.isGetProductLoading,
        isOpenMobileDrawer: reducer.isOpenMobileDrawer,
        selectedProductOptionIndex: reducer.selectedProductOptionIndex,
        newbie: reducer.newbie ? new Product(reducer.newbie) : null,
        tempArMti: reducer.tempArMti ? new ArModelTransformInfo(reducer.tempArMti) : null,
        tempLightingEnv: reducer.tempLightingEnv ? new LightingEnv(reducer.tempLightingEnv) : null,
        envImagePresets: reducer.envImagePresets ? List(reducer.envImagePresets.map(item => new EnvImagePreset(item))) : List(),
        faceEffects: reducer.faceEffects ? List(reducer.faceEffects.map(item => new FaceEffect(item))) : List(),
        tempFaceEffectInfo: reducer.tempFaceEffectInfo ? new FaceEffectInfo(reducer.tempFaceEffectInfo) : null,
        isSucceedUpdateArMti: reducer.isSucceedUpdateArMti,
        isFailUpdateArMti: reducer.isFailUpdateArMti,
        isGetProductError: reducer.isGetProductError,
        selectedModelDirection: reducer.selectedModelDirection,
    });
}

function deserializeAdminPaymentHistories(reducer) {
    return fromJS({
        isInitialized: reducer.isInitialized,
        isLoading: reducer.isLoading,
        count: reducer.count,
        error: reducer.error ? new RestError(reducer.error) : null,
        queryParams: reducer.queryParams ? reducer.queryParams : new PaymentHistoryQueryParam(),
        paymentHistories: reducer.paymentHistories ? List(reducer.paymentHistories.map(item => new PricingPaymentHistory(item))) : List(),
        shops: reducer.shops ? List(reducer.shops.map(item => new Shop(item))) : List(),
    });
}

function deserializeAdminShops(reducer) {
    return fromJS({
        isInitialized: reducer.isInitialized,
        isLoading: reducer.isLoading,
        count: reducer.count,
        error: reducer.error ? new RestError(reducer.error) : null,
        queryParams: reducer.queryParams ? new ShopQueryParam(reducer.queryParams) : new ShopQueryParam(),
        shops: reducer.shops ? List(reducer.shops.map(item => new Shop(item))) : List(),
    })
}

function deserializeAdminShopInfo(reducer) {
    return fromJS({
        isInitialized: reducer.isInitialized,
        isLoading: reducer.isLoading,
        shop: reducer.shop ? new Shop(reducer.shop) : new Shop(),
        error: null,
        newbie: reducer.newbie ? new Shop(reducer.newbie) : new Shop(),
    })
}
