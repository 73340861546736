import {request} from "universal-rxjs-ajax";
import {CONTENT_TYPE_JSON, createBasicHeader, createShopStaffHeaders, getBaseUrl} from "./APIClient";
import {convertQueryParams2Str} from "../utils/UrlUtils";

const PATH_SHOP_STAFFS = '/shop_staffs';
const PATH_SHOPS = '/shops';
const PATH_BRANDS = '/brands';
const PATH_PRODUCT_CATEGORIES = '/product_categories';
const PATH_PRODUCTS = '/products';
const PATH_WHATS_NEWS = '/aul_whats_news';
const PATH_PRODUCT_SIZES = '/product_sizes';
const PATH_PRODUCT_COLORS = '/product_colors';
const PATH_WORK_REQUESTS = '/work_requests';
const PATH_PRICING_PAYMENT_HISTORIES = '/pricing_payment_histories';
const PATH_HISTORIES = '/histories';
const PATH_PAYMENT = '/payments';
const PATH_SHOPIFY = '/shopify';
const PATH_TRIAL = '/trial'
const PATH_RECURRING_CHARGE = '/recurring_charge';
const PATH_ANNUAL_CHARGE = '/annual_charge';
const PATH_PRODUCT_CHARGE = '/product_charge';
const PATH_OPTIONS = '/options';
const PATH_ARMTIS = '/ar_mtis';
const PATH_UPLOAD_BATCH_FILE = '/upload_batch_file';
const PATH_PLUGINS = '/plugins';
const PATH_COPY_SAMPLE_PRODUCTS = '/copy_sample_products';
const PATH_IAMPORT = '/iamport';
const PATH_SCHEDULE_CHARGE = '/schedule_charge';
const PATH_SEND_VERIFICATION_EMAIL = '/send_verification_email';
const PATH_VERIFY_EMAIL = '/verify_email';

export function signup(payload) {
    const path = `${PATH_SHOP_STAFFS}/signup`;

    return request({
        url: `${getBaseUrl()}${path}`,
        method: 'POST',
        body: payload,
        headers: createShopStaffHeaders(path, null, CONTENT_TYPE_JSON),
    });
}

export function signin(email, password) {
    const path = `${PATH_SHOP_STAFFS}/signin`;

    return request({
        url: `${getBaseUrl()}${path}`,
        method: 'POST',
        body: {email, password},
        headers: createShopStaffHeaders(path, null, CONTENT_TYPE_JSON),
    });
}


export function verifyEmail(email, token) {
    const path = `${PATH_SHOP_STAFFS}${PATH_VERIFY_EMAIL}`;

    return request({
        url: `${getBaseUrl()}${path}`,
        method: 'POST',
        body: {email, token},
        headers: createBasicHeader(path)
    });
}

export function getShopStaff(shopStaffInfo) {
    const path = `${PATH_SHOP_STAFFS}/${shopStaffInfo.shopStaffId}`;

    return request({
        url: `${getBaseUrl()}${path}`,
        method: 'GET',
        headers: createShopStaffHeaders(path, shopStaffInfo),
    });
}

export function updateShopStaff(shopStaffInfo, name) {
    const path = `${PATH_SHOP_STAFFS}/${shopStaffInfo.shopStaffId}`;

    return request({
        url: `${getBaseUrl()}${path}`,
        method: 'PUT',
        body: {name},
        headers: createShopStaffHeaders(path, shopStaffInfo, CONTENT_TYPE_JSON),
    });
}

export function changeShopStaffPassword(shopStaffInfo, old_password, new_password) {
    const path = `${PATH_SHOP_STAFFS}/${shopStaffInfo.shopStaffId}/change_password`;

    return request({
        url: `${getBaseUrl()}${path}`,
        method: 'POST',
        body: {old_password, new_password},
        headers: createShopStaffHeaders(path, shopStaffInfo, CONTENT_TYPE_JSON),
    });
}

export function getShop(shopStaffInfo) {
    const path = `${PATH_SHOP_STAFFS}/${shopStaffInfo.shopStaffId}${PATH_SHOPS}/${shopStaffInfo.shopStaffShopId}`;

    return request({
        url: `${getBaseUrl()}${path}`,
        method: 'GET',
        headers: createShopStaffHeaders(path, shopStaffInfo),
    });
}

export function updateShop(shopStaffInfo, formData) {
    const path = `${PATH_SHOP_STAFFS}/${shopStaffInfo.shopStaffId}${PATH_SHOPS}/${shopStaffInfo.shopStaffShopId}`;

    return request({
        url: `${getBaseUrl()}${path}`,
        method: 'PUT',
        body: formData,
        headers: createShopStaffHeaders(path, shopStaffInfo),
    });
}

export function getShopPlugin(shopStaffInfo) {
    const path = `${PATH_SHOP_STAFFS}/${shopStaffInfo.shopStaffId}${PATH_SHOPS}/${shopStaffInfo.shopStaffShopId}/plugin`;

    return request({
        url: `${getBaseUrl()}${path}`,
        method: 'GET',
        headers: createShopStaffHeaders(path, shopStaffInfo),
    });
}

export function updateShopPlugin(shopStaffInfo, pluginId, formData) {
    const path = `${PATH_SHOP_STAFFS}/${shopStaffInfo.shopStaffId}${PATH_SHOPS}/${shopStaffInfo.shopStaffShopId}/plugins/${pluginId}`;

    return request({
        url: `${getBaseUrl()}${path}`,
        method: 'PUT',
        body: formData,
        headers: createShopStaffHeaders(path, shopStaffInfo),
    });
}

export function getPaymentHistories(shopStaffInfo, queryParams) {
    const path = `${PATH_SHOP_STAFFS}/${shopStaffInfo.shopStaffId}${PATH_PRICING_PAYMENT_HISTORIES}`;
    let url = `${getBaseUrl()}${path}${convertQueryParams2Str(queryParams)}`;

    return request({url, method: 'GET', headers: createShopStaffHeaders(path, shopStaffInfo)});
}

export function getBrands(shopStaffInfo, queryParams) {
    const path = `${PATH_SHOP_STAFFS}/${shopStaffInfo.shopStaffId}${PATH_BRANDS}`;
    let url = `${getBaseUrl()}${path}${convertQueryParams2Str(queryParams)}`;

    return request({url, method: 'GET', headers: createShopStaffHeaders(path, shopStaffInfo)});
}

export function createBrand(shopStaffInfo, formData) {
    const path = `${PATH_SHOP_STAFFS}/${shopStaffInfo.shopStaffId}${PATH_BRANDS}`;

    return request({
        url: `${getBaseUrl()}${path}`,
        method: 'POST',
        body: formData,
        headers: createShopStaffHeaders(path, shopStaffInfo),
    });
}

export function getBrand(shopStaffInfo, brandId) {
    const path = `${PATH_SHOP_STAFFS}/${shopStaffInfo.shopStaffId}${PATH_BRANDS}/${brandId}`;

    return request({
        url: `${getBaseUrl()}${path}`,
        method: 'GET',
        headers: createShopStaffHeaders(path, shopStaffInfo),
    });
}

export function updateBrand(shopStaffInfo, brandId, formData) {
    const path = `${PATH_SHOP_STAFFS}/${shopStaffInfo.shopStaffId}${PATH_BRANDS}/${brandId}`;

    return request({
        url: `${getBaseUrl()}${path}`,
        method: 'PUT',
        body: formData,
        headers: createShopStaffHeaders(path, shopStaffInfo),
    });
}

export function deleteBrands(shopStaffInfo, brand_ids) {
    const path = `${PATH_SHOP_STAFFS}/${shopStaffInfo.shopStaffId}${PATH_BRANDS}/delete`;

    return request({
        url: `${getBaseUrl()}${path}`,
        method: 'POST',
        body: {brand_ids},
        headers: createShopStaffHeaders(path, shopStaffInfo, CONTENT_TYPE_JSON),
    });
}

export function changeBrandsOrder(shopStaffInfo, order_items) {
    const path = `${PATH_SHOP_STAFFS}/${shopStaffInfo.shopStaffId}${PATH_BRANDS}/change_order`;

    return request({
        url: `${getBaseUrl()}${path}`,
        method: 'POST',
        body: {order_items},
        headers: createShopStaffHeaders(path, shopStaffInfo, CONTENT_TYPE_JSON),
    });
}

export function getProductCategories(shopStaffInfo, queryParams) {
    const path = `${PATH_SHOP_STAFFS}/${shopStaffInfo.shopStaffId}${PATH_PRODUCT_CATEGORIES}`;
    let url = `${getBaseUrl()}${path}${convertQueryParams2Str(queryParams)}`;

    return request({url, method: 'GET', headers: createShopStaffHeaders(path, shopStaffInfo)});
}

export function getProductSizes(shopStaffInfo, queryParams) {
    const path = `${PATH_SHOP_STAFFS}/${shopStaffInfo.shopStaffId}${PATH_PRODUCT_SIZES}`;
    let url = `${getBaseUrl()}${path}${convertQueryParams2Str(queryParams)}`;

    return request({url, method: 'GET', headers: createShopStaffHeaders(path, shopStaffInfo)});
}

export function createProductSize(shopStaffInfo, formData) {
    const path = `${PATH_SHOP_STAFFS}/${shopStaffInfo.shopStaffId}${PATH_PRODUCT_SIZES}`;

    return request({
        url: `${getBaseUrl()}${path}`,
        method: 'POST',
        body: formData,
        headers: createShopStaffHeaders(path, shopStaffInfo),
    });
}

export function getProductSize(shopStaffInfo, productSizeId) {
    const path = `${PATH_SHOP_STAFFS}/${shopStaffInfo.shopStaffId}${PATH_PRODUCT_SIZES}/${productSizeId}`;

    return request({
        url: `${getBaseUrl()}${path}`,
        method: 'GET',
        headers: createShopStaffHeaders(path, shopStaffInfo),
    });
}

export function updateProductSize(shopStaffInfo, productSizeId, formData) {
    const path = `${PATH_SHOP_STAFFS}/${shopStaffInfo.shopStaffId}${PATH_PRODUCT_SIZES}/${productSizeId}`;

    return request({
        url: `${getBaseUrl()}${path}`,
        method: 'PUT',
        body: formData,
        headers: createShopStaffHeaders(path, shopStaffInfo),
    });
}

export function deleteProductSizes(shopStaffInfo, product_size_ids) {
    const path = `${PATH_SHOP_STAFFS}/${shopStaffInfo.shopStaffId}${PATH_PRODUCT_SIZES}/delete`;

    return request({
        url: `${getBaseUrl()}${path}`,
        method: 'POST',
        body: {product_size_ids},
        headers: createShopStaffHeaders(path, shopStaffInfo, CONTENT_TYPE_JSON),
    });
}

export function getProductColors(shopStaffInfo, queryParams) {
    const path = `${PATH_SHOP_STAFFS}/${shopStaffInfo.shopStaffId}${PATH_PRODUCT_COLORS}`;
    let url = `${getBaseUrl()}${path}${convertQueryParams2Str(queryParams)}`;

    return request({url, method: 'GET', headers: createShopStaffHeaders(path, shopStaffInfo)});
}

export function createProductColor(shopStaffInfo, formData) {
    const path = `${PATH_SHOP_STAFFS}/${shopStaffInfo.shopStaffId}${PATH_PRODUCT_COLORS}`;

    return request({
        url: `${getBaseUrl()}${path}`,
        method: 'POST',
        body: formData,
        headers: createShopStaffHeaders(path, shopStaffInfo),
    });
}

export function getProductColor(shopStaffInfo, productColorId) {
    const path = `${PATH_SHOP_STAFFS}/${shopStaffInfo.shopStaffId}${PATH_PRODUCT_COLORS}/${productColorId}`;

    return request({
        url: `${getBaseUrl()}${path}`,
        method: 'GET',
        headers: createShopStaffHeaders(path, shopStaffInfo),
    });
}

export function updateProductColor(shopStaffInfo, productColorId, formData) {
    const path = `${PATH_SHOP_STAFFS}/${shopStaffInfo.shopStaffId}${PATH_PRODUCT_COLORS}/${productColorId}`;

    return request({
        url: `${getBaseUrl()}${path}`,
        method: 'PUT',
        body: formData,
        headers: createShopStaffHeaders(path, shopStaffInfo),
    });
}

export function deleteProductColors(shopStaffInfo, product_color_ids) {
    const path = `${PATH_SHOP_STAFFS}/${shopStaffInfo.shopStaffId}${PATH_PRODUCT_COLORS}/delete`;

    return request({
        url: `${getBaseUrl()}${path}`,
        method: 'POST',
        body: {product_color_ids},
        headers: createShopStaffHeaders(path, shopStaffInfo, CONTENT_TYPE_JSON),
    });
}

export function getProducts(shopStaffInfo, queryParams) {
    const path = `${PATH_SHOP_STAFFS}/${shopStaffInfo.shopStaffId}${PATH_PRODUCTS}`;
    let url = `${getBaseUrl()}${path}${convertQueryParams2Str(queryParams)}`;

    return request({url, method: 'GET', headers: createShopStaffHeaders(path, shopStaffInfo)});
}

export function getWhatsNews(shopStaffInfo, queryParams) {
    const path = `${PATH_SHOP_STAFFS}/${shopStaffInfo.shopStaffId}${PATH_WHATS_NEWS}`;
    let url = `${getBaseUrl()}${path}${convertQueryParams2Str({...queryParams, disable_paging: 1})}`;

    return request({url, method: 'GET', headers: createShopStaffHeaders(path, shopStaffInfo)});
}

export function readyProductData(shopStaffInfo, queryParams = {}) {
    const path = `${PATH_SHOP_STAFFS}/${shopStaffInfo.shopStaffId}/ready_product_data`;
    let url = `${getBaseUrl()}${path}${convertQueryParams2Str(queryParams)}`;

    return request({url, method: 'GET', headers: createShopStaffHeaders(path, shopStaffInfo)});
}

export function importBuilderProductInfo(shopStaffInfo, category_uid, uid) {
    const path = `${PATH_SHOP_STAFFS}/${shopStaffInfo.shopStaffId}${PATH_PRODUCTS}/import_builder_product_info`;
    let url = `${getBaseUrl()}${path}${convertQueryParams2Str({category_uid, uid})}`;

    return request({url, method: 'GET', headers: createShopStaffHeaders(path, shopStaffInfo)});
}

export function createProduct(shopStaffInfo, formData) {
    const path = `${PATH_SHOP_STAFFS}/${shopStaffInfo.shopStaffId}${PATH_PRODUCTS}`;

    return request({
        url: `${getBaseUrl()}${path}`,
        method: 'POST',
        body: formData,
        headers: createShopStaffHeaders(path, shopStaffInfo),
    });
}

export function createWhatsNews(shopStaffInfo, product_ids) {
    const path = `${PATH_SHOP_STAFFS}/${shopStaffInfo.shopStaffId}${PATH_WHATS_NEWS}`;

    return request({
        url: `${getBaseUrl()}${path}`,
        method: 'POST',
        body: {product_ids},
        headers: createShopStaffHeaders(path, shopStaffInfo, CONTENT_TYPE_JSON),
    });
}

export function copySampleProducts(shopStaffInfo, category_uid) {
    const path = `${PATH_SHOP_STAFFS}/${shopStaffInfo.shopStaffId}${PATH_COPY_SAMPLE_PRODUCTS}`;

    return request({
        url: `${getBaseUrl()}${path}`,
        method: 'POST',
        body: {category_uid},
        headers: createShopStaffHeaders(path, shopStaffInfo),
    });
}

export function getProduct(shopStaffInfo, productId) {
    const path = `${PATH_SHOP_STAFFS}/${shopStaffInfo.shopStaffId}${PATH_PRODUCTS}/${productId}`;

    return request({
        url: `${getBaseUrl()}${path}`,
        method: 'GET',
        headers: createShopStaffHeaders(path, shopStaffInfo),
    });
}

export function updateProduct(shopStaffInfo, productId, formData) {
    const path = `${PATH_SHOP_STAFFS}/${shopStaffInfo.shopStaffId}${PATH_PRODUCTS}/${productId}`;

    return request({
        url: `${getBaseUrl()}${path}`,
        method: 'PUT',
        body: formData,
        headers: createShopStaffHeaders(path, shopStaffInfo),
    });
}

export function deleteProducts(shopStaffInfo, product_ids) {
    const path = `${PATH_SHOP_STAFFS}/${shopStaffInfo.shopStaffId}${PATH_PRODUCTS}/delete`;

    return request({
        url: `${getBaseUrl()}${path}`,
        method: 'POST',
        body: {product_ids},
        headers: createShopStaffHeaders(path, shopStaffInfo, CONTENT_TYPE_JSON),
    });
}

export function deleteWhatsNews(shopStaffInfo, aul_whats_new_ids) {
    const path = `${PATH_SHOP_STAFFS}/${shopStaffInfo.shopStaffId}${PATH_WHATS_NEWS}/delete`;

    return request({
        url: `${getBaseUrl()}${path}`,
        method: 'POST',
        body: {aul_whats_new_ids},
        headers: createShopStaffHeaders(path, shopStaffInfo, CONTENT_TYPE_JSON),
    });
}

export function changeProductsBulkData(shopStaffInfo, product_ids, key, value) {
    const path = `${PATH_SHOP_STAFFS}/${shopStaffInfo.shopStaffId}${PATH_PRODUCTS}/change_bulk_data`;

    return request({
        url: `${getBaseUrl()}${path}`,
        method: 'POST',
        body: {product_ids, key, value},
        headers: createShopStaffHeaders(path, shopStaffInfo, CONTENT_TYPE_JSON),
    });
}

export function changeProductsOrder(shopStaffInfo, order_items) {
    const path = `${PATH_SHOP_STAFFS}/${shopStaffInfo.shopStaffId}${PATH_PRODUCTS}/change_order`;

    return request({
        url: `${getBaseUrl()}${path}`,
        method: 'POST',
        body: {order_items},
        headers: createShopStaffHeaders(path, shopStaffInfo, CONTENT_TYPE_JSON),
    });
}

export function changeWhatsNewsOrder(shopStaffInfo, order_items) {
    const path = `${PATH_SHOP_STAFFS}/${shopStaffInfo.shopStaffId}${PATH_WHATS_NEWS}/change_order`;

    return request({
        url: `${getBaseUrl()}${path}`,
        method: 'POST',
        body: {order_items},
        headers: createShopStaffHeaders(path, shopStaffInfo, CONTENT_TYPE_JSON),
    });
}

export function getWorkRequestHistories(shopStaffInfo, workRequestId, page) {
    const path = `${PATH_SHOP_STAFFS}/${shopStaffInfo.shopStaffId}${PATH_WORK_REQUESTS}/${workRequestId}${PATH_HISTORIES}`

    return request({
        url: `${getBaseUrl()}${path}${convertQueryParams2Str({page})}`,
        method: 'GET',
        headers: createShopStaffHeaders(path, shopStaffInfo),
    });
}

export function payShopifyTrial(shopStaffInfo, payload) {
    const path = `${PATH_SHOP_STAFFS}/${shopStaffInfo.shopStaffId}${PATH_PAYMENT}${PATH_SHOPIFY}${PATH_TRIAL}`;

    return request({
        url: `${getBaseUrl()}${path}`,
        method: 'POST',
        body: payload,
        headers: createShopStaffHeaders(path, shopStaffInfo, CONTENT_TYPE_JSON),
    });
}

export function payShopifyRecurringCharge(shopStaffInfo, payload) {
    const path = `${PATH_SHOP_STAFFS}/${shopStaffInfo.shopStaffId}${PATH_PAYMENT}${PATH_SHOPIFY}${PATH_RECURRING_CHARGE}`;

    return request({
        url: `${getBaseUrl()}${path}`,
        method: 'POST',
        body: payload,
        headers: createShopStaffHeaders(path, shopStaffInfo, CONTENT_TYPE_JSON),
    });
}

export function payShopifyAnnualCharge(shopStaffInfo, payload) {
    const path = `${PATH_SHOP_STAFFS}/${shopStaffInfo.shopStaffId}${PATH_PAYMENT}${PATH_SHOPIFY}${PATH_ANNUAL_CHARGE}`;

    return request({
        url: `${getBaseUrl()}${path}`,
        method: 'POST',
        body: payload,
        headers: createShopStaffHeaders(path, shopStaffInfo, CONTENT_TYPE_JSON),
    });
}

export function payShopifyProductCharge(shopStaffInfo, payload) {
    const path = `${PATH_SHOP_STAFFS}/${shopStaffInfo.shopStaffId}${PATH_PAYMENT}${PATH_SHOPIFY}${PATH_PRODUCT_CHARGE}`;

    return request({
        url: `${getBaseUrl()}${path}`,
        method: 'POST',
        body: payload,
        headers: createShopStaffHeaders(path, shopStaffInfo, CONTENT_TYPE_JSON),
    });
}

export function updateArTransformModelInfo(shopStaffInfo, productId, productOptionId, armtiId, formData) {
    const path = `${PATH_SHOP_STAFFS}/${shopStaffInfo.shopStaffId}${PATH_PRODUCTS}/${productId}${PATH_OPTIONS}/${productOptionId}${PATH_ARMTIS}/${armtiId}`;
    return request({
        url: `${getBaseUrl()}${path}`,
        method: 'PUT',
        body: formData,
        headers: createShopStaffHeaders(path, shopStaffInfo),
    });
}

export function updateSkinFilterViewerArModelTransformInfo(shopStaffInfo, pluginId, skin_smoothing_filter) {
    const path = `${PATH_SHOP_STAFFS}/${shopStaffInfo.shopStaffId}${PATH_SHOPS}/${shopStaffInfo.shopStaffShopId}${PATH_PLUGINS}/${pluginId}`;

    return request({
        url: `${getBaseUrl()}${path}`,
        method: 'PUT',
        body: {is_test_mode: 0, template: "", skin_smoothing_filter},
        headers: createShopStaffHeaders(path, shopStaffInfo),
    });
}

export function createBatchFileData(shopStaffInfo, batch_file) {
    const path = `${PATH_SHOP_STAFFS}/${shopStaffInfo.shopStaffId}${PATH_PRODUCTS}${PATH_UPLOAD_BATCH_FILE}`;
    return request({
        url: `${getBaseUrl()}${path}`,
        method: 'POST',
        body: batch_file,
        headers: createShopStaffHeaders(path, shopStaffInfo)
    })
}

export function getPaymentHistory(shopStaffInfo, paymentHistoryId) {
    const path = `${PATH_SHOP_STAFFS}/${shopStaffInfo.shopStaffId}${PATH_PRICING_PAYMENT_HISTORIES}/${paymentHistoryId}`;
    return request({
        url: `${getBaseUrl()}${path}`,
        method: 'GET',
        headers: createShopStaffHeaders(path, shopStaffInfo),
    });
}

export function updatePaymentHistory(shopStaffInfo, payload, paymentHistoryId) {
    const path = `${PATH_SHOP_STAFFS}/${shopStaffInfo.shopStaffId}${PATH_PRICING_PAYMENT_HISTORIES}/${paymentHistoryId}`;
    return request({
        url: `${getBaseUrl()}${path}`,
        method: 'PUT',
        body: payload,
        headers: createShopStaffHeaders(path, shopStaffInfo),
    });
}

export function deletePaymentHistory(shopStaffInfo, paymentHistoryId) {
    const path = `${PATH_SHOP_STAFFS}/${shopStaffInfo.shopStaffId}${PATH_PRICING_PAYMENT_HISTORIES}/${paymentHistoryId}`;
    return request({
        url: `${getBaseUrl()}${path}`,
        method: 'DELETE',
        headers: createShopStaffHeaders(path, shopStaffInfo),
    });
}

export function payIamportScheduleCharge(shopStaffInfo, payload) {
    const path = `${PATH_SHOP_STAFFS}/${shopStaffInfo.shopStaffId}${PATH_PAYMENT}${PATH_IAMPORT}${PATH_SCHEDULE_CHARGE}`;
    return request({
        url: `${getBaseUrl()}${path}`,
        method: 'POST',
        body: payload,
        headers: createShopStaffHeaders(path, shopStaffInfo),
    });
}

export function payIamportProductCharge(shopStaffInfo, payload) {
    const path = `${PATH_SHOP_STAFFS}/${shopStaffInfo.shopStaffId}${PATH_PAYMENT}${PATH_IAMPORT}${PATH_PRODUCT_CHARGE}`;
    return request({
        url: `${getBaseUrl()}${path}`,
        method: 'POST',
        body: payload,
        headers: createShopStaffHeaders(path, shopStaffInfo),
    });
}

export function payTrial(shopStaffInfo, payload) {
    const path = `${PATH_SHOP_STAFFS}/${shopStaffInfo.shopStaffId}${PATH_PAYMENT}${PATH_IAMPORT}${PATH_TRIAL}`;

    return request({
        url: `${getBaseUrl()}${path}`,
        method: 'POST',
        body: payload,
        headers: createShopStaffHeaders(path, shopStaffInfo),
    });
}

export function sendVerificationEmail(shopStaffInfo) {
    const path = `${PATH_SHOP_STAFFS}/${shopStaffInfo.shopStaffId}${PATH_SEND_VERIFICATION_EMAIL}`;

    return request({
        url: `${getBaseUrl()}${path}`,
        method: 'POST',
        body: {},
        headers: createShopStaffHeaders(path, shopStaffInfo),
    })
}