import {ofType} from "redux-observable";
import {of} from "rxjs";
import {catchError, map, mergeMap} from "rxjs/operators";
import {loadResult, occurError} from "../actions/RESTAction";
import {
    FAIL_GET_CAFE24_STORE,
    REQUEST_CREATE_BUILDER_SCRIPTTAG,
    REQUEST_DELETE_BUILDER_SCRIPTTAG,
    REQUEST_GET_CAFE24_STORE,
    SUCCEED_GET_CAFE24_STORE,
} from "../actions/RESTActionTypes";
import {createBuilderScripttag, deleteBuilderScripttag, getCafe24Store} from "../../rest/PluginsAPI";

export const getCafe24StoreEpic = action$ => action$.pipe(
    ofType(REQUEST_GET_CAFE24_STORE),
    mergeMap(action => getCafe24Store(action.mallId, action.shopNo).pipe(
        map(res => loadResult(SUCCEED_GET_CAFE24_STORE, res.response)),
        catchError(error => of(occurError(FAIL_GET_CAFE24_STORE, error.xhr)))
    ))
);

export const createBuilderScripttagEpic = action$ => action$.pipe(
    ofType(REQUEST_CREATE_BUILDER_SCRIPTTAG),
    mergeMap(action => createBuilderScripttag(
        action.builder, action.categoryUid, action.pluginId, action.mallId, action.shopNo
    ).pipe(
        map(res => loadResult(action.succeedType, res.response)),
        catchError(error => of(occurError(action.failType, error.xhr)))
    ))
);

export const deleteBuilderScripttagEpic = action$ => action$.pipe(
    ofType(REQUEST_DELETE_BUILDER_SCRIPTTAG),
    mergeMap(action => deleteBuilderScripttag(
        action.builder, action.categoryUid, action.pluginId, action.mallId, action.shopNo
    ).pipe(
        map(res => loadResult(action.succeedType, res.response)),
        catchError(error => of(occurError(action.failType, error.xhr)))
    ))
);
