import {I18NLanguageMixin} from "./I18NLanguage";
import {LANGUAGES_KEY, UPDATED_PREFIX} from "../constants/CommonConstants";
import {indexOfLanguageCode} from "../utils/ListUtils";

export const I18NLanguageProductSizeMixin = defaultValues => class extends I18NLanguageMixin({
    name: '',
    ...defaultValues
}) {

    constructor(args = {}) {
        super({...args});
    }

    canAdd() {
        return Boolean(this.language_code) && Boolean(this.name);
    }

    isSame(other) {
        if (!other) {
            return false;
        }

        return this.language_code === other.get('language_code')
            && this.name === other.get('name')
            && this.is_default === other.get('is_default');
    }

    makeFormData(formData, oldbieProductSize = null) {
        let oldbieItem = null;

        if (oldbieProductSize !== null) {
            const oldbieItems = oldbieProductSize.get('i18n_languages');
            const indexOfOld = indexOfLanguageCode(oldbieItems, this.language_code);

            oldbieItem = indexOfOld >= 0 ? oldbieItems.get(indexOfOld) : null;
        }

        const shouldUpdate = oldbieItem != null;
        const keyPrefix = shouldUpdate ?
            `${UPDATED_PREFIX}${LANGUAGES_KEY}${this.language_code}-` : `${LANGUAGES_KEY}${this.language_code}-`;
        let shouldAddItem = false;

        if (shouldUpdate) {
            if (!this.isSame(oldbieItem)) {
                shouldAddItem = true;
            }
        } else {
            shouldAddItem = true;
        }

        if (shouldAddItem) {
            formData.append(`${keyPrefix}is_default`, this.is_default ? '1' : '0');
            formData.append(`${keyPrefix}name`, this.name);
            formData.append(`${keyPrefix}language_code`, this.language_code);
        }
    }
};

export default class I18NLanguageProductSize extends I18NLanguageProductSizeMixin({}) {

    constructor(args = {}) {
        super({...args});
    }
}
