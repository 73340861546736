import {AR_VIEWER_2ND_AR_MODEL_TRANSFORM_INFO_LIST} from "../constants/ArMtiConstants";

export function setArModelTramsformUvMapPosition(uvMapPosition, uvMapPosition2nd) {
    if (window.LolozemWebArController && typeof window.LolozemWebArController.setUvMapPosition === 'function') {
        window.LolozemWebArController.setUvMapPosition(uvMapPosition, uvMapPosition2nd);
    }
}

export function setAllArModelTransform(info, info2nd) {
    if(
        typeof window !== 'undefined'
        && window.LolozemWebArController
        && typeof window.LolozemWebArController.setModelTransformInfo === 'function'
    ) {
        if (info) {
            window.LolozemWebArController.setModelTransformInfo({
                x: info.get('px'),
                y: info.get('py'),
                z: info.get('pz'),
                rx: info.get('rx'),
                ry: info.get('ry'),
                rz: info.get('rz'),
                scale: info.get('scale'),
            }, null)
        } else if (info2nd) {
            window.LolozemWebArController.setModelTransformInfo(null, {
                x: info2nd.get('px_2nd'),
                y: info2nd.get('py_2nd'),
                z: info2nd.get('pz_2nd'),
                rx: info2nd.get('rx_2nd'),
                ry: info2nd.get('ry_2nd'),
                rz: info2nd.get('rz_2nd'),
                scale: info2nd.get('scale_2nd'),
            })
        } else {
            return;
        }
    }
}

export function changeArModelTransform(key, value, value2nd) {
    if (window.LolozemWebArController && typeof window.LolozemWebArController.changeModelTransform === 'function') {
        if (value) {
            window.LolozemWebArController.changeModelTransform(key, parseFloat(value), null);
        } else if (value2nd) {
            window.LolozemWebArController.changeModelTransform(key, null, parseFloat(value2nd));
        } else {
            return;
        }
    }
}
