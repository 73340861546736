export const KEY_CODE_ENTER = 13;

export const SNACKBAR_DEFAULT_HIDE_DURATION = 2000;

export const API_PAGE_COUNT = 24;

export const SHOPIFY_TEST_EGG_UUID = '375e6fc8-a5a4-4e37-bc68-e19b814a3ac8';

export const HOMEPAGE_START_DATE = "2021-10-01";

export const OPTIONS_KEY = 'options-';
export const AR_MTIS_KEY = 'ar_mtis-';
export const LIGHTING_ENV_ROTATION_KEY = 'lighting_env__rotation';
export const LIGHTING_ENV_ROTATION_VIEWER_KEY = 'lighting_env_viewer__rotation'
export const WORK_REQUEST_KEY = 'work_request-';
export const IMAGES_KEY = 'images-';
export const LANGUAGES_KEY = 'languages-';
export const COUNTRIES_KEY = 'countries-';
export const PLUGIN_TEMPLATE_KEY = 'plugin_template-';
export const UPDATED_PREFIX = 'updated-';
export const DELETED_PREFIX = 'deleted-';
export const OPTION_COLOR_IMAGES_PREFIX = 'option_color_images-';
export const DELETE_IDS = 'delete_ids';
export const DELETE_LANGUAGE_CODES = 'delete_language_codes';
export const DELETE_COUNTRY_CODES = 'delete_country_codes';

export const ALL = 'all';
export const SELECT = 'select';

export const TASK_DELETE = 'TASK_DELETE';
export const TASK_MENU_ACTIVATION = 'TASK_MENU_ACTIVATION';
export const TASK_MENU_DEACTIVATION = 'TASK_MENU_DEACTIVATION';
export const TASK_MENU_ENABLE_HOT_DEAL = 'TASK_MENU_ENABLE_HOT_DEAL';
export const TASK_MENU_DISABLE_HOT_DEAL = 'TASK_MENU_DISABLE_HOT_DEAL';

export const NEW_WINDOW_FEATURES = 'location=yes,width=1024,height=800,scrollbars=yes,status=yes';

export const NOTION_GUIDE_TEST_STORE_CAFE24 = "https://lologem.notion.site/1f0b5627784b45f69efc0c06e8663caa";
export const NOTION_GUIDE_TEST_STORE_SHOPIFY =
    "https://lologem.notion.site/Creating-a-Shopify-test-store-14d3453bd07841dabe3c1e3e4145d840";

export const NOTION_GUIDE_PLUGIN_INSTALL_SHOPIFY = "https://lologem.notion.site/Install-Plug-in-0548a2d5fe62429c8240ab5bc87c2bfe";
export const NOTION_GUIDE_PLUGIN_INSTALL_KO = "https://lologem.notion.site/a1db71d91559484195696bf1731148e6";
export const NOTION_GUIDE_PLUGIN_INSTALL_EN = "https://lologem.notion.site/Install-Plug-in-bc0d4728554b427fb48de5366f070062";