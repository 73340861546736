import {Set} from "immutable";

export function collectRootCategoryUids(categories) {
    let results = Set();

    if (!categories) {
        return results;
    }

    categories.forEach(item => {
        const rootCategoryUid = item.getRootCategoryUid();

        if (rootCategoryUid && !results.includes(rootCategoryUid)) {
            results = results.add(rootCategoryUid);
        }
    });

    return results;
}
