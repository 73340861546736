import {ofType} from "redux-observable";
import {
    FAIL_CHANGE_BRANDS_ORDER_BY_SHOP_STAFF,
    FAIL_CREATE_BRAND_BY_SHOP_STAFF,
    FAIL_DELETE_BRANDS_BY_SHOP_STAFF,
    FAIL_GET_BRAND_BY_SHOP_STAFF,
    FAIL_UPDATE_BRAND_BY_SHOP_STAFF,
    REQUEST_CHANGE_BRANDS_ORDER_BY_SHOP_STAFF,
    REQUEST_CREATE_BRAND_BY_SHOP_STAFF,
    REQUEST_DELETE_BRANDS_BY_SHOP_STAFF,
    REQUEST_GET_BRAND_BY_SHOP_STAFF,
    REQUEST_GET_BRANDS_BY_SHOP_STAFF,
    REQUEST_GET_BRANDS_BY_SHOP_STAFF_IN_CHANGE_ORDER,
    REQUEST_UPDATE_BRAND_BY_SHOP_STAFF,
    SUCCEED_CHANGE_BRANDS_ORDER_BY_SHOP_STAFF,
    SUCCEED_CREATE_BRAND_BY_SHOP_STAFF,
    SUCCEED_DELETE_BRANDS_BY_SHOP_STAFF,
    SUCCEED_GET_BRAND_BY_SHOP_STAFF,
    SUCCEED_UPDATE_BRAND_BY_SHOP_STAFF
} from "../../actions/RESTActionTypes";
import {catchError, map, mergeMap} from "rxjs/operators";
import {
    changeBrandsOrder,
    createBrand,
    deleteBrands,
    getBrand,
    getBrands,
    updateBrand,
} from "../../../rest/ShopStaffsAPI";
import {loadResult, loadResults, occurError} from "../../actions/RESTAction";
import {of} from "rxjs";

export const getBrandsEpic = action$ => action$.pipe(
    ofType(REQUEST_GET_BRANDS_BY_SHOP_STAFF, REQUEST_GET_BRANDS_BY_SHOP_STAFF_IN_CHANGE_ORDER),
    mergeMap(action => getBrands(action.shopStaffInfo, action.queryParams).pipe(
        map(res => loadResults(action.succeedType, res.response, action.queryParams.page)),
        catchError(error => of(occurError(action.failType, error.xhr)))
    ))
);

export const createBrandEpic = action$ => action$.pipe(
    ofType(REQUEST_CREATE_BRAND_BY_SHOP_STAFF),
    mergeMap(action => createBrand(action.shopStaffInfo, action.formData).pipe(
        map(res => loadResult(SUCCEED_CREATE_BRAND_BY_SHOP_STAFF, res.response)),
        catchError(error => of(occurError(FAIL_CREATE_BRAND_BY_SHOP_STAFF, error.xhr)))
    ))
);

export const getBrandEpic = action$ => action$.pipe(
    ofType(REQUEST_GET_BRAND_BY_SHOP_STAFF),
    mergeMap(action => getBrand(action.shopStaffInfo, action.brandId).pipe(
        map(res => loadResult(SUCCEED_GET_BRAND_BY_SHOP_STAFF, res.response)),
        catchError(error => of(occurError(FAIL_GET_BRAND_BY_SHOP_STAFF, error.xhr)))
    ))
);

export const updateBrandEpic = action$ => action$.pipe(
    ofType(REQUEST_UPDATE_BRAND_BY_SHOP_STAFF),
    mergeMap(action => updateBrand(action.shopStaffInfo, action.brandId, action.formData).pipe(
        map(res => loadResult(SUCCEED_UPDATE_BRAND_BY_SHOP_STAFF, res.response)),
        catchError(error => of(occurError(FAIL_UPDATE_BRAND_BY_SHOP_STAFF, error.xhr)))
    ))
);

export const deleteBrandsEpic = action$ => action$.pipe(
    ofType(REQUEST_DELETE_BRANDS_BY_SHOP_STAFF),
    mergeMap(action => deleteBrands(action.shopStaffInfo, action.brandIds).pipe(
        map(res => loadResult(SUCCEED_DELETE_BRANDS_BY_SHOP_STAFF, res.response)),
        catchError(error => of(occurError(FAIL_DELETE_BRANDS_BY_SHOP_STAFF, error.xhr)))
    ))
);

export const changeBrandsOrderEpic = action$ => action$.pipe(
    ofType(REQUEST_CHANGE_BRANDS_ORDER_BY_SHOP_STAFF),
    mergeMap(action => changeBrandsOrder(action.shopStaffInfo, action.orderItems).pipe(
        map(res => loadResult(SUCCEED_CHANGE_BRANDS_ORDER_BY_SHOP_STAFF, res.response)),
        catchError(error => of(occurError(FAIL_CHANGE_BRANDS_ORDER_BY_SHOP_STAFF, error.xhr)))
    ))
);
