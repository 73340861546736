export function changeSkinFilterViewers(value) {
    if (window.LolozemWebArController && typeof window.LolozemWebArController.changeShaderOptions === 'function') {
        window.LolozemWebArController.changeShaderOptions(value);
    }
}

export function setSkinFilterViewers(info) {
    if(
        typeof window !== 'undefined'
        && window.LolozemWebArController
        && typeof window.LolozemWebArController.changeShaderOptions === 'function'
    ) {
        if (info) {
            window.LolozemWebArController.changeShaderOptions({
                kernel_size: info.get('kernel_size'),
                exponent: info.get('exponent'),
                color_adj_brightness: info.get('color_adj_brightness'),
                color_adj_contrast: info.get('color_adj_contrast'),
                color_adj_saturation: info.get('color_adj_saturation'),
                color_adj_vibrance: info.get('color_adj_vibrance'),
            }, null)
        } else {
            return;
        }
    }
}