import {fromJS, List, Map} from "immutable";
import RestError from "../../models/RestError";
import {
    FAIL_INIT_PRODUCT_THREE_VIEWER_BY_MANAGER,
    FAIL_INIT_PRODUCT_THREE_VIEWER_BY_SHOP_STAFF,
    REQUEST_INIT_PRODUCT_THREE_VIEWER_BY_MANAGER,
    REQUEST_INIT_PRODUCT_THREE_VIEWER_BY_SHOP_STAFF,
    REQUEST_GET_ENV_IMAGE_PRESETS_BY_MANAGER_IN_PRODUCT_THREE_VIEWER,
    SUCCEED_INIT_PRODUCT_THREE_VIEWER_BY_MANAGER,
    SUCCEED_INIT_PRODUCT_THREE_VIEWER_BY_SHOP_STAFF,
    SUCCEED_GET_ENV_IMAGE_PRESETS_BY_MANAGER_IN_PRODUCT_THREE_VIEWER,
    FAIL_GET_ENV_IMAGE_PRESETS_BY_MANAGER_IN_PRODUCT_THREE_VIEWER,
    REQUEST_UPDATE_THREE_MODEL_TRANSFORM_INFO_BY_MANAGER_IN_PRODUCT_THREE_VIEWER,
    SUCCEED_UPDATE_THREE_MODEL_TRANSFORM_INFO_BY_MANAGER_IN_PRODUCT_THREE_VIEWER,
    FAIL_UPDATE_THREE_MODEL_TRANSFORM_INFO_BY_MANAGER_IN_PRODUCT_THREE_VIEWER,
} from "../actions/RESTActionTypes";
import {
    PRODUCT_THREE_VIEWER_CHANGE_ENV_VIEWER_IMAGE_PRESETS,
    PRODUCT_THREE_VIEWER_CHANGE_LIGHTING_ENV_VIEWER_INPUTS,
    PRODUCT_THREE_VIEWER_CLEAR, PRODUCT_THREE_VIEWER_GET_SELECTED_OPTION,
    PRODUCT_THREE_VIEWER_HANDLE_ERROR_MODEL,
    PRODUCT_THREE_VIEWER_LOADED_THREE_MODEL,
    PRODUCT_THREE_VIEWER_RESET_THREE_VIEWER_MODEL_TRANSFORM,
    PRODUCT_THREE_VIEWER_SELECT_PRODUCT_OPTION,
    PRODUCT_THREE_VIEWER_SET_INITIALIZED,
    PRODUCT_THREE_VIEWER_SET_IS_MODEL_VIEWER_POPUP_SHOWN,
    PRODUCT_THREE_VIEWER_TOGGLE_OPTION_CONTAINER,
} from "../actions/UIActionTypes";
import Shop from "../../models/Shop";
import Product from "../../models/Product";
import EnvImagePreset from "../../models/EnvImagePreset";
import {getOptionIndex} from "../../behaviors/ProductOptionBehavior";
import {
    PRODUCT_OPTION_LIGHTING_ENV_EXPOSURE_KEY,
    PRODUCT_OPTION_LIGHTING_ENV_ROTATION_Y_KEY
} from "../../constants/ProductOptionConstants";
import ProductOption from "../../models/ProductOption";
import {
    changeProductOptionLightingEnvExposure,
    changeProductOptionLightingEnvRotationY,
    changeProductOptionLightingEnvUrl,
    setProductOptionLightingEnvExposure,
    setProductOptionLightingEnvRotationY,
    setProductOptionLightingEnvUrl
} from "../../behaviors/WebThreeViewerProductOptionBehavior";

export const initialProductThreeViewer = fromJS({
    isGetProductLoading: false,
    isGetProductError: false,
    isUpdateThreeMtiLoading: false,
    isSucceedUpdateThreeMti: false,
    isFailUpdateThreeMti: false,
    isOpenMobileDrawer: false,
    tempLightingEnvViewer: Map(),
    shop: null,
    product: null,
    envImagePresets: List(),
    error: null,
    isOptionContainerOpened: false,
    selectedOptionIndex: 0,
    isModelLoading: false,
    errorModel: Map({isModelReady: null, failToLoad: null}),
    isInitialized: false,
    isModelViewerPopupShown: false,
});

export function productThreeViewer(state = initialProductThreeViewer, action) {
    let product, exposure, rotationY;
    let productOptions;
    let centerOptionIndex;
    let selectedOptionIndex, optionIndex;
    let tempLightingEnvViewer, tempLightingEnvViewerRotation;

    switch (action.type) {
        case PRODUCT_THREE_VIEWER_CLEAR:
            return initialProductThreeViewer;

        case REQUEST_INIT_PRODUCT_THREE_VIEWER_BY_SHOP_STAFF:
        case REQUEST_INIT_PRODUCT_THREE_VIEWER_BY_MANAGER:
            return initialProductThreeViewer.set('isGetProductLoading', true);

        case SUCCEED_INIT_PRODUCT_THREE_VIEWER_BY_SHOP_STAFF:
        case SUCCEED_INIT_PRODUCT_THREE_VIEWER_BY_MANAGER:
            product = new Product(action.product);
            productOptions = product.get('options');
            centerOptionIndex = productOptions && Math.floor(productOptions.size / 2);
            optionIndex = productOptions && getOptionIndex(productOptions, centerOptionIndex);
            tempLightingEnvViewer = productOptions && productOptions.getIn([optionIndex, 'lighting_env_viewer']);

            return state.set('isGetProductLoading', false)
                .set('shop', new Shop(action.shop))
                .set('product', product)
                .set('tempLightingEnvViewer', tempLightingEnvViewer)
                .set('isModelLoading', true)
                .set('error', null);

        case FAIL_INIT_PRODUCT_THREE_VIEWER_BY_SHOP_STAFF:
        case FAIL_INIT_PRODUCT_THREE_VIEWER_BY_MANAGER:
            return state.set('isGetProductLoading', false).set('error', new RestError(action.error));

        case REQUEST_GET_ENV_IMAGE_PRESETS_BY_MANAGER_IN_PRODUCT_THREE_VIEWER:
            return state.set('isGetProductLoading', true);

        case SUCCEED_GET_ENV_IMAGE_PRESETS_BY_MANAGER_IN_PRODUCT_THREE_VIEWER:
            return state.set('isGetProductLoading', false)
                .set('envImagePresets', List(action.results.map(item => new EnvImagePreset(item))))
                .set('error', null);

        case FAIL_GET_ENV_IMAGE_PRESETS_BY_MANAGER_IN_PRODUCT_THREE_VIEWER:
            return state.set('isUpdateThreeMtiLoading', false)
                .set('error', new RestError(action.error))
                .set('isFailUpdateThreeMti', true);

        case REQUEST_UPDATE_THREE_MODEL_TRANSFORM_INFO_BY_MANAGER_IN_PRODUCT_THREE_VIEWER:
            return state.set('isUpdateThreeMtiLoading', true)
                .set('isSucceedUpdateThreeMti', false)
                .set('isFailUpdateThreeMti', false)

        case SUCCEED_UPDATE_THREE_MODEL_TRANSFORM_INFO_BY_MANAGER_IN_PRODUCT_THREE_VIEWER:
            product = state.get('product');
            optionIndex = product.get('options').findIndex(item => item.get('id') === action.result.id);

            if (optionIndex >= 0) {
                product = product.setIn(['options', optionIndex], new ProductOption(action.result));
            } else {
                return state.set('isUpdateThreeMtiLoading', false)
                    .set('isFailUpdateThreeMti', true);
            }
            return state.set('isUpdateThreeMtiLoading', false)
                .set('isSucceedUpdateThreeMti', true)
                .set('product', product);

        case FAIL_UPDATE_THREE_MODEL_TRANSFORM_INFO_BY_MANAGER_IN_PRODUCT_THREE_VIEWER:
            return state.set('isUpdateThreeMtiLoading', false)
                .set('isFailUpdateThreeMti', true)
                .set('error', new RestError(action.error));

        case PRODUCT_THREE_VIEWER_CHANGE_ENV_VIEWER_IMAGE_PRESETS:
            const envImagePresetId = action.envImagePresets ? action.envImagePresets.get('id') : 0;
            const envImagePresetUrl = action.envImagePresets ? action.envImagePresets.get('hdr_image') : '';

            changeProductOptionLightingEnvUrl(envImagePresetUrl);
            tempLightingEnvViewer = state.get('tempLightingEnvViewer');
            exposure = tempLightingEnvViewer.get('exposure');
            rotationY = tempLightingEnvViewer.getIn(['rotation', 'y']);

            return state.setIn(['tempLightingEnvViewer', 'env_image_preset_id'], envImagePresetId)
                .setIn(['tempLightingEnvViewer', 'exposure'], envImagePresetId === 0 ? 0 : parseFloat(exposure))
                .setIn(['tempLightingEnvViewer', 'rotation', 'y'], envImagePresetId === 0 ? 0 : parseFloat(rotationY));

        case PRODUCT_THREE_VIEWER_CHANGE_LIGHTING_ENV_VIEWER_INPUTS:
            if (action.key === PRODUCT_OPTION_LIGHTING_ENV_EXPOSURE_KEY) {

                changeProductOptionLightingEnvExposure(action.value);
                state = state.setIn(['tempLightingEnvViewer', action.key], parseFloat(action.value));
            } else if (action.key === PRODUCT_OPTION_LIGHTING_ENV_ROTATION_Y_KEY) {

                changeProductOptionLightingEnvRotationY(action.value);
                state = state.setIn(['tempLightingEnvViewer', 'rotation', action.key], parseFloat(action.value));
            }

            return state;

        case PRODUCT_THREE_VIEWER_RESET_THREE_VIEWER_MODEL_TRANSFORM:
            selectedOptionIndex = state.get('selectedOptionIndex');
            tempLightingEnvViewer = state.getIn(['product', 'options', selectedOptionIndex, 'lighting_env_viewer']);
            tempLightingEnvViewerRotation = tempLightingEnvViewer.get('rotation');

            setProductOptionLightingEnvUrl(tempLightingEnvViewer);
            setProductOptionLightingEnvExposure(tempLightingEnvViewer);
            setProductOptionLightingEnvRotationY(tempLightingEnvViewerRotation);
            return state.set('tempLightingEnvViewer', tempLightingEnvViewer);

        case PRODUCT_THREE_VIEWER_GET_SELECTED_OPTION:
            productOptions = state.getIn(['product', 'options']);
            optionIndex = productOptions.findIndex(item => item.get('id') === action.optionId);
            let index = 0;

            if (optionIndex >= 0) {
                index = optionIndex;
                tempLightingEnvViewer = productOptions && productOptions.getIn([optionIndex, 'lighting_env_viewer']);
            }

            return state.set('selectedOptionIndex', index)
                .set('tempLightingEnvViewer', tempLightingEnvViewer ? tempLightingEnvViewer : null)

        case PRODUCT_THREE_VIEWER_SET_INITIALIZED:
            return state.set('isInitialized', true);

        case PRODUCT_THREE_VIEWER_SET_IS_MODEL_VIEWER_POPUP_SHOWN:
            return state.set('isModelViewerPopupShown', action.isModelViewerPopupShown);

        case PRODUCT_THREE_VIEWER_TOGGLE_OPTION_CONTAINER:
            return state.set('isOptionContainerOpened', !state.get('isOptionContainerOpened'));

        case PRODUCT_THREE_VIEWER_SELECT_PRODUCT_OPTION:
            return state.set('selectedOptionIndex', action.index).set('isModelLoading', true);

        case PRODUCT_THREE_VIEWER_HANDLE_ERROR_MODEL:
            return state.set('isModelLoading', false).set('errorModel', state.get('errorModel').merge(action.value));

        case PRODUCT_THREE_VIEWER_LOADED_THREE_MODEL:
            if (action.isOptionContainerOpened !== null) {
                state = state.set('isOptionContainerOpened', action.isOptionContainerOpened);
            }
            return state.set('isModelLoading', false);

        default:
            return state;
    }
}
