import PricingPaymentHistory from "../../../models/PricingPaymentHistory";
import {fromJS, List} from "immutable";
import {
    SHOP_STAFF_SHOP_PAYMENT_HISTORY_CHANGE_PERIOD,
    SHOP_STAFF_SHOP_PAYMENT_HISTORY_CHANGE_QUERY_PARAMS,
    SHOP_STAFF_SHOP_PAYMENT_HISTORY_INIT_DATA,
    SHOP_STAFF_SHOP_PAYMENT_HISTORY_RESET_QUERY_PARAMS
} from "../../actions/UIActionTypes";
import {
    FAIL_GET_PAYMENT_HISTORY_BY_SHOP_STAFF,
    FAIL_INITIALIZE_PAYMENT_HISTORIES_BY_SHOP_STAFF,
    REQUEST_GET_PAYMENT_HISTORY_BY_SHOP_STAFF,
    REQUEST_INITIALIZE_PAYMENT_HISTORIES_BY_SHOP_STAFF,
    SUCCEED_GET_PAYMENT_HISTORY_BY_SHOP_STAFF,
    SUCCEED_INITIALIZE_PAYMENT_HISTORIES_BY_SHOP_STAFF
} from "../../actions/RESTActionTypes";
import {parseStartAndEndDateFromQuery} from "../../../utils/DateUtils";
import RestError from "../../../models/RestError";
import PaymentHistoryQueryParam from "../../../models/ui/PaymentHistoryQueryParam";
import Shop from "../../../models/Shop";

export const initialShopStaffPaymentHistories = fromJS({
    isInitialized: false,
    isLoading: false,
    shop: null,
    count: 0,
    error: null,
    queryParams: new PaymentHistoryQueryParam(),
    paymentHistories: List(),
    selectedPaymentHistory: null,
})

export function shopStaffPaymentHistories(state = initialShopStaffPaymentHistories, action) {
    let selectedPaymentHistory;

    switch (action.type) {
        case SHOP_STAFF_SHOP_PAYMENT_HISTORY_INIT_DATA:
            return state.set('queryParams', action.queryParams);

        case REQUEST_INITIALIZE_PAYMENT_HISTORIES_BY_SHOP_STAFF:
            return state.set('isLoading', true);

        case SUCCEED_INITIALIZE_PAYMENT_HISTORIES_BY_SHOP_STAFF:
            const shop = new Shop(action.shop);
            const paymentHistories = List(action.paymentHistories.map(item => new PricingPaymentHistory(item)));

            return state.set('isLoading', false)
                .set('shop', shop)
                .set('isInitialized', true)
                .set('paymentHistories', paymentHistories)
                .set('count', action.count);

        case FAIL_INITIALIZE_PAYMENT_HISTORIES_BY_SHOP_STAFF:
            return state.set('isLoading', false)
                .set('error', new RestError(action.error));

        case SHOP_STAFF_SHOP_PAYMENT_HISTORY_CHANGE_QUERY_PARAMS:
            return state.set('queryParams', state.get('queryParams').set(action.key, action.value));

        case SHOP_STAFF_SHOP_PAYMENT_HISTORY_CHANGE_PERIOD:
            return state.set(
                'queryParams',
                state.get('queryParams').set('startDate', action.startDate).set('endDate', action.endDate)
            );

        case SHOP_STAFF_SHOP_PAYMENT_HISTORY_RESET_QUERY_PARAMS:
            const queryParams = state.get('queryParams');
            const {startDate, endDate} = parseStartAndEndDateFromQuery({});

            return state.set(
                'queryParams',
                initialShopStaffPaymentHistories.get('queryParams')
                    .set('page', queryParams.get('page'))
                    .set('startDate', startDate)
                    .set('endDate', endDate)
            )

        case REQUEST_GET_PAYMENT_HISTORY_BY_SHOP_STAFF:
            return state.set('isLoading', true);

        case SUCCEED_GET_PAYMENT_HISTORY_BY_SHOP_STAFF:
            selectedPaymentHistory = new PricingPaymentHistory(action.result);

            return state.set('selectedPaymentHistory', selectedPaymentHistory)
                .set('isLoading', false);

        case FAIL_GET_PAYMENT_HISTORY_BY_SHOP_STAFF:
            return state.set('isLoading', false)
                .set('error', new RestError(action.error));

        default:
            return state;
    }
}