import {ofType} from "redux-observable";
import {
    FAIL_GET_SHOP_BY_MANAGER,
    REQUEST_GET_SHOP_BY_MANAGER,
    REQUEST_GET_SHOPS_BY_MANAGER,
    REQUEST_GET_PAYMENT_HISTORIES_BY_MANAGER,
    SUCCEED_GET_SHOP_BY_MANAGER,
    REQUEST_GET_SHOPS_BY_MANAGER_IN_PRODUCTS,
    REQUEST_GET_SHOPS_BY_MANAGER_IN_PAYMENT_HISTORIES,
} from "../../actions/RESTActionTypes";
import {catchError, map, mergeMap} from "rxjs/operators";
import {getShop, getShops, getPaymentHistories,} from "../../../rest/ManagersApI";
import {loadResult, loadResults, occurError} from "../../actions/RESTAction";
import {of} from "rxjs";

export const getShopsByManagerEpic = action$ => action$.pipe(
    ofType(
        REQUEST_GET_SHOPS_BY_MANAGER,
        REQUEST_GET_SHOPS_BY_MANAGER_IN_PRODUCTS,
        REQUEST_GET_SHOPS_BY_MANAGER_IN_PAYMENT_HISTORIES,
    ),
    mergeMap(action => getShops(action.managerInfo, action.queryParams).pipe(
        map(res => loadResults(action.succeedType, res.response)),
        catchError(error => of(occurError(action.failType, error.xhr)))
    ))
);

export const getShopByManagerEpic = action$ => action$.pipe(
    ofType(REQUEST_GET_SHOP_BY_MANAGER),
    mergeMap(action => getShop(action.managerInfo, action.shopId).pipe(
        map(res => loadResult(SUCCEED_GET_SHOP_BY_MANAGER, res.response)),
        catchError(error => of(occurError(FAIL_GET_SHOP_BY_MANAGER, error.xhr)))
    ))
);

export const getPaymentHistoriesByManagerEpic = action$ => action$.pipe(
    ofType(REQUEST_GET_PAYMENT_HISTORIES_BY_MANAGER),
    mergeMap(action => getPaymentHistories(action.managerInfo, action.queryParams).pipe(
        map(res => loadResults(action.succeedType, res.response, action.queryParams.page)),
        catchError(error => of(occurError(action.failType, error.xhr)))
    ))
);