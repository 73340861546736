import {fromJS, List, Map, Set} from "immutable";
import RestError from "../../../models/RestError";
import {
    SHOP_STAFF_PRODUCTS_CHANGE_PERIOD,
    SHOP_STAFF_PRODUCTS_CHANGE_QUERY_PARAMS,
    SHOP_STAFF_PRODUCTS_CLEAR_BATCH_FILE,
    SHOP_STAFF_PRODUCTS_CLEAR_CHANGE_ORDER_STATE,
    SHOP_STAFF_PRODUCTS_CLEAR_TASK_STATE,
    SHOP_STAFF_PRODUCTS_CLOSE_AR_VIEWER,
    SHOP_STAFF_PRODUCTS_INIT_DATA,
    SHOP_STAFF_PRODUCTS_OPEN_AR_VIEWER_WITHOUT_API,
    SHOP_STAFF_PRODUCTS_OPEN_CHANGE_ORDER,
    SHOP_STAFF_PRODUCTS_RENDERED_REORDER_LIST,
    SHOP_STAFF_PRODUCTS_REORDER,
    SHOP_STAFF_PRODUCTS_RESET_QUERY_PARAMS,
    SHOP_STAFF_PRODUCTS_SELECT_BRAND_IN_CHANGE_ORDER,
    SHOP_STAFF_PRODUCTS_SELECT_CATEGORY_IN_CHANGE_ORDER,
    SHOP_STAFF_PRODUCTS_SELECT_COPY_SAMPLE_PRODUCT_CATEGORY,
    SHOP_STAFF_PRODUCTS_SET_AR_VIEWER_ERROR,
    SHOP_STAFF_PRODUCTS_SET_BATCH_FILE,
    SHOP_STAFF_PRODUCTS_SET_BATCH_FILE_UPLOAD_DIALOG,
    SHOP_STAFF_PRODUCTS_SET_IS_WORK_REQUEST_OPEN,
    SHOP_STAFF_PRODUCTS_SET_SELECTED_ITEM,
    SHOP_STAFF_PRODUCTS_TOGGLE_COPY_SAMPLE_PRODUCT_DIALOG,
} from "../../actions/UIActionTypes";
import {
    FAIL_CHANGE_PRODUCTS_BULK_DATA_BY_SHOP_STAFF,
    FAIL_CHANGE_PRODUCTS_ORDER_BY_SHOP_STAFF,
    FAIL_COPY_SAMPLE_PRODUCTS_BY_SHOP_STAFF,
    FAIL_CREATE_BATCH_FILE_DATA_BY_SHOP_STAFF,
    FAIL_DELETE_PRODUCTS_BY_SHOP_STAFF,
    FAIL_GET_PRODUCT_BY_SHOP_STAFF,
    FAIL_GET_PRODUCT_BY_SHOP_STAFF_FOR_AR_VIEWER,
    FAIL_GET_PRODUCTS_BY_SHOP_STAFF,
    FAIL_GET_PRODUCTS_BY_SHOP_STAFF_IN_CHANGE_ORDER,
    FAIL_GET_SHOP_STAFF_BRANDS_CATEGORIES_SHOP_IN_PRODUCTS,
    REQUEST_CHANGE_PRODUCTS_BULK_DATA_BY_SHOP_STAFF,
    REQUEST_CHANGE_PRODUCTS_ORDER_BY_SHOP_STAFF,
    REQUEST_COPY_SAMPLE_PRODUCTS_BY_SHOP_STAFF,
    REQUEST_CREATE_BATCH_FILE_DATA_BY_SHOP_STAFF,
    REQUEST_DELETE_PRODUCTS_BY_SHOP_STAFF,
    REQUEST_GET_PRODUCT_BY_SHOP_STAFF,
    REQUEST_GET_PRODUCT_BY_SHOP_STAFF_FOR_AR_VIEWER,
    REQUEST_GET_PRODUCTS_BY_SHOP_STAFF,
    REQUEST_GET_PRODUCTS_BY_SHOP_STAFF_IN_CHANGE_ORDER,
    REQUEST_GET_SHOP_STAFF_BRANDS_CATEGORIES_SHOP_IN_PRODUCTS,
    SUCCEED_CHANGE_PRODUCTS_BULK_DATA_BY_SHOP_STAFF,
    SUCCEED_CHANGE_PRODUCTS_ORDER_BY_SHOP_STAFF,
    SUCCEED_COPY_SAMPLE_PRODUCTS_BY_SHOP_STAFF,
    SUCCEED_CREATE_BATCH_FILE_DATA_BY_SHOP_STAFF,
    SUCCEED_DELETE_PRODUCTS_BY_SHOP_STAFF,
    SUCCEED_GET_PRODUCT_BY_SHOP_STAFF,
    SUCCEED_GET_PRODUCT_BY_SHOP_STAFF_FOR_AR_VIEWER,
    SUCCEED_GET_PRODUCTS_BY_SHOP_STAFF,
    SUCCEED_GET_PRODUCTS_BY_SHOP_STAFF_IN_CHANGE_ORDER,
    SUCCEED_GET_SHOP_STAFF_BRANDS_CATEGORIES_SHOP_IN_PRODUCTS,
} from "../../actions/RESTActionTypes";
import ProductQueryParam from "../../../models/ui/ProductQueryParam";
import Brand from "../../../models/Brand";
import Category from "../../../models/Category";
import Product from "../../../models/Product";
import {addExclusively} from "../../../utils/ListUtils";
import Shop from "../../../models/Shop";
import {parseStartAndEndDateFromQuery} from "../../../utils/DateUtils";

export const initialShopStaffProducts = fromJS({
    isLoading: false,
    isInitialized: false,
    brands: List(),
    categories: List(),
    products: List(),
    count: 0,
    error: null,
    queryParams: new ProductQueryParam(),
    selectedItemIds: Set(),
    isTaskWorking: false,
    succeedTask: false,
    succeedDelete: false,
    errorTask: null,
    isGetProductInfoLoading: false,
    isOpenWorkRequest: false,
    isOpenArViewer: false,
    isArViewerLoading: false,
    product: null,
    shop: null,
    isArViewerError: false,
    batchFile: Set(),
    copySampleProductCategory: null,
    isSuccessCopySampleProducts: false,
    errorCopySampleProducts: null,
    openCopySampleProductDialog: false,
    openBatchFileUploadDialog: false,
});

export function shopStaffProducts(state = initialShopStaffProducts, action) {
    let product, products, selectedProductIndex;
    let shop;

    switch (action.type) {
        case SHOP_STAFF_PRODUCTS_CLEAR_TASK_STATE:
            return state.set('isTaskWorking', initialShopStaffProducts.get('isTaskWorking'))
                .set('succeedTask', initialShopStaffProducts.get('succeedTask'))
                .set('succeedDelete', initialShopStaffProducts.get('succeedDelete'))
                .set('errorTask', initialShopStaffProducts.get('errorTask'));

        case SHOP_STAFF_PRODUCTS_INIT_DATA:
            return state.set('queryParams', action.queryParams);

        case REQUEST_GET_SHOP_STAFF_BRANDS_CATEGORIES_SHOP_IN_PRODUCTS:
            return initialShopStaffProducts.set('isLoading', true);

        case SUCCEED_GET_SHOP_STAFF_BRANDS_CATEGORIES_SHOP_IN_PRODUCTS:
            shop = new Shop(action.shop);
            const categories = shop.get('categories');
            const copySampleProductCategory =  categories.get('0');

            return state.set('isLoading', false)
                .set('isInitialized', true)
                .set('brands', List(action.brands.map(item => new Brand(item))))
                .set('categories', categories)
                .set('copySampleProductCategory', copySampleProductCategory)
                .set('shop', shop)
                .set('error', null);

        case FAIL_GET_SHOP_STAFF_BRANDS_CATEGORIES_SHOP_IN_PRODUCTS:
            return state.set('isLoading', false).set('error', new RestError(action.error));

        case REQUEST_GET_PRODUCTS_BY_SHOP_STAFF:
            return state.set('isLoading', true)
                .set('selectedItemIds', initialShopStaffProducts.get('selectedItemIds'))
                .set('isTaskWorking', initialShopStaffProducts.get('isTaskWorking'))
                .set('succeedTask', initialShopStaffProducts.get('succeedTask'))
                .set('succeedDelete', initialShopStaffProducts.get('succeedDelete'))
                .set('errorTask', initialShopStaffProducts.get('errorTask'));

        case SUCCEED_GET_PRODUCTS_BY_SHOP_STAFF:
            return state.set('isLoading', false)
                .set('products', List(action.results.map(item => new Product(item))))
                .set('count', action.count)
                .set('error', null);

        case FAIL_GET_PRODUCTS_BY_SHOP_STAFF:
            return state.set('isLoading', false).set('products', List()).set('error', new RestError(action.error));

        case REQUEST_COPY_SAMPLE_PRODUCTS_BY_SHOP_STAFF:
            return state.set('isTaskWorking', true)
                .set('isSuccessCopySampleProducts', false)
                .set('errorCopySampleProducts', null);

        case SUCCEED_COPY_SAMPLE_PRODUCTS_BY_SHOP_STAFF:
            return state.set('isTaskWorking', false)
                .set('isSuccessCopySampleProducts', true)
                .set('errorCopySampleProducts', null);

        case FAIL_COPY_SAMPLE_PRODUCTS_BY_SHOP_STAFF:
            return state.set('isTaskWorking', false)
                .set('isSuccessCopySampleProducts', false)
                .set('errorCopySampleProducts', new RestError(action.error));

        case SHOP_STAFF_PRODUCTS_CHANGE_QUERY_PARAMS:
            return state.set('queryParams', state.get('queryParams').set(action.key, action.value));

        case SHOP_STAFF_PRODUCTS_CHANGE_PERIOD:
            return state.set(
                'queryParams',
                state.get('queryParams').set('startDate', action.startDate).set('endDate', action.endDate)
            );

        case SHOP_STAFF_PRODUCTS_RESET_QUERY_PARAMS:
            const queryParams = state.get('queryParams');
            const {startDate, endDate} = parseStartAndEndDateFromQuery({});

            return state.set(
                'queryParams',
                initialShopStaffProducts.get('queryParams')
                    .set('page', queryParams.get('page'))
                    .set('startDate', startDate)
                    .set('endDate', endDate)
            );

        case SHOP_STAFF_PRODUCTS_SET_SELECTED_ITEM:
            return state.set('selectedItemIds', action.selectedItemIds);

        case REQUEST_DELETE_PRODUCTS_BY_SHOP_STAFF:
        case REQUEST_CHANGE_PRODUCTS_BULK_DATA_BY_SHOP_STAFF:
            return state.set('isTaskWorking', true)
                .set('succeedTask', false)
                .set('succeedDelete', false)
                .set('errorTask', null);

        case SUCCEED_DELETE_PRODUCTS_BY_SHOP_STAFF:
            return state.set('isTaskWorking', false).set('succeedDelete', true).set('errorTask', null);

        case SUCCEED_CHANGE_PRODUCTS_BULK_DATA_BY_SHOP_STAFF:
            return state.set('isTaskWorking', false).set('succeedTask', true).set('errorTask', null);

        case FAIL_DELETE_PRODUCTS_BY_SHOP_STAFF:
        case FAIL_CHANGE_PRODUCTS_BULK_DATA_BY_SHOP_STAFF:
            return state.set('isTaskWorking', false)
                .set('succeedTask', false)
                .set('succeedDelete', false)
                .set('errorTask', new RestError(action.error));

        case REQUEST_GET_PRODUCT_BY_SHOP_STAFF:
            return state.set('isGetProductInfoLoading', true);

        case SUCCEED_GET_PRODUCT_BY_SHOP_STAFF:
            products = state.get('products');
            product = new Product(action.result);
            selectedProductIndex = products.findIndex(item => item.get('id') === product.get('id'));

            if (selectedProductIndex >= 0) {
                products = products.setIn([selectedProductIndex, 'options'], product.get('options'));
            }

            return state.set('isOpenWorkRequest', true)
                .set('products', products)
                .set('isGetProductInfoLoading', false);

        case FAIL_GET_PRODUCT_BY_SHOP_STAFF:
            return state.set('isGetProductInfoLoading', false).set('error', new RestError(action.error));

        case SHOP_STAFF_PRODUCTS_SET_BATCH_FILE_UPLOAD_DIALOG:
            return state.set('openBatchFileUploadDialog', action.open);

        case SHOP_STAFF_PRODUCTS_SET_BATCH_FILE:
            return state.set('batchFile', action.batchFile)
                .set('error', null);

        case SHOP_STAFF_PRODUCTS_CLEAR_BATCH_FILE:
            return state.set('batchFile', null)
                .set('openBatchFileUploadDialog', false)
                .set('error', null);

        case REQUEST_CREATE_BATCH_FILE_DATA_BY_SHOP_STAFF:
            return state.set('succeedTask', false)
                .set('isTaskWorking', true)

        case SUCCEED_CREATE_BATCH_FILE_DATA_BY_SHOP_STAFF:
            return state.set('succeedTask', true)
                .set('isTaskWorking', false)
                .set('openBatchFileUploadDialog', false)

        case FAIL_CREATE_BATCH_FILE_DATA_BY_SHOP_STAFF:
            return state.set('isTaskWorking', false)
                .set('succeedTask', false)
                .set('error', new RestError(action.error))

        case REQUEST_GET_PRODUCT_BY_SHOP_STAFF_FOR_AR_VIEWER:
            return state.set('isArViewerLoading', true)
                .set('isArViewerError', false);

        case SUCCEED_GET_PRODUCT_BY_SHOP_STAFF_FOR_AR_VIEWER:
            products = state.get('products');
            product = new Product(action.result);
            selectedProductIndex = products.findIndex(item => item.get('id') === product.get('id'));

            if (selectedProductIndex >= 0) {
                products = products.setIn([selectedProductIndex, 'options'], product.get('options'));
            }

            return state.set('isArViewerLoading', false)
                .set('products', products)
                .set('product', product)
                .set('isOpenArViewer', true)

        case FAIL_GET_PRODUCT_BY_SHOP_STAFF_FOR_AR_VIEWER:
            return state.set('isGetProductInfoLoading', false).set('error', new RestError(action.error))
                .set('isOpenArViewer', false)
                .set('isArViewerLoading', false)

        case SHOP_STAFF_PRODUCTS_SET_IS_WORK_REQUEST_OPEN:
            return state.set('isOpenWorkRequest', action.isOpenWorkRequest);

        case SHOP_STAFF_PRODUCTS_CLOSE_AR_VIEWER:
            return state.set('isOpenArViewer', false);

        case SHOP_STAFF_PRODUCTS_OPEN_AR_VIEWER_WITHOUT_API:
            products = state.get('products');
            product = products.find(item => item.get('id') === action.productId);

            if (!product) {
                return state;
            }

            return state.set('isOpenArViewer', true)
                .set('isArViewerError', false)
                .set('product', product);

        case SHOP_STAFF_PRODUCTS_SET_AR_VIEWER_ERROR:
            return state.set('isArViewerError', true)
                .set('isOpenArViewer', false);

        case SHOP_STAFF_PRODUCTS_TOGGLE_COPY_SAMPLE_PRODUCT_DIALOG:
            return state.set('openCopySampleProductDialog', action.open)
                .set('errorCopySampleProducts', null);

        case SHOP_STAFF_PRODUCTS_SELECT_COPY_SAMPLE_PRODUCT_CATEGORY:
            return state.set('copySampleProductCategory', action.category)
                .set('errorCopySampleProducts', null);

        default:
            return state;
    }
}

export const initialShopStaffProductsChangeOrder = fromJS({
    isOpened: false,
    brand: null,
    category: null,
    isLoading: false,
    products: List(),
    count: 0,
    hasMore: false,
    page: 0,
    error: null,
    orderData: Map(),
    isReordered: false,
    isChanging: false,
    succeedChange: false,
    errorChange: null,
});

export function shopStaffProductsChangeOrder(state = initialShopStaffProductsChangeOrder, action) {
    switch (action.type) {
        case SHOP_STAFF_PRODUCTS_INIT_DATA:
            return initialShopStaffProductsChangeOrder;

        case SHOP_STAFF_PRODUCTS_OPEN_CHANGE_ORDER:
            if (!action.open) {
                return initialShopStaffProductsChangeOrder;
            }
            return state.set('isOpened', action.open).set('brand', action.brand).set('category', action.category);

        case SHOP_STAFF_PRODUCTS_SELECT_BRAND_IN_CHANGE_ORDER:
            return state.set('brand', action.brand)
                .set('products', List())
                .set('count', 0)
                .set('hasMore', false)
                .set('page', 0);

        case SHOP_STAFF_PRODUCTS_SELECT_CATEGORY_IN_CHANGE_ORDER:
            return state.set('category', action.category)
                .set('products', List())
                .set('count', 0)
                .set('hasMore', false)
                .set('page', 0);

        case REQUEST_GET_PRODUCTS_BY_SHOP_STAFF_IN_CHANGE_ORDER:
            return state.set('isLoading', true);

        case SUCCEED_GET_PRODUCTS_BY_SHOP_STAFF_IN_CHANGE_ORDER:
            let products = List(action.results.map(item => new Product(item)));

            if (action.page === 1) {
                state = state.set('orderData', Map());
            } else if (action.page > 1) {
                products = addExclusively(state.get('products'), products);
            }

            return state.set('isLoading', false)
                .set('products', products)
                .set('count', action.count)
                .set('hasMore', action.hasMore)
                .set('page', action.page)
                .set('error', null);

        case FAIL_GET_PRODUCTS_BY_SHOP_STAFF_IN_CHANGE_ORDER:
            return state.set('isLoading', false).set('error', new RestError(action.error));

        case SHOP_STAFF_PRODUCTS_REORDER:
            return state.set('products', action.products)
                .set('orderData', action.orderData)
                .set('isReordered', true);

        case SHOP_STAFF_PRODUCTS_RENDERED_REORDER_LIST:
            return state.set('isReordered', false);

        case REQUEST_CHANGE_PRODUCTS_ORDER_BY_SHOP_STAFF:
            return state.set('isChanging', true).set('succeedChange', false).set('errorChange', null);

        case SUCCEED_CHANGE_PRODUCTS_ORDER_BY_SHOP_STAFF:
            return state.set('isChanging', false).set('succeedChange', true).set('errorChange', null);

        case FAIL_CHANGE_PRODUCTS_ORDER_BY_SHOP_STAFF:
            return state.set('isChanging', false)
                .set('succeedChange', false)
                .set('errorChange', new RestError(action.error));

        case SHOP_STAFF_PRODUCTS_CLEAR_CHANGE_ORDER_STATE:
            return state.set('isChanging', false).set('succeedChange', false).set('errorChange', null);

        default:
            return state;
    }
}
