import {fromJS, Set} from "immutable";
import {
    FAIL_GET_SHOP_BY_SHOP_STAFF,
    FAIL_UPDATE_SHOP_BY_SHOP_STAFF,
    REQUEST_GET_SHOP_BY_SHOP_STAFF,
    REQUEST_UPDATE_SHOP_BY_SHOP_STAFF,
    SUCCEED_GET_SHOP_BY_SHOP_STAFF,
    SUCCEED_UPDATE_SHOP_BY_SHOP_STAFF,
} from "../../actions/RESTActionTypes";
import {
    SHOP_STAFF_SHOP_INFO_ADD_LANGUAGE_CODE,
    SHOP_STAFF_SHOP_INFO_CHANGE_LANGUAGE_INFO,
    SHOP_STAFF_SHOP_INFO_CHANGE_SHOP,
    SHOP_STAFF_SHOP_INFO_CLEAR,
    SHOP_STAFF_SHOP_INFO_CLEAR_EDIT_STATE,
    SHOP_STAFF_SHOP_INFO_DELETE_LANGUAGE_CODE,
    SHOP_STAFF_SHOP_INFO_SET_DEFAULT_LANGUAGE,
} from "../../actions/UIActionTypes";
import {indexOfLanguageCode, updateListByKeyValue} from "../../../utils/ListUtils";
import {changeDefaultLanguageInProduct, deleteLanguageInProduct} from "../../../behaviors/ProductBehavior";
import RestError from "../../../models/RestError";
import I18NLanguageProduct from "../../../models/I18NLanguageProduct";
import Shop from "../../../models/Shop";

export const initialShopStaffShopInfo = fromJS({
    isLoading: false,
    shop: new Shop(),
    error: null,
    newbie: new Shop(),
    defaultLanguageCode: '',
    deleteLanguageCodes: Set(),
    isEditing: false,
    succeedEdit: false,
    errorEdit: null,
});

export function shopStaffShopInfo(state = initialShopStaffShopInfo, action) {
    let index = -1;
    let shop, newbie, i18nLanguages;

    switch (action.type) {
        case SHOP_STAFF_SHOP_INFO_CLEAR:
            return initialShopStaffShopInfo;

        case SHOP_STAFF_SHOP_INFO_CLEAR_EDIT_STATE:
            return state.set('isEditing', initialShopStaffShopInfo.get('isEditing'))
                .set('succeedEdit', initialShopStaffShopInfo.get('succeedEdit'))
                .set('errorEdit', initialShopStaffShopInfo.get('errorEdit'));

        case REQUEST_GET_SHOP_BY_SHOP_STAFF:
            return state.set('isLoading', true);

        case SUCCEED_GET_SHOP_BY_SHOP_STAFF:
            shop = new Shop(action.result);

            return state.set('isLoading', false)
                .set('shop', shop)
                .set('newbie', shop)
                .set('defaultLanguageCode', shop.getDefaultLanguageCode())
                .set('error', null);

        case FAIL_GET_SHOP_BY_SHOP_STAFF:
            return state.set('isLoading', false)
                .set('shop', new Shop())
                .set('newbie', new Shop())
                .set('error', new RestError(action.error));

        case SHOP_STAFF_SHOP_INFO_ADD_LANGUAGE_CODE:
            newbie = state.get('newbie');
            i18nLanguages = newbie.get('i18n_languages');
            index = indexOfLanguageCode(i18nLanguages, action.languageCode);

            if (index >= 0) {
                return state;
            }

            newbie = newbie.set(
                'i18n_languages',
                i18nLanguages.push(new I18NLanguageProduct({language_code: action.languageCode}))
            );

            return state.set('newbie', newbie);

        case SHOP_STAFF_SHOP_INFO_DELETE_LANGUAGE_CODE:
            newbie = state.get('newbie');
            i18nLanguages = newbie.get('i18n_languages');
            index = indexOfLanguageCode(i18nLanguages, action.languageCode);

            if (index >= 0) {
                const {i18nLanguages: newI18nLanguages, deleteI18nLanguageCodes} = deleteLanguageInProduct(
                    index, i18nLanguages, state.get('deleteLanguageCodes')
                );

                newbie = newbie.set('i18n_languages', newI18nLanguages);

                state = state.set('newbie', newbie).set('deleteLanguageCodes', deleteI18nLanguageCodes);
            }

            return state;

        case SHOP_STAFF_SHOP_INFO_SET_DEFAULT_LANGUAGE:
            newbie = state.get('newbie');

            return state.set(
                'newbie',
                newbie.set(
                    'i18n_languages', changeDefaultLanguageInProduct(newbie.get('i18n_languages'), action.languageCode)
                )
            )
                .set('defaultLanguageCode', action.languageCode);

        case SHOP_STAFF_SHOP_INFO_CHANGE_SHOP:
            return state.set('newbie', state.get('newbie').set(action.key, action.value));

        case SHOP_STAFF_SHOP_INFO_CHANGE_LANGUAGE_INFO:
            newbie = state.get('newbie');

            return state.set(
                'newbie',
                newbie.set(
                    'i18n_languages',
                    updateListByKeyValue(newbie.get('i18n_languages'), action.index, action.key, action.value)
                )
            );

        case REQUEST_UPDATE_SHOP_BY_SHOP_STAFF:
            return state.set('isEditing', true).set('succeedEdit', false).set('errorEdit', null);

        case SUCCEED_UPDATE_SHOP_BY_SHOP_STAFF:
            return state.set('isEditing', false).set('succeedEdit', true).set('errorEdit', null);

        case FAIL_UPDATE_SHOP_BY_SHOP_STAFF:
            return state.set('isEditing', false)
                .set('succeedEdit', false)
                .set('errorEdit', new RestError(action.error));

        default:
            return state;
    }
}
