export function setItem(key, value, ttl = 0) {
    if (typeof window === 'undefined') {
        return;
    }

    const item = {value, ttl: ttl > 0 ? new Date().getTime() + ttl : 0};

    window.localStorage.setItem(key, JSON.stringify(item));
}

export function getItem(key) {
    if (typeof window === 'undefined') {
        return null;
    }

    const item = window.localStorage.getItem(key);

    if (!item) {
        return null;
    }

    const {value, ttl} = JSON.parse(item);

    if (ttl === 0 || new Date().getTime() < ttl) {
        return value;
    } else {
        return null;
    }
}
