import {fromJS} from "immutable";
import {SET_LAPTOP_DRAWER, SET_MOBILE_DRAWER} from "../actions/UIActionTypes";
import {setItem} from "../../utils/StorageUtils";

export const initialGnbManage = fromJS({
    isOpenTemporaryDrawer: false,
    isOpenPersistentDrawer: false,
});

export function gnbManage(state = initialGnbManage, action) {
    switch (action.type) {
        case SET_MOBILE_DRAWER:
            return state.set('isOpenTemporaryDrawer', action.openDrawer)
                .set('isOpenPersistentDrawer', false);

        case SET_LAPTOP_DRAWER:
            setItem('is_open_drawer', action.openDrawer);

            return state.set('isOpenPersistentDrawer', action.openDrawer).set('isOpenTemporaryDrawer', false);

        default:
            return state;
    }
}
