import {fromJS, List, Set} from "immutable";
import {
    ADMIN_PRODUCTS_CHANGE_PERIOD,
    ADMIN_PRODUCTS_CHANGE_QUERY_PARAMS,
    ADMIN_PRODUCTS_CLEAR_TASK_STATE,
    ADMIN_PRODUCTS_INIT_DATA,
    ADMIN_PRODUCTS_RESET_QUERY_PARAMS,
    ADMIN_PRODUCTS_SET_IS_WORK_REQUEST_OPEN,
    ADMIN_PRODUCTS_SET_SELECTED_ITEM,
} from "../../actions/UIActionTypes";
import {
    FAIL_CHANGE_PRODUCTS_BULK_DATA_BY_MANAGER,
    FAIL_DELETE_PRODUCTS_BY_MANAGER,
    FAIL_GET_PRODUCT_BY_SHOP_STAFF,
    FAIL_GET_PRODUCTS_BY_MANAGER,
    FAIL_GET_SHOPS_BY_MANAGER_IN_PRODUCTS,
    REQUEST_CHANGE_PRODUCTS_BULK_DATA_BY_MANAGER,
    REQUEST_DELETE_PRODUCTS_BY_MANAGER,
    REQUEST_GET_PRODUCT_FOR_WORK_REQUEST_BY_MANAGER,
    REQUEST_GET_PRODUCTS_BY_MANAGER,
    REQUEST_GET_SHOPS_BY_MANAGER_IN_PRODUCTS,
    SUCCEED_CHANGE_PRODUCTS_BULK_DATA_BY_MANAGER,
    SUCCEED_DELETE_PRODUCTS_BY_MANAGER,
    SUCCEED_GET_PRODUCT_FOR_WORK_REQUEST_BY_MANAGER,
    SUCCEED_GET_PRODUCTS_BY_MANAGER,
    SUCCEED_GET_SHOPS_BY_MANAGER_IN_PRODUCTS,
} from "../../actions/RESTActionTypes";
import RestError from "../../../models/RestError";
import Product from "../../../models/Product";
import ProductQueryParam from "../../../models/ui/ProductQueryParam";
import Shop from "../../../models/Shop";
import {parseStartAndEndDateFromQuery} from "../../../utils/DateUtils";

export const initialAdminProducts = fromJS({
    isLoading: false,
    isInitialized: false,
    products: List(),
    shops: List(),
    count: 0,
    error: null,
    queryParams: new ProductQueryParam(),
    selectedItemIds: Set(),
    isTaskWorking: false,
    succeedTask: false,
    succeedDelete: false,
    errorTask: null,
    isGetProductInfoLoading: false,
    isOpenWorkRequest: false,
});

export function adminProducts(state = initialAdminProducts, action) {
    let queryParams;
    let products, product, selectedProductIndex;

    switch (action.type) {
        case ADMIN_PRODUCTS_CLEAR_TASK_STATE:
            return state.set('isTaskWorking', initialAdminProducts.get('isTaskWorking'))
                .set('succeedTask', initialAdminProducts.get('succeedTask'))
                .set('succeedDelete', initialAdminProducts.get('succeedDelete'))
                .set('errorTask', initialAdminProducts.get('errorTask'));

        case REQUEST_GET_SHOPS_BY_MANAGER_IN_PRODUCTS:
            return state.set('isLoading', true)

        case SUCCEED_GET_SHOPS_BY_MANAGER_IN_PRODUCTS:
            return state.set('isLoading', false)
                .set('shops', List(action.results.map(item => new Shop(item))));

        case FAIL_GET_SHOPS_BY_MANAGER_IN_PRODUCTS:
            return state.set('isTaskWorking', false)
                .set('succeedTask', false)
                .set('succeedDelete', false)
                .set('errorTask', new RestError(action.error));

        case ADMIN_PRODUCTS_INIT_DATA:
            return state.set('queryParams', action.queryParams);

        case REQUEST_GET_PRODUCTS_BY_MANAGER:
            return state.set('isLoading', true)
                .set('selectedItemIds', initialAdminProducts.get('selectedItemIds'))
                .set('isTaskWorking', initialAdminProducts.get('isTaskWorking'))
                .set('succeedTask', initialAdminProducts.get('succeedTask'))
                .set('succeedDelete', initialAdminProducts.get('succeedDelete'))
                .set('errorTask', initialAdminProducts.get('errorTask'));

        case SUCCEED_GET_PRODUCTS_BY_MANAGER:
            return state.set('isLoading', false)
                .set('isInitialized', true)
                .set('products', List(action.results.map(item => new Product(item))))
                .set('count', action.count)
                .set('error', null);

        case FAIL_GET_PRODUCTS_BY_MANAGER:
            return state.set('isLoading', false).set('products', List()).set('error', new RestError(action.error));

        case ADMIN_PRODUCTS_CHANGE_QUERY_PARAMS:
            return state.set('queryParams', state.get('queryParams').set(action.key, action.value));

        case ADMIN_PRODUCTS_CHANGE_PERIOD:
            return state.set(
                'queryParams',
                state.get('queryParams').set('startDate', action.startDate).set('endDate', action.endDate)
            );

        case ADMIN_PRODUCTS_RESET_QUERY_PARAMS:
            queryParams = state.get('queryParams');
            const {startDate, endDate} = parseStartAndEndDateFromQuery({});

            return state.set(
                'queryParams',
                initialAdminProducts.get('queryParams')
                    .set('page', queryParams.get('page'))
                    .set('startDate', startDate)
                    .set('endDate', endDate)
            );

        case ADMIN_PRODUCTS_SET_SELECTED_ITEM:
            return state.set('selectedItemIds', action.selectedItemIds);

        case REQUEST_DELETE_PRODUCTS_BY_MANAGER:
        case REQUEST_CHANGE_PRODUCTS_BULK_DATA_BY_MANAGER:
            return state.set('isTaskWorking', true)
                .set('succeedTask', false)
                .set('succeedDelete', false)
                .set('errorTask', null);

        case SUCCEED_DELETE_PRODUCTS_BY_MANAGER:
            return state.set('isTaskWorking', false).set('succeedDelete', true).set('errorTask', null);

        case SUCCEED_CHANGE_PRODUCTS_BULK_DATA_BY_MANAGER:
            return state.set('isTaskWorking', false).set('succeedTask', true).set('errorTask', null);

        case FAIL_DELETE_PRODUCTS_BY_MANAGER:
        case FAIL_CHANGE_PRODUCTS_BULK_DATA_BY_MANAGER:
            return state.set('isTaskWorking', false)
                .set('succeedTask', false)
                .set('succeedDelete', false)
                .set('errorTask', new RestError(action.error));

        case ADMIN_PRODUCTS_SET_IS_WORK_REQUEST_OPEN:
            return state.set('isOpenWorkRequest', action.isOpenWorkRequest);

        case REQUEST_GET_PRODUCT_FOR_WORK_REQUEST_BY_MANAGER:
            return state.set('isGetProductInfoLoading', true);

        case SUCCEED_GET_PRODUCT_FOR_WORK_REQUEST_BY_MANAGER:
            products = state.get('products');
            product = new Product(action.result);
            selectedProductIndex = products.findIndex(item => item.get('id') === product.get('id'));

            if (selectedProductIndex >= 0) {
                products = products.setIn([selectedProductIndex, 'options'], product.get('options'));
            }

            return state.set('isOpenWorkRequest', true)
                .set('products', products)
                .set('isGetProductInfoLoading', false);

        case FAIL_GET_PRODUCT_BY_SHOP_STAFF:
            return state.set('isGetProductInfoLoading', false).set('error', new RestError(action.error));

        default:
            return state;
    }
}
