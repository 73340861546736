import {Record} from "immutable";
import {convertDateFormat} from "../utils/DateUtils";

export const EntityMixin = defaultValues => class extends Record({
    id: 0,
    created_date: null,
    updated_date: null,
    ...defaultValues
}) {
    constructor(args = {}) {
        super({...args});
    }

    equals(other) {
        if (!other) {
            return false;
        }
        return this.id === other.get('id');
    }

    getCreatedDate(format) {
        return convertDateFormat(this.created_date, format);
    }

    getUpdatedDate(format) {
        return convertDateFormat(this.updated_date, format);
    }

    isNewbie() {
        return this.id <= 0;
    }
};

export class Entity extends EntityMixin({}) {

    constructor(args = {}) {
        super({...args});
    }
}
