import {List} from "immutable";
import WorkRequestHistory from "./WorkRequestHistory";
import {EntityMixin} from "./Entity";
import {OPTIONS_KEY, UPDATED_PREFIX, WORK_REQUEST_KEY} from "../constants/CommonConstants";
import {ProductImageItem, ProductSpec} from "./WorkRequestData";
import {CATEGORY_UID_EYEWEAR} from "./Category";
import {
    WORK_REQUEST_STATUS_COMPLETE,
    WORK_REQUEST_STATUS_IN_PROGRESS,
    WORK_REQUEST_STATUS_READY,
    WORK_REQUEST_STATUS_REQUEST,
    WORK_REQUEST_STATUSES
} from "../constants/WorkRequestConstants";

const WorkRequestRecord = EntityMixin({
    shop_id: 0,
    shop: null,
    product_id: 0,
    product_option_id: 0,
    internal_mgmt_id: '',
    product_image_item: new ProductImageItem(),
    product_spec: new ProductSpec(),
    request_message: '',
    status: WORK_REQUEST_STATUS_REQUEST,
    comment: '',
    histories: List(),
});

class WorkRequest extends WorkRequestRecord {

    constructor(args = {}) {
        super({
            ...args,
            histories: args.histories ? List(args.histories.map(item => new WorkRequestHistory(item))): List(),
            product_image_item: args.product_image_item ? new ProductImageItem(args.product_image_item) : new ProductImageItem(),
            product_spec: args.product_spec ? new ProductSpec(args.product_spec) : new ProductSpec(),
        });
    }

    getStatusStr(t) {
        if (this.status === WORK_REQUEST_STATUS_REQUEST) {
            return t('work_request_status_request');
        } else if (this.status === WORK_REQUEST_STATUS_READY) {
            return t('work_request_status_ready');
        } else if (this.status === WORK_REQUEST_STATUS_IN_PROGRESS) {
            return t('work_request_status_in_progress');
        } else if (this.status === WORK_REQUEST_STATUS_COMPLETE) {
            return t('work_request_status_complete')
        } else {
            return ""
        }
    }

    canAdd(categoryUid) {
        return this.product_image_item.canAdd(categoryUid)
            && this.product_spec.canAdd(categoryUid)
            && WORK_REQUEST_STATUSES.includes(this.status);
    }

    isSame(other) {
        if (!other) {
            return false;
        }

        return this.internal_mgmt_id === other.get('internal_mgmt_id')
            && this.product_image_item.equals(other.get('product_image_item'))
            && this.product_spec.equals(other.get('product_spec'))
            && this.request_message === other.get('request_message')
            && this.status === other.get('status')
            && this.comment === other.get('comment');
    }

    makeFormData(formData, categoryUid, optionIndex, oldbieProductOption = null) {
        const shouldUpdate = this.id > 0;
        const keyPrefix = shouldUpdate
            ? `${UPDATED_PREFIX}${OPTIONS_KEY}${optionIndex}-${WORK_REQUEST_KEY}`
            : `${OPTIONS_KEY}${optionIndex}-${WORK_REQUEST_KEY}`;
        let oldbieItem = null;

        if (shouldUpdate && oldbieProductOption !== null) {
            oldbieItem = oldbieProductOption.get('work_request');
        }

        formData.append(`${keyPrefix}category_uid`, categoryUid);

        if (this.internal_mgmt_id) {
            if (shouldUpdate && oldbieItem) {
                if (this.internal_mgmt_id !== oldbieItem.get('internal_mgmt_id')) {
                    formData.append(`${keyPrefix}internal_mgmt_id`, this.internal_mgmt_id);
                }
            } else {
                formData.append(`${keyPrefix}internal_mgmt_id`, this.internal_mgmt_id);
            }
        }

        if (this.product_image_item) {
            const productImageItem = this.product_image_item.get(categoryUid);

            if (categoryUid === CATEGORY_UID_EYEWEAR && productImageItem) {
                if (productImageItem.get('frontImageData')) {
                    formData.append(
                        `${keyPrefix}product_image__front`, productImageItem.get('frontImageData').get('file')
                    );
                }

                if (productImageItem.get('sideImageData')) {
                    formData.append(
                        `${keyPrefix}product_image__side`, productImageItem.get('sideImageData').get('file')
                    );
                }

                if (productImageItem.get('side45ImageData')) {
                    formData.append(
                        `${keyPrefix}product_image__side_45`, productImageItem.get('side45ImageData').get('file')
                    );
                }

                if (productImageItem.get('topImageData')) {
                    formData.append(
                        `${keyPrefix}product_image__top`, productImageItem.get('topImageData').get('file')
                    );
                }
            }
        }

        if (this.product_spec) {
            if (shouldUpdate && oldbieItem) {
                if (!this.product_spec.equals(oldbieItem.get('product_spec'))) {
                    formData.append(`${keyPrefix}product_spec`, JSON.stringify(this.product_spec.toJS()));
                }
            } else {
                formData.append(`${keyPrefix}product_spec`, JSON.stringify(this.product_spec.toJS()));
            }
        }

        if (this.request_message) {
            if (shouldUpdate && oldbieItem) {
                if (this.request_message !== oldbieItem.get('request_message')) {
                    formData.append(`${keyPrefix}request_message`, this.request_message);
                }
            } else {
                formData.append(`${keyPrefix}request_message`, this.request_message);
            }
        }

        if (this.status) {
            if (shouldUpdate && oldbieItem) {
                if (this.status !== oldbieItem.get('status')) {
                    formData.append(`${keyPrefix}status`, `${this.status}`);
                }
            } else {
                formData.append(`${keyPrefix}status`, `${this.status}`);
            }
        }

        if (this.comment) {
            if (shouldUpdate && oldbieItem) {
                if (this.comment !== oldbieItem.get('comment')) {
                    formData.append(`${keyPrefix}comment`, this.comment);
                }
            } else {
                formData.append(`${keyPrefix}comment`, this.comment);
            }
        }
    }
}

export default WorkRequest;
