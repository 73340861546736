import {fromJS} from "immutable";
import {
    SHOP_STAFF_SIGNUP_CLEAR,
    SHOP_STAFF_SIGNUP_ENTER_EMAIL,
    SHOP_STAFF_SIGNUP_ENTER_NAME,
    SHOP_STAFF_SIGNUP_ENTER_PASSWORD,
    SHOP_STAFF_SIGNUP_ENTER_PASSWORD_CHECK,
    SHOP_STAFF_SIGNUP_ENTER_PHONE_NUMBER,
    SHOP_STAFF_SIGNUP_ENTER_SHOP_NAME,
    SHOP_STAFF_SIGNUP_ENTER_SHOP_PATH,
    SHOP_STAFF_SIGNUP_CHANGE_CATEGORY, SHOP_STAFF_SIGNUP_CHANGE_IS_VALID_CATEGORY,
} from "../../actions/UIActionTypes";
import {isEmailValid} from "../../../utils/RegexUtils";
import {
    FAIL_SIGNUP_SHOP_STAFF,
    REQUEST_SIGNUP_SHOP_STAFF,
    SUCCEED_SIGNUP_SHOP_STAFF
} from "../../actions/RESTActionTypes";
import ShopStaff from "../../../models/ShopStaff";
import RestError from "../../../models/RestError";
import {SELECT} from "../../../constants/CommonConstants";

export const initialShopStaffSignup = fromJS({
    email: '',
    isEmailValid: false,
    password: '',
    isPasswordValid: false,
    passwordCheck: '',
    name: '',
    phoneNumber: '',
    shopName: '',
    shopPath: '',
    selectedCategory: SELECT,
    isValidCategory: true,
    isLoading: false,
    shopStaff: null,
    error: null,
});

export function shopStaffSignup(state = initialShopStaffSignup, action) {
    switch (action.type) {
        case SHOP_STAFF_SIGNUP_CLEAR:
            return initialShopStaffSignup;

        case SHOP_STAFF_SIGNUP_ENTER_EMAIL:
            return state.set('email', action.email).set('isEmailValid', isEmailValid(action.email))
                .set('error', null);

        case SHOP_STAFF_SIGNUP_ENTER_PASSWORD:
            return state.set('password', action.password).set('isPasswordValid', action.password.length > 0);

        case SHOP_STAFF_SIGNUP_ENTER_PASSWORD_CHECK:
            return state.set('passwordCheck', action.passwordCheck).set('isPasswordValid', action.passwordCheck.length > 0);

        case SHOP_STAFF_SIGNUP_ENTER_NAME:
            return state.set('name', action.name);

        case SHOP_STAFF_SIGNUP_ENTER_PHONE_NUMBER:
            return state.set('phoneNumber', action.phoneNumber);

        case SHOP_STAFF_SIGNUP_ENTER_SHOP_NAME:
            return state.set('shopName', action.shopName);

        case SHOP_STAFF_SIGNUP_ENTER_SHOP_PATH:
            return state.set('shopPath', action.shopPath).set('error', null);

        case SHOP_STAFF_SIGNUP_CHANGE_CATEGORY:
            return state.set('selectedCategory', action.value).set('isValidCategory', SELECT !== action.value);

        case SHOP_STAFF_SIGNUP_CHANGE_IS_VALID_CATEGORY:
            return state.set('isValidCategory', action.isValidCategory);

        case REQUEST_SIGNUP_SHOP_STAFF:
            return state.set('isLoading', true).set('shopStaff', null).set('error', null);

        case SUCCEED_SIGNUP_SHOP_STAFF:
            return state.set('isLoading', false).set('shopStaff', new ShopStaff(action.result));

        case FAIL_SIGNUP_SHOP_STAFF:
            return state.set('isLoading', false).set('shopStaff', null).set('error', new RestError(action.error));
        default:
            return state;
    }
}
