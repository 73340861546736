import {fromJS} from "immutable";
import Shop from "../../../models/Shop";
import {
    FAIL_GET_SHOPS_BY_MANAGER,
    REQUEST_GET_SHOP_BY_MANAGER,
    SUCCEED_GET_SHOP_BY_MANAGER
} from "../../actions/RESTActionTypes";
import RestError from "../../../models/RestError";
import {ADMIN_SHOP_INFO_CLEAR} from "../../actions/UIActionTypes";
import {initializeAdminShops} from "./ShopsReducer";

export const initializeAdminShopInfo = fromJS({
    isInitialized: false,
    isLoading: false,
    shop: new Shop(),
    error: null,
    newbie: new Shop(),
})

export function adminShopInfo(state = initializeAdminShopInfo, action) {
    let shop, newbie;

    switch (action.type) {
        case ADMIN_SHOP_INFO_CLEAR:
            return initializeAdminShops;

        case REQUEST_GET_SHOP_BY_MANAGER:
            return state.set('isLoading', true);

        case SUCCEED_GET_SHOP_BY_MANAGER:
            shop = new Shop(action.result);

            return state.set('isLoading', false)
                .set('isInitialized', true)
                .set('shop', shop)
                .set('newbie', shop)
                .set('error', null)
        case FAIL_GET_SHOPS_BY_MANAGER:
            return state.set('isLoading', false)
                .set('shop', new Shop())
                .set('newbie', new Shop())
                .set('error', new RestError(action.error));
        default:
            return state;
    }
}