import {I18NLanguageMixin} from "./I18NLanguage";
import {indexOfLanguageCode} from "../utils/ListUtils";
import {LANGUAGES_KEY, OPTIONS_KEY, UPDATED_PREFIX} from "../constants/CommonConstants";

export const I18NLanguageProductOptionMixin = defaultValues => class extends I18NLanguageMixin({
    name: '',
    ...defaultValues
}) {
    constructor(args = {}) {
        super({...args});
    }

    canAdd() {
        // return Boolean(this.language_code) && Boolean(this.name);
        return Boolean(this.language_code);
    }

    isSame(other) {
        if (!other) {
            return false;
        }

        return this.language_code === other.get('language_code')
            // && this.name === other.get('name')
            && this.is_default === other.get('is_default');
    }

    makeFormData(optionIndex, formData, oldbieProductOption = null) {
        let oldbieItem = null;

        if (oldbieProductOption !== null) {
            const oldbieItems = oldbieProductOption.get('i18n_languages');
            const indexOfOld = indexOfLanguageCode(oldbieItems, this.language_code);

            oldbieItem = indexOfOld >= 0 ? oldbieItems.get(indexOfOld) : null;
        }

        const shouldUpdate = oldbieItem != null;
        const keyPrefix = shouldUpdate
            ? `${UPDATED_PREFIX}${OPTIONS_KEY}${optionIndex}-${LANGUAGES_KEY}${this.language_code}-`
            : `${OPTIONS_KEY}${optionIndex}-${LANGUAGES_KEY}${this.language_code}-`;
        let shouldAddItem = false;

        if (shouldUpdate) {
            if (!this.isSame(oldbieItem)) {
                shouldAddItem = true;
            }
        } else {
            shouldAddItem = true;
        }

        if (shouldAddItem) {
            formData.append(`${keyPrefix}is_default`, this.is_default ? '1' : '0');
            // formData.append(`${keyPrefix}name`, this.name);
            formData.append(`${keyPrefix}name`, '');
            formData.append(`${keyPrefix}language_code`, this.language_code);
        }
    }
};

export default class I18NLanguageProductOption extends I18NLanguageProductOptionMixin({}) {

    constructor(args = {}) {
        super({...args});
    }
}
