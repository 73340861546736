import {fromJS} from "immutable";
import {ADMIN_SIGNIN_CLEAR, ADMIN_SIGNIN_ENTER_EMAIL, ADMIN_SIGNIN_ENTER_PASSWORD} from "../../actions/UIActionTypes";
import {FAIL_SIGNIN_MANAGER, REQUEST_SIGNIN_MANAGER, SUCCEED_SIGNIN_MANAGER} from "../../actions/RESTActionTypes";
import {isEmailValid} from "../../../utils/RegexUtils";
import RestError from "../../../models/RestError";
import Manager from "../../../models/Manager";

export const initialAdminSignin = fromJS({
    email: '',
    isEmailValid: false,
    password: '',
    isPasswordValid: false,
    isLoading: false,
    manager: null,
    error: null,
});

export function adminSignin(state = initialAdminSignin, action) {
    switch (action.type) {
        case ADMIN_SIGNIN_CLEAR:
            return initialAdminSignin;

        case ADMIN_SIGNIN_ENTER_EMAIL:
            return state.set('email', action.email).set('isEmailValid', isEmailValid(action.email));

        case ADMIN_SIGNIN_ENTER_PASSWORD:
            return state.set('password', action.password).set('isPasswordValid', action.password.length > 0);

        case REQUEST_SIGNIN_MANAGER:
            return state.set('isLoading', true).set('manager', null).set('error', null);

        case SUCCEED_SIGNIN_MANAGER:
            return state.set('isLoading', false).set('manager', new Manager(action.result));

        case FAIL_SIGNIN_MANAGER:
            return state.set('isLoading', false).set('manager', null).set('error', new RestError(action.error));

        default:
            return state;
    }
}
