import {ofType} from "redux-observable";
import {
    FAIL_INITIALIZE_PRICING_BY_SHOP_STAFF,
    REQUEST_INITIALIZE_PRICING_BY_SHOP_STAFF,
    SUCCEED_INITIALIZE_PRICING_BY_SHOP_STAFF
} from "../actions/RESTActionTypes";
import {catchError, map, mergeMap} from "rxjs/operators";

import {loadResults, occurError} from "../actions/RESTAction";
import {of} from "rxjs";
import {initializePricing} from "../../rest/PricingApi";

export const initializePricingEpic = action$ => action$.pipe(
    ofType(REQUEST_INITIALIZE_PRICING_BY_SHOP_STAFF),
    mergeMap(action => initializePricing(action.countryCode, action.categoryUid).pipe(
        map(res => loadResults(SUCCEED_INITIALIZE_PRICING_BY_SHOP_STAFF, res.response)),
        catchError(error => of(occurError(FAIL_INITIALIZE_PRICING_BY_SHOP_STAFF, error.xhr)))
    ))
);