import {List, Record} from "immutable";
import moment from "moment";
import {getDefaultEndDate, getDefaultStartDate} from "../../utils/DateUtils";

export const ACTIVATION_STATUS_ALL = '-1';
export const ACTIVATION_STATUS_ON = '1';
export const ACTIVATION_STATUS_OFF = '0';
export const ACTIVATION_STATUSES = List.of(ACTIVATION_STATUS_ALL, ACTIVATION_STATUS_ON, ACTIVATION_STATUS_OFF);

export const ORDER_LATEST = 'latest';
export const ORDER_ORDERED = 'ordered';

export const QueryParameterMixin = defaultValues => class extends Record({
    page: 1,
    activationStatus: ACTIVATION_STATUS_ALL,
    queryType: '0',
    query: '',
    periodType: '0',
    startDate: '',
    endDate: '',
    order: ORDER_LATEST,
    ...defaultValues,
}) {

    constructor(args = {}) {
        super({
            ...args,
            page: isNaN(args.page) ? 1 : parseInt(args.page, 10),
            activationStatus: ACTIVATION_STATUSES.includes(args.activationStatus)
                ? args.activationStatus : ACTIVATION_STATUS_ALL,
            query: args.query || '',
            order: args.order || ORDER_LATEST,
        });
    }

    convertStartDate2Moment(format = null) {
        return moment(this.startDate);
    }

    convertEndDate2Moment(format = null) {
        return moment(this.endDate);
    }

    toObject() {
        return {
            page: this.page,
            is_activated: this.activationStatus,
            query_type: this.queryType,
            query: this.query,
            period_type: this.periodType,
            start_date: this.startDate,
            end_date: this.endDate,
            order: this.order,
        };
    }
};

export class QueryParameter extends QueryParameterMixin({}) {

    constructor(args = {}) {
        super({...args});
    }
}
