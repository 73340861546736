import {combineEpics} from "redux-observable";
import {getProductCategoriesEpic, getProductCategoryEpic} from "./ProductsEpic";
import {createBuilderScripttagEpic, deleteBuilderScripttagEpic, getCafe24StoreEpic} from "./PluginsEpic";
import {
    changeShopStaffPasswordEpic,
    deletePaymentHistoryEpic,
    getPaymentHistoryEpic,
    getShopStaffEpic,
    payIamportProductChargeEpic,
    payIamportScheduleChargeEpic,
    payShopifyAnnualChargeEpic,
    payShopifyProductChargeEpic,
    payShopifyRecurringChargeEpic,
    payShopifyTrialChargeEpic,
    payTrialChargeEpic,
    signinShopStaffEpic,
    signupShopStaffEpic,
    updatePaymentHistoryEpic,
    updateShopStaffArTransformInfoEpic,
    updateShopStaffEpic,
    sendVerificationEmailEpic,
    verifyEmailEpic,
} from "./ShopStaff/ShopStaffEpic";
import {
    getShopEpic,
    getShopPluginEpic,
    initializePaymentHistoriesEpic,
    initPluginSettingsEpic,
    updateShopEpic,
    updateShopPluginEpic,
    initPluginSkinFilterViewerEpic,
    updatePluginSkinFilterViewerModelTransformInfoEpic,
    getPaymentHistoriesEpic,
} from "./ShopStaff/ShopEpic";
import {
    changeBrandsOrderEpic,
    createBrandEpic,
    deleteBrandsEpic,
    getBrandEpic,
    getBrandsEpic,
    updateBrandEpic,
} from "./ShopStaff/BrandEpic";
import {
    createProductSizeEpic,
    deleteProductSizesEpic,
    getProductSizeEpic,
    getProductSizesEpic,
    updateProductSizeEpic,
} from "./ShopStaff/ProductSizeEpic";
import {
    createProductColorEpic,
    deleteProductColorsEpic,
    getProductColorEpic,
    getProductColorsEpic,
    initShopStaffProductColorAddEpic,
    updateProductColorEpic,
} from "./ShopStaff/ProductColorEpic";
import {
    changeProductsBulkDataEpic,
    changeProductsOrderEpic,
    createProductEpic,
    createWhatsNewsEpic,
    copySampleProductsEpic,
    createBatchFileDataEpic,
    deleteProductsEpic,
    getProductEpic,
    getProductsEpic,
    getShopStaffBrandsAndCategoriesAndShopEpic,
    getWorkRequestHistoriesByShopStaffEpic,
    importBuilderProductEpic,
    initProductThreeViewerEpic,
    initShopStaffProductAddEpic,
    initShopStaffProductEditEpic,
    updateProductEpic, getWhatsNewsEpic, changeWhatsNewsOrderEpic, deleteWhatsNewsEpic,
} from "./ShopStaff/ProductEpic";
import {
    changeManagerPasswordEpic,
    getManagerEpic,
    signinManagerEpic,
    updateArTransformInfoEpic,
    updateThreeModelModelTransformInfoEpic,
    updateManagerEpic
} from "./Manager/ManagerEpic";
import {getPaymentHistoriesByManagerEpic, getShopByManagerEpic, getShopsByManagerEpic} from "./Manager/ShopEpic";
import {
    changeProductsBulkDataByManagerEpic,
    createProductByManagerEpic,
    deleteProductsByManagerEpic,
    getProductByManagerEpic,
    getProductsByManagerEpic,
    getWorkRequestHistoriesByManagerEpic,
    initAdminProductAddEpic,
    initAdminProductEditEpic,
    initProductThreeViewerByManagerEpic,
    updateProductByManagerEpic,
    getFaceEffectsByManagerEpic,
    getEnvImagePresetsByManagerEpic,
} from "./Manager/ProductEpic";
import {initializePricingEpic} from "./PricingEpic";
import {createIamportPaymentEpic} from "./IamportEpic";

const rootEpic = combineEpics(
    getProductCategoriesEpic,
    getProductCategoryEpic,

    signupShopStaffEpic,
    signinShopStaffEpic,
    getShopStaffEpic,
    updateShopStaffEpic,
    changeShopStaffPasswordEpic,
    payShopifyTrialChargeEpic,
    payShopifyRecurringChargeEpic,
    payShopifyAnnualChargeEpic,
    payShopifyProductChargeEpic,
    updateShopStaffArTransformInfoEpic,
    sendVerificationEmailEpic,
    verifyEmailEpic,

    getPaymentHistoryEpic,
    payTrialChargeEpic,
    deletePaymentHistoryEpic,
    updatePaymentHistoryEpic,
    payIamportScheduleChargeEpic,
    payIamportProductChargeEpic,
    getShopEpic,
    updateShopEpic,
    initPluginSettingsEpic,
    getShopPluginEpic,
    updateShopPluginEpic,
    getPaymentHistoriesEpic,
    initPluginSkinFilterViewerEpic,
    updatePluginSkinFilterViewerModelTransformInfoEpic,
    initializePaymentHistoriesEpic,

    getBrandsEpic,
    createBrandEpic,
    getBrandEpic,
    updateBrandEpic,
    deleteBrandsEpic,
    changeBrandsOrderEpic,

    getProductSizesEpic,
    createProductSizeEpic,
    getProductSizeEpic,
    updateProductSizeEpic,
    deleteProductSizesEpic,

    getProductColorsEpic,
    initShopStaffProductColorAddEpic,
    createProductColorEpic,
    getProductColorEpic,
    updateProductColorEpic,
    deleteProductColorsEpic,

    getShopStaffBrandsAndCategoriesAndShopEpic,
    getProductsEpic,
    getWhatsNewsEpic,
    initShopStaffProductAddEpic,
    initShopStaffProductEditEpic,
    importBuilderProductEpic,
    createProductEpic,
    createWhatsNewsEpic,
    copySampleProductsEpic,
    createBatchFileDataEpic,
    getProductEpic,
    updateProductEpic,
    deleteProductsEpic,
    deleteWhatsNewsEpic,
    changeProductsBulkDataEpic,
    changeProductsOrderEpic,
    changeWhatsNewsOrderEpic,
    initProductThreeViewerEpic,

    getCafe24StoreEpic,
    createBuilderScripttagEpic,
    deleteBuilderScripttagEpic,

    signinManagerEpic,
    getManagerEpic,
    updateManagerEpic,
    changeManagerPasswordEpic,

    getShopsByManagerEpic,
    getShopByManagerEpic,
    getPaymentHistoriesByManagerEpic,

    getProductsByManagerEpic,
    initAdminProductAddEpic,
    initAdminProductEditEpic,
    createProductByManagerEpic,
    getProductByManagerEpic,
    updateProductByManagerEpic,
    deleteProductsByManagerEpic,
    changeProductsBulkDataByManagerEpic,
    initProductThreeViewerByManagerEpic,
    getWorkRequestHistoriesByManagerEpic,
    getWorkRequestHistoriesByShopStaffEpic,
    updateArTransformInfoEpic,
    updateThreeModelModelTransformInfoEpic,
    getFaceEffectsByManagerEpic,
    getEnvImagePresetsByManagerEpic,

    initializePricingEpic,

    createIamportPaymentEpic,
);

export default rootEpic;
