import {request} from "universal-rxjs-ajax";
import {CONTENT_TYPE_JSON, createManagerHeaders, getBaseUrl} from "./APIClient";
import {convertQueryParams2Str} from "../utils/UrlUtils";

const PATH_MANAGERS = '/managers';
const PATH_SHOPS = '/shops';
const PATH_PRODUCTS = '/products';
const PATH_WORK_REQUESTS = '/work_requests';
const PATH_HISTORIES = '/histories';
const PATH_OPTIONS = '/options';
const PATH_ARMTIS = '/ar_mtis';
const PATH_PRICING_PAYMENT_HISTORIES = '/pricing_payment_histories';
const PATH_FACE_EFFECTS = '/face_effects';
const PATH_ENV_IMAGE_PRESETS = '/env_image_presets';
const PATH_CHANGE_3D_MODEL_INFOS = '/change_3d_model_infos';

export function signin(email, password) {
    const path = `${PATH_MANAGERS}/signin`;

    return request({
        url: `${getBaseUrl()}${path}`,
        method: 'POST',
        body: {email, password},
        headers: createManagerHeaders(path, null, CONTENT_TYPE_JSON),
    });
}

export function getManager(managerInfo) {
    const path = `${PATH_MANAGERS}/${managerInfo.managerId}`;

    return request({
        url: `${getBaseUrl()}${path}`,
        method: 'GET',
        headers: createManagerHeaders(path, managerInfo),
    });
}

export function updateManager(managerInfo, payload) {
    const path = `${PATH_MANAGERS}/${managerInfo.managerId}`;

    return request({
        url: `${getBaseUrl()}${path}`,
        method: 'PUT',
        body: payload,
        headers: createManagerHeaders(path, managerInfo, CONTENT_TYPE_JSON),
    });
}

export function changeManagerPassword(managerInfo, old_password, new_password) {
    const path = `${PATH_MANAGERS}/${managerInfo.managerId}/change_password`;

    return request({
        url: `${getBaseUrl()}${path}`,
        method: 'POST',
        body: {old_password, new_password},
        headers: createManagerHeaders(path, managerInfo, CONTENT_TYPE_JSON),
    });
}

export function getShops(managerInfo, queryParams) {
    const path = `${PATH_MANAGERS}/${managerInfo.managerId}${PATH_SHOPS}`;
    let url = `${getBaseUrl()}${path}${convertQueryParams2Str(queryParams)}`;

    return request({url, method: 'GET', headers: createManagerHeaders(path, managerInfo)});
}

export function getShop(managerInfo, shopId) {
    const path = `${PATH_MANAGERS}/${managerInfo.managerId}${PATH_SHOPS}/${shopId}`;

    return request({
        url: `${getBaseUrl()}${path}`,
        method: 'GET',
        headers: createManagerHeaders(path, managerInfo),
    });
}

export function getPaymentHistories(managerInfo, queryParams) {
    const path = `${PATH_MANAGERS}/${managerInfo.managerId}${PATH_PRICING_PAYMENT_HISTORIES}`;
    let url = `${getBaseUrl()}${path}${convertQueryParams2Str(queryParams)}`;

    return request({url, method: 'GET', headers: createManagerHeaders(path, managerInfo)});
}

export function getProducts(managerInfo, queryParams) {
    const path = `${PATH_MANAGERS}/${managerInfo.managerId}${PATH_PRODUCTS}`;
    let url = `${getBaseUrl()}${path}${convertQueryParams2Str(queryParams)}`;

    return request({url, method: 'GET', headers: createManagerHeaders(path, managerInfo)});
}

export function readyProductData(managerInfo, queryParams) {
    const path = `${PATH_MANAGERS}/${managerInfo.managerId}/ready_product_data`;
    let url = `${getBaseUrl()}${path}${convertQueryParams2Str(queryParams)}`;

    return request({url, method: 'GET', headers: createManagerHeaders(path, managerInfo)});
}

export function createProduct(managerInfo, formData) {
    const path = `${PATH_MANAGERS}/${managerInfo.managerId}${PATH_PRODUCTS}`;

    return request({
        url: `${getBaseUrl()}${path}`,
        method: 'POST',
        body: formData,
        headers: createManagerHeaders(path, managerInfo),
    });
}

export function getProduct(managerInfo, productId) {
    const path = `${PATH_MANAGERS}/${managerInfo.managerId}${PATH_PRODUCTS}/${productId}`;

    return request({
        url: `${getBaseUrl()}${path}`,
        method: 'GET',
        headers: createManagerHeaders(path, managerInfo),
    });
}

export function updateProduct(managerInfo, productId, formData) {
    const path = `${PATH_MANAGERS}/${managerInfo.managerId}${PATH_PRODUCTS}/${productId}`;

    return request({
        url: `${getBaseUrl()}${path}`,
        method: 'PUT',
        body: formData,
        headers: createManagerHeaders(path, managerInfo),
    });
}

export function deleteProducts(managerInfo, product_ids) {
    const path = `${PATH_MANAGERS}/${managerInfo.managerId}${PATH_PRODUCTS}/delete`;

    return request({
        url: `${getBaseUrl()}${path}`,
        method: 'POST',
        body: {product_ids},
        headers: createManagerHeaders(path, managerInfo, CONTENT_TYPE_JSON),
    });
}

export function changeProductsBulkData(managerInfo, product_ids, key, value) {
    const path = `${PATH_MANAGERS}/${managerInfo.managerId}${PATH_PRODUCTS}/change_bulk_data`;

    return request({
        url: `${getBaseUrl()}${path}`,
        method: 'POST',
        body: {product_ids, key, value},
        headers: createManagerHeaders(path, managerInfo, CONTENT_TYPE_JSON),
    });
}

export function getWorkRequests(managerInfo, queryParams) {
    const path = `${PATH_MANAGERS}/${managerInfo.managerId}${PATH_WORK_REQUESTS}`;
    let url = `${getBaseUrl()}${path}${convertQueryParams2Str(queryParams)}`;

    return request({url, method: 'GET', headers: createManagerHeaders(path, managerInfo)});
}

export function getWorkRequest(managerInfo, workRequestId) {
    const path = `${PATH_MANAGERS}/${managerInfo.managerId}${PATH_WORK_REQUESTS}/${workRequestId}`;

    return request({
        url: `${getBaseUrl()}${path}`,
        method: 'GET',
        headers: createManagerHeaders(path, managerInfo),
    });
}

export function updateWorkRequest(managerInfo, workRequestId, formData) {
    const path = `${PATH_MANAGERS}/${managerInfo.managerId}${PATH_WORK_REQUESTS}/${workRequestId}`;

    return request({
        url: `${getBaseUrl()}${path}`,
        method: 'PUT',
        body: formData,
        headers: createManagerHeaders(path, managerInfo),
    });
}

export function getWorkRequestHistories(managerInfo, workRequestId, page) {
    const path = `${PATH_MANAGERS}/${managerInfo.managerId}${PATH_WORK_REQUESTS}/${workRequestId}${PATH_HISTORIES}`

    return request({
        url: `${getBaseUrl()}${path}${convertQueryParams2Str({page})}`,
        method: 'GET',
        headers: createManagerHeaders(path, managerInfo),
    })
}

export function updateArTransformModelInfo(managerInfo, productId, productOptionId, armtiId, formData) {
    const path = `${PATH_MANAGERS}/${managerInfo.managerId}${PATH_PRODUCTS}/${productId}${PATH_OPTIONS}/${productOptionId}${PATH_ARMTIS}/${armtiId}`;

    return request({
        url: `${getBaseUrl()}${path}`,
        method: 'PUT',
        body: formData,
        headers: createManagerHeaders(path, managerInfo),
    });
}

export function updateThreeModelModelTransformInfo(
    shop_id,
    managerInfo,
    productId,
    productOptionId,
    ar_mti_items,
    lighting_env,
    face_effect_info,
    lighting_env_viewer
) {
    const path = `${PATH_MANAGERS}/${managerInfo.managerId}${PATH_PRODUCTS}/${productId}${PATH_OPTIONS}/${productOptionId}${PATH_CHANGE_3D_MODEL_INFOS}`;

    return request({
        url: `${getBaseUrl()}${path}`,
        method: 'PUT',
        body: {shop_id, ar_mti_items, lighting_env_viewer, lighting_env, face_effect_info},
        headers: createManagerHeaders(path, managerInfo),
    });
}

export function getFaceEffects(managerInfo, queryParams) {
    const path = `${PATH_MANAGERS}/${managerInfo.managerId}${PATH_FACE_EFFECTS}`;
    let url = `${getBaseUrl()}${path}${convertQueryParams2Str(queryParams)}`;

    return request({url, method: 'GET', headers: createManagerHeaders(path, managerInfo)});
}

export function getEnvImagePresets(managerInfo, queryParams) {
    const path = `${PATH_MANAGERS}/${managerInfo.managerId}${PATH_ENV_IMAGE_PRESETS}`;
    let url = `${getBaseUrl()}${path}${convertQueryParams2Str(queryParams)}`;

    return request({url, method: 'GET', headers: createManagerHeaders(path, managerInfo)});
}
