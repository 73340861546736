import {Record} from "immutable";

const ImageDataRecord = Record({
    imagePreview: null,
    file: null,
    exif: null,
});

class ImageData extends ImageDataRecord {

    constructor(args = {}) {
        super({...args});
    }

    equals(other) {
        if (!other) {
            return false;
        }
        return this.imagePreview === other.imagePreview || this.file === other.file;
    }
}

export default ImageData;
