import {fromJS, List, Map} from 'immutable';
import {
    FAIL_CREATE_PRODUCT_BY_SHOP_STAFF,
    FAIL_IMPORT_BUILDER_PRODUCT_INFO_IN_PRODUCT_ADD,
    FAIL_INIT_SHOP_STAFF_PRODUCT_ADD,
    REQUEST_CREATE_PRODUCT_BY_SHOP_STAFF,
    REQUEST_IMPORT_BUILDER_PRODUCT_INFO_IN_PRODUCT_ADD,
    REQUEST_INIT_SHOP_STAFF_PRODUCT_ADD,
    SUCCEED_CREATE_PRODUCT_BY_SHOP_STAFF,
    SUCCEED_IMPORT_BUILDER_PRODUCT_INFO_IN_PRODUCT_ADD,
    SUCCEED_INIT_SHOP_STAFF_PRODUCT_ADD,
} from "../../actions/RESTActionTypes";
import {
    SHOP_STAFF_PRODUCT_ADD_ADD_COUNTRY_CODE,
    SHOP_STAFF_PRODUCT_ADD_ADD_LANGUAGE_CODE,
    SHOP_STAFF_PRODUCT_ADD_ADD_OPTION,
    SHOP_STAFF_PRODUCT_ADD_ADD_OPTIONS_SPEC_DATA,
    SHOP_STAFF_PRODUCT_ADD_ADD_PRODUCT_COLOR,
    SHOP_STAFF_PRODUCT_ADD_ADD_PRODUCT_SIZE,
    SHOP_STAFF_PRODUCT_ADD_ADD_TEMP_WORK_REQUEST,
    SHOP_STAFF_PRODUCT_ADD_ADD_WORK_REQUEST,
    SHOP_STAFF_PRODUCT_ADD_CHANGE_CATEGORY,
    SHOP_STAFF_PRODUCT_ADD_CHANGE_COUNTRY_INFO,
    SHOP_STAFF_PRODUCT_ADD_CHANGE_LANGUAGE_INFO,
    SHOP_STAFF_PRODUCT_ADD_CHANGE_OPTION,
    SHOP_STAFF_PRODUCT_ADD_CHANGE_OPTION_COLOR,
    SHOP_STAFF_PRODUCT_ADD_CHANGE_OPTION_COLOR_INFO,
    SHOP_STAFF_PRODUCT_ADD_CHANGE_OPTION_COUNTRY_INFO,
    SHOP_STAFF_PRODUCT_ADD_CHANGE_OPTION_LANGUAGE_INFO,
    SHOP_STAFF_PRODUCT_ADD_CHANGE_PRODUCT,
    SHOP_STAFF_PRODUCT_ADD_CHANGE_RESOURCE_FILE_DATA,
    SHOP_STAFF_PRODUCT_ADD_CHANGE_TEMP_WORK_REQUEST_IMAGE,
    SHOP_STAFF_PRODUCT_ADD_CHANGE_TEMP_WORK_REQUEST_ALL_IMAGES,
    SHOP_STAFF_PRODUCT_ADD_CHANGE_TEMP_WORK_REQUEST_REQUEST_MESSAGE,
    SHOP_STAFF_PRODUCT_ADD_CHANGE_TEMP_WORK_REQUEST_SPEC,
    SHOP_STAFF_PRODUCT_ADD_CHANGE_TEMP_WORK_REQUEST_SPEC_UNIT,
    SHOP_STAFF_PRODUCT_ADD_CLEAR_CREATE_STATE,
    SHOP_STAFF_PRODUCT_ADD_CLEAR_IMPORT_BUILDER_PRODUCT_INFO_STATE,
    SHOP_STAFF_PRODUCT_ADD_DELETE_COUNTRY_CODE,
    SHOP_STAFF_PRODUCT_ADD_DELETE_LANGUAGE_CODE,
    SHOP_STAFF_PRODUCT_ADD_DELETE_OPTION,
    SHOP_STAFF_PRODUCT_ADD_DELETE_TEMP_WORK_REQUEST,
    SHOP_STAFF_PRODUCT_ADD_DELETE_TEMP_WORK_REQUEST_IMAGE,
    SHOP_STAFF_PRODUCT_ADD_IMPORT_TEMP_WORK_REQUEST_SPEC,
    SHOP_STAFF_PRODUCT_ADD_INIT_DATA,
    SHOP_STAFF_PRODUCT_ADD_REQUIRED_ERRORS,
    SHOP_STAFF_PRODUCT_ADD_REQUIRED_OPTION_ERRORS,
    SHOP_STAFF_PRODUCT_ADD_REQUIRED_I18N_PRODUCT_LANGUAGE_ERRORS,
    SHOP_STAFF_PRODUCT_ADD_REQUIRED_I18N_PRODUCT_COUNTRY_ERRORS,
    SHOP_STAFF_PRODUCT_ADD_SET_DEFAULT_COUNTRY,
    SHOP_STAFF_PRODUCT_ADD_SET_DEFAULT_LANG_AND_COUNTRY,
    SHOP_STAFF_PRODUCT_ADD_SET_DEFAULT_LANGUAGE,
    SHOP_STAFF_PRODUCT_ADD_REQUIRED_TEMP_WORK_REQUEST_ERRORS,
    SHOP_STAFF_PRODUCT_ADD_CHANGE_TEMP_WORK_REQUEST_ALL_SPECS,
    SHOP_STAFF_PRODUCT_ADD_TOGGLE_ADD_MODE,
    SHOP_STAFF_PRODUCT_ADD_SET_IS_OPENED_CHANGE_ORDER_DIALOG,
    SHOP_STAFF_PRODUCT_ADD_REORDER, SHOP_STAFF_PRODUCT_ADD_REORDER_LIST, SHOP_STAFF_PRODUCT_ADD_CHANGE_OPTIONS_ORDER,
} from '../../actions/UIActionTypes';
import {indexOfCountryCode, indexOfLanguageCode, updateListByKeyValue, updateListItem} from "../../../utils/ListUtils";
import {
    changeDefaultCountryInProduct,
    changeDefaultLanguageInProduct,
    deleteCountryInProduct,
    deleteLanguageInProduct,
    mergeBuilderProduct2Product,
    reduceCategories,
} from "../../../behaviors/ProductBehavior";
import {
    addCountry2Options,
    addLanguage2Options,
    changeDefaultCountryInOptions,
    changeDefaultLanguageInOptions,
    changeProductOptionColor,
    deleteCountryInOptions,
    deleteLanguageInOptions, getOptionsByChangeOrder,
} from '../../../behaviors/ProductOptionBehavior';
import RestError from "../../../models/RestError";
import Brand from "../../../models/Brand";
import Product from "../../../models/Product";
import ProductOption from "../../../models/ProductOption";
import I18NLanguageProduct from "../../../models/I18NLanguageProduct";
import I18NCountryProduct from "../../../models/I18NCountryProduct";
import I18NLanguageProductOption from "../../../models/I18NLanguageProductOption";
import I18NCountryProductOption from "../../../models/I18NCountryProductOption";
import ProductSize from "../../../models/ProductSize";
import ProductColor from "../../../models/ProductColor";
import WorkRequest from "../../../models/WorkRequest";
import {
    ProductI18nCountriesError,
    ProductI18nLanguagesError,
    ProductOptionRequiredError,
    ProductRequiredError
} from "../../../models/ui/ProductRequiredError";
import Shop from "../../../models/Shop";

export const initialShopStaffProductAdd = fromJS({
    isInitialized: false,
    isLoading: false,
    isSaveAndAddAnotherMode: false,
    brands: List(),
    categories: List(),
    productSizes: List(),
    productColors: List(),
    categoryRootUid: null,
    error: null,
    defaultLanguageCode: '',
    defaultCountryCode: '',
    product: new Product(),
    shop: new Shop(),
    productOptionColors: List(),
    isCreating: false,
    succeedCreate: false,
    errorCreate: null,
    isBuilderProductImported: false,
    errorImportBuilderProduct: null,
    tempWorkRequest: new WorkRequest(),
    optionsSpecData: null,
    requiredErrors: new ProductRequiredError(),
    isOpenedChangeOptionDialog: false,
    isReordered: false,
    tempOptions: List(),
});

export function shopStaffProductAdd(state = initialShopStaffProductAdd, action) {
    let index = -1;
    let product, i18nLanguages, i18nCountries;
    let options, option;
    let productOptionColors;
    let categoryRootUid;
    let tempWorkRequest;
    let requiredErrors, requiredErrorOptions, requiredErrorOption;
    let tempOptions;

    switch (action.type) {
        case SHOP_STAFF_PRODUCT_ADD_INIT_DATA:
            return initialShopStaffProductAdd.set('product', state.get('product').set('shop_id', action.shopId));

        case SHOP_STAFF_PRODUCT_ADD_CLEAR_CREATE_STATE:
            return state.set('isCreating', initialShopStaffProductAdd.get('isCreating'))
                .set('succeedCreate', initialShopStaffProductAdd.get('succeedCreate'))
                .set('errorCreate', initialShopStaffProductAdd.get('errorCreate'));

        case REQUEST_INIT_SHOP_STAFF_PRODUCT_ADD:
            return state.set('isInitialized', false).set('isLoading', true);

        case SUCCEED_INIT_SHOP_STAFF_PRODUCT_ADD:
            const shop = new Shop(action.shop);
            const languageCode = shop.get('language_code');
            const countryCode = shop.get('country_code');
            const defaultLanguage = {language_code: languageCode, is_default: true};
            const defaultCountry = {country_code: countryCode, is_default: true};

            product = state.get('product');
            option = new ProductOption();
            requiredErrors = state.get('requiredErrors');

            return state.set('isInitialized', true)
                .set('isLoading', false)
                .set('shop', shop)
                .set('brands', List(action.brands.map(item => new Brand(item))))
                .set('categories', reduceCategories(action.categories, 1))
                .set('productSizes', List(action.product_sizes.map(item => new ProductSize(item))))
                .set('productColors', List(action.product_colors.map(item => new ProductColor(item))))
                .set('defaultLanguageCode', languageCode)
                .set('defaultCountryCode', countryCode)
                .set(
                    'product',
                    product.set(
                        'i18n_languages', product.get('i18n_languages').push(new I18NLanguageProduct(defaultLanguage))
                    )
                        .set(
                            'i18n_countries',
                            product.get('i18n_countries').push(new I18NCountryProduct(defaultCountry))
                        )
                        .set(
                            'options',
                            product.get('options').push(
                                option.set(
                                    'i18n_languages',
                                    option.get('i18n_languages').push(new I18NLanguageProductOption(defaultLanguage))
                                )
                                    .set(
                                        'i18n_countries',
                                        option.get('i18n_countries').push(new I18NCountryProductOption(defaultCountry))
                                    )
                            )
                        )
                )
                .set(
                    'requiredErrors',
                    requiredErrors.set(
                        'options', requiredErrors.get('options').push(new ProductOptionRequiredError())
                    )
                        .set(
                            'i18n_languages',
                            requiredErrors.get('i18n_languages').push(new ProductI18nLanguagesError())
                        )
                        .set(
                            'i18n_countries',
                            requiredErrors.get('i18n_countries').push(new ProductI18nCountriesError())
                        )
                )
                .set('error', null);

        case FAIL_INIT_SHOP_STAFF_PRODUCT_ADD:
            return state.set('isLoading', false)
                .set('brands', List())
                .set('categories', List())
                .set('productSizes', List())
                .set('productColors', List())
                .set('product', new Product())
                .set('error', new RestError(action.error));

        case SHOP_STAFF_PRODUCT_ADD_ADD_PRODUCT_SIZE:
            return state.set('productSizes', state.get('productSizes').push(action.productSize));

        case SHOP_STAFF_PRODUCT_ADD_ADD_PRODUCT_COLOR:
            return state.set('productColors', state.get('productColors').push(action.productColor));

        // case SHOP_STAFF_PRODUCT_ADD_SET_DEFAULT_LANG_AND_COUNTRY:
        //     const defaultLanguage = {language_code: action.languageCode, is_default: true};
        //     const defaultCountry = {country_code: action.countryCode, is_default: true};
        //     product = state.get('product');
        //     option = new ProductOption();
        //
        //     requiredErrors = state.get('requiredErrors');
        //
        //     return state.set('defaultLanguageCode', action.languageCode)
        //         .set('defaultCountryCode', action.countryCode)
        //         .set(
        //             'product',
        //             product.set(
        //                 'i18n_languages', product.get('i18n_languages').push(new I18NLanguageProduct(defaultLanguage))
        //             )
        //                 .set(
        //                     'i18n_countries',
        //                     product.get('i18n_countries').push(new I18NCountryProduct(defaultCountry))
        //                 )
        //                 .set(
        //                     'options',
        //                     product.get('options').push(
        //                         option.set(
        //                             'i18n_languages',
        //                             option.get('i18n_languages').push(new I18NLanguageProductOption(defaultLanguage))
        //                         )
        //                             .set(
        //                                 'i18n_countries',
        //                                 option.get('i18n_countries').push(new I18NCountryProductOption(defaultCountry))
        //                             )
        //                     )
        //                 )
        //         )
        //         .set(
        //             'requiredErrors',
        //             requiredErrors.set(
        //                 'options', requiredErrors.get('options').push(new ProductOptionRequiredError())
        //             )
        //                 .set(
        //                     'i18n_languages',
        //                     requiredErrors.get('i18n_languages').push(new ProductI18nLanguagesError())
        //                 )
        //                 .set(
        //                     'i18n_countries',
        //                     requiredErrors.get('i18n_countries').push(new ProductI18nCountriesError())
        //                 )
        //         );

        case SHOP_STAFF_PRODUCT_ADD_ADD_LANGUAGE_CODE:
            product = state.get('product');
            i18nLanguages = product.get('i18n_languages');
            index = indexOfLanguageCode(i18nLanguages, action.languageCode);

            if (index >= 0) {
                return state;
            }

            options = product.get('options');

            product = product.set(
                'i18n_languages', i18nLanguages.push(new I18NLanguageProduct({language_code: action.languageCode}))
            )
                .set('options', addLanguage2Options(options, action.languageCode));

            return state.set('product', product);

        case SHOP_STAFF_PRODUCT_ADD_DELETE_LANGUAGE_CODE:
            product = state.get('product');
            i18nLanguages = product.get('i18n_languages');
            index = indexOfLanguageCode(i18nLanguages, action.languageCode);

            if (index >= 0) {
                const {i18nLanguages: newI18nLanguages} = deleteLanguageInProduct(index, i18nLanguages);
                const {newOptions} = deleteLanguageInOptions(product.get('options'), action.languageCode);

                product = product.set('i18n_languages', newI18nLanguages).set('options', newOptions);

                state = state.set('product', product)
            }

            return state;

        case SHOP_STAFF_PRODUCT_ADD_SET_DEFAULT_LANGUAGE:
            product = state.get('product');

            return state.set(
                'product',
                product.set(
                    'i18n_languages',
                    changeDefaultLanguageInProduct(product.get('i18n_languages'), action.languageCode)
                )
                    .set('options', changeDefaultLanguageInOptions(product.get('options'), action.languageCode))
            )
                .set('defaultLanguageCode', action.languageCode);

        case SHOP_STAFF_PRODUCT_ADD_ADD_COUNTRY_CODE:
            product = state.get('product');
            i18nCountries = product.get('i18n_countries');
            index = indexOfCountryCode(i18nCountries, action.countryCode);

            if (index >= 0) {
                return state;
            }

            options = product.get('options');

            product = product.set(
                'i18n_countries', i18nCountries.push(new I18NCountryProduct({country_code: action.countryCode}))
            )
                .set('options', addCountry2Options(options, action.countryCode));

            return state.set('product', product);

        case SHOP_STAFF_PRODUCT_ADD_DELETE_COUNTRY_CODE:
            product = state.get('product');
            i18nCountries = product.get('i18n_countries');
            index = indexOfCountryCode(i18nCountries, action.countryCode);

            if (index >= 0) {
                const {i18nCountries: newI18nCountries} = deleteCountryInProduct(index, i18nCountries);
                const {newOptions} = deleteCountryInOptions(product.get('options'), action.countryCode);

                product = product.set('i18n_countries', newI18nCountries).set('options', newOptions);

                state = state.set('product', product)
            }

            return state;

        case SHOP_STAFF_PRODUCT_ADD_SET_DEFAULT_COUNTRY:
            product = state.get('product');

            return state.set(
                'product',
                product.set(
                    'i18n_countries',
                    changeDefaultCountryInProduct(product.get('i18n_countries'), action.countryCode)
                )
                    .set('options', changeDefaultCountryInOptions(product.get('options'), action.countryCode))
            )
                .set('defaultCountryCode', action.countryCode);

        case SHOP_STAFF_PRODUCT_ADD_CHANGE_PRODUCT:
            if (action.key === 'uid') {
                state = state.setIn(['requiredErrors', action.key], !action.value);
            } else if (action.key === 'repImageData') {
                state = state.setIn(['requiredErrors', action.key], !action.value);
            }

            return state.set('product', state.get('product').set(action.key, action.value));

        case SHOP_STAFF_PRODUCT_ADD_CHANGE_LANGUAGE_INFO:
            product = state.get('product');

            if (action.key === 'name') {
                state = state.setIn(['requiredErrors', 'i18n_languages', action.index, action.key], !action.value);
            }

            return state.set(
                'product',
                product.set(
                    'i18n_languages',
                    updateListByKeyValue(product.get('i18n_languages'), action.index, action.key, action.value)
                )
            );

        case SHOP_STAFF_PRODUCT_ADD_CHANGE_COUNTRY_INFO:
            product = state.get('product');

            if (action.key === 'price') {
                state = state.setIn(
                    ['requiredErrors', 'i18n_countries', action.index, action.key], !parseFloat(action.value)
                );
            }

            return state.set(
                'product',
                product.set(
                    'i18n_countries',
                    updateListByKeyValue(product.get('i18n_countries'), action.index, action.key, action.value)
                )
            );

        case SHOP_STAFF_PRODUCT_ADD_ADD_OPTION:
            product = state.get('product');
            options = product.get('options');
            option = ProductOption.makeNewbie(product).set('order', options.size);

            requiredErrors = state.get('requiredErrors');
            requiredErrorOptions = requiredErrors.get('options');

            requiredErrorOption = requiredErrorOptions.push(
                new ProductOptionRequiredError()
            )

            return state.set('product', product.set('options', options.push(option)))
                .setIn(['requiredErrors', 'options'], requiredErrorOption);

        case SHOP_STAFF_PRODUCT_ADD_CHANGE_OPTION:
            product = state.get('product');

            if (action.key === 'uid') {
                state = state.setIn(['requiredErrors', 'options', action.index, action.key], !action.value);
            }

            return state.set(
                'product',
                product.set(
                    'options', updateListByKeyValue(product.get('options'), action.index, action.key, action.value)
                )
            )

        case SHOP_STAFF_PRODUCT_ADD_CHANGE_OPTION_COLOR:
            product = state.get('product');
            options = product.get('options');
            option = options.get(action.index);

            const [newbieOptions, newProductOptionColors] = changeProductOptionColor(
                product.get('options'), state.get('productOptionColors'), action.index, action.productColor
            );

            return state.set('product', product.set('options', newbieOptions))
                .set('productOptionColors', newProductOptionColors)
                .setIn(['requiredErrors', 'options', action.index, 'color'], !action.productColor);

        case SHOP_STAFF_PRODUCT_ADD_DELETE_OPTION:
            product = state.get('product');
            options = product.get('options');

            requiredErrors = state.get('requiredErrors');
            requiredErrorOptions = requiredErrors.get('options');

            const deletedOption = options.get(action.index);
            const newOptions = options.delete(action.index);
            const hasSameColor = newOptions.some(item => item.get('color_id') === deletedOption.get('color_id'));

            if (!hasSameColor) {
                productOptionColors = state.get('productOptionColors').reduce((prev, cur) => {
                    if (cur.get('id') === deletedOption.get('color_id')) {
                        return prev;
                    } else {
                        return prev.push(cur);
                    }
                }, List());

                state = state.set('productOptionColors', productOptionColors);
            }

            return state.set('product', product.set('options', newOptions))
                .set('requiredErrors', requiredErrors.set('options', requiredErrorOptions.delete(action.index)));

        case SHOP_STAFF_PRODUCT_ADD_CHANGE_OPTION_LANGUAGE_INFO:
            product = state.get('product');
            options = product.get('options');
            option = options.get(action.optionIndex);
            option = option.set(
                'i18n_languages',
                updateListByKeyValue(option.get('i18n_languages'), action.langIndex, action.key, action.value)
            );

            return state.set('product', product.set('options', updateListItem(options, action.optionIndex, option)));

        case SHOP_STAFF_PRODUCT_ADD_CHANGE_OPTION_COUNTRY_INFO:
            product = state.get('product');
            options = product.get('options');
            option = options.get(action.optionIndex);
            option = option.set(
                'i18n_countries',
                updateListByKeyValue(option.get('i18n_countries'), action.countryIndex, action.key, action.value)
            );

            return state.set('product', product.set('options', updateListItem(options, action.optionIndex, option)));

        case SHOP_STAFF_PRODUCT_ADD_CHANGE_OPTION_COLOR_INFO:
            if (action.key === 'imageData') {
                state = state.setIn(['requiredErrors', 'options', action.index, action.key], !action.value);
            }

            return state = state.set(
                'productOptionColors',
                updateListByKeyValue(state.get('productOptionColors'), action.index, action.key, action.value)
            );

        case SHOP_STAFF_PRODUCT_ADD_REQUIRED_ERRORS:
            return state.setIn(['requiredErrors', action.key], action.value);

        case SHOP_STAFF_PRODUCT_ADD_REQUIRED_OPTION_ERRORS:
            return state.setIn(['requiredErrors', 'options', action.index, action.key], action.value);

        case SHOP_STAFF_PRODUCT_ADD_REQUIRED_I18N_PRODUCT_LANGUAGE_ERRORS:
            return state.setIn(['requiredErrors', 'i18n_languages', action.index, action.key], action.value);

        case SHOP_STAFF_PRODUCT_ADD_REQUIRED_I18N_PRODUCT_COUNTRY_ERRORS:
            return state.setIn(['requiredErrors', 'i18n_countries', action.index, action.key], action.value);

        case SHOP_STAFF_PRODUCT_ADD_REQUIRED_TEMP_WORK_REQUEST_ERRORS:
            return state.setIn(
                ['requiredErrors', 'options', action.index, 'tempWorkRequest', action.key, action.categoryRootUid], action.value
            );

        case REQUEST_CREATE_PRODUCT_BY_SHOP_STAFF:
            return state.set('isCreating', true).set('succeedCreate', false).set('errorCreate', null);

        case SUCCEED_CREATE_PRODUCT_BY_SHOP_STAFF:
            return state.set('isCreating', false)
                .set('succeedCreate', true)
                .set('errorCreate', null);

        case FAIL_CREATE_PRODUCT_BY_SHOP_STAFF:
            return state.set('isCreating', false)
                .set('succeedCreate', false)
                .set('errorCreate', new RestError(action.error));

        case REQUEST_IMPORT_BUILDER_PRODUCT_INFO_IN_PRODUCT_ADD:
            return state.set('isLoading', true).set('errorImportBuilderProduct', null);

        case SUCCEED_IMPORT_BUILDER_PRODUCT_INFO_IN_PRODUCT_ADD:
            product = state.get('product');
            options = product.get('options');

            let defaultLanguageCode, defaultCountryCode;
            const builderProduct = new Product(action.product);
            const builderOptions = List(action.options.map(item => new ProductOption(item)));

            [product, defaultLanguageCode, defaultCountryCode] = mergeBuilderProduct2Product(
                product,
                state.get('defaultLanguageCode'),
                state.get('defaultCountryCode'),
                builderProduct,
                builderOptions
            );

            builderProduct.get('i18n_languages').forEach((item, index) => {
                state = state.setIn(['requiredErrors', 'i18n_languages', index, 'name'], false);
            });

            builderProduct.get('i18n_countries').forEach((item, index) => {
                state = state.setIn(['requiredErrors', 'i18n_countries', index, 'price'], false);
            })

            options = product.get('options').map((option, index) => {
                return option.set('order', index);
            });

            product = product.set('options', options);

            return state.set('isLoading', false)
                .set('defaultLanguageCode', defaultLanguageCode)
                .set('defaultCountryCode', defaultCountryCode)
                .set('product', product)
                .set('isBuilderProductImported', true)
                .set('errorImportBuilderProduct', null)
                .setIn(['requiredErrors', 'repImageData'], false)
                .setIn(
                    ['requiredErrors', 'options'], product.get('options').map(() => new ProductOptionRequiredError())
                );

        case FAIL_IMPORT_BUILDER_PRODUCT_INFO_IN_PRODUCT_ADD:
            return state.set('isLoading', false).set('errorImportBuilderProduct', new RestError(action.error));

        case SHOP_STAFF_PRODUCT_ADD_CLEAR_IMPORT_BUILDER_PRODUCT_INFO_STATE:
            return state.set('errorImportBuilderProduct', null);

        case SHOP_STAFF_PRODUCT_ADD_CHANGE_RESOURCE_FILE_DATA:
            return state.setIn(['product', 'work_request', 'resourceFileData'], action.resourceFileData);

        case SHOP_STAFF_PRODUCT_ADD_CHANGE_CATEGORY:
            categoryRootUid = action.category.getRootCategoryUid();

            return state.setIn(['product', 'category_id'], action.category.get('id'))
                .setIn(['product', 'category'], action.category)
                .set('categoryRootUid', categoryRootUid)
                .setIn(['requiredErrors', 'categoryRootUid'], false);

        case SHOP_STAFF_PRODUCT_ADD_CHANGE_TEMP_WORK_REQUEST_IMAGE:
            categoryRootUid = state.get('categoryRootUid');

            return state.setIn(
                ['tempWorkRequest', 'product_image_item', categoryRootUid, action.key], action.value
            );

        case SHOP_STAFF_PRODUCT_ADD_CHANGE_TEMP_WORK_REQUEST_ALL_IMAGES:
        case SHOP_STAFF_PRODUCT_ADD_CHANGE_TEMP_WORK_REQUEST_ALL_SPECS:
            return state.setIn(
                [
                    'requiredErrors',
                    'options',
                    action.index,
                    'tempWorkRequest',
                    action.key,
                    action.categoryRootUid
                ],
                action.value
            )

        case SHOP_STAFF_PRODUCT_ADD_DELETE_TEMP_WORK_REQUEST_IMAGE:
            let productImageItem;
            categoryRootUid = state.get('categoryRootUid');

            productImageItem = state.getIn(['tempWorkRequest', 'product_image_item']);
            productImageItem = productImageItem.setIn([categoryRootUid, action.imgKey], "")
                .setIn([categoryRootUid, action.imgDataKey], null)

            return state.setIn(['tempWorkRequest', 'product_image_item'], productImageItem)
                .setIn(
                    [
                        'requiredErrors',
                        'options',
                        action.index,
                        'tempWorkRequest',
                        'product_image_item',
                        action.categoryRootUid
                    ],
                    true
                );

        case SHOP_STAFF_PRODUCT_ADD_CHANGE_TEMP_WORK_REQUEST_REQUEST_MESSAGE:
            return state.setIn(['tempWorkRequest', 'request_message'], action.message);

        case SHOP_STAFF_PRODUCT_ADD_CHANGE_TEMP_WORK_REQUEST_SPEC_UNIT:
            categoryRootUid = state.get('categoryRootUid')
            return state.setIn(['tempWorkRequest', 'product_spec', categoryRootUid, 'unit'], action.unit);

        case SHOP_STAFF_PRODUCT_ADD_CHANGE_TEMP_WORK_REQUEST_SPEC:
            categoryRootUid = state.get('categoryRootUid');

            if (action.value === parseFloat('0')) {
                state = state .setIn(
                    [
                        'requiredErrors',
                        'options',
                        action.index,
                        'tempWorkRequest',
                        'product_spec',
                        action.categoryRootUid
                    ],
                    true
                );
            }
            return state.setIn(['tempWorkRequest', 'product_spec', categoryRootUid, action.key], action.value);

        case SHOP_STAFF_PRODUCT_ADD_ADD_TEMP_WORK_REQUEST:
            return state.set('tempWorkRequest', action.workRequest);

        case SHOP_STAFF_PRODUCT_ADD_ADD_WORK_REQUEST:
            tempWorkRequest = state.get('tempWorkRequest');

            if(action.optionIndex === 0 || action.optionIndex) {
                state = state.setIn(['requiredErrors', 'options', action.optionIndex, 'work_request'], false)
            }

            return state.setIn(['product', 'options', action.optionIndex, 'work_request'], tempWorkRequest)
                .set('tempWorkRequest', new WorkRequest());

        case SHOP_STAFF_PRODUCT_ADD_DELETE_TEMP_WORK_REQUEST:
            return state.set('tempWorkRequest', new WorkRequest());

        case SHOP_STAFF_PRODUCT_ADD_ADD_OPTIONS_SPEC_DATA:
            return state.set('optionsSpecData', action.optionsSpecData);

        case SHOP_STAFF_PRODUCT_ADD_IMPORT_TEMP_WORK_REQUEST_SPEC:
            categoryRootUid = state.get('categoryRootUid');

            return state.setIn(['tempWorkRequest', 'product_spec', categoryRootUid], action.specData)
                .set('optionsSpecData', null);

        case SHOP_STAFF_PRODUCT_ADD_TOGGLE_ADD_MODE:
            return state.set('isSaveAndAddAnotherMode', action.isSaveAndAddAnotherMode)

        case SHOP_STAFF_PRODUCT_ADD_SET_IS_OPENED_CHANGE_ORDER_DIALOG:
            if (action.isOpenedChangeOptionDialog) {
                options = state.getIn(['product', 'options']);
                state = state.set('tempOptions', options);
            }

            return state.set('isOpenedChangeOptionDialog', action.isOpenedChangeOptionDialog);

        case SHOP_STAFF_PRODUCT_ADD_REORDER:
            tempOptions = state.get('tempOptions');

            if (action.startIndex === action.endIndex) {
                return state;
            }

            tempOptions = getOptionsByChangeOrder(tempOptions, action.startIndex, action.endIndex);

            return state.set('tempOptions', tempOptions)
            .set('isReordered', true);

        case SHOP_STAFF_PRODUCT_ADD_REORDER_LIST:
            return state.set('isReordered', false);

        case SHOP_STAFF_PRODUCT_ADD_CHANGE_OPTIONS_ORDER:
            tempOptions = state.get('tempOptions');

            return state.setIn(['product', 'options'], tempOptions)
                .set('isOpenedChangeOptionDialog', false);

        default:
            return state;
    }
}
