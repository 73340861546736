import {EntityMixin} from "./Entity";

const ManagerRecord = EntityMixin({
    name: null,
    email: null,
    uid: null,
    auth_token: null,
    signin_date: null,
});

class Manager extends ManagerRecord {

    constructor(args = {}) {
        super({...args});
    }
}

export default Manager;
