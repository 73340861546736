import {Record} from "immutable";
import {
    ERROR_1101_ALREADY_IN_USE_EMAIL,
    ERROR_1105_INVALID_SHOP_PATH,
    ERROR_1106_EXPIRED_EMAIL_VERIFY_TOKEN,
} from "../rest/ErrorCodes";

const RestErrorRecord = Record({
    statusCode: 0,
    code: '0',
    message: '',
});

class RestError extends RestErrorRecord {

    constructor(args = {}) {
        super({...args});
    }

    isAuthenticationError() {
        return this.statusCode === 401 || this.statusCode === 403;
    }

    isNotFound() {
        return this.statusCode === 404;
    }

    isAlreadyEmail() {
        return this.code === ERROR_1101_ALREADY_IN_USE_EMAIL;
    }

    isInvalidShopPath() {
        return this.code === ERROR_1105_INVALID_SHOP_PATH;
    }

    isExpiredEmailVerifyToken() {
        return this.code === ERROR_1106_EXPIRED_EMAIL_VERIFY_TOKEN;
    }
}

export default RestError;
