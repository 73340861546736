export const REQUEST_GET_PRODUCT_CATEGORIES = 'REQUEST_GET_PRODUCT_CATEGORIES';
export const SUCCEED_GET_PRODUCT_CATEGORIES = 'SUCCEED_GET_PRODUCT_CATEGORIES';
export const FAIL_GET_PRODUCT_CATEGORIES = 'FAIL_GET_PRODUCT_CATEGORIES';
export const REQUEST_GET_PRODUCT_CATEGORY = 'REQUEST_GET_PRODUCT_CATEGORY';
export const SUCCEED_GET_PRODUCT_CATEGORY = 'SUCCEED_GET_PRODUCT_CATEGORY';
export const FAIL_GET_PRODUCT_CATEGORY = 'FAIL_GET_PRODUCT_CATEGORY';


export const REQUEST_SIGNUP_SHOP_STAFF = 'REQUEST_SIGNUP_SHOP_STAFF';
export const SUCCEED_SIGNUP_SHOP_STAFF = 'SUCCEED_SIGNUP_SHOP_STAFF';
export const FAIL_SIGNUP_SHOP_STAFF = 'FAIL_SIGNUP_SHOP_STAFF';
export const REQUEST_SIGNIN_SHOP_STAFF = 'REQUEST_SIGNIN_SHOP_STAFF';
export const SUCCEED_SIGNIN_SHOP_STAFF = 'SUCCEED_SIGNIN_SHOP_STAFF';
export const FAIL_SIGNIN_SHOP_STAFF = 'FAIL_SIGNIN_SHOP_STAFF';
export const REQUEST_GET_SHOP_STAFF = 'REQUEST_GET_SHOP_STAFF';
export const SUCCEED_GET_SHOP_STAFF = 'SUCCEED_GET_SHOP_STAFF';
export const FAIL_GET_SHOP_STAFF = 'FAIL_GET_SHOP_STAFF';
export const REQUEST_UPDATE_SHOP_STAFF = 'REQUEST_UPDATE_SHOP_STAFF';
export const SUCCEED_UPDATE_SHOP_STAFF = 'SUCCEED_UPDATE_SHOP_STAFF';
export const FAIL_UPDATE_SHOP_STAFF = 'FAIL_UPDATE_SHOP_STAFF';
export const REQUEST_CHANGE_SHOP_STAFF_PASSWORD = 'REQUEST_CHANGE_SHOP_STAFF_PASSWORD';
export const SUCCEED_CHANGE_SHOP_STAFF_PASSWORD = 'SUCCEED_CHANGE_SHOP_STAFF_PASSWORD';
export const FAIL_CHANGE_SHOP_STAFF_PASSWORD = 'FAIL_CHANGE_SHOP_STAFF_PASSWORD';
export const REQUEST_SEND_VERIFICATION_EMAIL_SHOP_STAFF = 'REQUEST_SEND_VERIFICATION_EMAIL_SHOP_STAFF';
export const SUCCEED_SEND_VERIFICATION_EMAIL_SHOP_STAFF = 'SUCCEED_SEND_VERIFICATION_EMAIL_SHOP_STAFF';
export const FAIL_SEND_VERIFICATION_EMAIL_SHOP_STAFF = 'FAIL_SEND_VERIFICATION_EMAIL_SHOP_STAFF';
export const REQUEST_VERIFY_EMAIL_SHOP_STAFF = 'REQUEST_VERIFY_EMAIL_SHOP_STAFF';
export const SUCCEED_VERIFY_EMAIL_SHOP_STAFF = 'SUCCEED_VERIFY_EMAIL_SHOP_STAFF';
export const FAIL_VERIFY_EMAIL_SHOP_STAFF = 'FAIL_VERIFY_EMAIL_SHOP_STAFF';
export const REQUEST_GET_SHOP_STAFF_IN_VERIFY_EMAIL= 'REQUEST_GET_SHOP_STAFF_IN_VERIFY_EMAIL';
export const SUCCEED_GET_SHOP_STAFF_IN_VERIFY_EMAIL= 'SUCCEED_GET_SHOP_STAFF_IN_VERIFY_EMAIL';
export const FAIL_GET_SHOP_STAFF_IN_VERIFY_EMAIL= 'FAIL_GET_SHOP_STAFF_IN_VERIFY_EMAIL';

export const REQUEST_GET_SHOP_BY_SHOP_STAFF = 'REQUEST_GET_SHOP_BY_SHOP_STAFF';
export const SUCCEED_GET_SHOP_BY_SHOP_STAFF = 'SUCCEED_GET_SHOP_BY_SHOP_STAFF';
export const FAIL_GET_SHOP_BY_SHOP_STAFF = 'FAIL_GET_SHOP_BY_SHOP_STAFF';
export const REQUEST_UPDATE_SHOP_BY_SHOP_STAFF = 'REQUEST_UPDATE_SHOP_BY_SHOP_STAFF';
export const SUCCEED_UPDATE_SHOP_BY_SHOP_STAFF = 'SUCCEED_UPDATE_SHOP_BY_SHOP_STAFF';
export const FAIL_UPDATE_SHOP_BY_SHOP_STAFF = 'FAIL_UPDATE_SHOP_BY_SHOP_STAFF';
export const REQUEST_INIT_PLUGIN_SETTINGS_BY_SHOP_STAFF = 'REQUEST_INIT_PLUGIN_SETTINGS_BY_SHOP_STAFF';
export const SUCCEED_INIT_PLUGIN_SETTINGS_BY_SHOP_STAFF = 'SUCCEED_INIT_PLUGIN_SETTINGS_BY_SHOP_STAFF';
export const FAIL_INIT_PLUGIN_SETTINGS_BY_SHOP_STAFF = 'FAIL_INIT_PLUGIN_SETTINGS_BY_SHOP_STAFF';
export const REQUEST_GET_SHOP_PLUGIN_BY_SHOP_STAFF = 'REQUEST_GET_SHOP_PLUGIN_BY_SHOP_STAFF';
export const SUCCEED_GET_SHOP_PLUGIN_BY_SHOP_STAFF = 'SUCCEED_GET_SHOP_PLUGIN_BY_SHOP_STAFF';
export const FAIL_GET_SHOP_PLUGIN_BY_SHOP_STAFF = 'FAIL_GET_SHOP_PLUGIN_BY_SHOP_STAFF';
export const REQUEST_UPDATE_SHOP_PLUGIN_BY_SHOP_STAFF = 'REQUEST_UPDATE_SHOP_PLUGIN_BY_SHOP_STAFF';
export const SUCCEED_UPDATE_SHOP_PLUGIN_BY_SHOP_STAFF = 'SUCCEED_UPDATE_SHOP_PLUGIN_BY_SHOP_STAFF';
export const FAIL_UPDATE_SHOP_PLUGIN_BY_SHOP_STAFF = 'FAIL_UPDATE_SHOP_PLUGIN_BY_SHOP_STAFF';
export const REQUEST_GET_PAYMENT_HISTORIES_BY_SHOP_STAFF = 'REQUEST_GET_PAYMENT_HISTORIES_BY_SHOP_STAFF';
export const SUCCEED_GET_PAYMENT_HISTORIES_BY_SHOP_STAFF = 'SUCCEED_GET_PAYMENT_HISTORIES_BY_SHOP_STAFF';
export const FAIL_GET_PAYMENT_HISTORIES_BY_SHOP_STAFF = 'FAIL_GET_PAYMENT_HISTORIES_BY_SHOP_STAFF';
export const REQUEST_INIT_PLUGIN_SKIN_FILTER_VIEWER_BY_SHOP_STAFF = 'REQUEST_INIT_PLUGIN_SKIN_FILTER_VIEWER_BY_SHOP_STAFF';
export const SUCCEED_INIT_PLUGIN_SKIN_FILTER_VIEWER_BY_SHOP_STAFF = 'SUCCEED_INIT_PLUGIN_SKIN_FILTER_VIEWER_BY_SHOP_STAFF';
export const FAIL_INIT_PLUGIN_SKIN_FILTER_VIEWER_BY_SHOP_STAFF = 'FAIL_INIT_PLUGIN_SKIN_FILTER_VIEWER_BY_SHOP_STAFF';
export const REQUEST_COPY_SAMPLE_PRODUCTS_BY_SHOP_STAFF = 'REQUEST_COPY_SAMPLE_PRODUCTS_BY_SHOP_STAFF';
export const SUCCEED_COPY_SAMPLE_PRODUCTS_BY_SHOP_STAFF = 'SUCCEED_COPY_SAMPLE_PRODUCTS_BY_SHOP_STAFF';
export const FAIL_COPY_SAMPLE_PRODUCTS_BY_SHOP_STAFF = 'FAIL_COPY_SAMPLE_PRODUCTS_BY_SHOP_STAFF';
export const REQUEST_INITIALIZE_PAYMENT_HISTORIES_BY_SHOP_STAFF = 'REQUEST_INITIALIZE_PAYMENT_HISTORIES_BY_SHOP_STAFF';
export const SUCCEED_INITIALIZE_PAYMENT_HISTORIES_BY_SHOP_STAFF = 'SUCCEED_INITIALIZE_PAYMENT_HISTORIES_BY_SHOP_STAFF';
export const FAIL_INITIALIZE_PAYMENT_HISTORIES_BY_SHOP_STAFF = 'FAIL_INITIALIZE_PAYMENT_HISTORIES_BY_SHOP_STAFF';

export const REQUEST_GET_BRANDS_BY_SHOP_STAFF = 'REQUEST_GET_BRANDS_BY_SHOP_STAFF';
export const SUCCEED_GET_BRANDS_BY_SHOP_STAFF = 'SUCCEED_GET_BRANDS_BY_SHOP_STAFF';
export const FAIL_GET_BRANDS_BY_SHOP_STAFF = 'FAIL_GET_BRANDS_BY_SHOP_STAFF';
export const REQUEST_GET_BRANDS_BY_SHOP_STAFF_IN_CHANGE_ORDER = 'REQUEST_GET_BRANDS_BY_SHOP_STAFF_IN_CHANGE_ORDER';
export const SUCCEED_GET_BRANDS_BY_SHOP_STAFF_IN_CHANGE_ORDER = 'SUCCEED_GET_BRANDS_BY_SHOP_STAFF_IN_CHANGE_ORDER';
export const FAIL_GET_BRANDS_BY_SHOP_STAFF_IN_CHANGE_ORDER = 'FAIL_GET_BRANDS_BY_SHOP_STAFF_IN_CHANGE_ORDER';
export const REQUEST_CREATE_BRAND_BY_SHOP_STAFF = 'REQUEST_CREATE_BRAND_BY_SHOP_STAFF';
export const SUCCEED_CREATE_BRAND_BY_SHOP_STAFF = 'SUCCEED_CREATE_BRAND_BY_SHOP_STAFF';
export const FAIL_CREATE_BRAND_BY_SHOP_STAFF = 'FAIL_CREATE_BRAND_BY_SHOP_STAFF';
export const REQUEST_GET_BRAND_BY_SHOP_STAFF = 'REQUEST_GET_BRAND_BY_SHOP_STAFF';
export const SUCCEED_GET_BRAND_BY_SHOP_STAFF = 'SUCCEED_GET_BRAND_BY_SHOP_STAFF';
export const FAIL_GET_BRAND_BY_SHOP_STAFF = 'FAIL_GET_BRAND_BY_SHOP_STAFF';
export const REQUEST_UPDATE_BRAND_BY_SHOP_STAFF = 'REQUEST_UPDATE_BRAND_BY_SHOP_STAFF';
export const SUCCEED_UPDATE_BRAND_BY_SHOP_STAFF = 'SUCCEED_UPDATE_BRAND_BY_SHOP_STAFF';
export const FAIL_UPDATE_BRAND_BY_SHOP_STAFF = 'FAIL_UPDATE_BRAND_BY_SHOP_STAFF';
export const REQUEST_DELETE_BRANDS_BY_SHOP_STAFF = 'REQUEST_DELETE_BRANDS_BY_SHOP_STAFF';
export const SUCCEED_DELETE_BRANDS_BY_SHOP_STAFF = 'SUCCEED_DELETE_BRANDS_BY_SHOP_STAFF';
export const FAIL_DELETE_BRANDS_BY_SHOP_STAFF = 'FAIL_DELETE_BRANDS_BY_SHOP_STAFF';
export const REQUEST_CHANGE_BRANDS_ORDER_BY_SHOP_STAFF = 'REQUEST_CHANGE_BRANDS_ORDER_BY_SHOP_STAFF';
export const SUCCEED_CHANGE_BRANDS_ORDER_BY_SHOP_STAFF = 'SUCCEED_CHANGE_BRANDS_ORDER_BY_SHOP_STAFF';
export const FAIL_CHANGE_BRANDS_ORDER_BY_SHOP_STAFF = 'FAIL_CHANGE_BRANDS_ORDER_BY_SHOP_STAFF';

export const REQUEST_GET_PRODUCT_SIZES_BY_SHOP_STAFF = 'REQUEST_GET_PRODUCT_SIZES_BY_SHOP_STAFF';
export const SUCCEED_GET_PRODUCT_SIZES_BY_SHOP_STAFF = 'SUCCEED_GET_PRODUCT_SIZES_BY_SHOP_STAFF';
export const FAIL_GET_PRODUCT_SIZES_BY_SHOP_STAFF = 'FAIL_GET_PRODUCT_SIZES_BY_SHOP_STAFF';
export const REQUEST_CREATE_PRODUCT_SIZE_BY_SHOP_STAFF = 'REQUEST_CREATE_PRODUCT_SIZE_BY_SHOP_STAFF';
export const SUCCEED_CREATE_PRODUCT_SIZE_BY_SHOP_STAFF = 'SUCCEED_CREATE_PRODUCT_SIZE_BY_SHOP_STAFF';
export const FAIL_CREATE_PRODUCT_SIZE_BY_SHOP_STAFF = 'FAIL_CREATE_PRODUCT_SIZE_BY_SHOP_STAFF';
export const REQUEST_GET_PRODUCT_SIZE_BY_SHOP_STAFF = 'REQUEST_GET_PRODUCT_SIZE_BY_SHOP_STAFF';
export const SUCCEED_GET_PRODUCT_SIZE_BY_SHOP_STAFF = 'SUCCEED_GET_PRODUCT_SIZE_BY_SHOP_STAFF';
export const FAIL_GET_PRODUCT_SIZE_BY_SHOP_STAFF = 'FAIL_GET_PRODUCT_SIZE_BY_SHOP_STAFF';
export const REQUEST_UPDATE_PRODUCT_SIZE_BY_SHOP_STAFF = 'REQUEST_UPDATE_PRODUCT_SIZE_BY_SHOP_STAFF';
export const SUCCEED_UPDATE_PRODUCT_SIZE_BY_SHOP_STAFF = 'SUCCEED_UPDATE_PRODUCT_SIZE_BY_SHOP_STAFF';
export const FAIL_UPDATE_PRODUCT_SIZE_BY_SHOP_STAFF = 'FAIL_UPDATE_PRODUCT_SIZE_BY_SHOP_STAFF';
export const REQUEST_DELETE_PRODUCT_SIZES_BY_SHOP_STAFF = 'REQUEST_DELETE_PRODUCT_SIZES_BY_SHOP_STAFF';
export const SUCCEED_DELETE_PRODUCT_SIZES_BY_SHOP_STAFF = 'SUCCEED_DELETE_PRODUCT_SIZES_BY_SHOP_STAFF';
export const FAIL_DELETE_PRODUCT_SIZES_BY_SHOP_STAFF = 'FAIL_DELETE_PRODUCT_SIZES_BY_SHOP_STAFF';

export const REQUEST_GET_PRODUCT_COLORS_BY_SHOP_STAFF = 'REQUEST_GET_PRODUCT_COLORS_BY_SHOP_STAFF';
export const SUCCEED_GET_PRODUCT_COLORS_BY_SHOP_STAFF = 'SUCCEED_GET_PRODUCT_COLORS_BY_SHOP_STAFF';
export const FAIL_GET_PRODUCT_COLORS_BY_SHOP_STAFF = 'FAIL_GET_PRODUCT_COLORS_BY_SHOP_STAFF';
export const REQUEST_INIT_SHOP_STAFF_PRODUCT_COLOR_ADD = 'REQUEST_INIT_SHOP_STAFF_PRODUCT_COLOR_ADD';
export const SUCCEED_INIT_SHOP_STAFF_PRODUCT_COLOR_ADD = 'SUCCEED_INIT_SHOP_STAFF_PRODUCT_COLOR_ADD';
export const FAIL_INIT_SHOP_STAFF_PRODUCT_COLOR_ADD = 'FAIL_INIT_SHOP_STAFF_PRODUCT_COLOR_ADD';
export const REQUEST_CREATE_PRODUCT_COLOR_BY_SHOP_STAFF = 'REQUEST_CREATE_PRODUCT_COLOR_BY_SHOP_STAFF';
export const SUCCEED_CREATE_PRODUCT_COLOR_BY_SHOP_STAFF = 'SUCCEED_CREATE_PRODUCT_COLOR_BY_SHOP_STAFF';
export const FAIL_CREATE_PRODUCT_COLOR_BY_SHOP_STAFF = 'FAIL_CREATE_PRODUCT_COLOR_BY_SHOP_STAFF';
export const REQUEST_GET_PRODUCT_COLOR_BY_SHOP_STAFF = 'REQUEST_GET_PRODUCT_COLOR_BY_SHOP_STAFF';
export const SUCCEED_GET_PRODUCT_COLOR_BY_SHOP_STAFF = 'SUCCEED_GET_PRODUCT_COLOR_BY_SHOP_STAFF';
export const FAIL_GET_PRODUCT_COLOR_BY_SHOP_STAFF = 'FAIL_GET_PRODUCT_COLOR_BY_SHOP_STAFF';
export const REQUEST_UPDATE_PRODUCT_COLOR_BY_SHOP_STAFF = 'REQUEST_UPDATE_PRODUCT_COLOR_BY_SHOP_STAFF';
export const SUCCEED_UPDATE_PRODUCT_COLOR_BY_SHOP_STAFF = 'SUCCEED_UPDATE_PRODUCT_COLOR_BY_SHOP_STAFF';
export const FAIL_UPDATE_PRODUCT_COLOR_BY_SHOP_STAFF = 'FAIL_UPDATE_PRODUCT_COLOR_BY_SHOP_STAFF';
export const REQUEST_DELETE_PRODUCT_COLORS_BY_SHOP_STAFF = 'REQUEST_DELETE_PRODUCT_COLORS_BY_SHOP_STAFF';
export const SUCCEED_DELETE_PRODUCT_COLORS_BY_SHOP_STAFF = 'SUCCEED_DELETE_PRODUCT_COLORS_BY_SHOP_STAFF';
export const FAIL_DELETE_PRODUCT_COLORS_BY_SHOP_STAFF = 'FAIL_DELETE_PRODUCT_COLORS_BY_SHOP_STAFF';

export const REQUEST_GET_SHOP_STAFF_BRANDS_CATEGORIES_SHOP_IN_PRODUCTS = 'REQUEST_GET_SHOP_STAFF_BRANDS_CATEGORIES_SHOP_IN_PRODUCTS';
export const SUCCEED_GET_SHOP_STAFF_BRANDS_CATEGORIES_SHOP_IN_PRODUCTS = 'SUCCEED_GET_SHOP_STAFF_BRANDS_CATEGORIES_SHOP_IN_PRODUCTS';
export const FAIL_GET_SHOP_STAFF_BRANDS_CATEGORIES_SHOP_IN_PRODUCTS = 'FAIL_GET_SHOP_STAFF_BRANDS_CATEGORIES_SHOP_IN_PRODUCTS';
export const REQUEST_GET_PRODUCTS_BY_SHOP_STAFF = 'REQUEST_GET_PRODUCTS_BY_SHOP_STAFF';
export const SUCCEED_GET_PRODUCTS_BY_SHOP_STAFF = 'SUCCEED_GET_PRODUCTS_BY_SHOP_STAFF';
export const FAIL_GET_PRODUCTS_BY_SHOP_STAFF = 'FAIL_GET_PRODUCTS_BY_SHOP_STAFF';
export const REQUEST_INIT_SHOP_STAFF_PRODUCT_ADD = 'REQUEST_INIT_SHOP_STAFF_PRODUCT_ADD';
export const SUCCEED_INIT_SHOP_STAFF_PRODUCT_ADD = 'SUCCEED_INIT_SHOP_STAFF_PRODUCT_ADD';
export const FAIL_INIT_SHOP_STAFF_PRODUCT_ADD = 'FAIL_INIT_SHOP_STAFF_PRODUCT_ADD';
export const REQUEST_INIT_SHOP_STAFF_PRODUCT_EDIT = 'REQUEST_INIT_SHOP_STAFF_PRODUCT_EDIT';
export const SUCCEED_INIT_SHOP_STAFF_PRODUCT_EDIT = 'SUCCEED_INIT_SHOP_STAFF_PRODUCT_EDIT';
export const FAIL_INIT_SHOP_STAFF_PRODUCT_EDIT = 'FAIL_INIT_SHOP_STAFF_PRODUCT_EDIT';
export const REQUEST_GET_PRODUCTS_BY_SHOP_STAFF_IN_CHANGE_ORDER = 'REQUEST_GET_PRODUCTS_BY_SHOP_STAFF_IN_CHANGE_ORDER';
export const SUCCEED_GET_PRODUCTS_BY_SHOP_STAFF_IN_CHANGE_ORDER = 'SUCCEED_GET_PRODUCTS_BY_SHOP_STAFF_IN_CHANGE_ORDER';
export const FAIL_GET_PRODUCTS_BY_SHOP_STAFF_IN_CHANGE_ORDER = 'FAIL_GET_PRODUCTS_BY_SHOP_STAFF_IN_CHANGE_ORDER';
export const REQUEST_IMPORT_BUILDER_PRODUCT_INFO_IN_PRODUCT_ADD = 'REQUEST_IMPORT_BUILDER_PRODUCT_INFO_IN_PRODUCT_ADD';
export const SUCCEED_IMPORT_BUILDER_PRODUCT_INFO_IN_PRODUCT_ADD = 'SUCCEED_IMPORT_BUILDER_PRODUCT_INFO_IN_PRODUCT_ADD';
export const FAIL_IMPORT_BUILDER_PRODUCT_INFO_IN_PRODUCT_ADD = 'FAIL_IMPORT_BUILDER_PRODUCT_INFO_IN_PRODUCT_ADD';
export const REQUEST_IMPORT_BUILDER_PRODUCT_INFO_IN_PRODUCT_EDIT = 'REQUEST_IMPORT_BUILDER_PRODUCT_INFO_IN_PRODUCT_EDIT';
export const SUCCEED_IMPORT_BUILDER_PRODUCT_INFO_IN_PRODUCT_EDIT = 'SUCCEED_IMPORT_BUILDER_PRODUCT_INFO_IN_PRODUCT_EDIT';
export const FAIL_IMPORT_BUILDER_PRODUCT_INFO_IN_PRODUCT_EDIT = 'FAIL_IMPORT_BUILDER_PRODUCT_INFO_IN_PRODUCT_EDIT';
export const REQUEST_CREATE_PRODUCT_BY_SHOP_STAFF = 'REQUEST_CREATE_PRODUCT_BY_SHOP_STAFF';
export const SUCCEED_CREATE_PRODUCT_BY_SHOP_STAFF = 'SUCCEED_CREATE_PRODUCT_BY_SHOP_STAFF';
export const FAIL_CREATE_PRODUCT_BY_SHOP_STAFF = 'FAIL_CREATE_PRODUCT_BY_SHOP_STAFF';
export const REQUEST_GET_PRODUCT_BY_SHOP_STAFF = 'REQUEST_GET_PRODUCT_BY_SHOP_STAFF';
export const SUCCEED_GET_PRODUCT_BY_SHOP_STAFF = 'SUCCEED_GET_PRODUCT_BY_SHOP_STAFF';
export const FAIL_GET_PRODUCT_BY_SHOP_STAFF = 'FAIL_GET_PRODUCT_BY_SHOP_STAFF';
export const REQUEST_GET_PRODUCT_BY_SHOP_STAFF_FOR_AR_VIEWER = 'REQUEST_GET_PRODUCT_BY_SHOP_STAFF_FOR_AR_VIEWER';
export const SUCCEED_GET_PRODUCT_BY_SHOP_STAFF_FOR_AR_VIEWER = 'SUCCEED_GET_PRODUCT_BY_SHOP_STAFF_FOR_AR_VIEWER';
export const FAIL_GET_PRODUCT_BY_SHOP_STAFF_FOR_AR_VIEWER = 'FAIL_GET_PRODUCT_BY_SHOP_STAFF_FOR_AR_VIEWER';
export const REQUEST_INIT_PRODUCT_THREE_VIEWER_BY_SHOP_STAFF = 'REQUEST_INIT_PRODUCT_THREE_VIEWER_BY_SHOP_STAFF';
export const SUCCEED_INIT_PRODUCT_THREE_VIEWER_BY_SHOP_STAFF = 'SUCCEED_INIT_PRODUCT_THREE_VIEWER_BY_SHOP_STAFF';
export const FAIL_INIT_PRODUCT_THREE_VIEWER_BY_SHOP_STAFF = 'FAIL_INIT_PRODUCT_THREE_VIEWER_BY_SHOP_STAFF';
export const REQUEST_INIT_PRODUCT_AR_VIEWER_BY_SHOP_STAFF = 'REQUEST_INIT_PRODUCT_AR_VIEWER_BY_SHOP_STAFF';
export const SUCCEED_INIT_PRODUCT_AR_VIEWER_BY_SHOP_STAFF = 'SUCCEED_INIT_PRODUCT_AR_VIEWER_BY_SHOP_STAFF';
export const FAIL_INIT_PRODUCT_AR_VIEWER_BY_SHOP_STAFF = 'FAIL_INIT_PRODUCT_AR_VIEWER_BY_SHOP_STAFF';
export const REQUEST_UPDATE_PRODUCT_BY_SHOP_STAFF = 'REQUEST_UPDATE_PRODUCT_BY_SHOP_STAFF';
export const SUCCEED_UPDATE_PRODUCT_BY_SHOP_STAFF = 'SUCCEED_UPDATE_PRODUCT_BY_SHOP_STAFF';
export const FAIL_UPDATE_PRODUCT_BY_SHOP_STAFF = 'FAIL_UPDATE_PRODUCT_BY_SHOP_STAFF';
export const REQUEST_DELETE_PRODUCTS_BY_SHOP_STAFF = 'REQUEST_DELETE_PRODUCTS_BY_SHOP_STAFF';
export const SUCCEED_DELETE_PRODUCTS_BY_SHOP_STAFF = 'SUCCEED_DELETE_PRODUCTS_BY_SHOP_STAFF';
export const FAIL_DELETE_PRODUCTS_BY_SHOP_STAFF = 'FAIL_DELETE_PRODUCTS_BY_SHOP_STAFF';
export const REQUEST_CHANGE_PRODUCTS_BULK_DATA_BY_SHOP_STAFF = 'REQUEST_CHANGE_PRODUCTS_BULK_DATA_BY_SHOP_STAFF';
export const SUCCEED_CHANGE_PRODUCTS_BULK_DATA_BY_SHOP_STAFF = 'SUCCEED_CHANGE_PRODUCTS_BULK_DATA_BY_SHOP_STAFF';
export const FAIL_CHANGE_PRODUCTS_BULK_DATA_BY_SHOP_STAFF = 'FAIL_CHANGE_PRODUCTS_BULK_DATA_BY_SHOP_STAFF';
export const REQUEST_CHANGE_PRODUCTS_ORDER_BY_SHOP_STAFF = 'REQUEST_CHANGE_PRODUCTS_ORDER_BY_SHOP_STAFF';
export const SUCCEED_CHANGE_PRODUCTS_ORDER_BY_SHOP_STAFF = 'SUCCEED_CHANGE_PRODUCTS_ORDER_BY_SHOP_STAFF';
export const FAIL_CHANGE_PRODUCTS_ORDER_BY_SHOP_STAFF = 'FAIL_CHANGE_PRODUCTS_ORDER_BY_SHOP_STAFF';
export const REQUEST_GET_WORK_REQUEST_HISTORIES_BY_SHOP_STAFF = 'REQUEST_GET_WORK_REQUEST_HISTORIES_BY_SHOP_STAFF';
export const SUCCEED_GET_WORK_REQUEST_HISTORIES_BY_SHOP_STAFF = 'SUCCEED_GET_WORK_REQUEST_HISTORIES_BY_SHOP_STAFF';
export const FAIL_GET_WORK_REQUEST_HISTORIES_BY_SHOP_STAFF = 'FAIL_GET_WORK_REQUEST_HISTORIES_BY_SHOP_STAFF';

export const REQUEST_GET_SHOP_STAFF_BRANDS_CATEGORIES_SHOP_IN_WHATS_NEWS = 'REQUEST_GET_SHOP_STAFF_BRANDS_CATEGORIES_SHOP_IN_WHATS_NEWS';
export const SUCCEED_GET_SHOP_STAFF_BRANDS_CATEGORIES_SHOP_IN_WHATS_NEWS = 'SUCCEED_GET_SHOP_STAFF_BRANDS_CATEGORIES_SHOP_IN_WHATS_NEWS';
export const FAIL_GET_SHOP_STAFF_BRANDS_CATEGORIES_SHOP_IN_WHATS_NEWS = 'FAIL_GET_SHOP_STAFF_BRANDS_CATEGORIES_SHOP_IN_WHATS_NEWS';
export const REQUEST_GET_WHATS_NEWS_BY_SHOP_STAFF = 'REQUEST_GET_WHATS_NEWS_BY_SHOP_STAFF';
export const SUCCEED_GET_WHATS_NEWS_BY_SHOP_STAFF = 'SUCCEED_GET_WHATS_NEWS_BY_SHOP_STAFF';
export const FAIL_GET_WHATS_NEWS_BY_SHOP_STAFF = 'FAIL_GET_WHATS_NEWS_BY_SHOP_STAFF';
export const REQUEST_GET_PRODUCTS_BY_SHOP_STAFF_IN_WHATS_NEWS = 'REQUEST_GET_PRODUCTS_BY_SHOP_STAFF_IN_WHATS_NEWS';
export const SUCCEED_GET_PRODUCTS_BY_SHOP_STAFF_IN_WHATS_NEWS = 'SUCCEED_GET_PRODUCTS_BY_SHOP_STAFF_IN_WHATS_NEWS';
export const FAIL_GET_PRODUCTS_BY_SHOP_STAFF_IN_WHATS_NEWS = 'FAIL_GET_PRODUCTS_BY_SHOP_STAFF_IN_WHATS_NEWS';
export const REQUEST_GET_WHATS_NEWS_BY_SHOP_STAFF_IN_CHANGE_ORDER = 'REQUEST_GET_WHATS_NEWS_BY_SHOP_STAFF_IN_CHANGE_ORDER';
export const SUCCEED_GET_WHATS_NEWS_BY_SHOP_STAFF_IN_CHANGE_ORDER = 'SUCCEED_GET_WHATS_NEWS_BY_SHOP_STAFF_IN_CHANGE_ORDER';
export const FAIL_GET_WHATS_NEWS_BY_SHOP_STAFF_IN_CHANGE_ORDER = 'FAIL_GET_WHATS_NEWS_BY_SHOP_STAFF_IN_CHANGE_ORDER';
export const REQUEST_CHANGE_WHATS_NEWS_ORDER_BY_SHOP_STAFF = 'REQUEST_CHANGE_WHATS_NEWS_ORDER_BY_SHOP_STAFF';
export const SUCCEED_CHANGE_WHATS_NEWS_ORDER_BY_SHOP_STAFF = 'SUCCEED_CHANGE_WHATS_NEWS_ORDER_BY_SHOP_STAFF';
export const FAIL_CHANGE_WHATS_NEWS_ORDER_BY_SHOP_STAFF = 'FAIL_CHANGE_WHATS_NEWS_ORDER_BY_SHOP_STAFF';
export const REQUEST_DELETE_WHATS_NEWS_BY_SHOP_STAFF = 'REQUEST_DELETE_WHATS_NEWS_BY_SHOP_STAFF';
export const SUCCEED_DELETE_WHATS_NEWS_BY_SHOP_STAFF = 'SUCCEED_DELETE_WHATS_NEWS_BY_SHOP_STAFF';
export const FAIL_DELETE_WHATS_NEWS_BY_SHOP_STAFF = 'FAIL_DELETE_WHATS_NEWS_BY_SHOP_STAFF';
export const REQUEST_CREATE_WHATS_NEWS_BY_SHOP_STAFF = 'REQUEST_CREATE_WHATS_NEWS_BY_SHOP_STAFF';
export const SUCCEED_CREATE_WHATS_NEWS_BY_SHOP_STAFF = 'SUCCEED_CREATE_WHATS_NEWS_BY_SHOP_STAFF';
export const FAIL_CREATE_WHATS_NEWS_BY_SHOP_STAFF = 'FAIL_CREATE_WHATS_NEWS_BY_SHOP_STAFF';

export const REQUEST_GET_CAFE24_STORE = 'REQUEST_GET_CAFE24_STORE';
export const SUCCEED_GET_CAFE24_STORE = 'SUCCEED_GET_CAFE24_STORE';
export const FAIL_GET_CAFE24_STORE = 'FAIL_GET_CAFE24_STORE';
export const REQUEST_CREATE_BUILDER_SCRIPTTAG = 'REQUEST_CREATE_BUILDER_SCRIPTTAG';
export const SUCCEED_CREATE_BUILDER_SCRIPTTAG = 'SUCCEED_CREATE_BUILDER_SCRIPTTAG';
export const FAIL_CREATE_BUILDER_SCRIPTTAG = 'FAIL_CREATE_BUILDER_SCRIPTTAG';
export const REQUEST_DELETE_BUILDER_SCRIPTTAG = 'REQUEST_DELETE_BUILDER_SCRIPTTAG';
export const SUCCEED_DELETE_BUILDER_SCRIPTTAG = 'SUCCEED_DELETE_BUILDER_SCRIPTTAG';
export const FAIL_DELETE_BUILDER_SCRIPTTAG = 'FAIL_DELETE_BUILDER_SCRIPTTAG';

export const REQUEST_INITIALIZE_PRICING_BY_SHOP_STAFF = 'REQUEST_INITIALIZE_PRICING_BY_SHOP_STAFF';
export const SUCCEED_INITIALIZE_PRICING_BY_SHOP_STAFF = 'SUCCEED_INITIALIZE_PRICING_BY_SHOP_STAFF';
export const FAIL_INITIALIZE_PRICING_BY_SHOP_STAFF = 'FAIL_INITIALIZE_PRICING_BY_SHOP_STAFF'
export const REQUEST_PAY_SHOPIFY_TRIAL_BY_SHOP_STAFF = 'REQUEST_PAY_SHOPIFY_TRIAL_BY_SHOP_STAFF';
export const SUCCEED_PAY_SHOPIFY_TRIAL_BY_SHOP_STAFF = 'SUCCEED_PAY_SHOPIFY_TRIAL_BY_SHOP_STAFF';
export const FAIL_PAY_SHOPIFY_TRIAL_BY_SHOP_STAFF = 'FAIL_PAY_SHOPIFY_TRIAL_BY_SHOP_STAFF';
export const REQUEST_PAY_SHOPIFY_RECURRING_CHARGE_BY_SHOP_STAFF = 'REQUEST_PAY_SHOPIFY_RECURRING_CHARGE_BY_SHOP_STAFF';
export const SUCCEED_PAY_SHOPIFY_RECURRING_CHARGE_BY_SHOP_STAFF = 'SUCCEED_PAY_SHOPIFY_RECURRING_CHARGE_BY_SHOP_STAFF';
export const FAIL_PAY_SHOPIFY_RECURRING_CHARGE_BY_SHOP_STAFF = 'FAIL_PAY_SHOPIFY_RECURRING_CHARGE_BY_SHOP_STAFF';
export const REQUEST_PAY_SHOPIFY_ANNUAL_CHARGE_BY_SHOP_STAFF = 'REQUEST_PAY_SHOPIFY_ANNUAL_CHARGE_BY_SHOP_STAFF';
export const SUCCEED_PAY_SHOPIFY_ANNUAL_CHARGE_BY_SHOP_STAFF = 'SUCCEED_PAY_SHOPIFY_ANNUAL_CHARGE_BY_SHOP_STAFF';
export const FAIL_PAY_SHOPIFY_ANNUAL_CHARGE_BY_SHOP_STAFF = 'FAIL_PAY_SHOPIFY_ANNUAL_CHARGE_BY_SHOP_STAFF';
export const REQUEST_PAY_SHOPIFY_PRODUCT_CHARGE_BY_SHOP_STAFF = 'REQUEST_PAY_SHOPIFY_PRODUCT_CHARGE_BY_SHOP_STAFF';
export const SUCCEED_PAY_SHOPIFY_PRODUCT_CHARGE_BY_SHOP_STAFF = 'SUCCEED_PAY_SHOPIFY_PRODUCT_CHARGE_BY_SHOP_STAFF';
export const FAIL_PAY_SHOPIFY_PRODUCT_CHARGE_BY_SHOP_STAFF = 'FAIL_PAY_SHOPIFY_PRODUCT_CHARGE_BY_SHOP_STAFF';

export const REQUEST_UPDATE_AR_MODEL_TRANSFORM_INFO_BY_SHOP_STAFF = 'REQUEST_UPDATE_AR_MODEL_TRANSFORM_INFO_BY_SHOP_STAFF';
export const SUCCEED_UPDATE_AR_MODEL_TRANSFORM_INFO_BY_SHOP_STAFF = 'SUCCEED_UPDATE_AR_MODEL_TRANSFORM_INFO_BY_SHOP_STAFF';
export const FAIL_UPDATE_AR_MODEL_TRANSFORM_INFO_BY_SHOP_STAFF = 'FAIL_UPDATE_AR_MODEL_TRANSFORM_INFO_BY_SHOP_STAFF'

export const REQUEST_CREATE_BATCH_FILE_DATA_BY_SHOP_STAFF = 'REQUEST_CREATE_BATCH_FILE_DATA_BY_SHOP_STAFF';
export const SUCCEED_CREATE_BATCH_FILE_DATA_BY_SHOP_STAFF = 'SUCCEED_CREATE_BATCH_FILE_DATA_BY_SHOP_STAFF';
export const FAIL_CREATE_BATCH_FILE_DATA_BY_SHOP_STAFF = 'FAIL_CREATE_BATCH_FILE_DATA_BY_SHOP_STAFF';

export const REQUEST_UPDATE_SKIN_FILTER_VIEWER_AR_MODEL_TRANSFORM_INFO_BY_SHOP_STAFF = 'REQUEST_UPDATE_SKIN_FILTER_VIEWER_AR_MODEL_TRANSFORM_INFO_BY_SHOP_STAFF';
export const SUCCEED_UPDATE_SKIN_FILTER_VIEWER_AR_MODEL_TRANSFORM_INFO_BY_SHOP_STAFF = 'SUCCEED_UPDATE_SKIN_FILTER_VIEWER_AR_MODEL_TRANSFORM_INFO_BY_SHOP_STAFF';
export const FAIL_UPDATE_SKIN_FILTER_VIEWER_AR_MODEL_TRANSFORM_INFO_BY_SHOP_STAFF = 'FAIL_UPDATE_SKIN_FILTER_VIEWER_AR_MODEL_TRANSFORM_INFO_BY_SHOP_STAFF';

export const REQUEST_PAY_TRIAL_BY_SHOP_STAFF = 'REQUEST_PAY_TRIAL_BY_SHOP_STAFF';
export const SUCCEED_PAY_TRIAL_BY_SHOP_STAFF = 'SUCCEED_PAY_TRIAL_BY_SHOP_STAFF';
export const FAIL_PAY_TRIAL_BY_SHOP_STAFF = 'FAIL_PAY_TRIAL_BY_SHOP_STAFF';
export const REQUEST_CREATE_IAMPORT_PAYMENT = 'REQUEST_CREATE_IAMPORT_PAYMENT';
export const SUCCEED_CREATE_IAMPORT_PAYMENT = 'SUCCEED_CREATE_IAMPORT_PAYMENT';
export const FAIL_CREATE_IAMPORT_PAYMENT = 'FAIL_CREATE_IAMPORT_PAYMENT';
export const REQUEST_GET_PAYMENT_HISTORY_BY_SHOP_STAFF = 'REQUEST_GET_PAYMENT_HISTORY_BY_SHOP_STAFF';
export const SUCCEED_GET_PAYMENT_HISTORY_BY_SHOP_STAFF = 'SUCCEED_GET_PAYMENT_HISTORY_BY_SHOP_STAFF';
export const FAIL_GET_PAYMENT_HISTORY_BY_SHOP_STAFF = 'FAIL_GET_PAYMENT_HISTORY_BY_SHOP_STAFF'
export const REQUEST_DELETE_PAYMENT_HISTORY_BY_SHOP_STAFF = 'REQUEST_DELETE_PAYMENT_HISTORY_BY_SHOP_STAFF';
export const SUCCEED_DELETE_PAYMENT_HISTORY_BY_SHOP_STAFF = 'SUCCEED_DELETE_PAYMENT_HISTORY_BY_SHOP_STAFF';
export const FAIL_DELETE_PAYMENT_HISTORY_BY_SHOP_STAFF = 'FAIL_DELETE_PAYMENT_HISTORY_BY_SHOP_STAFF';
export const REQUEST_UPDATE_PAYMENT_HISTORY_BY_SHOP_STAFF = 'REQUEST_UPDATE_PAYMENT_HISTORY_BY_SHOP_STAFF';
export const SUCCEED_UPDATE_PAYMENT_HISTORY_BY_SHOP_STAFF = 'SUCCEED_UPDATE_PAYMENT_HISTORY_BY_SHOP_STAFF';
export const FAIL_UPDATE_PAYMENT_HISTORY_BY_SHOP_STAFF = 'FAIL_UPDATE_PAYMENT_HISTORY_BY_SHOP_STAFF';
export const REQUEST_PAY_IAMPORT_SCHEDULE_CHARGE_BY_SHOP_STAFF = 'REQUEST_PAY_IAMPORT_SCHEDULE_CHARGE_BY_SHOP_STAFF';
export const SUCCEED_PAY_IAMPORT_SCHEDULE_CHARGE_BY_SHOP_STAFF = 'SUCCEED_PAY_IAMPORT_SCHEDULE_CHARGE_BY_SHOP_STAFF';
export const FAIL_PAY_IAMPORT_SCHEDULE_CHARGE_BY_SHOP_STAFF = 'FAIL_PAY_IAMPORT_SCHEDULE_CHARGE_BY_SHOP_STAFF';
export const REQUEST_PAY_IAMPORT_PRODUCT_CHARGE_BY_SHOP_STAFF = 'REQUEST_PAY_IAMPORT_PRODUCT_CHARGE_BY_SHOP_STAFF';
export const SUCCEED_PAY_IAMPORT_PRODUCT_CHARGE_BY_SHOP_STAFF = 'SUCCEED_PAY_IAMPORT_PRODUCT_CHARGE_BY_SHOP_STAFF';
export const FAIL_PAY_IAMPORT_PRODUCT_CHARGE_BY_SHOP_STAFF = 'FAIL_PAY_IAMPORT_PRODUCT_CHARGE_BY_SHOP_STAFF';

/* ADMIN */
export const REQUEST_SIGNIN_MANAGER = 'REQUEST_SIGNIN_MANAGER';
export const SUCCEED_SIGNIN_MANAGER = 'SUCCEED_SIGNIN_MANAGER';
export const FAIL_SIGNIN_MANAGER = 'FAIL_SIGNIN_MANAGER';
export const REQUEST_GET_MANAGER = 'REQUEST_GET_MANAGER';
export const SUCCEED_GET_MANAGER = 'SUCCEED_GET_MANAGER';
export const FAIL_GET_MANAGER = 'FAIL_GET_MANAGER';
export const REQUEST_UPDATE_MANAGER = 'REQUEST_UPDATE_MANAGER';
export const SUCCEED_UPDATE_MANAGER = 'SUCCEED_UPDATE_MANAGER';
export const FAIL_UPDATE_MANAGER = 'FAIL_UPDATE_MANAGER';
export const REQUEST_CHANGE_MANAGER_PASSWORD = 'REQUEST_CHANGE_MANAGER_PASSWORD';
export const SUCCEED_CHANGE_MANAGER_PASSWORD = 'SUCCEED_CHANGE_MANAGER_PASSWORD';
export const FAIL_CHANGE_MANAGER_PASSWORD = 'FAIL_CHANGE_MANAGER_PASSWORD';

export const REQUEST_GET_SHOPS_BY_MANAGER_IN_PRODUCTS = 'REQUEST_GET_SHOPS_BY_MANAGER_IN_PRODUCTS';
export const SUCCEED_GET_SHOPS_BY_MANAGER_IN_PRODUCTS = 'SUCCEED_GET_SHOPS_BY_MANAGER_IN_PRODUCTS';
export const FAIL_GET_SHOPS_BY_MANAGER_IN_PRODUCTS = 'FAIL_GET_SHOPS_BY_MANAGER_IN_PRODUCTS';
export const REQUEST_GET_SHOPS_BY_MANAGER_IN_PAYMENT_HISTORIES = 'REQUEST_GET_SHOPS_BY_MANAGER_IN_PAYMENT_HISTORIES';
export const SUCCEED_GET_SHOPS_BY_MANAGER_IN_PAYMENT_HISTORIES = 'SUCCEED_GET_SHOPS_BY_MANAGER_IN_PAYMENT_HISTORIES';
export const FAIL_GET_SHOPS_BY_MANAGER_IN_PAYMENT_HISTORIES = 'FAIL_GET_SHOPS_BY_MANAGER_IN_PAYMENT_HISTORIES';
export const REQUEST_GET_PRODUCTS_BY_SHOP_STAFF_IN_SKIN_FILTER_VIEWER = 'REQUEST_GET_PRODUCTS_BY_SHOP_STAFF_IN_SKIN_FILTER_VIEWER';
export const SUCCEED_GET_PRODUCTS_BY_SHOP_STAFF_IN_SKIN_FILTER_VIEWER = 'SUCCEED_GET_PRODUCTS_BY_SHOP_STAFF_IN_SKIN_FILTER_VIEWER';
export const FAIL_GET_PRODUCTS_BY_SHOP_STAFF_IN_SKIN_FILTER_VIEWER = 'FAIL_GET_PRODUCTS_BY_SHOP_STAFF_IN_SKIN_FILTER_VIEWER';

export const REQUEST_GET_SHOPS_BY_MANAGER = 'REQUEST_GET_SHOPS_BY_MANAGER';
export const SUCCEED_GET_SHOPS_BY_MANAGER = 'SUCCEED_GET_SHOPS_BY_MANAGER';
export const FAIL_GET_SHOPS_BY_MANAGER = 'FAIL_GET_SHOPS_BY_MANAGER';
export const REQUEST_GET_SHOP_BY_MANAGER = 'REQUEST_GET_SHOP_BY_MANAGER';
export const SUCCEED_GET_SHOP_BY_MANAGER = 'SUCCEED_GET_SHOP_BY_MANAGER';
export const FAIL_GET_SHOP_BY_MANAGER = 'FAIL_GET_SHOP_BY_MANAGER';

export const REQUEST_GET_PRODUCTS_BY_MANAGER = 'REQUEST_GET_PRODUCTS_BY_MANAGER';
export const SUCCEED_GET_PRODUCTS_BY_MANAGER = 'SUCCEED_GET_PRODUCTS_BY_MANAGER';
export const FAIL_GET_PRODUCTS_BY_MANAGER = 'FAIL_GET_PRODUCTS_BY_MANAGER';
export const REQUEST_INIT_ADMIN_PRODUCT_ADD = 'REQUEST_INIT_ADMIN_PRODUCT_ADD';
export const SUCCEED_INIT_ADMIN_PRODUCT_ADD = 'SUCCEED_INIT_ADMIN_PRODUCT_ADD';
export const FAIL_INIT_ADMIN_PRODUCT_ADD = 'FAIL_INIT_ADMIN_PRODUCT_ADD';
export const REQUEST_INIT_ADMIN_PRODUCT_EDIT = 'REQUEST_INIT_ADMIN_PRODUCT_EDIT';
export const SUCCEED_INIT_ADMIN_PRODUCT_EDIT = 'SUCCEED_INIT_ADMIN_PRODUCT_EDIT';
export const FAIL_INIT_ADMIN_PRODUCT_EDIT = 'FAIL_INIT_ADMIN_PRODUCT_EDIT';
export const REQUEST_CREATE_PRODUCT_BY_MANAGER = 'REQUEST_CREATE_PRODUCT_BY_MANAGER';
export const SUCCEED_CREATE_PRODUCT_BY_MANAGER = 'SUCCEED_CREATE_PRODUCT_BY_MANAGER';
export const FAIL_CREATE_PRODUCT_BY_MANAGER = 'FAIL_CREATE_PRODUCT_BY_MANAGER';
export const REQUEST_GET_PRODUCT_BY_MANAGER = 'REQUEST_GET_PRODUCT_BY_MANAGER';
export const SUCCEED_GET_PRODUCT_BY_MANAGER = 'SUCCEED_GET_PRODUCT_BY_MANAGER';
export const FAIL_GET_PRODUCT_FOR_WORK_REQUEST_BY_MANAGER = 'FAIL_GET_PRODUCT_FOR_WORK_REQUEST_BY_MANAGER';
export const REQUEST_GET_PRODUCT_FOR_WORK_REQUEST_BY_MANAGER = 'REQUEST_GET_PRODUCT_FOR_WORK_REQUEST_BY_MANAGER';
export const SUCCEED_GET_PRODUCT_FOR_WORK_REQUEST_BY_MANAGER = 'SUCCEED_GET_PRODUCT_FOR_WORK_REQUEST_BY_MANAGER';
export const FAIL_GET_PRODUCT_BY_MANAGER = 'FAIL_GET_PRODUCT_BY_MANAGER';
export const REQUEST_INIT_PRODUCT_THREE_VIEWER_BY_MANAGER = 'REQUEST_INIT_PRODUCT_THREE_VIEWER_BY_MANAGER';
export const SUCCEED_INIT_PRODUCT_THREE_VIEWER_BY_MANAGER = 'SUCCEED_INIT_PRODUCT_THREE_VIEWER_BY_MANAGER';
export const FAIL_INIT_PRODUCT_THREE_VIEWER_BY_MANAGER = 'FAIL_INIT_PRODUCT_THREE_VIEWER_BY_MANAGER';
export const REQUEST_INIT_PRODUCT_AR_VIEWER_BY_MANAGER = 'REQUEST_INIT_PRODUCT_AR_VIEWER_BY_MANAGER';
export const SUCCEED_INIT_PRODUCT_AR_VIEWER_BY_MANAGER = 'SUCCEED_INIT_PRODUCT_AR_VIEWER_BY_MANAGER';
export const FAIL_INIT_PRODUCT_AR_VIEWER_BY_MANAGER = 'FAIL_INIT_PRODUCT_AR_VIEWER_BY_MANAGER';
export const REQUEST_UPDATE_PRODUCT_BY_MANAGER = 'REQUEST_UPDATE_PRODUCT_BY_MANAGER';
export const SUCCEED_UPDATE_PRODUCT_BY_MANAGER = 'SUCCEED_UPDATE_PRODUCT_BY_MANAGER';
export const FAIL_UPDATE_PRODUCT_BY_MANAGER = 'FAIL_UPDATE_PRODUCT_BY_MANAGER';
export const REQUEST_DELETE_PRODUCTS_BY_MANAGER = 'REQUEST_DELETE_PRODUCTS_BY_MANAGER';
export const SUCCEED_DELETE_PRODUCTS_BY_MANAGER = 'SUCCEED_DELETE_PRODUCTS_BY_MANAGER';
export const FAIL_DELETE_PRODUCTS_BY_MANAGER = 'FAIL_DELETE_PRODUCTS_BY_MANAGER';
export const REQUEST_CHANGE_PRODUCTS_BULK_DATA_BY_MANAGER = 'REQUEST_CHANGE_PRODUCTS_BULK_DATA_BY_MANAGER';
export const SUCCEED_CHANGE_PRODUCTS_BULK_DATA_BY_MANAGER = 'SUCCEED_CHANGE_PRODUCTS_BULK_DATA_BY_MANAGER';
export const FAIL_CHANGE_PRODUCTS_BULK_DATA_BY_MANAGER = 'FAIL_CHANGE_PRODUCTS_BULK_DATA_BY_MANAGER';
export const REQUEST_GET_WORK_REQUEST_HISTORIES_BY_MANAGER = 'REQUEST_GET_WORK_REQUEST_HISTORIES_BY_MANAGER';
export const SUCCEED_GET_WORK_REQUEST_HISTORIES_BY_MANAGER = 'SUCCEED_GET_WORK_REQUEST_HISTORIES_BY_MANAGER';
export const FAIL_GET_WORK_REQUEST_HISTORIES_BY_MANAGER = 'FAIL_GET_WORK_REQUEST_HISTORIES_BY_MANAGER';
export const REQUEST_UPDATE_AR_MODEL_TRANSFORM_INFO_BY_MANAGER  = 'REQUEST_UPDATE_AR_MODEL_TRANSFORM_INFO_BY_MANAGER';
export const SUCCEED_UPDATE_AR_MODEL_TRANSFORM_INFO_BY_MANAGER = 'SUCCEED_UPDATE_AR_MODEL_TRANSFORM_INFO_BY_MANAGER';
export const FAIL_UPDATE_AR_MODEL_TRANSFORM_INFO_BY_MANAGER = 'FAIL_UPDATE_AR_MODEL_TRANSFORM_INFO_BY_MANAGER';
export const REQUEST_UPDATE_THREE_MODEL_TRANSFORM_INFO_BY_MANAGER = 'REQUEST_UPDATE_THREE_MODEL_TRANSFORM_INFO_BY_MANAGER';
export const SUCCEED_UPDATE_THREE_MODEL_TRANSFORM_INFO_BY_MANAGER = 'SUCCEED_UPDATE_THREE_MODEL_TRANSFORM_INFO_BY_MANAGER';
export const FAIL_UPDATE_THREE_MODEL_TRANSFORM_INFO_BY_MANAGER = 'FAIL_UPDATE_THREE_MODEL_TRANSFORM_INFO_BY_MANAGER';
export const REQUEST_GET_PAYMENT_HISTORIES_BY_MANAGER = 'REQUEST_GET_PAYMENT_HISTORIES_BY_MANAGER';
export const SUCCEED_GET_PAYMENT_HISTORIES_BY_MANAGER = 'SUCCEED_GET_PAYMENT_HISTORIES_BY_MANAGER';
export const FAIL_GET_PAYMENT_HISTORIES_BY_MANAGER = 'FAIL_GET_PAYMENT_HISTORIES_BY_MANAGER';
export const REQUEST_GET_FACE_EFFECTS_BY_MANAGER = 'REQUEST_GET_FACE_EFFECTS_BY_MANAGER';
export const SUCCEED_GET_FACE_EFFECTS_BY_MANAGER = 'SUCCEED_GET_FACE_EFFECTS_BY_MANAGER';
export const FAIL_GET_FACE_EFFECTS_BY_MANAGER = 'FAIL_GET_FACE_EFFECTS_BY_MANAGER';
export const REQUEST_GET_ENV_IMAGE_PRESETS_BY_MANAGER = 'REQUEST_GET_ENV_IMAGE_PRESETS_BY_MANAGER';
export const SUCCEED_GET_ENV_IMAGE_PRESETS_BY_MANAGER = 'SUCCEED_GET_ENV_IMAGE_PRESETS_BY_MANAGER';
export const FAIL_GET_ENV_IMAGE_PRESETS_BY_MANAGER = 'FAIL_GET_ENV_IMAGE_PRESETS_BY_MANAGER';
export const REQUEST_GET_ENV_IMAGE_PRESETS_BY_MANAGER_IN_PRODUCT_THREE_VIEWER = 'REQUEST_GET_ENV_IMAGE_PRESETS_BY_MANAGER_IN_PRODUCT_THREE_VIEWER';
export const SUCCEED_GET_ENV_IMAGE_PRESETS_BY_MANAGER_IN_PRODUCT_THREE_VIEWER = 'SUCCEED_GET_ENV_IMAGE_PRESETS_BY_MANAGER_IN_PRODUCT_THREE_VIEWER';
export const FAIL_GET_ENV_IMAGE_PRESETS_BY_MANAGER_IN_PRODUCT_THREE_VIEWER = 'FAIL_GET_ENV_IMAGE_PRESETS_BY_MANAGER_IN_PRODUCT_THREE_VIEWER';
export const REQUEST_UPDATE_THREE_MODEL_TRANSFORM_INFO_BY_MANAGER_IN_PRODUCT_THREE_VIEWER = 'REQUEST_UPDATE_THREE_MODEL_TRANSFORM_INFO_BY_MANAGER_IN_PRODUCT_THREE_VIEWER';
export const SUCCEED_UPDATE_THREE_MODEL_TRANSFORM_INFO_BY_MANAGER_IN_PRODUCT_THREE_VIEWER = 'SUCCEED_UPDATE_THREE_MODEL_TRANSFORM_INFO_BY_MANAGER_IN_PRODUCT_THREE_VIEWER';
export const FAIL_UPDATE_THREE_MODEL_TRANSFORM_INFO_BY_MANAGER_IN_PRODUCT_THREE_VIEWER = 'REQUEST_UPDATE_THREE_MODEL_TRANSFORM_INFO_BY_MANAGER_IN_PRODUCT_THREE_VIEWER';