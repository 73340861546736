import {fromJS} from "immutable";
import {
    SAMPLE_WEB_AR_CLEAR,
    SAMPLE_WEB_AR_SET_INITIALIZED,
    SAMPLE_WEB_AR_SET_IS_WEB_AR_POPUP_SHOWN,
} from "../actions/UIActionTypes";

export const initialSampleWebAr = fromJS({
    isInitialized: false,
    isWebArPopupShown: false,
});

export function sampleWebAr(state = initialSampleWebAr, action) {
    switch (action.type) {
        case SAMPLE_WEB_AR_CLEAR:
            return initialSampleWebAr;

        case SAMPLE_WEB_AR_SET_INITIALIZED:
            return state.set('isInitialized', true);

        case SAMPLE_WEB_AR_SET_IS_WEB_AR_POPUP_SHOWN:
            return state.set('isWebArPopupShown', action.isWebArPopupShown);

        default:
            return state;
    }
}
