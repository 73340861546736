import {List} from "immutable";
import {indexOfCountryCode, indexOfLanguageCode} from "../utils/ListUtils";
import I18NLanguageProductOption from "../models/I18NLanguageProductOption";
import I18NLanguageArModelTransformInfo from "../models/I18NLanguageArModelTransformInfo";
import I18NCountryProductOption from "../models/I18NCountryProductOption";

export function addLanguage2Options(options, languageCode) {
    return List(options.map(item => {
        const i18nLanguages = item.get('i18n_languages');
        const indexOfLanguage = indexOfLanguageCode(i18nLanguages, languageCode);

        if (indexOfLanguage < 0) {
            item = item.set(
                'i18n_languages', i18nLanguages.push(new I18NLanguageProductOption({language_code: languageCode}))
            );
            const newArMtis = item.get('armtis').map(arMti => {
                return arMti.set(
                    'i18n_languages',
                    arMti.get('i18n_languages')
                        .push(new I18NLanguageArModelTransformInfo({language_code: languageCode, name: 'Default'}))
                );
            });

            item = item.set('armtis', newArMtis);
        }

        return item;
    }));
}

export function deleteLanguageInOptions(options, languageCode) {
    const newOptions = List(options.map(item => {
        const i18nLanguages = item.get('i18n_languages');
        const indexOfLanguages = indexOfLanguageCode(i18nLanguages, languageCode);

        if (indexOfLanguages >= 0) {
            item = item.set('i18n_languages', i18nLanguages.delete(indexOfLanguages));
            const newArMtis = item.get('armtis').map(arMti => {
                return arMti.set('i18n_languages', arMti.get('i18n_languages').delete(indexOfLanguages));
            });

            item = item.set('armtis', newArMtis);
        }

        return item;
    }));

    return {newOptions};
}

export function changeDefaultLanguageInOptions(options, defaultLanguageCode) {
    return List(options.map(item => {
        const newLanguages = List(item.get('i18n_languages').map(item => {
            return item.set('is_default', item.get('language_code') === defaultLanguageCode);
        }));

        const newArMtis = List(item.get('armtis').map(item => {
            const newArMtisLangs = item.get('i18n_languages').map(i18n => {
                return i18n.set('is_default', i18n.get('language_code') === defaultLanguageCode);
            });

            return item.set('i18n_languages', newArMtisLangs)

        }));

        return item.set('i18n_languages', newLanguages).set('armtis', newArMtis);
    }));
}

export function addCountry2Options(options, countryCode) {
    return List(options.map(item => {
        const i18nCountries = item.get('i18n_countries');
        const index = indexOfCountryCode(i18nCountries, countryCode);

        if (index < 0) {
            return item.set(
                'i18n_countries', i18nCountries.push(new I18NCountryProductOption({country_code: countryCode}))
            );
        } else {
            return item;
        }
    }));
}

export function deleteCountryInOptions(options, countryCode) {
    const newOptions = List(options.map(item => {
        const i18nCountries = item.get('i18n_countries');
        const index = indexOfCountryCode(i18nCountries, countryCode);

        if (index >= 0) {
            return item.set('i18n_countries', i18nCountries.delete(index));
        } else {
            return item;
        }
    }));

    return {newOptions};
}

export function changeDefaultCountryInOptions(options, defaultCountryCode) {
    return List(options.map(item => {
        const newCountries = List(item.get('i18n_countries').map(item => {
            return item.set('is_default', item.get('country_code') === defaultCountryCode);
        }));

        return item.set('i18n_countries', newCountries);
    }));
}

export function changeProductOptionColor(options, productOptionColors, index, newProductColor) {
    const oldbieOption = options.get(index);
    let count = 0;

    options.some(item => {
        const color = item.get('color');

        if (color && color.equals(oldbieOption.get('color'))) {
            count++;
        }
        return count > 1;
    });

    if (count === 1) {
        const indexOfOldbieOption = productOptionColors.findIndex(item =>
            item.equals(oldbieOption.get('color'))
        );

        if (indexOfOldbieOption >= 0) {
            productOptionColors = productOptionColors.delete(indexOfOldbieOption);
        }
    }

    if (!productOptionColors.includes(newProductColor)) {
        productOptionColors = productOptionColors.push(newProductColor);
    }

    options = options.update(index, oldbie =>
        oldbie.set('color_id', newProductColor.get('id')).set('color', newProductColor)
    );

    return [options, productOptionColors];
}

export function getOptionIndex(options, centerOptionIndex) {
    let optionIndex = 0;

    if (options.size < 5) {
        if (options.size === 3) {
            optionIndex = centerOptionIndex;
        } else {
            optionIndex = 0;
        }

    } else {
        optionIndex = 0;
    }

    return optionIndex;
}

export function getOptionsByChangeOrder(options, startIndex, endIndex) {
    options = options.map((item) => {
        let order = item.get('order');

        if (order === startIndex) {
            item = item.set('order', endIndex);
        } else if (startIndex > endIndex) {
            if (order < startIndex && order >= endIndex) {
                item = item.set('order', item.get('order') + 1);
            }
        } else {
            if (order > startIndex && order <= endIndex) {
                item = item.set('order', order > 0 ? order - 1 : 0);
            }
        }
        return item;
    }).sort((a, b) => a.get('order') - b.get('order'))


    return options;
}