import {
    FAIL_GET_PRODUCT_BY_SHOP_STAFF,
    FAIL_GET_PRODUCT_BY_SHOP_STAFF_FOR_AR_VIEWER,
    FAIL_GET_PRODUCTS_BY_SHOP_STAFF,
    FAIL_INIT_PRODUCT_THREE_VIEWER_BY_SHOP_STAFF,
    REQUEST_CHANGE_PRODUCTS_BULK_DATA_BY_SHOP_STAFF,
    REQUEST_CHANGE_PRODUCTS_ORDER_BY_SHOP_STAFF,
    REQUEST_CREATE_BATCH_FILE_DATA_BY_SHOP_STAFF,
    REQUEST_CREATE_PRODUCT_BY_SHOP_STAFF,
    REQUEST_DELETE_PRODUCTS_BY_SHOP_STAFF,
    REQUEST_GET_PRODUCT_BY_SHOP_STAFF,
    REQUEST_GET_PRODUCT_BY_SHOP_STAFF_FOR_AR_VIEWER,
    REQUEST_GET_PRODUCTS_BY_SHOP_STAFF,
    REQUEST_GET_WORK_REQUEST_HISTORIES_BY_SHOP_STAFF,
    REQUEST_INIT_PRODUCT_THREE_VIEWER_BY_SHOP_STAFF,
    REQUEST_INIT_SHOP_STAFF_PRODUCT_ADD,
    REQUEST_INIT_SHOP_STAFF_PRODUCT_EDIT,
    REQUEST_UPDATE_AR_MODEL_TRANSFORM_INFO_BY_SHOP_STAFF,
    REQUEST_UPDATE_PRODUCT_BY_SHOP_STAFF,
    REQUEST_COPY_SAMPLE_PRODUCTS_BY_SHOP_STAFF,
    SUCCEED_GET_PRODUCT_BY_SHOP_STAFF,
    SUCCEED_GET_PRODUCT_BY_SHOP_STAFF_FOR_AR_VIEWER,
    SUCCEED_GET_PRODUCTS_BY_SHOP_STAFF,
    SUCCEED_INIT_PRODUCT_THREE_VIEWER_BY_SHOP_STAFF,
    SUCCEED_INIT_SHOP_STAFF_PRODUCT_ADD,
    SUCCEED_INIT_SHOP_STAFF_PRODUCT_EDIT,
    SUCCEED_UPDATE_AR_MODEL_TRANSFORM_INFO_BY_SHOP_STAFF,
    REQUEST_GET_WHATS_NEWS_BY_SHOP_STAFF,
    SUCCEED_GET_WHATS_NEWS_BY_SHOP_STAFF,
    FAIL_GET_WHATS_NEWS_BY_SHOP_STAFF,
    REQUEST_CHANGE_WHATS_NEWS_ORDER_BY_SHOP_STAFF,
    REQUEST_DELETE_WHATS_NEWS_BY_SHOP_STAFF,
    REQUEST_CREATE_WHATS_NEWS_BY_SHOP_STAFF,
} from "../RESTActionTypes";

export function getShopStaffBrandsAndCategories(shopStaffInfo, type, succeedType, failType) {
    return {type, shopStaffInfo, succeedType, failType};
}

export function loadShopStaffBrandsAndCategories(succeedType, brandsResponse, categoriesResponse, shopResponse) {
    return {
        type: succeedType,
        brands: brandsResponse.response.results,
        categories: categoriesResponse.response.results,
        shop: shopResponse.response.result,
    };
}

export function getProduct(
    shopStaffInfo,
    productId,
    type = REQUEST_GET_PRODUCT_BY_SHOP_STAFF,
    succeedType = SUCCEED_GET_PRODUCT_BY_SHOP_STAFF,
    failType = FAIL_GET_PRODUCT_BY_SHOP_STAFF,
) {
    return {type, succeedType, failType, shopStaffInfo, productId};
}

export function getProducts(
    shopStaffInfo,
    queryParams,
    type = REQUEST_GET_PRODUCTS_BY_SHOP_STAFF,
    succeedType = SUCCEED_GET_PRODUCTS_BY_SHOP_STAFF,
    failType = FAIL_GET_PRODUCTS_BY_SHOP_STAFF,
) {
    return {type, shopStaffInfo, queryParams, succeedType, failType};
}

export function getWhatsNews(
    shopStaffInfo,
    queryParams,
    type = REQUEST_GET_WHATS_NEWS_BY_SHOP_STAFF,
    succeedType = SUCCEED_GET_WHATS_NEWS_BY_SHOP_STAFF,
    failType = FAIL_GET_WHATS_NEWS_BY_SHOP_STAFF
) {
    return {type, shopStaffInfo, queryParams, succeedType, failType};
}

export function initShopStaffProductAdd(shopStaffInfo) {
    return {type: REQUEST_INIT_SHOP_STAFF_PRODUCT_ADD, shopStaffInfo};
}

export function loadShopStaffProductAdd(readyProductDataResponse) {
    const {brands, categories, product_sizes, product_colors, shop} = readyProductDataResponse.response.result;

    return {type: SUCCEED_INIT_SHOP_STAFF_PRODUCT_ADD, brands, categories, product_sizes, product_colors, shop};
}

export function initShopStaffProductEdit(shopStaffInfo, productId) {
    return {type: REQUEST_INIT_SHOP_STAFF_PRODUCT_EDIT, shopStaffInfo, productId};
}

export function loadShopStaffProductEdit(readyProductDataResponse, productResponse) {
    const {brands, categories, product_sizes, product_colors, shop} = readyProductDataResponse.response.result;

    return {
        type: SUCCEED_INIT_SHOP_STAFF_PRODUCT_EDIT,
        brands,
        categories,
        product_sizes,
        product_colors,
        shop,
        product: productResponse.response.result,
    };
}

export function importBuilderProductInfo(shopStaffInfo, categoryUid, productUid, type, succeedType, failType) {
    return {type, shopStaffInfo, categoryUid, productUid, succeedType, failType};
}

export function loadedImportBuilderProductInfo(succeedType, response) {
    const {product, options} = response.result;

    return {type: succeedType, product, options};
}

export function createProduct(shopStaffInfo, formData) {
    return {type: REQUEST_CREATE_PRODUCT_BY_SHOP_STAFF, shopStaffInfo, formData};
}

export function createWhatsNews(shopStaffInfo, productIds) {
    return {type: REQUEST_CREATE_WHATS_NEWS_BY_SHOP_STAFF, shopStaffInfo, productIds};
}

export function getProduct4WorkRequest(
    shopStaffInfo,
    productId,
    type = REQUEST_GET_PRODUCT_BY_SHOP_STAFF,
    succeedType = SUCCEED_GET_PRODUCT_BY_SHOP_STAFF,
    failType = FAIL_GET_PRODUCT_BY_SHOP_STAFF,
) {
    return {shopStaffInfo, productId, type, succeedType, failType};
}

export function getProduct4ArViewer(
    shopStaffInfo,
    productId,
    type = REQUEST_GET_PRODUCT_BY_SHOP_STAFF_FOR_AR_VIEWER,
    succeedType = SUCCEED_GET_PRODUCT_BY_SHOP_STAFF_FOR_AR_VIEWER,
    failType = FAIL_GET_PRODUCT_BY_SHOP_STAFF_FOR_AR_VIEWER,
) {
    return {shopStaffInfo, productId, type, succeedType, failType};
}

export function updateProduct(shopStaffInfo, productId, formData) {
    return {type: REQUEST_UPDATE_PRODUCT_BY_SHOP_STAFF, shopStaffInfo, productId, formData};
}

export function updateArModelTransformInfo(shopStaffInfo, productId, productOptionId, armtiId, formData) {
    return {
        type: REQUEST_UPDATE_AR_MODEL_TRANSFORM_INFO_BY_SHOP_STAFF,
        shopStaffInfo,
        productId,
        productOptionId,
        armtiId,
        formData,
    };
}

export function deleteProducts(shopStaffInfo, productIds) {
    return {type: REQUEST_DELETE_PRODUCTS_BY_SHOP_STAFF, shopStaffInfo, productIds};
}

export function deleteWhatsNews(shopStaffInfo, whatsNewIds) {
    return {type: REQUEST_DELETE_WHATS_NEWS_BY_SHOP_STAFF, shopStaffInfo, whatsNewIds};
}

export function changeProductsBulkData(shopStaffInfo, productIds, key, value) {
    return {type: REQUEST_CHANGE_PRODUCTS_BULK_DATA_BY_SHOP_STAFF, shopStaffInfo, productIds, key, value};
}

export function changeProductsOrder(shopStaffInfo, orderItems) {
    return {type: REQUEST_CHANGE_PRODUCTS_ORDER_BY_SHOP_STAFF, shopStaffInfo, orderItems};
}

export function changeWhatsNewsOrder(shopStaffInfo, orderItems) {
    return {type: REQUEST_CHANGE_WHATS_NEWS_ORDER_BY_SHOP_STAFF, shopStaffInfo, orderItems};
}

export function initShopStaffProductThreeViewer(
    shopStaffInfo,
    productId,
    type = REQUEST_INIT_PRODUCT_THREE_VIEWER_BY_SHOP_STAFF,
    succeedType = SUCCEED_INIT_PRODUCT_THREE_VIEWER_BY_SHOP_STAFF,
    failType = FAIL_INIT_PRODUCT_THREE_VIEWER_BY_SHOP_STAFF,
) {
    return {type, shopStaffInfo, productId, succeedType, failType};
}

export function loadShopStaffProductThreeViewer(succeedType, shopResponse, productResponse) {
    return {
        type: succeedType,
        shop: shopResponse.response.result,
        product: productResponse.response.result,
    };
}

export function loadUpdateArModelTransformInfo(productOptionId, armtiId, response) {
    return {
        type: SUCCEED_UPDATE_AR_MODEL_TRANSFORM_INFO_BY_SHOP_STAFF,
        arMti: response.result,
        productOptionId,
        armtiId,
    }
}

export function getWorkRequestHistories(page, shopStaffInfo, workRequestId) {
    return {
        type: REQUEST_GET_WORK_REQUEST_HISTORIES_BY_SHOP_STAFF,
        page,
        shopStaffInfo,
        workRequestId
    };
}

export function createBatchFileData(shopStaffInfo, formData) {
    return {type: REQUEST_CREATE_BATCH_FILE_DATA_BY_SHOP_STAFF, shopStaffInfo, formData};
}

export function copySampleProducts(shopStaffInfo, categoryUid) {
    return {type: REQUEST_COPY_SAMPLE_PRODUCTS_BY_SHOP_STAFF, shopStaffInfo, categoryUid};
}