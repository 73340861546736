import {ofType} from "redux-observable";
import {catchError, map, mergeMap} from "rxjs/operators";
import {
    FAIL_CREATE_IAMPORT_PAYMENT,
    REQUEST_CREATE_IAMPORT_PAYMENT, SUCCEED_CREATE_IAMPORT_PAYMENT

} from "../actions/RESTActionTypes";
import {loadCmsAPI, occurError} from "../actions/RESTAction";
import {of} from "rxjs";
import {createIamportPayment} from "../../rest/IamportsAPI";

export const createIamportPaymentEpic = action$ => action$.pipe(
    ofType(REQUEST_CREATE_IAMPORT_PAYMENT),
    mergeMap(action => createIamportPayment(action.shopStaffInfo, action.payload).pipe(
        map(res =>  loadCmsAPI(SUCCEED_CREATE_IAMPORT_PAYMENT, res.response)),
        catchError(error => of(occurError(FAIL_CREATE_IAMPORT_PAYMENT, error.xhr)))
    ))
);