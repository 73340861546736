import {
    FAIL_GET_PRODUCT_BY_MANAGER,
    REQUEST_GET_PRODUCT_BY_MANAGER,
    REQUEST_UPDATE_AR_MODEL_TRANSFORM_INFO_BY_MANAGER,
    SUCCEED_GET_PRODUCT_BY_MANAGER,
    SUCCEED_UPDATE_AR_MODEL_TRANSFORM_INFO_BY_MANAGER
} from "../RESTActionTypes";

export function getProduct(
    managerInfo,
    productId,
    type = REQUEST_GET_PRODUCT_BY_MANAGER,
    succeedType = SUCCEED_GET_PRODUCT_BY_MANAGER,
    failType = FAIL_GET_PRODUCT_BY_MANAGER,
) {
    return {type, succeedType, failType, managerInfo, productId};
}

export function updateArModelTransformInfo(managerInfo, productId, productOptionId, armtiId, formData) {
    return {
        type: REQUEST_UPDATE_AR_MODEL_TRANSFORM_INFO_BY_MANAGER,
        managerInfo,
        productId,
        productOptionId,
        armtiId,
        formData,
    };
}

export function loadUpdateArModelTransformInfo(productOptionId, armtiId, response) {
    return {
        type: SUCCEED_UPDATE_AR_MODEL_TRANSFORM_INFO_BY_MANAGER,
        arMti: response.result,
        productOptionId,
        armtiId,
    }
}
