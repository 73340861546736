import {EntityMixin} from "./Entity";
import {List} from "immutable";
import I18NLanguageProductSize from "./I18NLanguageProductSize";
import {LANG_KOREAN} from "../utils/LocaleUtils";

const ProductSizeRecord = EntityMixin({
    shop_id: 0,
    name: '',
    i18n_languages: List(),
});

class ProductSize extends ProductSizeRecord {

    constructor(args = {}) {
        super({
            ...args,
            i18n_languages: args.i18n_languages
                ? List(args.i18n_languages.map(item => new I18NLanguageProductSize(item))) : List(),
        });
    }

    findDefaultLanguageCode() {
        const i18nLanguage = this.i18n_languages.find(item => item.get('is_default'));

        return i18nLanguage ? i18nLanguage.get('language_code') : LANG_KOREAN;
    }

    canAdd() {
        return this.i18n_languages.size > 0 && this.i18n_languages.every(item => item.canAdd());
    }

    canEdit(oldbie) {
        if (!oldbie) {
            return false;
        }

        const oldbieI18nLanguages = oldbie.get('i18n_languages');
        let canI18nLanguagesEdit = false;

        if (this.i18n_languages.equals(oldbieI18nLanguages)) {
            let canAllAdd = true;

            for (let i=0; i < this.i18n_languages.size; i++) {
                const item = this.i18n_languages.get(i);

                if (!item.canAdd()) {
                    canAllAdd = false;
                    break;
                }

                if (!canI18nLanguagesEdit) {
                    canI18nLanguagesEdit = !item.isSame(oldbieI18nLanguages.get(i));
                }
            }

            if (!canAllAdd) {
                canI18nLanguagesEdit = false;
            }
        } else {
            canI18nLanguagesEdit = this.i18n_languages.every(item => item.canAdd());
        }

        return canI18nLanguagesEdit;
    }

    makeFormData(oldbie = null) {
        const formData = new FormData();

        this.i18n_languages.forEach(item => {
            item.makeFormData(formData, oldbie)
        });

        return formData;
    }
}

export default ProductSize;
